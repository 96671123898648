import { intToExcelCol } from "excel-column-name";
import Excel from "exceljs";
import _ from "lodash";

import {
  evaluateStudents,
  computeBMIPointResult,
  computeHeightPointResult,
  computeWeightLengthPointResult,
  computeWeightPointResult,
  round,
} from "../helpers";
import { writeHeaders, writeReport, writeCell, writeRange } from "./common";

const chatluonggiaoduc = async (
  allMonthData,
  activeYear,
  solieucando,
  unitName,
  useDay,
  eduQualityList,
  month,
  errorHandler,
  closeHandler,
  onComplete,
  sgd
) => {
  const addPrefix = (val) => {
    return val > 0 ? `+${val}` : val;
  };

  const keyMappingLeft = [
    "BTWeight",
    "NC1",
    "NC2",
    "BTHeight",
    "TC1",
    "TC2",
    "BTBmiWeiHei",
    "DC",
    "BP",
    "GC1",
    "GC2",
  ];
  const keyMappingRight = [
    "WeightInc",
    "WeightDec",
    "constWeight",
    "HeightInc",
    "constHeight",
    "tyLeBeCham",
    "soBeNgoan",
    "tyLeBeNgoan",
  ];
  const BeginCounter = {
    ALL: {},
    NT: {},
    group3t4t: {},
    group4t5t: {},
    group5t6t: {},
  };
  const beginningYearData = _.sortBy(allMonthData, [
    "order",
  ]).find(({ order, mth, ...rest }) =>
    Object.values(rest).some(
      ({ students }) => students && Object.keys(students).length
    )
  );

  if (month !== beginningYearData.mth) {
    _.forEach(
      ["ALL", "NT", "group3t4t", "group4t5t", "group5t6t"],
      (counterKey) => {
        _.forEach(
          [
            "BTWeight",
            "NC1",
            "NC2",
            "BTHeight",
            "TC1",
            "TC2",
            "BTBmiWeiHei",
            "DC",
            "BP",
            "GC1",
            "GC2",
            "total",
            "female",
            "checked",
            "HeightInc",
            "constHeight",
            "WeightInc",
            "WeightDec",
            "constWeight",
            "soBeCham",
            "tyLeBeCham",
            "soBeNgoan",
            "tyLeBeNgoan",
            "numberOfClasses",
          ],
          (key) => {
            _.set(BeginCounter, `${counterKey}.${key}`, 0);
          }
        );
      }
    );
    _.forEach(beginningYearData, (classData) => {
      const { day, students, grade } = classData;
      const eduQuality = _.get(classData, "eduQuality");
      if (_.get(classData, "students")) {
        let groupKey = "NT";
        if (grade.includes("3-4tuoi")) groupKey = "group3t4t";
        else if (grade.includes("4-5tuoi")) groupKey = "group4t5t";
        else if (grade.includes("5-6tuoi")) groupKey = "group5t6t";
        if (eduQuality) {
          _.forEach(eduQuality, (val, eduQualityKey) => {
            BeginCounter[groupKey][eduQualityKey] += parseFloat(val);
            BeginCounter.ALL[eduQualityKey] += parseFloat(val);
          });
        }
        if (grade.includes("5-6tuoi"))
          BeginCounter.group5t6t.numberOfClasses += 1;
        else if (grade.includes("4-5tuoi"))
          BeginCounter.group4t5t.numberOfClasses += 1;
        else if (grade.includes("3-4tuoi"))
          BeginCounter.group3t4t.numberOfClasses += 1;
        else BeginCounter.NT.numberOfClasses += 1;
        BeginCounter.ALL.numberOfClasses += 1;
        BeginCounter[groupKey].total += _.keys(students).length;
        BeginCounter[groupKey].checked += _.filter(
          students,
          (student) => student.weight && student.height
        ).length;
        BeginCounter[groupKey].female += _.filter(
          students,
          (student) => student.gender === "F"
        ).length;
        BeginCounter.ALL.total += _.keys(students).length;
        BeginCounter.ALL.checked += _.filter(
          students,
          (student) => student.weight && student.height
        ).length;
        BeginCounter.ALL.female += _.filter(
          students,
          (student) => student.gender === "F"
        ).length;
        const evaluatedStudents = evaluateStudents(
          students,
          9,
          activeYear,
          solieucando,
          day,
          useDay,
          sgd
        ).map((student) => {
          const weightPoint =
            student.weightPoint !== undefined
              ? computeWeightPointResult(student.weightPoint)
              : "";
          const heightPoint =
            student.heightPoint !== undefined
              ? computeHeightPointResult(student.heightPoint)
              : "";
          const bmiPoint =
            student.bmiPoint !== undefined
              ? computeBMIPointResult(student.bmiPoint)
              : "";
          const weightLengthPoint =
            student.weightLengthPoint !== undefined
              ? computeWeightLengthPointResult(student.weightLengthPoint)
              : "";
          return {
            ...student,
            weightPoint,
            heightPoint,
            bmiPoint,
            weightLengthPoint,
          };
        });
        _.forEach(
          evaluatedStudents,
          ({ weightPoint, heightPoint, bmiPoint, weightLengthPoint }) => {
            if (weightPoint === "BT" || weightPoint === "BT+") {
              BeginCounter[groupKey].BTWeight += 1;
              BeginCounter.ALL.BTWeight += 1;
            } else if (_.has(BeginCounter[groupKey], weightPoint)) {
              BeginCounter[groupKey][weightPoint] += 1;
              BeginCounter.ALL[weightPoint] += 1;
            }
            if (heightPoint === "BT" || heightPoint === "BT+") {
              BeginCounter[groupKey].BTHeight += 1;
              BeginCounter.ALL.BTHeight += 1;
            } else if (_.has(BeginCounter[groupKey], heightPoint)) {
              BeginCounter[groupKey][heightPoint] += 1;
              BeginCounter.ALL[heightPoint] += 1;
            }
            if ((weightLengthPoint || bmiPoint) === "BT") {
              BeginCounter[groupKey].BTBmiWeiHei += 1;
              BeginCounter.ALL.BTBmiWeiHei += 1;
            } else if (
              _.has(BeginCounter[groupKey], weightLengthPoint || bmiPoint)
            ) {
              BeginCounter[groupKey][weightLengthPoint || bmiPoint] += 1;
              BeginCounter.ALL[weightLengthPoint || bmiPoint] += 1;
            }
          }
        );
      }
    });
  }
  const monthData = _.get(allMonthData, month);
  const wb = new Excel.Workbook();
  const pageSetup = {
    scale: 70,
    orientation: "landscape",
    paperSize: 9,
    horizontalCentered: true,
    margins: {
      left: 0.32,
      right: 0.25,
      top: 0.85,
      bottom: 0.28,
      header: 0.2,
      footer: 0.24,
    },
  };
  const sheet1 = wb.addWorksheet(`Tháng ${month}`, {
    pageSetup,
  });
  const nhaTreSheet = wb.addWorksheet("Nhà trẻ", {
    pageSetup,
  });
  const sheet3t4t = wb.addWorksheet("3-4 tuổi", {
    pageSetup,
  });
  const sheet4t5t = wb.addWorksheet("4-5 tuổi", {
    pageSetup,
  });
  const sheet5t6t = wb.addWorksheet("5-6 tuổi", {
    pageSetup,
  });
  _.forEach([sheet1, nhaTreSheet, sheet3t4t, sheet4t5t, sheet5t6t], (sheet) => {
    sheet.getRow(1).height = 17;
    sheet.getRow(2).height = 17;
    sheet.getRow(3).height = 17;
    sheet.getRow(4).height = 12.75;
    sheet.getRow(5).height = 22.5;
    sheet.getRow(6).height = 22.5;
    sheet.getRow(7).height = 22.5;
    sheet.getRow(8).height = 30;
    sheet.getRow(9).height = 37.5;
    sheet.getRow(10).height = 11.25;
  });
  const rows = [];
  const counter = {
    NT: { name: "Nhà trẻ" },
    MG: { name: "Mẫu giáo" },
    ALL: { name: "Toàn trường" },
    group3t4t: { name: "3-4 tuổi" },
    group4t5t: { name: "4-5 tuổi" },
    group5t6t: { name: " 5-6 tuổi" },
  };
  _.forEach(eduQualityList, (data, classID) => {
    const classData = _.get(monthData, classID);
    const hasEduQuality = !_.every(data, (val) => val === "");
    if (data && _.get(classData, "students") && hasEduQuality) {
      const { soBeCham, tyLeBeCham, soBeNgoan, tyLeBeNgoan } = data;
      const subCounter = {
        NC1: 0,
        NC2: 0,
        TC1: 0,
        TC2: 0,
        GC1: 0,
        GC2: 0,
        BP: 0,
        DC: 0,
        BTWeight: 0,
        BTHeight: 0,
        BTBmiWeiHei: 0,
        total: 0,
        female: 0,
        checked: 0,
        HeightInc: 0,
        constHeight: 0,
        WeightInc: 0,
        WeightDec: 0,
        constWeight: 0,
        soBeCham,
        tyLeBeCham,
        soBeNgoan,
        tyLeBeNgoan,
      };
      const { day, students, name, teachers, grade } = classData;
      let teacherNames = "";
      const filteredTeachers = _.filter(
        teachers,
        (teacher) => _.keys(teacher).length
      );
      if (filteredTeachers.length > 1) {
        _.forEach(filteredTeachers, (teacher) => {
          teacherNames += `${_.get(teacher, "displayName", "")}\n`;
        });
      } else teacherNames = _.get(_.head(filteredTeachers), "displayName", "");
      subCounter.total = _.keys(students).length;
      subCounter.checked = _.filter(
        students,
        (student) => student.weight && student.height
      ).length;
      subCounter.female = _.filter(
        students,
        (student) => student.gender === "F"
      ).length;
      if (month !== beginningYearData.mth && month !== 1 && month !== 5) {
        subCounter.BTHeight = _.keys(students).length;
        subCounter.BTWeight = _.keys(students).length;
        subCounter.BTBmiWeiHei = _.keys(students).length;
      }
      const evaluatedStudents = evaluateStudents(
        students,
        parseFloat(month),
        activeYear,
        solieucando,
        day,
        useDay,
        sgd
      ).map((student) => {
        const weightPoint =
          student.weightPoint !== undefined
            ? computeWeightPointResult(student.weightPoint)
            : "";
        const heightPoint =
          student.heightPoint !== undefined
            ? computeHeightPointResult(student.heightPoint)
            : "";
        const bmiPoint =
          student.bmiPoint !== undefined
            ? computeBMIPointResult(student.bmiPoint)
            : "";
        const weightLengthPoint =
          student.weightLengthPoint !== undefined
            ? computeWeightLengthPointResult(student.weightLengthPoint)
            : "";
        return {
          ...student,
          weightPoint,
          heightPoint,
          bmiPoint,
          weightLengthPoint,
        };
      });
      _.forEach(
        evaluatedStudents,
        ({
          weightPoint,
          heightPoint,
          bmiPoint,
          weightLengthPoint,
          studentID,
          height,
          weight,
        }) => {
          const { height: preHeight, weight: preWeight } = _.get(
            _.get(allMonthData, 9),
            `${classID}.students.${studentID}`,
            {}
          );
          if (
            preHeight &&
            preWeight &&
            beginningYearData.mth !== month &&
            height &&
            weight
          ) {
            if (parseFloat(height) - parseFloat(preHeight) > 0)
              subCounter.HeightInc += 1;
            else if (parseFloat(height) === parseFloat(preHeight))
              subCounter.constHeight += 1;
            if (parseFloat(weight) - parseFloat(preWeight) > 0)
              subCounter.WeightInc += 1;
            else if (parseFloat(weight) - parseFloat(preWeight) < 0)
              subCounter.WeightDec += 1;
            else if (parseFloat(weight) === parseFloat(preWeight))
              subCounter.constWeight += 1;
          }
          if (month === beginningYearData.mth || month === 1 || month === 5) {
            if (weightPoint === "BT" || weightPoint === "BT+")
              subCounter.BTWeight += 1;
            else if (_.has(subCounter, weightPoint))
              subCounter[weightPoint] += 1;
            if (heightPoint === "BT" || heightPoint === "BT+")
              subCounter.BTHeight += 1;
            else if (_.has(subCounter, heightPoint))
              subCounter[heightPoint] += 1;
            if ((weightLengthPoint || bmiPoint) === "BT")
              subCounter.BTBmiWeiHei += 1;
            else if (_.has(subCounter, weightLengthPoint || bmiPoint))
              subCounter[weightLengthPoint || bmiPoint] += 1;
          } else {
            if (weightPoint === "NC1") {
              subCounter.NC1 += 1;
              subCounter.BTWeight -= 1;
            }
            if (weightPoint === "NC2") {
              subCounter.NC2 += 1;
              subCounter.BTWeight -= 1;
            }
            if (heightPoint === "TC1") {
              subCounter.TC1 += 1;
              subCounter.BTHeight -= 1;
            }
            if (heightPoint === "TC2") {
              subCounter.TC2 += 1;
              subCounter.BTHeight -= 1;
            }
            if (
              (weightLengthPoint || bmiPoint) !== "BT" &&
              _.has(subCounter, weightLengthPoint || bmiPoint)
            ) {
              subCounter[weightLengthPoint || bmiPoint] += 1;
              subCounter.BTBmiWeiHei -= 1;
            }
            if (
              _.has(subCounter, weightPoint) ||
              _.has(subCounter, heightPoint)
            ) {
              subCounter.BTBmiWeiHei =
                subCounter.BTBmiWeiHei > 0 ? subCounter.BTBmiWeiHei - 1 : 0;
            }
          }
        }
      );
      rows.push({
        day,
        students: evaluatedStudents,
        month,
        subCounter,
        name,
        teacherNames,
        grade,
      });
    }
  });

  _.forEach(
    [
      "BTWeight",
      "NC1",
      "NC2",
      "BTHeight",
      "TC1",
      "TC2",
      "BTBmiWeiHei",
      "DC",
      "BP",
      "GC1",
      "GC2",
      "total",
      "female",
      "checked",
      "HeightInc",
      "constHeight",
      "WeightInc",
      "WeightDec",
      "constWeight",
      "soBeCham",
      "tyLeBeCham",
      "soBeNgoan",
      "tyLeBeNgoan",
      "numberOfClasses",
    ],
    (key) => {
      _.set(counter, `ALL.${key}`, 0);
      _.set(counter, `NT.${key}`, 0);
      _.set(counter, `MG.${key}`, 0);
      _.set(counter, `group3t4t.${key}`, 0);
      _.set(counter, `group4t5t.${key}`, 0);
      _.set(counter, `group5t6t.${key}`, 0);
    }
  );
  _.forEach(rows, ({ subCounter, grade }) => {
    if (
      grade.includes("3-4tuoi") ||
      grade.includes("4-5tuoi") ||
      grade.includes("5-6tuoi")
    ) {
      counter.MG.numberOfClasses += 1;
      _.forEach(subCounter, (val, key) => {
        counter.MG[key] += parseFloat(val) || 0;
        counter.ALL[key] += parseFloat(val) || 0;
      });
    } else {
      if (
        !grade.includes("3-4tuoi") &&
        !grade.includes("4-5tuoi") &&
        !grade.includes("5-6tuoi")
      ) {
        counter.NT.numberOfClasses += 1;
      }
      _.forEach(subCounter, (val, key) => {
        if (
          !grade.includes("3-4tuoi") &&
          !grade.includes("4-5tuoi") &&
          !grade.includes("5-6tuoi")
        ) {
          counter.NT[key] += parseFloat(val) || 0;
        }
        counter.ALL[key] += parseFloat(val) || 0;
      });
    }
    if (grade.includes("5-6tuoi")) {
      counter.group5t6t.numberOfClasses += 1;
      _.forEach(subCounter, (val, key) => {
        counter.group5t6t[key] += parseFloat(val) || 0;
      });
    } else if (grade.includes("4-5tuoi")) {
      counter.group4t5t.numberOfClasses += 1;
      _.forEach(subCounter, (val, key) => {
        counter.group4t5t[key] += parseFloat(val) || 0;
      });
    } else if (grade.includes("3-4tuoi")) {
      counter.group3t4t.numberOfClasses += 1;
      _.forEach(subCounter, (val, key) => {
        counter.group3t4t[key] += parseFloat(val) || 0;
      });
    }
    counter.ALL.numberOfClasses += 1;
  });
  const sheetList = [
    { counterKey: "ALL", sheet: sheet1 },
    { counterKey: "NT", sheet: nhaTreSheet },
    { counterKey: "group3t4t", sheet: sheet3t4t },
    { counterKey: "group4t5t", sheet: sheet4t5t },
    { counterKey: "group5t6t", sheet: sheet5t6t },
  ];
  const commonStyles = {
    border: true,
    bold: true,
    fontSize: 14,
    font: "Times New Roman",
  };
  const year = month < 8 ? parseFloat(activeYear) + 1 : parseFloat(activeYear);
  _.forEach(sheetList, ({ sheet }) => {
    _.forEach(
      [
        { title: "Trường", value: unitName },
        { title: "Thời điểm", value: `Tháng ${month}/${year}` },
      ],
      ({ title, value }, idx) => {
        writeRange(sheet, idx + 1, 1, title, `A${idx + 1}:B${idx + 1}`, {
          bold: true,
          horizontal: "left",
          fontSize: 14,
          font: "Times New Roman",
        });
        writeCell(sheet, idx + 1, 3, value, {
          color: "FFFF0000",
          bold: true,
          horizontal: "left",
          fontSize: 14,
          font: "Times New Roman",
        });
      }
    );
    writeRange(
      sheet,
      1,
      4,
      "BẢNG TỔNG HỢP CHẤT LƯỢNG CHĂM SÓC - GIÁO DỤC TRẺ",
      "D1:AB3",
      { bold: true, fontSize: 16, font: "Times New Roman" }
    );
    writeRange(
      sheet,
      1,
      29,
      "BẢNG TỔNG HỢP CHẤT LƯỢNG CHĂM SÓC - GIÁO DỤC TRẺ",
      "AC1:AU3",
      { bold: true, fontSize: 16, font: "Times New Roman" }
    );
    writeHeaders(
      sheet,
      [
        { name: "STT", width: 6.5, wrapText: true },
        { name: "LỚP", width: 17.8, wrapText: true },
        { name: "GIÁO VIÊN PHỤ TRÁCH", width: 35.1, wrapText: true },
        { name: "SĨ SỐ HỌC SINH", width: 10.4, wrapText: true },
        { name: "NỮ", width: 10.4, wrapText: true },
        { name: "SỐ TRẺ ĐƯỢC CÂN ĐO", width: 10.4, wrapText: true },
      ],
      5,
      0,
      5,
      {
        fontSize: 14,
        font: "Times New Roman",
      }
    );
    writeRange(
      sheet,
      5,
      7,
      "ĐÁNH GIÁ CHẤT LƯỢNG CHĂM SÓC - GIÁO DỤC",
      "G5:AB5",
      commonStyles
    );
    writeRange(sheet, 6, 7, "CÂN NẶNG (Kg)", "G6:L6", commonStyles);
    writeRange(sheet, 6, 13, "CHIỀU CAO (m)", "M6:R6", commonStyles);
    writeRange(
      sheet,
      6,
      19,
      "CÂN NẶNG THEO CHIỀU CAO HOẶC BMI",
      "S6:AB6",
      commonStyles
    );
    writeRange(sheet, 7, 7, "BÌNH THƯỜNG", "G7:H8", commonStyles);
    writeRange(sheet, 7, 9, "SDD NHẸ CÂN", "I7:L7", commonStyles);
    writeRange(sheet, 8, 9, "SDD NHẸ CÂN", "I8:J8", commonStyles);
    writeRange(sheet, 8, 11, "SDD NHẸ CÂN\n(nặng)", "K8:L8", {
      ...commonStyles,
      wrapText: true,
    });
    writeRange(sheet, 7, 13, "BÌNH THƯỜNG", "M7:N8", commonStyles);
    writeRange(sheet, 7, 15, "SDD THẤP CÒI", "O7:R7", commonStyles);
    writeRange(sheet, 8, 15, "SDD THẤP CÒI", "O8:P8", commonStyles);
    writeRange(sheet, 8, 17, "SDD THẤP CÒI\n(nặng)", "Q8:R8", {
      ...commonStyles,
      wrapText: true,
    });
    writeRange(sheet, 7, 19, "BÌNH THƯỜNG", "S7:T8", commonStyles);
    writeRange(sheet, 7, 21, "THỪA CÂN", "U7:V8", commonStyles);
    writeRange(sheet, 7, 23, "BÉO PHÌ", "W7:X8", commonStyles);
    writeRange(sheet, 7, 25, "SDD GẦY CÒM", "Y7:Z8", commonStyles);
    writeRange(sheet, 7, 27, "SDD GẦY CÒM\n(nặng)", "AA7:AB8", {
      ...commonStyles,
      wrapText: true,
    });
    const rateHeaders = [];
    _.forEach(_.range(1, 12), (idx) =>
      rateHeaders.push(
        { name: "Số trẻ", width: 10.5, wrapText: true },
        { name: "Tỉ lệ\n%", width: 10.5, wrapText: true }
      )
    );
    writeHeaders(sheet, rateHeaders, 9, 6, 1, {
      fontSize: 14,
      font: "Times New Roman",
    });
    sheet.getRow(10).height = 13.5;
    writeHeaders(
      sheet,
      _.map(_.range(1, 29), (idx) => ({ name: idx })),
      10,
      0,
      1,
      { fill: "FFD9D9D9", fontSize: 14, font: "Times New Roman" }
    );
    writeHeaders(
      sheet,
      [
        { name: "STT", width: 6.5, wrapText: true },
        { name: "LỚP", width: 17.8, wrapText: true },
        { name: "GIÁO VIÊN PHỤ TRÁCH", width: 35.1, wrapText: true },
        { name: "SĨ SỐ HỌC SINH", width: 10.5, wrapText: true },
        { name: "NỮ", width: 10.5, wrapText: true },
        { name: "SỐ TRẺ ĐƯỢC CÂN ĐO", width: 10.5, wrapText: true },
      ],
      5,
      28,
      5,
      {
        fontSize: 14,
        font: "Times New Roman",
      }
    );
    writeRange(
      sheet,
      5,
      35,
      "THEO DÕI CHẤT LƯỢNG CHĂM SÓC - GIÁO DỤC",
      "AI5:AU6",
      commonStyles
    );
    let rightHeaderCountIdx = 35;
    _.forEach(
      [
        { val: "SỐ TRẺ TĂNG CÂN" },
        { val: "SỐ TRẺ GIẢM CÂN" },
        { val: "SỐ TRẺ ĐỨNG CÂN" },
        { val: "SỐ TRẺ TĂNG CHIỀU CAO" },
        { val: "SỐ TRẺ ĐỨNG CHIỀU CAO" },
        { val: "BÉ CHĂM" },
        { val: "BÉ NGOAN" },
      ],
      ({ val }) => {
        if (val !== "BÉ CHĂM") {
          writeRange(
            sheet,
            7,
            rightHeaderCountIdx,
            val,
            `${intToExcelCol(rightHeaderCountIdx)}7:${intToExcelCol(
              rightHeaderCountIdx + 1
            )}8`,
            {
              ...commonStyles,
              wrapText: true,
            }
          );
          writeCell(sheet, 9, rightHeaderCountIdx, "SỐ TRẺ", {
            ...commonStyles,
            wrapText: true,
          });
          writeCell(sheet, 9, rightHeaderCountIdx + 1, "TỈ LỆ\n%", {
            ...commonStyles,
            wrapText: true,
          });
          sheet.getColumn(rightHeaderCountIdx).width = 10.5;
          sheet.getColumn(rightHeaderCountIdx + 1).width = 10.5;
          rightHeaderCountIdx += 2;
        } else {
          writeRange(
            sheet,
            7,
            rightHeaderCountIdx,
            val,
            `${intToExcelCol(rightHeaderCountIdx)}7:${intToExcelCol(
              rightHeaderCountIdx
            )}8`,
            {
              ...commonStyles,
              wrapText: true,
            }
          );
          writeCell(sheet, 9, rightHeaderCountIdx, "TỈ LỆ\n %", {
            ...commonStyles,
            wrapText: true,
          });
          sheet.getColumn(rightHeaderCountIdx).width = 10.5;
          rightHeaderCountIdx += 1;
        }
      }
    );
    writeHeaders(
      sheet,
      _.map(_.range(1, 20), (idx) => ({ name: idx })),
      10,
      28,
      1,
      { fill: "FFD9D9D9", fontSize: 14, font: "Times New Roman" }
    );
  });
  _.forEach(sheetList, ({ counterKey, sheet }) => {
    let rowIndex = 11;
    let filteredRows = [];
    if (counterKey === "group5t6t") {
      filteredRows = _.filter(rows, (row) => {
        const gradeOfRow = _.get(row, "grade");
        return gradeOfRow.includes("5-6tuoi");
      });
    } else if (counterKey === "group4t5t") {
      filteredRows = _.filter(rows, (row) => {
        const gradeOfRow = _.get(row, "grade");
        return (
          gradeOfRow.includes("4-5tuoi") && !gradeOfRow.includes("5-6tuoi")
        );
      });
    } else if (counterKey === "group3t4t") {
      filteredRows = _.filter(rows, (row) => {
        const gradeOfRow = _.get(row, "grade");
        return (
          !gradeOfRow.includes("4-5tuoi") &&
          !gradeOfRow.includes("5-6tuoi") &&
          gradeOfRow.includes("3-4tuoi")
        );
      });
    } else if (counterKey === "NT") {
      filteredRows = _.filter(rows, (row) => {
        const gradeOfRow = _.get(row, "grade");
        return (
          !gradeOfRow.includes("4-5tuoi") &&
          !gradeOfRow.includes("5-6tuoi") &&
          !gradeOfRow.includes("3-4tuoi")
        );
      });
    } else filteredRows = rows;
    _.forEach(filteredRows, ({ subCounter, name, teacherNames }, idx) => {
      let dataColIdx = 7;
      const { total, female, checked } = subCounter;
      writeCell(sheet, rowIndex, 1, idx + 1, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      writeCell(sheet, rowIndex, 2, name, {
        border: true,
        font: "Times New Roman",
        fontSize: 14,
      });
      writeCell(sheet, rowIndex, 3, teacherNames, {
        border: true,
        wrapText: true,
        vertical: "middle",
        horizontal: "left",
        fontSize: 14,
        font: "Times New Roman",
      });
      writeCell(sheet, rowIndex, 4, total, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      writeCell(sheet, rowIndex, 5, female, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      writeCell(sheet, rowIndex, 6, checked, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      _.forEach(keyMappingLeft, (key) => {
        writeCell(sheet, rowIndex, dataColIdx, _.get(subCounter, key, 0), {
          border: true,
          fontSize: 14,
          font: "Times New Roman",
        });
        writeCell(
          sheet,
          rowIndex,
          dataColIdx + 1,
          round((_.get(subCounter, key, 0) / total) * 100, 2),
          {
            border: true,
            numberFormat: "0.00",
            fontSize: 14,
            font: "Times New Roman",
          }
        );
        dataColIdx += 2;
      });
      writeCell(sheet, rowIndex, dataColIdx, idx + 1, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      dataColIdx += 1;
      writeCell(sheet, rowIndex, dataColIdx, name, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      dataColIdx += 1;
      writeCell(sheet, rowIndex, dataColIdx, teacherNames, {
        border: true,
        wrapText: true,
        vertical: "middle",
        horizontal: "left",
        fontSize: 14,
        font: "Times New Roman",
      });
      dataColIdx += 1;
      writeCell(sheet, rowIndex, dataColIdx, total, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      dataColIdx += 1;
      writeCell(sheet, rowIndex, dataColIdx, female, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      dataColIdx += 1;
      writeCell(sheet, rowIndex, dataColIdx, checked, {
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      dataColIdx += 1;
      _.forEach(keyMappingRight, (key) => {
        if (
          key !== "tyLeBeCham" &&
          key !== "soBeNgoan" &&
          key !== "tyLeBeNgoan"
        ) {
          writeCell(sheet, rowIndex, dataColIdx, subCounter[key], {
            border: true,
            fontSize: 14,
            font: "Times New Roman",
          });
          writeCell(
            sheet,
            rowIndex,
            dataColIdx + 1,
            round((subCounter[key] / total) * 100, 2),
            {
              border: true,
              numberFormat: "0.00",
              fontSize: 14,
              font: "Times New Roman",
            }
          );
          dataColIdx += 2;
        } else {
          writeCell(sheet, rowIndex, dataColIdx, subCounter[key], {
            border: true,
            fontSize: 14,
            font: "Times New Roman",
          });
          dataColIdx += 1;
        }
      });
      rowIndex += 1;
    });
    writeRange(
      sheet,
      rowIndex,
      1,
      "Tổng hợp",
      month !== beginningYearData.mth
        ? `A${rowIndex}:B${counterKey !== "NT" ? rowIndex + 3 : rowIndex + 2}`
        : `A${rowIndex}:B${counterKey !== "NT" ? rowIndex + 2 : rowIndex + 1}`,
      { border: true, bold: true, fontSize: 14, font: "Times New Roman" }
    );
    writeRange(
      sheet,
      rowIndex,
      29,
      "Tổng hợp",
      month !== beginningYearData.mth
        ? `AC${rowIndex}:AD${counterKey !== "NT" ? rowIndex + 3 : rowIndex + 2}`
        : `AC${rowIndex}:AD${
            counterKey !== "NT" ? rowIndex + 2 : rowIndex + 1
          }`,
      { border: true, bold: true, fontSize: 14, font: "Times New Roman" }
    );
    const SummaryRows =
      counterKey !== "NT" ? ["NT", "MG", counterKey] : ["NT", "MG"];
    _.forEach(SummaryRows, (key) => {
      const { name, total, female, checked, ...restKey } = _.get(counter, key);
      writeCell(sheet, rowIndex, 3, name, {
        bold: true,
        border: true,
        horizontal: "left",
        fontSize: 14,
        font: "Times New Roman",
      });
      writeCell(sheet, rowIndex, 4, total, {
        bold: true,
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      writeCell(sheet, rowIndex, 5, female, {
        bold: true,
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      writeCell(sheet, rowIndex, 6, checked, {
        bold: true,
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      let totalColIdx = 7;
      _.forEach(keyMappingLeft, (keyMapping) => {
        writeCell(sheet, rowIndex, totalColIdx, _.get(restKey, keyMapping), {
          border: true,
          bold: true,
          fontSize: 14,
          font: "Times New Roman",
        });
        writeCell(
          sheet,
          rowIndex,
          totalColIdx + 1,
          round((_.get(restKey, keyMapping, 0) / total) * 100, 2),
          {
            border: true,
            bold: true,
            numberFormat: "0.00",
            fontSize: 14,
            font: "Times New Roman",
          }
        );
        totalColIdx += 2;
      });
      totalColIdx += 2;
      writeCell(sheet, rowIndex, totalColIdx, name, {
        bold: true,
        border: true,
        horizontal: "left",
        fontSize: 14,
        font: "Times New Roman",
      });
      totalColIdx += 1;
      writeCell(sheet, rowIndex, totalColIdx, total, {
        bold: true,
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      totalColIdx += 1;
      writeCell(sheet, rowIndex, totalColIdx, female, {
        bold: true,
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      totalColIdx += 1;
      writeCell(sheet, rowIndex, totalColIdx, checked, {
        bold: true,
        border: true,
        fontSize: 14,
        font: "Times New Roman",
      });
      totalColIdx += 1;
      _.forEach(keyMappingRight, (keyMapping) => {
        if (
          keyMapping !== "tyLeBeCham" &&
          keyMapping !== "soBeNgoan" &&
          keyMapping !== "tyLeBeNgoan"
        ) {
          writeCell(
            sheet,
            rowIndex,
            totalColIdx,
            _.get(restKey, keyMapping, ""),
            { border: true, fontSize: 14, font: "Times New Roman" }
          );
          writeCell(
            sheet,
            rowIndex,
            totalColIdx + 1,
            round((_.get(restKey, keyMapping, 0) / checked) * 100, 2),
            {
              border: true,
              numberFormat: "0.00",
              fontSize: 14,
              font: "Times New Roman",
            }
          );
          totalColIdx += 2;
        }
      });
      writeCell(
        sheet,
        rowIndex,
        totalColIdx,
        round(
          parseFloat(_.get(restKey, "tyLeBeCham", 0)) / restKey.numberOfClasses
        ),
        {
          border: true,
          numberFormat: "0.00",
          fontSize: 14,
          font: "Times New Roman",
        }
      );
      writeCell(
        sheet,
        rowIndex,
        totalColIdx + 1,
        _.get(restKey, "soBeNgoan", ""),
        { border: true, fontSize: 14, font: "Times New Roman" }
      );
      writeCell(
        sheet,
        rowIndex,
        totalColIdx + 2,
        round((parseFloat(_.get(restKey, "soBeNgoan", 0)) / total) * 100),
        {
          border: true,
          numberFormat: "0.00",
          fontSize: 14,
          font: "Times New Roman",
        }
      );
      rowIndex += 1;
    });

    if (month !== beginningYearData.mth) {
      let comparedColIdx = 4;
      writeCell(sheet, rowIndex, 3, "So với đầu năm", {
        bold: true,
        border: true,
        color: "FFFF0000",
        horizontal: "left",
        fontSize: 14,
        font: "Times New Roman",
      });
      const hasChecked = _.get(counter, `${counterKey}.checked`, 0);
      _.forEach(["total", "female", "checked"], (key) => {
        writeCell(
          sheet,
          rowIndex,
          comparedColIdx,
          hasChecked
            ? addPrefix(
                _.get(counter, `${counterKey}.${key}`, 0) -
                  _.get(BeginCounter, `${counterKey}.${key}`, 0)
              )
            : "",
          { bold: true, border: true, fontSize: 14, font: "Times New Roman" }
        );
        comparedColIdx += 1;
      });
      _.forEach(keyMappingLeft, (mappingKey) => {
        const differ =
          _.get(counter, `${counterKey}.${mappingKey}`, 0) -
          parseFloat(_.get(BeginCounter, `${counterKey}.${mappingKey}`, 0));
        writeCell(
          sheet,
          rowIndex,
          comparedColIdx,
          hasChecked
            ? addPrefix(
                parseFloat(_.get(counter, `${counterKey}.${mappingKey}`, 0)) -
                  parseFloat(
                    _.get(BeginCounter, `${counterKey}.${mappingKey}`, 0)
                  )
              )
            : "",
          { border: true, bold: true, fontSize: 14, font: "Times New Roman" }
        );
        comparedColIdx += 1;
        const comparedNumber = parseFloat(
          _.get(counter, `${counterKey}.total`, 0)
        );
        const comparedRatio =
          hasChecked && comparedNumber > 0
            ? (differ / comparedNumber) * 100
            : 0;
        writeCell(
          sheet,
          rowIndex,
          comparedColIdx,
          hasChecked ? round(Math.abs(comparedRatio), 2) : "",
          {
            border: true,
            bold: true,
            numberFormat: "0.00",
            fontSize: 14,
            font: "Times New Roman",
          }
        );
        comparedColIdx += 1;
      });
      comparedColIdx += 2;
      writeCell(sheet, rowIndex, comparedColIdx, "So với đầu năm", {
        bold: true,
        border: true,
        color: "FFFF0000",
        horizontal: "left",
        fontSize: 14,
        font: "Times New Roman",
      });
      comparedColIdx += 1;
      _.forEach(["total", "female", "checked"], (key) => {
        writeCell(
          sheet,
          rowIndex,
          comparedColIdx,
          hasChecked
            ? addPrefix(
                _.get(counter, `${counterKey}.${key}`, 0) -
                  parseFloat(_.get(BeginCounter, `${counterKey}.${key}`, 0))
              )
            : "",
          { bold: true, border: true, fontSize: 14, font: "Times New Roman" }
        );
        comparedColIdx += 1;
      });
      _.forEach(keyMappingRight, (mappingKey) => {
        const differ =
          _.get(counter, `${counterKey}.${mappingKey}`, 0) -
          _.get(BeginCounter, `${counterKey}.${mappingKey}`, 0);
        if (
          mappingKey !== "tyLeBeCham" &&
          mappingKey !== "soBeNgoan" &&
          mappingKey !== "tyLeBeNgoan"
        ) {
          writeCell(
            sheet,
            rowIndex,
            comparedColIdx,
            hasChecked
              ? addPrefix(
                  _.get(counter, `${counterKey}.${mappingKey}`, 0) -
                    _.get(BeginCounter, `${counterKey}.${mappingKey}`, 0)
                )
              : "",
            { border: true, bold: true, fontSize: 14, font: "Times New Roman" }
          );
          comparedColIdx += 1;
          const comparedNumber = _.get(counter, `${counterKey}.total`, 0);
          const comparedRatio =
            hasChecked && comparedNumber > 0
              ? (differ / comparedNumber) * 100
              : 0;
          writeCell(
            sheet,
            rowIndex,
            comparedColIdx,
            hasChecked ? round(Math.abs(comparedRatio), 2) : "",
            {
              border: true,
              bold: true,
              numberFormat: "0.00",
              fontSize: 14,
              font: "Times New Roman",
            }
          );
          comparedColIdx += 1;
        }
      });
      const totalTyLeBeChamOfBegining =
        _.get(BeginCounter, `${counterKey}.tyLeBeCham`, 0) /
        _.get(BeginCounter, `${counterKey}.numberOfClasses`, 1);
      const totalSoBeNgoanOfBegining = _.get(
        BeginCounter,
        `${counterKey}.soBeNgoan`,
        0
      );
      const totalTyLeBeNgoanOfBegining =
        (_.get(BeginCounter, `${counterKey}.soBeNgoan`, 0) /
          _.get(BeginCounter, `${counterKey}.total`, 1)) *
        100;
      const totalTyLeBeChamOfCurrent =
        _.get(counter, `${counterKey}.tyLeBeCham`, 0) /
        _.get(counter, `${counterKey}.numberOfClasses`, 1);
      const totalSoBeNgoanOfCurrent = parseFloat(
        _.get(counter, `${counterKey}.soBeNgoan`, 0)
      );
      const totalTyLeBeNgoanOfCurrent =
        (_.get(counter, `${counterKey}.soBeNgoan`, 0) /
          _.get(counter, `${counterKey}.total`, 1)) *
        100;
      writeCell(
        sheet,
        rowIndex,
        comparedColIdx,
        addPrefix(round(totalTyLeBeChamOfCurrent - totalTyLeBeChamOfBegining)),
        { border: true, bold: true, fontSize: 14, font: "Times New Roman" }
      );
      writeCell(
        sheet,
        rowIndex,
        comparedColIdx + 1,
        addPrefix(round(totalSoBeNgoanOfCurrent - totalSoBeNgoanOfBegining)),
        { border: true, bold: true, fontSize: 14, font: "Times New Roman" }
      );
      writeCell(
        sheet,
        rowIndex,
        comparedColIdx + 2,
        addPrefix(
          round(totalTyLeBeNgoanOfCurrent - totalTyLeBeNgoanOfBegining)
        ),
        { border: true, bold: true, fontSize: 14, font: "Times New Roman" }
      );
    }
  });
  return wb.xlsx.writeBuffer().then((data) => {
    if (data && rows.length) {
      writeReport(
        new Blob([data]),
        `Tong hop chat luong CSGD ${month}_${year}.xlsx`,
        closeHandler
      );
    } else {
      errorHandler("Không có dữ liệu, vui lòng thử lại");
      onComplete();
    }
  });
};

export default chatluonggiaoduc;
