import ReactGA from "react-ga";
import { dev } from "./firebase";


export const initializeGA = (uid, version) => {
  if (dev)
    return;
  ReactGA.initialize("UA-124208793-11", {
    gaOptions: {
      userId: uid,
      appId: "rebot-release.firebaseapp.com",
      appVersion: version
    }
  });
}

export const eventTracking = (category, action, value = 1, label = "") => {
  const event = {
    category,
    action,
    value
  };
  if (label) {
    event.label = (label.indexOf("@") !== -1) ? label.replace("@", "amoc") : label;
  }
  ReactGA.event(event);
}