import { USER_FETCH, USER_IDENTIFY, SIGN_OUT, USER_UNIT, SYSTEM_ADMIN } from "./constants";


/**
 * Fetch general information of a user
 * 
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userFetchInformation
 * @param  {String} [displayName]
 * @param  {String} [email]
 * @param  {String} [phoneNumber]
 * @param  {String} [photoURL]
 * @param  {String} [uid]
 */
export function userFetchInformation(displayName, email, phoneNumber, photoURL, uid) {
  const name = (displayName === null)? "" : displayName;
  const phone = (phoneNumber === null)? "" : phoneNumber;
  const avatar = (photoURL === null)? "" : photoURL;
  return {
    type: USER_FETCH,
    displayName: name,
    email,
    phoneNumber: phone,
    photoURL: avatar,
    uid
  };
}

/**
 * Fetch detail information of a user
 * 
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userIdentify
 * @param  {String} [displayName]
 * @param  {String} [phoneNumber]
 * @param  {String} [photoURL]
 * @param  {String} [gender] "M" or "F", could be undefined
 */
export function userIdentify(displayName, phoneNumber, photoURL, gender) {
  const name = (displayName === undefined)? "" : displayName;
  const phone = (phoneNumber === undefined)? "" : phoneNumber;
  const avatar = (photoURL === undefined)? "" : photoURL;
  return {
    type: USER_IDENTIFY,
    displayName: name,
    phoneNumber: phone,
    photoURL: avatar,
    gender
  };
}

/**
 * Add units information detail which user belongs to
 * 
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userAddUnit
 * @param  {Object} [eduunit]
 */

export function userAddUnit(eduunit) {
  return {
    type: USER_UNIT,
    eduunit
  };
}

/**
 * This user is admin
 * 
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userAdmin
 */

export function userAdmin() {
  return {
    type: SYSTEM_ADMIN
  };
}

/**
 * User has signed out
 * 
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $signOut
 */

export function signOut() {
  return {
    type: SIGN_OUT
  };
}