import { REPORT_STATUS, SIGN_OUT } from "../actions/constants";


const initialState = {
};

export default function status(state = initialState, action) {
  switch(action.type) {
    case REPORT_STATUS:
      return {
        ...state,
        [action.unitID]: action.status
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}