/**
 * Convert timestamp to short datetime
 *
 * @module Libs.time
 * @ngdoc method
 * @name $convertTimeStamp
 * @param  {Number} [timestamp] unix timestamp
 * @return {String} formatted datetime
 * @return {Bool} if full option is specified, return full datatime
 */
function convertTimeStamp(timestamp, full) {
  const date = new Date(timestamp);
  if (full !== undefined && full === true) {
    const mins = date.getMinutes();
    return `Ngày ${date.getDate()} Tháng ${(date.getMonth()+1)}, lúc ${date.getHours()}:${(mins < 10)? `0${mins}` : mins}`;
  }
  const diff = (new Date()).getTime() - timestamp;
  
  const mins = diff / (1000*60);
  const hours = mins/60;
  const days = hours/24;

  if (parseInt(mins, 10) === 0)
    return "vừa mới đây";
  else if (parseInt(hours, 10) === 0)
    return `${parseInt(mins, 10)} phút trước`;
  switch(parseInt(days, 10)) {
    case 0:
      return `${parseInt(hours, 10)} giờ trước`;
    case 1:
      return "Hôm qua";
    default:
      return `Ngày ${date.getDate()} Tháng ${(date.getMonth()+1)}`;
  }
}

export { convertTimeStamp }