/* eslint no-eval: 0 */
/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import _ from "lodash";

import { isMN, isMG, isCL, isDL, isTT, isTruong, isCSMGNT } from "./school";

// expand {key} or {reportKey.key} by its value
function expandKeyVar(allReports, currentReport, keyVar, unitId) {
  if (keyVar.includes('.')) {
    const dotIndex = keyVar.indexOf('.');
    const reportKey = keyVar.slice(1, dotIndex);
    const columnKey = keyVar.slice(dotIndex + 1, keyVar.length - 1);
    if (allReports[reportKey] === undefined)
      return "";
    const { data } = allReports[reportKey];
    if (data === undefined)
      return "";
    if (data[columnKey] === undefined)
      return "";
    if (unitId === undefined)
      return data[columnKey];
    if (data[columnKey][unitId] === undefined)
      return "";
    return data[columnKey][unitId];
  }
  const columnKey = keyVar.slice(1, keyVar.length - 1);
  return unitId === undefined ? currentReport[columnKey] : currentReport[columnKey][unitId];
}

// expand all {key} or {reportKey.key} in exp by their values
function expandExp(allReports, currentReport, exp, customLogic = [], unitId) {
  let expExpanded = exp;

  // expand from customLogic, e.g. { "{lib.MG}": 1, "{lib.MN}": 2 }
  Object.keys(customLogic).forEach(keyVar => {
    expExpanded = expExpanded.replace(keyVar, customLogic[keyVar]);
  });

  // expand from report, set to 0 if not found
  const regExp = /\{([^}]+)\}/g;
  const keyVars = expExpanded.match(regExp);
  if (keyVars !== null) {
    keyVars.forEach(keyVar => {
      let finalValue = 0;
      const value = expandKeyVar(allReports, currentReport, keyVar, unitId);
      if (value !== undefined && value !== "")
        finalValue = parseFloat(value);
      expExpanded = expExpanded.replace(keyVar, finalValue);
    });
  }

  return expExpanded;
}

//
// public
//

function getCustomLogic(unitType, schoolModel, schoolType, reportKey, data, externalData = undefined) {
  const customLogic = {};

  if (unitType === "th") {
    customLogic["{lib.NT}"] = 0;
    customLogic["{lib.MN}"] = isMN(schoolModel) ? 1 : 0;
    customLogic["{lib.MG}"] = isMG(schoolModel) ? 1 : 0;
    customLogic["{lib.CL}"] = isCL(schoolType) ? 1 : 0;
    customLogic["{lib.MNCL}"] = (isMN(schoolModel) && isCL(schoolType)) ? 1 : 0;
    customLogic["{lib.MGCL}"] = (isMG(schoolModel) && isCL(schoolType)) ? 1 : 0;
    customLogic["{lib.DL}"] = isDL(schoolType) ? 1 : 0;
    customLogic["{lib.TT}"] = isTT(schoolType) ? 1 : 0;
    customLogic["{lib.DLTT}"] = (isDL(schoolType) || isTT(schoolType)) ? 1 : 0;
    customLogic["{lib.BC}"] = 0;
    customLogic["{lib.Truong}"] = isTruong(schoolModel) ? 1 : 0;
    customLogic["{lib.CSMGNT}"] = isCSMGNT(schoolModel) ? 1 : 0;

    if (_.get(externalData, "unit")) {
      customLogic["{lib.address}"] = _.get(externalData, "unit.address", "");
      customLogic["{lib.phone}"] = _.get(externalData, "unit.phone", "");
      customLogic["{lib.admin}"] = _.get(externalData, "unit.admin", "");
      customLogic["{lib.license}"] = _.get(externalData, "unit.license", "");
      customLogic["{lib.noLicense}"] = _.get(externalData, "unit.license") ? 1 : 0;
      customLogic["{lib.operationCert}"] = _.get(externalData, "unit.operationCert", "");
      customLogic["{lib.noOperationCert}"] = _.get(externalData, "unit.operationCert") ? 1 : 0;
      customLogic["{lib.industrialZone}"] = _.get(externalData, "unit.industrialZone") ? 1 : 0;
    }
  }

  switch (reportKey) {
    case "cchamsocnuoiduong":
      if (data !== undefined &&
        ((!isNaN(data.U) && data.U !== 0) ||
          (!isNaN(data.V) && data.V !== 0) ||
          (!isNaN(data.W) && data.W !== 0) ||
          (!isNaN(data.X) && data.X !== 0) ||
          (!isNaN(data.Y) && data.Y !== 0) ||
          (!isNaN(data.Z) && data.Z !== 0) ||
          (!isNaN(data.AA) && data.AA !== 0) ||
          (!isNaN(data.AB) && data.AB !== 0) ||
          (!isNaN(data.AC) && data.AC !== 0) ||
          (!isNaN(data.AD) && data.AD !== 0) ||
          (!isNaN(data.AE) && data.AE !== 0) ||
          (!isNaN(data.AF) && data.AF !== 0)))
        customLogic["{lib.BT}"] = 1;
      else
        customLogic["{lib.BT}"] = 0;
      break;
    case "ntangcuongtiengviet":
    case "fmgdantoc":
      if (_.get(externalData, "btruongloptre.data") && !isNaN(externalData.btruongloptre.data.AI) && externalData.btruongloptre.data.AI !== 0)
        customLogic["{lib.DTTS}"] = 1;
      else if (_.get(externalData, 'fmgdantoc.data.B'))
        customLogic["{lib.DTTS}"] = 1;
      else
        customLogic["{lib.DTTS}"] = 0;
      break;
    case "odoinguvacosovatchat":
      if (_.get(externalData, "unit.xakhokhan")) {
        const gsoluongcbgv1 = (_.get(externalData, "gsoluongcbgv.data") && !isNaN(externalData.gsoluongcbgv.data.F)) ? externalData.gsoluongcbgv.data.F : 0;
        const gsoluongcbgv2 = (_.get(externalData, "gsoluongcbgv.data") && !isNaN(externalData.gsoluongcbgv.data.I)) ? externalData.gsoluongcbgv.data.I : 0;
        customLogic["{lib.CBQLDTTS}"] = gsoluongcbgv1 + gsoluongcbgv2;
      } else
        customLogic["{lib.CBQLDTTS}"] = 0;
      break;
    default:
  }

  return customLogic;
}

/**
 * Expand variables in the logic of the current report
 *
 * @module Libs.report
 * @ngdoc method
 * @name $expandLogic
 * @param {Object} data of all reports
 * @param {Object} data of the current report
 * @param {Object} logic of the current report
 * @return {Object} expanded form of the given logic
 */
function expandLogic(allReports, currentReport, currentLogic, customLogic, unitId) {
  const currentLogicExpanded = {};
  if (currentLogic === undefined || currentLogic === null)
    return currentLogic;
  let tmp = NaN;
  Object.keys(currentLogic).forEach(key => {
    if (currentLogic[key].eval !== undefined) {
      const expExpanded = expandExp(allReports, currentReport, currentLogic[key].eval, customLogic, unitId);
      try {
        tmp = eval(expExpanded);
        if (!isNaN(tmp) && tmp !== Infinity)
          currentLogicExpanded[key] = tmp;
        else
          currentLogicExpanded[key] = "";
      } catch (err) {
        currentLogicExpanded[key] = expExpanded;
      }
    }
  });
  return currentLogicExpanded;
}

function computeFromDetail(data, computation, detail, allReports, logic) {
  const clone = _.cloneDeep(data);
  _.forEach(computation, (info, key) => {
    const {
      enable,
      compute
    } = info;
    let cont = true;
    if (enable) {
      const exp = expandExp(allReports, data, enable, logic);
      if (!eval(exp))
        cont = false;
    }
    if (cont) {
      const { type, columns } = compute;
      switch (type) {
        case 'count':
          clone[key] = _.filter(detail, row => row[columns[0]]).length;
          break;
        case 'countOr':
          clone[key] = _.filter(detail, row => _.some(columns, ele => row[ele])).length;
          break;
        case 'sum':
          clone[key] = _.sum(_.map(detail, row => isNaN(_.toNumber(row[columns[0]])) ? 0 : parseFloat(row[columns[0]])));
          break;
        case 'sumProduct':
          clone[key] = _.sum(_.map(detail, row => {
            let prod = isNaN(_.toNumber(row[columns[0]])) ? 0 : parseFloat(row[columns[0]]);
            _.forEach(_.range(1, columns.length), index => {
              prod *= isNaN(_.toNumber(row[columns[index]])) ? 0 : parseFloat(row[columns[index]]);
            })
            return prod;
          }));
          break;
        default:
      }
    }
  });
  return clone;
}

export { getCustomLogic, expandLogic, expandExp, computeFromDetail }
