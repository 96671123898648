/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, Button, Modal, Header, TextArea, Feed, Popup, Label } from "semantic-ui-react";
import _ from "lodash";

import ConfirmModal from "../../../components/ConfirmModal";

import { convertTimeStamp } from "../../../libs/time";


export default class Comment extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    commentHandler: PropTypes.func.isRequired,
    removeHandler: PropTypes.func.isRequired,
    school: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    data: PropTypes.shape({
      content: PropTypes.string.isRequired,
      modifiedBy: PropTypes.shape({
        email: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired
      }).isRequired,
      modifiedTime: PropTypes.number.isRequired
    }),
    allowEdit: PropTypes.bool
  };

  static defaultProps = {
    data: undefined,
    allowEdit: false
  }

  constructor(props) {
    super(props);

    this.state = {
      content: _.get(this.props, "data.content", ""),
      askToRemove: false
    }
  }

  renderStatus = () => {
    const { data } = this.props;
    if (data) {
      const { modifiedBy, modifiedTime } = data;
      return (
        <Header.Subheader>
          <Feed>
            <Feed.Event>
              <Feed.Label>
                <Icon name="save" />
              </Feed.Label>
              <Feed.Content>
                <Feed.Summary>
                  Lần nhận xét gần nhất bởi {(modifiedBy.gender === "M")? "Thầy " : "Cô "}
                  <Popup 
                    trigger={
                      <Label as="a">
                        <Icon name={(modifiedBy.gender === "M")? "male" : "female"} />
                        {modifiedBy.displayName}
                      </Label>
                    }
                    content={modifiedBy.email}
                  />.
                  <Feed.Date>{convertTimeStamp(modifiedTime)}</Feed.Date>
                </Feed.Summary>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Header.Subheader>
      );
    }
    return null;
  }

  render() {
    const { school, data, commentHandler, closeHandler, removeHandler, allowEdit } = this.props;
    const { content, askToRemove } = this.state;
    return (
      <Modal className="custom" size="fullscreen" open closeIcon={<Icon name="close" color="red" size="large" onClick={closeHandler} />}>
        <Header className="form-header">
          Đơn vị {school.name}
          {this.renderStatus()}
        </Header>
        <Modal.Content scrolling>
          {askToRemove ? (
            <ConfirmModal
              icon="warning"
              title="Xoá nhận xét"
              content="Sau khi xoá thì sẽ không thể phục hồi. Tiếp tục xoá?"
              closeHandler={() => this.setState({ askToRemove: false })}
              confirmHandler={removeHandler}
            />
          ) : null}
          <TextArea 
            style={{ minHeight: 200, width: "100%" }}
            value={content}
            onChange={(e,{value}) => this.setState({ content: value })}
          />
        </Modal.Content>
        {allowEdit? (
          <Modal.Actions>
            {data? <Button color="red" onClick={() => this.setState({ askToRemove: true })}>Xoá nhận xét</Button> : null}
            <Button color="blue" onClick={() => commentHandler(content)}>Gửi nhận xét</Button>
          </Modal.Actions>
        ) : null}
      </Modal>
    )
  }
}