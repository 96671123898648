/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Dimmer, Loader, Form, Dropdown, Icon, Button, Popup, Input, Label, Modal, Header, Message } from "semantic-ui-react";
import _ from "lodash";
import axios from "axios";
import { FormattedNumber } from "react-intl";

// components
import School from "./school";
import Comment from "./comment";

// services
import { db } from "../../../libs/firebase";
import { RESOURCES } from "../../../libs/config";
import { convertTimeStamp } from "../../../libs/time";


const months = [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7];

class DinhDuong extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        pgd: PropTypes.string
      }).isRequired,
    }).isRequired,
    manage: PropTypes.object.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired
    }).isRequired,
    allowEdit: PropTypes.bool
  };

  static defaultProps = {
    allowEdit: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      filter: "",
      schools: []
    }
  }

  componentWillMount() {
    axios.get(`${RESOURCES}/rebot/dinhduong.json`).then(data => {
      this.setState({ dinhduongCatalogs: data.data });
    }).catch(() => this.props.errorHandler("Tải cấu hình dinh dưỡng không thành công."));

    if (_.get(this.props, "unit.information.type", "") === "pgd")
      this.setState({ schools: this.getChilds() });
  }

  componentWillUnmount() {
    if (this.listener)
      this.listener.off();
  }

  getChilds = (option) => {
    const manage = _.get(this.props, "manage", {});
    if (option)
      return _.map(_.keys(manage), unit => ({
        text: _.get(manage, `${unit}.information.name`, ""),
        value: unit
      }));
    return _.sortBy(
      _.map(_.keys(manage), unit => ({
        id: unit,
        ..._.get(manage, `${unit}.information`, {})
      })),
      ["name"]
    );
  }

  submitHandler = () => {
    const { month, data } = this.state;
    const { activeYear, unitID, information } = this.props.unit;
    if (information.pgd) {
      this.setState({ isWaiting: true });
      const status = {
        nhatre: {
          price: _.get(data, "nhatre.price", 0),
          cocau: _.get(data, "nhatre.cocau", ""),
          total: 0,
          passed: 0
        },
        maugiao: {
          price: _.get(data, "maugiao.price", 0),
          cocau: _.get(data, "maugiao.cocau", ""),
          total: 0,
          passed: 0
        }
      };
      _.forEach(["nhatre", "maugiao"], grade => {
        const days = _.get(data, `${grade}.days`, {});
        _.forEach(_.keys(days), day => {
          status[grade].total += 1;
          status[grade].passed += _.get(days, `${day}.data.Passed`, false) ? 1 : 0
        });
      });
      db.ref(`dinhduong/${activeYear}/final/${information.pgd}/data/${month}/${unitID}`).set({
        modifiedBy: this.props.user.email,
        modifiedTime: new Date().getTime(),
        data: {
          nhatre: _.get(data, "nhatre.days", {}),
          maugiao: _.get(data, "maugiao.days", {})
        }
      }, err => {
        if (err) {
          this.setState({ isWaiting: false });
          this.props.errorHandler("Gửi báo cáo không thành công. Xin vui lòng thử lại sau.");
        } else
          db.ref(`dinhduong/${activeYear}/final/${information.pgd}/status/${month}/${unitID}`).update(status, error => {
            this.setState({ isWaiting: false });
            if (error)
              this.props.errorHandler("Gửi báo cáo không thành công. Xin vui lòng thử lại sau.");
            else
              this.props.successHandler("Gửi báo cáo thành công.");
          });
      });

    } else
      this.props.errorHandler("Không kết nối được đến dữ liệu của Phòng Giáo dục. Xin vui lòng thử lại sau.");
  }

  pgdHandler = (e, { value }) => {
    this.setState({ isWaiting: true, selectedPGD: value, schools: [], data: undefined }, () => {
      if (this.listener)
        this.listener.off();
      const { month } = this.state;
      const { activeYear } = this.props.unit;
      const schools = [];
      const childs = _.get(this.props, `manage.${value}.information.units`, {});
      _.forEach(_.keys(childs), child => {
        db.ref(`eduunits/${child}`).once("value", (info) => {
          if (info !== null && info.val() !== null)
            schools.push({
              id: child,
              ...info.val()
            });
          if (schools.length === _.keys(childs).length) {
            this.listener = db.ref(`dinhduong/${activeYear}/final/${value}/status/${month}`);
            this.listener.on("value", data => {
              this.setState({
                isWaiting: false,
                schools,
                data: (data !== null && data.val() !== null) ? data.val() : {}
              });
            }, (error) => {
              this.setState({ isWaiting: false });
              if (error)
                this.props.errorHandler("Kết nối đến cơ sở dữ liệu không thành công. Xin vui lòng thử lại sau.");
            });
          }
        });
      });
    });
  }

  monthHandler = (e, { value }) => {
    if (this.state.month === value)
      return;
    if (this.listener)
      this.listener.off();
    const { unitID, activeYear, information } = this.props.unit;
    if (information.type === "sgd") {
      this.setState({ month: value });
      return;
    }
    this.setState({ isWaiting: true, month: value, evaluation: undefined }, () => {
      if (information.type === "th") {
        db.ref(`dinhduong/${activeYear}/final/${information.pgd}/status/${value}/${unitID}/comment`).once("value", data => {
          if (data !== null && data.val() !== null)
            this.setState({ evaluation: data.val() });
        }, (error) => {
          if (error)
            this.props.errorHandler("Không kết nối được đến dữ liệu nhận xét, đánh giá của Phòng, Sở.");
        });
      }
      this.listener = db.ref(`dinhduong/${activeYear}/reports/${unitID}/${value}`);
      if (information.type === "pgd")
        this.listener = db.ref(`dinhduong/${activeYear}/final/${unitID}/status/${value}`);
      if (this.listener)
        this.listener.on("value", data => {
          this.setState({
            isWaiting: false,
            data: (data !== null && data.val() !== null) ? data.val() : {}
          });
        }, (error) => {
          this.setState({ isWaiting: false, data: undefined });
          if (error)
            this.props.errorHandler("Kết nối đến cơ sở dữ liệu không thành công. Xin vui lòng thử lại sau.");
        });
      else
        this.setState({ isWaiting: false });
    });
  }

  removeCommentHandler = () => {
    this.setState({ isWaiting: true });
    const { unit } = this.props;
    const { unitID, activeYear } = unit;
    const { month, selectedPGD, comment } = this.state;
    db.ref(`dinhduong/${activeYear}/final/${selectedPGD || unitID}/status/${month}/${comment.school.id}/comment/${comment.type}`).set(null, error => {
      if (error) {
        this.setState({ isWaiting: false });
        this.props.errorHandler("Kết nối đến cơ sở dữ liệu không thành công. Xin vui lòng thử lại sau.");
      } else {
        this.setState({ isWaiting: false, comment: undefined });
        this.props.successHandler("Xoá nhận xét thành công.");
      }
    });
  }

  commentHandler = (content) => {
    this.setState({ isWaiting: true });
    const { user, unit } = this.props;
    const { unitID, activeYear } = unit;
    const { month, selectedPGD, comment } = this.state;
    db.ref(`dinhduong/${activeYear}/final/${selectedPGD || unitID}/status/${month}/${comment.school.id}/comment/${comment.type}`).set({
      content,
      modifiedBy: {
        email: user.email,
        displayName: user.displayName,
        gender: user.gender
      },
      modifiedTime: new Date().getTime()
    }, error => {
      if (error) {
        this.setState({ isWaiting: false });
        this.props.errorHandler("Kết nối đến cơ sở dữ liệu không thành công. Xin vui lòng thử lại sau.");
      } else {
        this.setState({ isWaiting: false, comment: undefined });
        this.props.successHandler("Gửi nhận xét thành công.");
      }
    });
  }

  schoolHandler = (school, status) => {
    this.setState({ isWaiting: true });
    const { unitID, activeYear } = this.props.unit;
    const { month, selectedPGD } = this.state;
    db.ref(`dinhduong/${activeYear}/final/${selectedPGD || unitID}/data/${month}/${school.id}`).once("value", data => {
      if (data === null || data.val() === null) {
        this.setState({ isWaiting: false });
        this.props.errorHandler("Không tìm thấy dữ liệu báo cáo của trường. Xin vui lòng thử lại sau hoặc yêu cầu trường gửi báo cáo lại.");
      } else {
        const processed = {};
        _.forEach(["nhatre", "maugiao"], grade => {
          processed[grade] = {
            price: _.get(status, `${grade}.price`, 0),
            cocau: _.get(status, `${grade}.cocau`),
            days: _.get(data.val(), `data.${grade}`, {})
          }
        });
        this.setState({
          isWaiting: false,
          selectedSchool: {
            ...school,
            data: processed
          }
        });
      }
    }, error => {
      this.setState({ isWaiting: false });
      if (error)
        this.props.errorHandler("Kết nối đến cơ sở dữ liệu không thành công. Xin vui lòng thử lại sau.");
    });
  }

  renderCommentAction = (school, comment, type) => {
    const { information } = this.props.unit;
    if (type !== information.type && !comment)
      return null;
    return (
      <Popup
        trigger={(
          <Icon
            name={comment ? "comment" : "add"}
            color={comment ? "brown" : "green"}
            style={{ cursor: "pointer" }}
            onClick={() => this.setState({
              comment: {
                school,
                data: comment,
                type
              }
            })}
          />
        )}
        content="Nhận xét"
      />
    );
  }

  renderSchoolPreview = (school, index, data) => {
    const { id, name } = school;
    const rest = [];
    let warning = false;
    let negative = false;
    if (data) {
      _.forEach(["nhatre", "maugiao"], grade => {
        const style = (grade === "maugiao") ? { background: "beige" } : { background: "ghostwhite" };
        rest.push(
          <Table.Cell key={`${id}.${grade}.price`} style={style}>
            <FormattedNumber value={_.get(data, `${grade}.price`, "")} />
          </Table.Cell>
        );
        rest.push(<Table.Cell key={`${id}.${grade}.cocau`} style={style}>{_.get(data, `${grade}.cocau`, "")}</Table.Cell>);
        rest.push(<Table.Cell key={`${id}.${grade}.passed`} style={style}>
          {_.get(data, `${grade}.passed`, "")}
/
          {_.get(data, `${grade}.total`, "")}
        </Table.Cell>);
      });
    } else if (_.get(school, "plugins.dinhduong")) {
      warning = true;
      rest.push(<Table.Cell key={`${id}.warning`} colSpan="8">Chưa gửi báo cáo</Table.Cell>);
    } else {
      negative = true;
      rest.push(<Table.Cell key={`${id}.negative`} colSpan="8">Không sử dụng phần mềm</Table.Cell>);
    }
    return (
      <Table.Row key={id} style={{ textAlign: "center" }} warning={warning} negative={negative} positive={!warning && !negative}>
        <Table.Cell key={`${id}.view`} collapsing>
          {(rest.length > 1) ? (
            <Popup
              trigger={(
                <Icon
                  name="file"
                  color="blue"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.schoolHandler(school, data)}
                />
              )}
              content="Xem chi tiết"
            />
          ) : null}
        </Table.Cell>
        <Table.Cell key={`${id}.stt`} collapsing>{index + 1}</Table.Cell>
        <Table.Cell key={`${id}.name`}>{name}</Table.Cell>
        {rest}
        {(rest.length > 1) ? (
          <>
            <Table.Cell key={`${id}.comment.pgd`} collapsing>
              {this.renderCommentAction(school, _.get(data.comment, "pgd"), "pgd")}
            </Table.Cell>
            <Table.Cell key={`${id}.comment.sgd`} collapsing>
              {this.renderCommentAction(school, _.get(data.comment, "sgd"), "sgd")}
            </Table.Cell>
          </>
        ) : null}
      </Table.Row>
    )
  }

  renderData = () => {
    const { month, data, dinhduongCatalogs, schools, filter, selectedPGD } = this.state;
    const { allowEdit, unit, errorHandler, successHandler } = this.props;
    const notifications = { errorHandler, successHandler };
    if (unit.information.type === "th") {
      if (data)
        return (
          <School
            dinhduongCatalogs={dinhduongCatalogs}
            unitID={unit.unitID}
            unitName={unit.information.name}
            activeYear={unit.activeYear}
            month={month}
            data={data}
            allowEdit={allowEdit}
            {...notifications}
          />
        );
      return null;
    }
    if (unit.information.type === "sgd" && !selectedPGD)
      return null;
    if (month) {
      const orderedSchools = _.sortBy(schools, school => {
        if (_.get(school, "plugins.dinhduong", false))
          return `a${school.name}`;
        return `b${school.name}`;
      });
      const registered = _.filter(schools, school => _.get(school, "plugins.dinhduong", false));
      return (
        <>
          <Label.Group>
            <Label>
              {schools.length}
              {' '}
đơn vị
            </Label>
            <Label color="blue">
              {registered.length}
              {' '}
dùng phần mềm
            </Label>
            <Label color="olive">
              {_.filter(registered, school => _.get(data, school.id)).length}
              {' '}
đã gửi báo cáo
            </Label>
            <Label color="red">
              {schools.length - registered.length}
              {' '}
chưa dùng phần mềm
            </Label>
          </Label.Group>
          <Table style={{ background: "transparent" }} celled striped color="brown" selectable>
            <Table.Header>
              <Table.Row style={{ textAlign: "center" }}>
                <Table.HeaderCell collapsing colSpan="2">STT</Table.HeaderCell>
                <Table.HeaderCell>Đơn vị</Table.HeaderCell>
                <Table.HeaderCell collapsing colSpan="3" style={{ background: "ghostwhite" }}>Nhà trẻ</Table.HeaderCell>
                <Table.HeaderCell collapsing colSpan="3" style={{ background: "beige" }}>Mẫu giáo</Table.HeaderCell>
                <Table.HeaderCell collapsing>Nhận xét</Table.HeaderCell>
              </Table.Row>
              <Table.Row style={{ textAlign: "center" }}>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    icon="search"
                    placeholder="Lọc theo tên"
                    value={filter}
                    onChange={(e, { value }) => this.setState({ filter: value })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell collapsing style={{ background: "ghostwhite" }}>Tiền ăn</Table.HeaderCell>
                <Table.HeaderCell collapsing style={{ background: "ghostwhite" }}>Cơ cấu áp dụng</Table.HeaderCell>
                <Table.HeaderCell collapsing style={{ background: "ghostwhite" }}>Thực đơn đạt</Table.HeaderCell>
                <Table.HeaderCell collapsing style={{ background: "beige" }}>Tiền ăn</Table.HeaderCell>
                <Table.HeaderCell collapsing style={{ background: "beige" }}>Cơ cấu áp dụng</Table.HeaderCell>
                <Table.HeaderCell collapsing style={{ background: "beige" }}>Thực đơn đạt</Table.HeaderCell>
                <Table.HeaderCell collapsing>PGD</Table.HeaderCell>
                <Table.HeaderCell collapsing>SGD</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                _.filter(orderedSchools, school => {
                  if (filter)
                    return _.toLower(school.name).indexOf(_.toLower(filter)) !== -1;
                  return true;
                }),
                (school, index) => this.renderSchoolPreview(school, index, _.get(data, school.id))
              )}
            </Table.Body>
          </Table>
        </>
      );
    }
    return null;
  }

  renderSelectedSchool = () => {
    const { selectedSchool, month, dinhduongCatalogs } = this.state;
    if (selectedSchool) {
      const { name, data } = selectedSchool;
      const { unit, errorHandler, successHandler } = this.props;
      const notifications = { errorHandler, successHandler };
      return (
        <Modal className="custom" size="fullscreen" open closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState({ selectedSchool: undefined })} />}>
          <Header className="form-header">
            Đơn vị
            {' '}
            {name}
          </Header>
          <Modal.Content scrolling>
            <School
              dinhduongCatalogs={dinhduongCatalogs}
              unitID={unit.unitID}
              unitName={name}
              activeYear={unit.activeYear}
              month={month}
              data={data}
              {...notifications}
            />
          </Modal.Content>
        </Modal>
      );
    }
    return null;
  }

  renderComment = () => {
    const { comment } = this.state;
    const { information } = this.props.unit;
    if (comment) {
      return (
        <Comment
          closeHandler={() => this.setState({ comment: undefined })}
          commentHandler={this.commentHandler}
          removeHandler={this.removeCommentHandler}
          school={comment.school}
          data={comment.data}
          allowEdit={information.type === comment.type}
        />
      );
    }
    return null;
  }

  renderEvaluation = () => {
    const { evaluation } = this.state;
    if (evaluation) {
      const renderer = (data, type) => {
        if (data) {
          const { content, modifiedBy, modifiedTime } = data;
          if (content)
            return (
              <Message key={type} negative={type === "sgd"} warning={type === "pgd"}>
                <Message.Header as="h5">
                  {(type === "sgd") ? "Sở Giáo dục" : "Phòng Giáo dục"}
:
                  {' '}
                  {(modifiedBy.gender === "M") ? "Thầy " : "Cô "}
                  <Popup trigger={<strong>{modifiedBy.displayName}</strong>} content={modifiedBy.email} />
                </Message.Header>
                <Message.Content>
                  {content}
.
                  {' '}
                  <br />
                  {convertTimeStamp(modifiedTime)}
                </Message.Content>
              </Message>
            );
        }
        return null;
      };
      return _.compact(_.map(["sgd", "pgd"], type => renderer(evaluation[type], type)));
    }
    return null;
  }

  render() {
    const { isWaiting, dinhduongCatalogs, month, selectedPGD } = this.state;
    const { type } = this.props.unit.information;
    return (
      <div>
        {(isWaiting || !dinhduongCatalogs) ? (
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
        ) : null}
        {this.renderComment()}
        {this.renderSelectedSchool()}
        <Form style={{ marginBottom: 10 }}>
          <Form.Group inline>
            <Form.Field required>
              <label>Chọn tháng</label>
              <Dropdown
                search
                selection
                multiple={false}
                onChange={this.monthHandler}
                options={months.map(m => ({
                  key: m,
                  text: `Tháng ${m}`,
                  value: m
                }))}
                value={month}
                placeholder="Chọn tháng muốn thao tác"
              />
            </Form.Field>
            {(type === "th" && month) ? (
              <Form.Field>
                <Popup
                  trigger={<Button icon="send" color="blue" onClick={this.submitHandler} />}
                  content="Gửi báo cáo"
                  position="bottom right"
                />
              </Form.Field>
            ) : null}
          </Form.Group>
          {(type === "sgd" && month) ? (
            <Form.Field required>
              <label>Chọn Phòng Giáo dục</label>
              <Dropdown
                search
                multiple={false}
                onChange={this.pgdHandler}
                options={this.getChilds(true)}
                value={selectedPGD}
                placeholder="Chọn Phòng Giáo dục muốn theo dõi"
              />
            </Form.Field>
          ) : null}
        </Form>
        {this.renderEvaluation()}
        {this.renderData()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  unit: state.unit,
  manage: state.manage
});

export default connect(mapStateToProps)(DinhDuong);