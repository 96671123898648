import { combineReducers } from "redux";

import user from "./user";
import unit from "./unit";
import reports from "./reports";
import data from "./data";
import year from "./year";
import status from "./status";
import manage from "./manage";
import milestone from "./milestone";
import cando from "./cando";

const rootReducer = combineReducers({
  user,
  unit,
  reports,
  data,
  year,
  status,
  manage,
  milestone,
  cando
});

export default rootReducer;