/* eslint no-eval: 0 */
/* eslint-disable no-restricted-globals */
import _ from "lodash";

import {
  isMN,
  isCL,
  isDL,
  isTT,
  isCSMGNT,
  isMGCL,
  isMGDL,
  isMGTT,
  isMNCL,
  isMNDL,
  isMNTT,
  readSchoolTypeOrModel
} from "./school";
import { expandExp } from "./report";


const cities = require("./cities.json");

/**
 * Evaluate all libs for exporting SGD excel
 *
 * @module Libs.export
 * @ngdoc method
 * @name $computeSGDExportLibs
 * @param  {String} [unitID]
 * @param  {Snapshot} [units] all schools and pgd
 * @param  {Object} [childs] all pgd
 * @param  {String} [province] province ID
 * @param  {Object} [data] reports data
 */
export function computeSGDExportLibs(unitID, units, childs, province, data) {
  const libs = {};

  const MNCounter = {};
  const PhuongDatChuanCounter = {};
  ["{lib.MN}", "{lib.CL}", "{lib.DL}", "{lib.TT}", "{lib.CSMGNT}", "{lib.MGCL}", "{lib.MGDL}", "{lib.MGTT}", "{lib.MNCL}", "{lib.MNDL}", "{lib.MNTT}"].forEach(lib => { libs[lib] = 0 });
  units.forEach(unit => {
    if (unit.val().type === "th") {
      if (isMN(unit.val().schoolModel))
        MNCounter[unit.val().ward] = 1;

      if (isCL(unit.val().schoolModel))
        libs["{lib.CL}"] += 1;
      if (isDL(unit.val().schoolModel))
        libs["{lib.DL}"] += 1;
      if (isTT(unit.val().schoolModel))
        libs["{lib.TT}"] += 1;

      if (isCSMGNT(unit.val().schoolModel))
        libs["{lib.CSMGNT}"] += 1;

      if (isMGCL(unit.val().schoolModel, unit.val().schoolType))
        libs["{lib.MGCL}"] += 1;
      else if (isMGDL(unit.val().schoolModel, unit.val().schoolType))
        libs["{lib.MGDL}"] += 1;
      else if (isMGTT(unit.val().schoolModel, unit.val().schoolType))
        libs["{lib.MGTT}"] += 1;
      else if (isMNCL(unit.val().schoolModel, unit.val().schoolType))
        libs["{lib.MNCL}"] += 1;
      else if (isMNDL(unit.val().schoolModel, unit.val().schoolType))
        libs["{lib.MNDL}"] += 1;
      else if (isMNTT(unit.val().schoolModel, unit.val().schoolType))
        libs["{lib.MNTT}"] += 1;

      if (unit.val().xadatchuan !== undefined && unit.val().xadatchuan === true) {
        PhuongDatChuanCounter[unit.val().ward] = 1;
        if (unit.val().ward2 !== undefined)
          PhuongDatChuanCounter[unit.val().ward2] = 1;
      }
    }
  });
  libs["{lib.MN}"] = Object.keys(MNCounter).length;
  libs["{lib.PhuongDatChuan}"] = Object.keys(PhuongDatChuanCounter).length;

  const QuanDatChuanCounter = {};
  let wards = 0;
  libs["{lib.PGD}"] = (childs !== undefined) ? Object.keys(childs).length : 0;
  if (cities[province] !== undefined && cities[province].districts !== undefined) {
    Object.keys(cities[province].districts).forEach(district => {
      let quandatchuan = true;
      if (cities[province].districts[district].wards !== undefined) {
        Object.keys(cities[province].districts[district].wards).forEach(wardInformation => {
          wards += 1;
          if (PhuongDatChuanCounter[wardInformation] === undefined)
            quandatchuan = quandatchuan && false;
        });
      }
      if (quandatchuan)
        QuanDatChuanCounter[district] = 1;
    });
  }
  libs["{lib.QuanDatChuan}"] = Object.keys(QuanDatChuanCounter).length;
  libs["{lib.Phuong}"] = wards;

  ["{lib.ThongTinChungO}", "{lib.ThongTinChungX}", "{lib.ThongTinChungAG}", "{lib.ThongTinChungY}", "{lib.ThongTinChungAH}", "{lib.ThongTinChungQ}", "{lib.ThongTinChungZ}"].forEach(lib => { libs[lib] = 0 });
  if (data !== undefined && data.thongtinchung !== undefined) {
    Object.keys(data.thongtinchung).forEach(pgd => { // pgd or ward
      if (data.thongtinchung[pgd].data !== undefined) {
        ["O", "X", "AG", "Y", "AH", "Q", "Z"].forEach(key => {
          if (data.thongtinchung[pgd].data[key] !== undefined)
            Object.keys(data.thongtinchung[pgd].data[key]).forEach(ward => {
              libs[`{lib.ThongTinChung${key}}`] += data.thongtinchung[pgd].data[key][ward];
            });
        });
      }
    })
  }

  ["{lib.NhanSucanbo}", "{lib.NhanSubienche}", "{lib.NhanSudantoc}", "{lib.NhanSuPhongcanbo}", "{lib.NhanSuPhongbienche}", "{lib.NhanSuPhongdantoc}", "{lib.NhanSucanbodatchuantrolen}", "{lib.NhanSucanbotrenchuan}", "{lib.NhanSucanbothieu}"].forEach(lib => { libs[lib] = 0 });
  if (data !== undefined && data.nhansu !== undefined) {
    Object.keys(data.nhansu).forEach(unit => {
      if (data.nhansu[unit].data !== undefined) {
        if (unit === unitID) {
          ["canbo", "bienche", "dantoc", "canbodatchuantrolen", "canbotrenchuan", "canbothieu"].forEach(type => {
            libs[`{lib.NhanSu${type}}`] = (isNaN(data.nhansu[unit].data[type]) || data.nhansu[unit].data[type] === "") ? 0 : eval(data.nhansu[unit].data[type]);
          });
        } else {
          ["canbo", "bienche", "dantoc"].forEach(type => {
            libs[`{lib.NhanSuPhong${type}}`] += (isNaN(data.nhansu[unit].data[type]) || data.nhansu[unit].data[type] === "") ? 0 : eval(data.nhansu[unit].data[type]);
          });
        }
      }
    });
  }
  return libs;
}

/**
 * Sum report by schoolType
 *
 * @module Libs.export
 * @ngdoc method
 * @name $evaluateReportsBySchoolType
 * @param  {String} [unitID]
 * @param  {Object} [data] reports data
 */
export function evaluateReportsBySchoolType(unitID, data) {
  const reports = {};
  if (data !== undefined && data[unitID] !== undefined) {
    ["cl", "dl", "tt"].forEach(schoolType => {
      reports[schoolType] = {};
      Object.keys(data[unitID]).forEach(report => {
        reports[schoolType][report] = {
          data: {}
        };
        if (data[unitID][report].data !== undefined && data[unitID][report].data[schoolType] !== undefined) {
          Object.keys(data[unitID][report].data[schoolType]).forEach(pgd => {
            Object.keys(data[unitID][report].data[schoolType][pgd]).forEach(school => {
              Object.keys(data[unitID][report].data[schoolType][pgd][school]).forEach(key => {
                const value = isNaN(data[unitID][report].data[schoolType][pgd][school][key]) ? 0 : data[unitID][report].data[schoolType][pgd][school][key];
                if (reports[schoolType][report].data[key] === undefined)
                  reports[schoolType][report].data[key] = value;
                else
                  reports[schoolType][report].data[key] += value;
              });
            });
          });
        }
      });
    });
  }
  return reports;
}

/**
 * Evaludate rows for exporting
 *
 * @module Libs.export
 * @ngdoc method
 * @name $computeSGDExportRows
 * @param  {Object} [rowExportEvals] eval schema for each row to export
 * @param  {Object} [data] reports data by schoolType
 */
export function computeSGDExportRows(rowExportEvals, data) {
  const evaluatedRows = {};
  Object.keys(rowExportEvals).forEach(row => {
    evaluatedRows[row] = {};
    ["cl", "dl", "tt"].forEach(schoolType => {
      evaluatedRows[row][schoolType] = eval(expandExp(data[schoolType], {}, rowExportEvals[row].eval));
    });
  });
  return evaluatedRows;
}

/**
 * Export one report to one sheet
 *
 * @module Libs.export
 * @ngdoc method
 * @name $computeSGDExportRows
 * @param  {String} [unitType] pgd or sgd
 * @param  {Object} [workbook] excel object
 * @param  {Object} [data] data as in firebase
 * @param  {Object} [schema] exports schema
 * @param  {Object} [manage] manage reducer
 */
export function writeReport(unitType, workbook, data, schema, manage, name = "", units = {}) {
  if (data !== undefined && schema.sheet !== undefined && schema.row !== undefined && schema.data !== undefined) {
    let rowIndex = schema.row;
    const sheet = workbook.sheet(schema.sheet);
    if (unitType === "th") {
      sheet.cell(`A${rowIndex}`).value(name);
      sheet.cell(`A${rowIndex}`).style({ bold: true, border: true });
      _.forEach(data, (value, key) => {
        if (_.get(schema.data, key)) {
          sheet.cell(`${schema.data[key]}${rowIndex}`).style("border", true);
          if (value)
            sheet.cell(`${schema.data[key]}${rowIndex}`).value(value);
        }
      });
    }
    else {
      const exportSchoolType = (row, schoolType, report) => {
        if (_.keys(report[schoolType] || {}).length < 1)
          return row;
        const schoolTypeIndex = row;
        let rowSTT = row;
        const sum = {};
        sheet.cell(`A${rowSTT}`).value(readSchoolTypeOrModel(schoolType));
        sheet.cell(`A${rowSTT}`).style({ bold: true, fill: { type: "solid", color: "A9F5F2" }, border: true });
        rowSTT += 1;
        const validUnits = {};
        _.forEach(report[schoolType], (reportsData, unitID) => {
          if (_.get(manage, `${unitID}.information`))
            validUnits[unitID] = {
              reportsData,
              name: _.get(manage, `${unitID}.information.name`, "")
            };
        });
        _.forEach(_.orderBy(validUnits, ["name"], ["asc"]), value => {
          sheet.cell(`A${rowSTT}`).value(value.name);
          sheet.cell(`A${rowSTT}`).style({ bold: true, border: true });

          let reportData = {};
          if (unitType === "pgd")
            reportData = value.reportsData;
          else if (unitType === "sgd") {
            _.keys(value.reportsData).forEach(schoolID => {
              _.keys(value.reportsData[schoolID]).forEach(key => {
                if (reportData[key] === undefined)
                  reportData[key] = 0;
                reportData[key] += isNaN(value.reportsData[schoolID][key]) ? 0 : value.reportsData[schoolID][key]
              });
            });
          }
          _.keys(reportData).forEach(key => {
            if (schema.data[key] !== undefined) {
              if (reportData[key])
                sheet.cell(`${schema.data[key]}${rowSTT}`).value(reportData[key]);
              sheet.cell(`${schema.data[key]}${rowSTT}`).style("border", true);

              if (sum[key] === undefined)
                sum[key] = 0;
              sum[key] += isNaN(reportData[key]) ? 0 : reportData[key];
            }
          });
          rowSTT += 1;
        });
        _.keys(sum).forEach(key => {
          sheet.cell(`${schema.data[key]}${schoolTypeIndex}`).value(sum[key]);
          sheet.cell(`${schema.data[key]}${schoolTypeIndex}`).style({ bold: true, fill: { type: "solid", color: "A9F5F2" }, border: true });
        });
        return rowSTT;
      };

      _.forEach(["cl", "dl"], schoolType => {
        if (_.get(data, schoolType))
          rowIndex = exportSchoolType(rowIndex, schoolType, data);
      });
      const groups = {
        "csmn": {},
        "nt": {},
        "lmg": {},
        "tt": {}
      };
      _.forEach(_.get(data, "tt"), (report, unit) => {
        if (unitType !== 'sgd') {
          const info = _.get(manage, unit);
          if (_.get(groups, _.get(info, "information.schoolModel")))
            groups[_.get(info, "information.schoolModel", "tt")][unit] = report;
          else
            groups.tt[unit] = report;
        } else {
          _.forEach(report, (childData, child) => {
            if (_.get(groups, _.get(units, `${child}.schoolModel`)))
              _.set(groups, `${_.get(units, `${child}.schoolModel`, "tt")}.${unit}.${child}`, childData);
            else
              _.set(groups, `tt.${unit}.${child}`, childData);
          });
        }
      });
      _.forEach(["tt", "csmn", "nt", "lmg"], schoolType => {
        if (_.keys(groups[schoolType]).length)
          rowIndex = exportSchoolType(rowIndex, schoolType, groups);
      });
    }
  }
}