import { intToExcelCol } from "excel-column-name";
import Excel from "exceljs";
import _ from "lodash";
import {
  writeHeaders,
  writeReport,
  writeCell,
  writeRange,
  round,
} from "./common";

const tonghopsuckhoelop = (
  healthRecords,
  unit,
  errorHandler,
  closeHandler,
  onComplete,
  pickedPeriod
) => {
  const mappingHealthList = {
    BT: "SK bình thường:",
    SDD: "Suy dinh dưỡng",
    [`DC-BP`]: "Thừa cân, béo phì",
    TuH: "Tuần hoàn",
    HH: "Hô hấp",
    TH: "Tiêu hoá",
    [`T-TN`]: "Thận, tiết niệu",
    [`TK-TT`]: "Thần kinh tâm thần",
    M: "Mắt",
    RHM: "Răng hàm mặt",
    TMH: "Tai mũi họng",
    CXK: "cơ xương khớp",
    TM: 'Bệnh về tim mạch',
    BK: "Bệnh khác",
  };
  const wb = new Excel.Workbook();
    if (!_.isEmpty(healthRecords)) {
      const sheet = wb.addWorksheet('Tong hop theo doi kham suc khoe', {
        pageSetup: {
          paperSize: 9,
          horizontalCentered: true,
          margins: {
            left: 0.5,
            right: 0.5,
            top: 1.2,
            bottom: 1.1,
            header: 0.7,
            footer: 0.8,
          },
          fitToPage: true,
          fitToWidth: 1
        },
      });
      sheet.getColumn(2).width = 4.1;
      sheet.getColumn(3).width = 21.4;
      sheet.getRow(5).height = 25.5;
      sheet.getRow(6).height = 12.75;
      const healthList = [];
      const originHealthList = [
          'BT',
          'SDD',
          'DC-BP',
          'TuH',
          'HH',
          'TH',
          'T-TN',
          'TK-TT',
          'M',
          'RHM',
          'TMH',
          'CXK',
          'BK',
          'TM'
      ]
      const rows = [];
      const counter = {
        BT: 0,
        SDD: 0,
        [`DC-BP`]: 0,
        TuH: 0,
        HH: 0,
        TH: 0,
        [`T-TN`]: 0,
        [`TK-TT`]: 0,
        M: 0,
        RHM: 0,
        TMH: 0,
        CXK: 0,
        BK: 0,
        total: 0,
        checked: 0,
        female: 0,
      };
      _.forEach(healthRecords, (ClassHealthRecord) => {
          const classsCounter = {
            BT: 0,
            SDD: 0,
            [`DC-BP`]: 0,
            TuH: 0,
            HH: 0,
            TH: 0,
            [`T-TN`]: 0,
            [`TK-TT`]: 0,
            M: 0,
            RHM: 0,
            TMH: 0,
            CXK: 0,
            BK: 0,
            total: 0,
            checked: 0,
            female: 0
          }
          const classData = _.get(ClassHealthRecord, pickedPeriod);
          const className = _.get(classData, 'name');
          if(!_.isEmpty(classData)) {
            const students = _.get(classData, 'students');
            counter.total += _.keys(students).length;
            counter.checked += _.filter(students, ({ healthRecords: healthData})=> healthData).length;
            counter.female += _.filter(students, ({ gender })=> gender === 'F').length;
            classsCounter.total = _.keys(students).length;
            classsCounter.checked = _.filter(students, ({ healthRecords: healthData})=> healthData).length;
            classsCounter.female = _.filter(students, ({ gender })=> gender === 'F').length;
            _.forEach(students, ({ healthRecords: healthData }) => {
              _.forEach(healthData, (health) => {
                if (_.keys(classsCounter).includes(health)) {
                  classsCounter[health] += 1;
                  counter[health] += 1;
                }
                if (!healthList.includes(health)) healthList.push(health);
              });
            });
            rows.push({
              classsCounter,
              className
            })
          }
      });
      const totalCol = healthList.length * 2 + 6;
      const healthKeys = [];

      _.forEach(originHealthList, (healthKey) => {
        if(healthList.includes(healthKey)) healthKeys.push(healthKey);
      });
      const infos = [
        { title: "Trường", value: _.get(unit, 'information.name', '') },
        { title: "Năm học", value: _.get(unit, 'activeYear', '') },
      ];
      _.forEach(infos, ({ title, value }, idx) => {
        writeRange(
            sheet,
            idx + 1,
            1,
            title,
            `A${idx + 1}:B${idx + 1}`,
            {
            bold: true,
            horizontal: "left",
        });
        writeRange(
            sheet,
            idx + 1,
            3,
            value,
            `C${idx + 1}:E${idx + 1}`,
            {
            color: "FFFF0000",
            bold: true,
            horizontal: "left",
            }
        );
      });
      writeRange(
        sheet,
        1,
        6,
        "THEO DÕI TỔNG HỢP TÌNH TRẠNG SỨC KHỎE HỌC SINH",
        `F1:${intToExcelCol(totalCol)}3`,
        {
          bold: true,
          fontSize: 16,
          wrapText: true,
        }
      );
      const headers  = [
          { name: 'STT', width: 4.7, wrapText: true },
          { name: 'Tên lớp', width: 12.7, wrapText: true },
          { name: 'Sĩ số', width: 8.7, wrapText: true },
          { name: 'Nữ', width: 8.7, wrapText: true },
          { name: 'Trẻ khám sức khỏe', subHeaders: [
              { name: 'Số trẻ', width: 8.7 },
              { name: 'Tỷ lệ', width: 8.7 },
          ]},
      ]
      _.forEach(healthKeys, (healthKey) => {
          headers.push(
              {name: `${_.get(mappingHealthList, (healthKey), '')}`, subHeaders: [
                  { name: 'Số trẻ', width: 8.7 },
                  { name: 'Tỉ lệ', width: 8.7 },
              ]},
          );
      });
      writeHeaders(
          sheet,
          headers,
          5,
          0,
          2
      );
      writeHeaders(
          sheet,
          _.map(_.range(1, totalCol + 1), (idx) => ({ name: idx})),
          7,
          0,
          1,
          {
              fill: 'FFD9D9D9'
          }
      )
      let rowIndex = 8;
      _.forEach(rows, (row, index) => {
          const {
              className,
              classsCounter
          } = row;
          writeCell(
              sheet,
              rowIndex,
              1,
              index + 1,
              {
                  border: true,
              }
          );
          writeCell(
              sheet,
              rowIndex,
              2,
              className,
              {
                  border: true,
              }
          );
          writeCell(
              sheet,
              rowIndex,
              3,
              _.get(classsCounter, 'total'),
              {
                  border: true,
              }
          );
          writeCell(
              sheet,
              rowIndex,
              4,
              _.get(classsCounter, 'female'),
              {
                  border: true,
              }
          );
          writeCell(
              sheet,
              rowIndex,
              5,
              _.get(classsCounter, 'checked'),
              {
                  border: true,
              }
          );
          const checkedRatio = (_.get(classsCounter, 'checked') / _.get(classsCounter, 'total')) * 100;
          writeCell(
            sheet,
            rowIndex,
            6,
            round(checkedRatio, 2),
            {
                border: true,
            }
        );
          let columnIndex = 7;
          _.forEach(healthKeys, (healthKey) => {
              writeCell(
                  sheet,
                  rowIndex,
                  columnIndex,
                  _.get(classsCounter, healthKey),
                  {
                      border: true
                  }
              );
              columnIndex += 1;
              const ratio = (_.get(classsCounter, healthKey) / _.get(classsCounter, 'total')) * 100;
              writeCell(
                  sheet,
                  rowIndex,
                  columnIndex,
                  round(ratio, 2),
                  {
                      border: true,
                  }
              );
              columnIndex += 1;
          })
          rowIndex += 1;
      });
      writeRange(
          sheet,
          rowIndex,
          1,
          'Tổng hợp',
          `A${rowIndex}:B${rowIndex}`,
          {
              border: true,
              bold: true,
          }
      );
      writeCell(
          sheet,
          rowIndex,
          3,
          _.get(counter, 'total'),
          {
              border: true
          }
      );
      writeCell(
          sheet,
          rowIndex,
          4,
          _.get(counter, 'female'),
          {
              border: true
          }
      );
      writeCell(
          sheet,
          rowIndex,
          5,
          _.get(counter, 'checked'),
          {
              border: true
          }
      );
      const totalCheckedRatio = (_.get(counter, 'checked') / _.get(counter, 'total')) * 100;
      writeCell(
        sheet,
        rowIndex,
        6,
        round(totalCheckedRatio, 2),
        {
            border: true
        }
    );
      let totalColIdx = 7;
      _.forEach(healthKeys, (healthKey) => {
          writeCell(
              sheet,
              rowIndex,
              totalColIdx,
              _.get(counter, healthKey),
              {
                  border: true,
              }
          );
          totalColIdx += 1;
          const ratio = (_.get(counter, healthKey) / _.get(counter, 'total')) * 100;
          writeCell(
              sheet,
              rowIndex,
              totalColIdx,
              round(ratio, 2),
              {
                  border: true,
              }
          );
          totalColIdx += 1;
      });
    };
  return wb.xlsx.writeBuffer().then((data) => {
    if (data && _.keys(healthRecords).length) {
      writeReport(
        new Blob([data]),
        `TongHopTheoDoiKhamSucKhoe ${_.get(unit, 'activeYear')}.xlsx`,
        closeHandler
      );
    } else {
      errorHandler("Xuất báo cáo không thành công, vui lòng kiểm tra lại");
      onComplete();
    }
  });
};

export default tonghopsuckhoelop;
