export const USER_FETCH = "USER_FETCH";
export const USER_IDENTIFY = "USER_IDENTIFY";
export const USER_UNIT = "USER_UNIT";
export const SIGN_OUT = "SIGN_OUT";
export const SYSTEM_ADMIN = "SYSTEM_ADMIN";

export const UNIT_FETCH = "UNIT_FETCH";
export const UNIT_ACTIVE_YEAR = "UNIT_ACTIVE_YEAR";
export const UNIT_ACTIVE_MILESTONE = "UNIT_ACTIVE_MILESTONE";
export const UNIT_NOTIFICATION = "UNIT_NOTIFICATION";
export const UNIT_DEADLINE = "UNIT_DEADLINE";

export const REPORTS_FETCHING = "REPORTS_FETCHING";
export const REPORTS_FETCH_ERROR = "REPORTS_FETCH_ERROR";
export const REPORTS_FETCH = "REPORTS_FETCH";
export const REPORT_THONGTINCHUNG = "REPORT_THONGTINCHUNG";
export const REPORT_VALIDATE = "REPORT_VALIDATE";
export const REPORT_EXPORTS = "REPORT_EXPORTS";

export const REBOT_DATA_READING = "REBOT_DATA_READING";
export const REBOT_DATA_READ_ERROR = "REBOT_DATA_READ_ERROR";
export const REBOT_DATA_REPORT = "REBOT_DATA_REPORT";
export const REBOT_THONGTINCHUNG = "REBOT_THONGTINCHUNG";
export const REBOT_NHANSU = "REBOT_NHANSU";

export const YEAR_FETCH = "YEAR_FETCH";

export const REPORT_STATUS = "REPORT_STATUS";

export const REBOT_REPORTS_MANAGE = "REBOT_REPORTS_MANAGE";
export const REBOT_UNITS_MANAGE = "REBOT_UNITS_MANAGE";

export const REBOT_MILESTONE = "REBOT_MILESTONE";
export const CLEAR_MILESTONE = "CLEAR_MILESTONE";

export const CANDO = "CANDO";
export const CANDO_CLASS = "CANDO_CLASS";