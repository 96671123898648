import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Radio, Input, Divider, Button, Dimmer, Loader, Dropdown, List, Label, Icon, TextArea, Checkbox } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import axios from "axios";

// services
import { db, app } from "../../libs/firebase";
import { getSchoolModelOptions, getSchoolTypeOptions } from "../../libs/school";
import { BACKEND_URL } from "../../libs/config";


const btoa = require("btoa");

const uuid = require("uuid/v1");
const cities = require("../../libs/cities.json");

const schoolModels = getSchoolModelOptions(false);
const schoolTypes = getSchoolTypeOptions(false);

export default class Unit extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    warningHandler: PropTypes.func.isRequired,
    unit: PropTypes.shape({
      type: PropTypes.string.isRequired,        // [sgd,pgd,th]
      search: PropTypes.string.isRequired,      // search key for firebase
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      phone: PropTypes.string,
      note: PropTypes.string,
      unitID: PropTypes.string,                 // if create new unit, leave it undeinfed
      province: PropTypes.string,               // only required for SGD
      district: PropTypes.string,               // only required for PGD
      ward: PropTypes.string,                   // only required for TH
      ward2: PropTypes.string,                  // only required for TH
      sgd: PropTypes.string,                    // only required for TH and PGD
      pgd: PropTypes.string,                    // only required for TH
      parent: PropTypes.string,                 // only required for TH and PGD
      schoolType: PropTypes.string,             // only required for TH
      schoolModel: PropTypes.string,            // only required for TH
      duoi7tre: PropTypes.bool,                 // optional for TH
      chuacapphep: PropTypes.bool,              // optional for TH
      xadatchuan: PropTypes.bool,               // optional for TH
      xakhokhan: PropTypes.bool,                // optional for TH
      xanongthonmoi: PropTypes.bool,            // optional for TH
      plugins: PropTypes.shape({                // optional for TH
        cando: PropTypes.bool,
        dinhduong: PropTypes.bool
      })
    }).isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      name: this.props.unit.name,
      address: this.props.unit.address,
      phone: (this.props.unit.phone === undefined) ? "" : this.props.unit.phone,
      note: (this.props.unit.note === undefined) ? "" : this.props.unit.note,
      selectedSchoolModel: this.props.unit.schoolModel,
      selectedSchoolType: this.props.unit.schoolType,
      duoi7tre: this.props.unit.duoi7tre !== undefined && this.props.unit.duoi7tre !== false,
      chuacapphep: this.props.unit.chuacapphep !== undefined && this.props.unit.chuacapphep !== false,
      xadatchuan: this.props.unit.xadatchuan !== undefined && this.props.unit.xadatchuan !== false,
      xakhokhan: this.props.unit.xakhokhan !== undefined && this.props.unit.xakhokhan !== false,
      xanongthonmoi: this.props.unit.xanongthonmoi !== undefined && this.props.unit.xanongthonmoi !== false,
      districts: [],
      wards: [],
      plugins: (this.props.unit.plugins !== undefined) ? {
        cando: this.props.unit.plugins.cando !== undefined && this.props.unit.plugins.cando,
        dinhduong: this.props.unit.plugins.dinhduong !== undefined && this.props.unit.plugins.dinhduong
      } : undefined,
      selectedDistrict: undefined,
      selectedWard: undefined
    }
  }

  // TH may need to select Districts and Wards
  componentWillMount() {
    if (cities[this.props.unit.province] !== undefined && cities[this.props.unit.province].districts !== undefined) {
      const districts = [];
      Object.keys(cities[this.props.unit.province].districts).map(key => (
        districts.push({
          key,
          text: cities[this.props.unit.province].districts[key].name,
          value: key
        })
      ));
      if (this.props.unit.district === undefined)
        this.setState({
          districts,
          wards: [],
          selectedDistrict: undefined,
          selectedWard: undefined
        });
      else {
        const wards = [];
        Object.keys(cities[this.props.unit.province].districts[this.props.unit.district].wards).map(key => (
          wards.push({
            key,
            text: cities[this.props.unit.province].districts[this.props.unit.district].wards[key].name,
            value: key
          })
        ));
        if (this.props.unit.ward !== undefined)
          this.setState({
            districts,
            wards,
            selectedDistrict: this.props.unit.district,
            selectedWard: this.props.unit.ward
          });
        else
          this.setState({
            districts,
            wards,
            selectedDistrict: this.props.unit.district,
            selectedWard: undefined
          });
      }
    }

    if (this.props.unit.unitID !== undefined) {
      this.adminRef = db.ref(`certificate/eduunits/${this.props.unit.unitID}/admin`);
      this.adminRef.on("value", data => {
        if (data !== null && data.val() !== null)
          this.setState({ certificateAdmin: data.val() });
        else
          this.setState({ certificateAdmin: "" });
      }, (error) => {
        if (error)
          this.notificationSystem.addNotification({
            title: "Lấy thông tin Admin thất bại",
            message: error,
            level: "error",
            position: "tr",
            autoDismiss: 4
          });
      });
      this.staffsRef = db.ref(`certificate/eduunits/${this.props.unit.unitID}/staffs`);
      this.staffsRef.on("value", data => {
        let emails = [];
        let certificateStaffs = "";
        if (data !== null && data.val() !== null) {
          certificateStaffs = data.val();
          emails = data.val().split(";").map(email => _.trim(email));
        }
        this.setState({ emails, certificateStaffs });
      }, (error) => {
        if (error)
          this.notificationSystem.addNotification({
            title: "Lấy danh sách Nhân sự thất bại",
            message: error,
            level: "error",
            position: "tr",
            autoDismiss: 4
          });
      });
    }
  }

  componentWillUnmount() {
    if (this.props.unit.unitID !== undefined) {
      this.adminRef.off();
      this.staffsRef.off();
    }
  }

  validate = () => {
    if (this.state.name === "") {
      this.props.warningHandler("Thông tin không hợp lệ", "Tên đơn vị không được bỏ trống");
      return false;
    }
    if (this.state.address === "") {
      this.props.warningHandler("Thông tin không hợp lệ", "Địa chỉ đơn vị không được bỏ trống");
      return false;
    }
    if (this.props.unit.type === "th") {
      if (this.state.selectedWard === undefined) {
        this.props.warningHandler("Thông tin không hợp lệ", "Phường/Xã không được bỏ trống");
        return false;
      }
      if (this.props.unit.sgd === undefined) {
        this.props.warningHandler("Thông tin không hợp lệ", "Không tìm thấy mã Sở Giáo dục");
        return false;
      }
      if (this.state.selectedSchoolModel === "none") {
        this.props.warningHandler("Thông tin không hợp lệ", "Quy mô trường không được bỏ trống");
        return false;
      }
      if (this.state.selectedSchoolType === "none") {
        this.props.warningHandler("Thông tin không hợp lệ", "Loại hình trường không được bỏ trống");
        return false;
      }
    }
    return true;
  }

  districtHandler = (event, { value }) => {
    this.setState({
      selectedDistrict: value,
      wards: [],
      selectedWard: undefined
    });
    const wards = [];
    Object.keys(cities[this.props.unit.province].districts[value].wards).map(key => (
      wards.push({
        key,
        text: cities[this.props.unit.province].districts[value].wards[key].name,
        value: key
      })
    ));
    this.setState({ wards });
  }

  backupCando = () => {
    this.setState({
      isWaiting: true
    });
    const onError = (err) => {
      console.log(err);
      this.setState({ isWaiting: false });
      this.props.errorHandler(err.message || "Lỗi không xác định");
    }
    app.auth().currentUser.getIdToken(true).then(token => {
      const { unit } = this.props;
      const { unitID, sgd, classes } = unit;
      const year = moment().year();

      const data = {
        isSupport: true,  // TODO: get from user
        sgd,
        unitID,
        year: `${year - 1}-${year}`,
        classes: classes || {}
      };
      axios.post(`${BACKEND_URL}/userRequest`, {
        token,
        project: "rebot",
        type: "backup",
        data: JSON.stringify(data)
      }).then((res) => {
        if (res.status === 200) {
          this.setState({ isWaiting: false });
          this.props.successHandler('Backup thành công');
        } else
          onError(res.status);
      }).catch(onError);
    }).catch(onError);;
  }

  saveHandler = () => {
    if (this.validate()) {
      this.setState({ isWaiting: true });
      // Required shared information for all TH, PGD, SGD
      const updateContent = {
        name: this.state.name,
        address: this.state.address,
        phone: this.state.phone,
        note: this.state.note,
        search: this.props.unit.search,
        type: this.props.unit.type
      };

      switch (this.props.unit.type) {
        case "sgd":
          updateContent.province = this.props.unit.province;
          break;
        case "pgd":
          updateContent.district = this.props.unit.district;
          updateContent.sgd = this.props.unit.sgd;
          break;
        case "th":
          updateContent.schoolType = this.state.selectedSchoolType;
          updateContent.schoolModel = this.state.selectedSchoolModel;
          updateContent.duoi7tre = this.state.duoi7tre !== undefined && this.state.duoi7tre !== false;
          updateContent.chuacapphep = this.state.chuacapphep !== undefined && this.state.chuacapphep !== false;
          updateContent.xadatchuan = this.state.xadatchuan !== undefined && this.state.xadatchuan !== false;
          updateContent.xakhokhan = this.state.xakhokhan !== undefined && this.state.xakhokhan !== false;
          updateContent.xanongthonmoi = this.state.xanongthonmoi !== undefined && this.state.xanongthonmoi !== false;
          updateContent.ward = this.state.selectedWard;
          updateContent.sgd = this.props.unit.sgd;
          // It could be wrong if this TH are belong to a PGD but only provice SGD => The system may think this TH is belong to SGD after updating
          if (this.props.unit.pgd !== undefined)
            updateContent.pgd = this.props.unit.pgd;
          if (this.state.plugins !== undefined && this.state.plugins.cando !== undefined && this.state.plugins.cando)
            updateContent.plugins = {
              cando: true
            };
          if (this.state.plugins !== undefined && this.state.plugins.dinhduong !== undefined && this.state.plugins.dinhduong) {
            if (updateContent.plugins)
              updateContent.plugins.dinhduong = true;
            else
              updateContent.plugins = {
                dinhduong: true
              };
          }
          break;
        default:
      }

      const unitID = (this.props.unit.unitID !== undefined) ? this.props.unit.unitID : uuid();
      db.ref(`eduunits/${unitID}`).update(updateContent, (error) => {
        this.setState({ isWaiting: false });
        if (error)
          this.props.errorHandler(error);
        else if (this.props.unit.unitID === undefined && this.props.unit.parent !== undefined) {
          db.ref(`eduunits/${this.props.unit.parent}/units`).update({ [unitID]: true }, (err) => {
            if (err)
              this.props.errorHandler(err);
            else
              this.props.successHandler();
          });
        } else
          this.props.successHandler();
      });
    }
  }

  certificateHandler = () => {
    if (this.state.certificateAdmin !== undefined) {
      this.setState({ isWaiting: true });
      if (_.trim(this.state.certificateAdmin) !== "") {
        db.ref(`certificate/email/${btoa(_.trim(this.state.certificateAdmin))}/eduunits/${this.props.unit.unitID}`).set(true, error => {
          if (error) {
            this.props.errorHandler(error);
            this.setState({ isWaiting: false });
          } else {
            db.ref(`certificate/eduunits/${this.props.unit.unitID}/admin`).set(this.state.certificateAdmin.trim(), (err) => {
              if (err)
                this.props.errorHandler(err);
              else
                this.props.successHandler("Cập nhật thông tin Admin thành công");
              this.setState({ isWaiting: false });
            });
          }
        });
      }
    }
    if (this.state.certificateStaffs !== undefined) {
      this.setState({ isWaiting: true });
      const emails = _.compact(this.state.certificateStaffs.split(";").map(email => _.trim(email)));
      if (emails.length === 0) {
        db.ref(`certificate/eduunits/${this.props.unit.unitID}/staffs`).set("", (err) => {
          if (err)
            this.props.errorHandler(err);
          else
            this.props.successHandler("Cập nhật thông tin Nhân sự thành công");
          this.setState({ isWaiting: false });
        });
      }
      else {
        let count = 0;
        emails.forEach(email => {
          if (email !== "" && email.length > 10) {
            db.ref(`certificate/email/${btoa(_.trim(email))}/eduunits/${this.props.unit.unitID}`).set(false, error => {
              count += 1;
              if (error)
                this.props.errorHandler(`Cập nhật email ${email} bị lỗi: ${error}`);
              else if (count === emails.length) {
                db.ref(`certificate/eduunits/${this.props.unit.unitID}/staffs`).set(this.state.certificateStaffs, (err) => {
                  if (err)
                    this.props.errorHandler(err);
                  else
                    this.props.successHandler("Cập nhật thông tin Nhân sự thành công");
                  this.setState({ isWaiting: false });
                });
              }
            });
          }
        });
      }
    }
  }

  emailHandler = () => {
    const emails = this.state.certificateStaffs.split(";").map(email => _.trim(email));
    this.setState({ emails });
  }

  candoHandler = () => {
    if (this.state.plugins !== undefined && this.state.plugins.cando !== undefined && this.state.plugins.cando)
      this.setState({
        plugins: {
          ..._.get(this.state, "plugins", {}),
          cando: false
        }
      });
    else
      this.setState({
        plugins: {
          ..._.get(this.state, "plugins", {}),
          cando: true
        }
      });
  }

  dinhduongHandler = () => {
    if (this.state.plugins !== undefined && this.state.plugins.dinhduong !== undefined && this.state.plugins.dinhduong)
      this.setState({
        plugins: {
          ..._.get(this.state, "plugins", {}),
          dinhduong: false
        }
      });
    else
      this.setState({
        plugins: {
          ..._.get(this.state, "plugins", {}),
          dinhduong: true
        }
      });
  }

  renderSchoolInformation = () => (
    <div>
      <Form.Group inline>
        <Form.Field>
          <label>Quy mô: </label>
          <Dropdown
            search
            selection
            fluid
            multiple={false}
            options={schoolModels}
            value={this.state.selectedSchoolModel}
            onChange={(e, { value }) => this.setState({ selectedSchoolModel: value })}
            placeholder="Chọn quy mô trường"
          />
        </Form.Field>
        <Form.Field>
          <label>Loại hình trường: </label>
          <Dropdown
            search
            selection
            fluid
            multiple={false}
            options={schoolTypes}
            value={this.state.selectedSchoolType}
            onChange={(e, { value }) => this.setState({ selectedSchoolType: value })}
            placeholder="Chọn loại hình trường"
          />
        </Form.Field>
        <Form.Checkbox checked={this.state.duoi7tre} onChange={() => this.setState({ duoi7tre: !this.state.duoi7tre })} label="Dưới 7 trẻ" />
        <Form.Checkbox checked={this.state.chuacapphep} onChange={() => this.setState({ chuacapphep: !this.state.chuacapphep })} label="Chưa cấp phép" />
      </Form.Group>
      <Form.Group inline>
        <Form.Checkbox checked={this.state.xadatchuan} onChange={() => this.setState({ xadatchuan: !this.state.xadatchuan })} label="Xã đạt chuẩn PCGDMN5T" />
        <Form.Checkbox checked={this.state.xakhokhan} onChange={() => this.setState({ xakhokhan: !this.state.xakhokhan })} label="Xã đặc biệt khó khăn" />
        <Form.Checkbox checked={this.state.xanongthonmoi} onChange={() => this.setState({ xanongthonmoi: !this.state.xanongthonmoi })} label="Xã xây dựng nông thôn mới" />
      </Form.Group>
    </div>
  )

  renderProvinceDistrictWard = () => {
    const dropdownOptions = { search: true, selection: true, multiple: false };
    return (
      <Form.Group widths="equal">
        <Form.Field>
          <label>Tỉnh</label>
          <Input value={cities[this.props.unit.province].name} />
        </Form.Field>
        <Form.Field>
          <label>Quận/Huyện</label>
          <Dropdown
            {...dropdownOptions}
            label="Quận: "
            placeholder="Nhập tên Quận Huyện"
            options={this.state.districts}
            value={this.state.selectedDistrict}
            onChange={this.districtHandler}
          />
        </Form.Field>
        <Form.Field required>
          <label>Phường/Xã</label>
          <Dropdown
            {...dropdownOptions}
            label="Phường: "
            placeholder="Nhập tên Phường Xã"
            options={this.state.wards}
            value={this.state.selectedWard}
            onChange={(e, { value }) => this.setState({ selectedWard: value })}
          />
        </Form.Field>
        {(this.props.unit.ward2 !== undefined) ? (
          <Form.Field required>
            <label>Liên xã</label>
            <Dropdown
              {...dropdownOptions}
              label="Phường: "
              placeholder="Nhập tên Phường Xã"
              disabled
              options={this.state.wards}
              value={this.props.unit.ward2}
            />
          </Form.Field>
        ) : null}
      </Form.Group>
    );
  }

  render() {
    const { unit } = this.props;
    return (
      <div>
        {this.state.isWaiting ? (
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
        ) : null}
        <Form>
          <Form.Group inline>
            <label>Loại đơn vị: </label>
            <Form.Field control={Radio} label="Sở Giáo dục" value="sgd" checked={this.props.unit.type === "sgd"} />
            <Form.Field control={Radio} label="Phòng Giáo dục" value="pgd" checked={this.props.unit.type === "pgd"} />
            <Form.Field control={Radio} label="Trường học" value="th" checked={this.props.unit.type === "th"} />
          </Form.Group>
          <Form.Group inline>
            <label>Plugins: </label>
            <Form.Field
              control={Checkbox}
              label="Cân đo"
              value="cando"
              checked={this.state.plugins !== undefined && this.state.plugins.cando !== undefined && this.state.plugins.cando}
              onChange={this.candoHandler}
            />
            <Form.Field
              control={Checkbox}
              label="Dinh dưỡng"
              value="dinhduong"
              checked={this.state.plugins !== undefined && this.state.plugins.dinhduong !== undefined && this.state.plugins.dinhduong}
              onChange={this.dinhduongHandler}
            />
          </Form.Group>
          {(this.props.unit.type === "th") ? this.renderSchoolInformation() : null}
          {(this.props.unit.type === "th") ? this.renderProvinceDistrictWard() : null}
          <Form.Field required>
            <label>Tên đơn vị</label>
            <Input placeholder="Tên đơn vị" value={this.state.name} onChange={(event, { value }) => this.setState({ name: value })} />
          </Form.Field>
          <Form.Group>
            <Form.Field required width="11">
              <label>Địa chỉ</label>
              <Input placeholder="Địa chỉ" value={this.state.address} onChange={(event, { value }) => this.setState({ address: value })} />
            </Form.Field>
            <Form.Field width="5">
              <label>SĐT</label>
              <Input placeholder="SĐT" value={this.state.phone} onChange={(event, { value }) => this.setState({ phone: value })} />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Ghi chú</label>
            <Form.TextArea placeholder="Ghi chú" value={this.state.note} onChange={(event, { value }) => this.setState({ note: value })} />
          </Form.Field>
          {(this.state.certificateAdmin !== undefined) ? (
            <Form.Field>
              <label>Email Admin</label>
              <Input placeholder="Email của Admin đơn vị" value={this.state.certificateAdmin} onChange={(event, { value }) => this.setState({ certificateAdmin: value })} />
            </Form.Field>
          ) : null}
          {(this.state.certificateStaffs !== undefined) ? (
            <Form.Field>
              <label>Danh sách Nhân sự</label>
              <TextArea icon={<Button icon="wizard" onClick={this.emailHandler} />} placeholder="Sử dụng ; để phân cách cùng lúc nhiều email" value={this.state.certificateStaffs} onChange={(event, { value }) => this.setState({ certificateStaffs: value })} />
              {(this.state.emails !== undefined) ? (
                <List horizontal>
                  {this.state.emails.map((email, index) => (
                    <List.Item key={index}>
                      <List.Content>
                        <Label>
                          <Icon name="mail" />
                          {' '}
                          {email}
                        </Label>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              ) : null}
            </Form.Field>
          ) : null}
          <Divider />
          <div>
            {(this.staffsRef !== undefined && this.adminRef !== undefined) ? <Button color="blue" onClick={this.certificateHandler}>Cập nhật nhân sự</Button> : null}
            {_.get(unit, "plugins.cando", false) ? <Button color="teal" onClick={this.backupCando}>Backup Cân đo</Button> : null}
            <Button style={{ float: "right", marginBottom: "10px" }} color="brown" onClick={this.saveHandler}>Cập nhật hồ sơ</Button>
          </div>
        </Form>
      </div>
    )
  }
}