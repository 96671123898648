/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Button,
  Modal,
  Header,
  Table,
  Dropdown,
  Form,
  Radio
} from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import moment from "moment";

import { db } from "../../../libs/firebase";
import { RESOURCES } from "../../../libs/config";
import { convertTimeStamp } from "../../../libs/time";
import {
  evaluateStudents,
  computeBMIPointResult,
  computeHeightPointResult,
  computeWeightLengthPointResult,
  computeWeightPointResult,
  readDoTuoi
} from "./helpers";


const monthsOptions = _.map([8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7], idx => ({
  key: idx,
  text: idx,
  value: idx
}));

const description = {
  NC2: "SDD nhẹ cân (nặng)",
  NC1: "SDD nhẹ cân",
  TC2: "SDD thấp còi (nặng)",
  TC1: "SDD thấp còi",
  GC1: "SDD gầy còm",
  GC2: "SDD gầy còm (nặng)",
  BP: "Béo phì",
  DC: "Dư cân",
  BT: "Bình thường",
  [`BT+`]: "Trên mức bình thường"
};

class HeathTrack extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    closeHandler: PropTypes.func.isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape({
      classID: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      grade: PropTypes.arrayOf(PropTypes.string).isRequired
    })).isRequired,
    unitID: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    activeYear: PropTypes.string.isRequired,
    monthsStatus: PropTypes.object.isRequired,
    solieucando: PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const year = this.computeYear(9);
    this.state = {
      isExporting: false,
      useDay: false,
      waiting: {},
      day: 1,
      month: 9,
      daysOptions: _.range(moment(`${year}-09`, "YYYY-MM").daysInMonth()).map(idx => ({
        key: idx + 1,
        text: idx + 1,
        value: idx + 1
      }))
    };
  }

  getStatus = (classID, month) => {
    const { monthsStatus } = this.props;
    if (_.get(monthsStatus, `${classID}.${month}`))
      return `${_.get(monthsStatus, `${classID}.${month}.modifiedBy`)}. ${convertTimeStamp(_.get(monthsStatus, `${classID}.${month}.modifiedTime`))}`;
    return "Chưa có số liệu";
  }

  computeYear = (month) => {
    const { activeYear } = this.props;
    if (month >= 9)
      return parseFloat(activeYear);
    return parseFloat(activeYear) + 1;
  }

  exportErrorHandler = () => {
    this.setState({ isExporting: false });
    this.props.errorHandler("Xuất báo cáo không thành công.");
  }

  doExport = (classesData) => {
    this.setState({ isExporting: false, waiting: {} });
    const isAbnormal = (weightPoint, heightPoint, weightLengthPoint) => {
      if (weightPoint && weightPoint !== "BT" && weightPoint !== "BT+")
        return true;
      if (heightPoint && heightPoint !== "BT" && heightPoint !== "BT+")
        return true;
      if (weightLengthPoint && weightLengthPoint !== "BT" && weightLengthPoint !== "BT+")
        return true;
      return false;
    }
    const { month, day } = this.state;
    const {
      classes,
      activeYear,
      solieucando,
      unitName,
      unit,
    } = this.props;

    try {
      const req = new XMLHttpRequest();
      req.open("GET", `${RESOURCES}/rebot/export/tonghopsk.xlsx`, true);
      req.responseType = "arraybuffer";
      req.onload = () => {
        let year = parseFloat(activeYear);
        if (month < 8)
          year += 1;
        XlsxPopulate.fromDataAsync(req.response).then(wb => {
          const allSheet = wb.sheet(0);
          const sddSheet = wb.sheet(1);
          allSheet.name(`SK Tháng ${month}`);
          allSheet.cell("A2").value(`Trường: ${unitName}`);
          allSheet.cell("A3").value(`Thời điểm: ${day}/${month}/${year}`);
          allSheet.column('C').width(18);
          allSheet.row(5).height(18);
          allSheet.range('A5', 'S7').style({ verticalAlignment: 'center' });
          sddSheet.cell("A4").value(`Trường: ${unitName}`);
          sddSheet.cell("A5").value(`Thời điểm: ${day}/${month}/${year}`);
          sddSheet.range('H8', 'I8').merged(false);
          sddSheet.range('H8', 'J8').merged(true);
          sddSheet.range('H8', 'J8').style({ border: true });
          sddSheet.range('J9', 'J10').merged(true);
          sddSheet.range('J9', 'J10').style({ border: true });
          sddSheet.cell('J9').value('Suy Dinh Dưỡng').style({ wrapText: true, bold: true });
          sddSheet.column('H').width(10);
          sddSheet.column('I').width(8);
          sddSheet.column('J').width(11);
          
          let allRowIndex = 8;
          let sddRowIndex = 11;
          const counter = {
            total: 0,
            weight: {},
            height: {},
            BT: 0,
            SDDTD: 0,
            DC: 0,
            BP: 0,
            SDDGCN: 0,
          };
          _.forEach(classes, (classInfo, index) => {
            const { classID, name } = classInfo;
            allSheet.range(`A${allRowIndex}`, `S${allRowIndex}`).style("border", true);
            allSheet.cell(`A${allRowIndex}`).value(index + 1);
            allSheet.cell(`B${allRowIndex}`).value(name);
            const sgd = _.get(this.props, 'unit.information.sgd')
            const data = evaluateStudents(
              _.get(classesData, classID, {}),
              parseFloat(month),
              activeYear,
              solieucando,
              day,
              this.state.useDay,
              sgd
            ).map(student => {
              const weightPoint = (student.weightPoint !== undefined) ? computeWeightPointResult(student.weightPoint) : "";
              const heightPoint = (student.heightPoint !== undefined) ? computeHeightPointResult(student.heightPoint) : "";
              const bmiPoint = (student.bmiPoint !== undefined) ? computeBMIPointResult(student.bmiPoint) : "";
              const weightLengthPoint = (student.weightLengthPoint !== undefined) ? computeWeightLengthPointResult(student.weightLengthPoint) : "";
              let warning = "";
              if (weightPoint === "BT+")
                warning = "Nặng hơn";
              if (heightPoint === "BT+") {
                if (warning === "")
                  warning = "Cao hơn so với tuổi";
                else
                  warning += " và Cao hơn so với tuổi";
              } else if (weightPoint === "BT+")
                warning += " so với tuổi";
              return ({
                ...student,
                weightPoint,
                heightPoint,
                bmiPoint,
                weightLengthPoint,
                warning
              });
            });
            const subCounter = {
              checked: _.filter(data, ({ height, weight }) => height && weight ).length,
              total: data.length,
              weight: {},
              height: {},
              BT: 0,
              SDDTD: 0,
              DC: 0,
              BP: 0
            };
            counter.total += subCounter.total;
            allSheet.cell(`C${allRowIndex}`).value(_.keys(data).length);
            _.forEach(_.orderBy(data, ["lastName", "firstName"]), (student, stt) => {
              const {
                lastName,
                firstName,
                height,
                heightPoint,
                weight,
                weightPoint,
                weightLengthPoint,
                bmiPoint
              } = student;
              if (isAbnormal(weightPoint, heightPoint, bmiPoint || weightLengthPoint)) {
                sddSheet.range(`A${sddRowIndex}`, `J${sddRowIndex}`).style("border", true);
                sddSheet.cell(`A${sddRowIndex}`).value(stt + 1);
                sddSheet.cell(`B${sddRowIndex}`).value(`${lastName} ${firstName}`);
                sddSheet.cell(`C${sddRowIndex}`).value(name);
                if (weight)
                  sddSheet.cell(`D${sddRowIndex}`).value(weight);
                if (weightPoint && description[weightPoint])
                  sddSheet.cell(`E${sddRowIndex}`).value(description[weightPoint]);
                if (height)
                  sddSheet.cell(`F${sddRowIndex}`).value(height);
                if (heightPoint && description[heightPoint])
                  sddSheet.cell(`G${sddRowIndex}`).value(description[heightPoint]);
                if (weightLengthPoint === "DC" || bmiPoint === "DC")
                  sddSheet.cell(`H${sddRowIndex}`).value("X");
                if (weightLengthPoint === "BP" || bmiPoint === "BP")
                  sddSheet.cell(`I${sddRowIndex}`).value("X");
                if (weightLengthPoint === "GC1"  || weightLengthPoint === "GC2")
                  sddSheet.cell(`J${sddRowIndex}`).value("X");
                if (bmiPoint === "GC1"  || bmiPoint === "GC2")
                  sddSheet.cell(`J${sddRowIndex}`).value("X");
                sddRowIndex += 1;
              }
              subCounter.weight[weightPoint] = _.get(subCounter.weight, weightPoint, 0) + 1;
              subCounter.height[heightPoint] = _.get(subCounter.height, heightPoint, 0) + 1;
              counter.weight[weightPoint] = _.get(counter.weight, weightPoint, 0) + 1;
              counter.height[heightPoint] = _.get(counter.height, heightPoint, 0) + 1;
              if ((bmiPoint || weightLengthPoint) === "BT") {
                subCounter.BT += 1;
                counter.BT += 1;
              } else if ((bmiPoint || weightLengthPoint) === "GC1" || (bmiPoint || weightLengthPoint) === "GC2") {
                if ((bmiPoint || weightLengthPoint) === "GC2") {
                  counter.SDDGCN += 1;
                }
                subCounter.SDDTD += 1;
                counter.SDDTD += 1;
              } else if ((bmiPoint || weightLengthPoint) === "DC") {
                subCounter.DC += 1;
                counter.DC += 1;
              } else if ((bmiPoint || weightLengthPoint) === "BP") {
                subCounter.BP += 1;
                counter.BP += 1;
              }
            });
            if (subCounter.total) {
              const subWeightBT = unit.information.sgd === 'tayninh' ? (_.get(subCounter.weight, "BT", 0) + _.get(subCounter.weight, "BT+", 0) - subCounter.DC - subCounter.BP) : _.get(subCounter.weight, "BT", 0) + _.get(subCounter.weight, "BT+", 0);
              const subHeightBT = unit.information.sgd === 'tayninh' ? (_.get(subCounter.height, "BT", 0) + _.get(subCounter.height, "BT+", 0) - subCounter.DC - subCounter.BP) : _.get(subCounter.height, "BT", 0) + _.get(subCounter.height, "BT+", 0);
              allSheet.cell(`D${allRowIndex}`).value(subWeightBT);
              allSheet.cell(`E${allRowIndex}`).value(Math.round(subWeightBT * 10000 / subCounter.total) / 100);
              allSheet.cell(`F${allRowIndex}`).value(_.get(subCounter.weight, "NC1", 0) + _.get(subCounter.weight, "NC2", 0));
              allSheet.cell(`G${allRowIndex}`).value(Math.round((_.get(subCounter.weight, "NC1", 0) + _.get(subCounter.weight, "NC2", 0)) * 10000 / subCounter.total) / 100);

              allSheet.cell(`H${allRowIndex}`).value(subHeightBT);
              allSheet.cell(`I${allRowIndex}`).value(Math.round(subHeightBT * 10000 / subCounter.total) / 100);
              allSheet.cell(`J${allRowIndex}`).value(_.get(subCounter.height, "TC1", 0) + _.get(subCounter.height, "TC2", 0));
              allSheet.cell(`K${allRowIndex}`).value(Math.round((_.get(subCounter.height, "TC1", 0) + _.get(subCounter.height, "TC2", 0)) * 10000 / subCounter.total) / 100);

              allSheet.cell(`L${allRowIndex}`).value(_.get(subCounter, "BT", 0));
              allSheet.cell(`M${allRowIndex}`).value(Math.round(_.get(subCounter, "BT", 0) * 10000 / subCounter.total) / 100);
              allSheet.cell(`N${allRowIndex}`).value(_.get(subCounter, "SDDTD", 0));
              allSheet.cell(`O${allRowIndex}`).value(Math.round(_.get(subCounter, "SDDTD", 0) * 10000 / subCounter.total) / 100);
              allSheet.cell(`P${allRowIndex}`).value(_.get(subCounter, "DC", 0));
              allSheet.cell(`Q${allRowIndex}`).value(Math.round(_.get(subCounter, "DC", 0) * 10000 / subCounter.total) / 100);
              allSheet.cell(`R${allRowIndex}`).value(_.get(subCounter, "BP", 0));
              allSheet.cell(`S${allRowIndex}`).value(Math.round(_.get(subCounter, "BP", 0) * 10000 / subCounter.total) / 100);
            }
            allRowIndex += 1;
          });
          const weightBT = unit.information.sgd === 'tayninh' ? (_.get(counter.weight, "BT", 0) + _.get(counter.weight, "BT+", 0) - counter.BP - counter.DC) : _.get(counter.weight, "BT", 0) + _.get(counter.weight, "BT+", 0);
          const heightBT = unit.information.sgd === 'tayninh' ? (_.get(counter.height, "BT", 0) + _.get(counter.height, "BT+", 0) - counter.BP - counter.DC) : _.get(counter.height, "BT", 0) + _.get(counter.height, "BT+", 0);
          allSheet.range(`A${allRowIndex}`, `B${allRowIndex}`).merged(true);
          allSheet.cell(`A${allRowIndex}`).value('Tổng cộng').style({ horizontalAlignment: 'center' });
          allSheet.cell(`C${allRowIndex}`).value(counter.total);
          allSheet.cell(`D${allRowIndex}`).value(weightBT);
          allSheet.cell(`E${allRowIndex}`).value(Math.round(weightBT * 10000  / counter.total) / 100);
          allSheet.cell(`F${allRowIndex}`).value(_.get(counter.weight, 'NC1', 0) + _.get(counter.weight, 'NC2', 0));
          allSheet.cell(`G${allRowIndex}`).value(Math.round((_.get(counter.weight, 'NC1', 0) + _.get(counter.weight, 'NC2', 0) ) * 10000  / counter.total)  / 100);
          allSheet.cell(`H${allRowIndex}`).value(heightBT);
          allSheet.cell(`I${allRowIndex}`).value(Math.round(heightBT * 10000 / counter.total)  / 100)
          allSheet.cell(`J${allRowIndex}`).value(_.get(counter.height, 'TC1', 0) + _.get(counter.height, 'TC2', 0));
          allSheet.cell(`K${allRowIndex}`).value(Math.round((_.get(counter.height, 'TC1', 0) + _.get(counter.height, 'TC2', 0)) * 10000 / counter.total) / 100)
          allSheet.cell(`L${allRowIndex}`).value(counter.BT);
          allSheet.cell(`M${allRowIndex}`).value(Math.round(counter.BT * 10000 / counter.total) / 100);
          allSheet.cell(`N${allRowIndex}`).value(counter.SDDTD);
          allSheet.cell(`O${allRowIndex}`).value(Math.round(counter.SDDTD * 10000 / counter.total) / 100);
          allSheet.cell(`P${allRowIndex}`).value(counter.DC);
          allSheet.cell(`Q${allRowIndex}`).value(Math.round(counter.DC * 10000 / counter.total) / 100);
          allSheet.cell(`R${allRowIndex}`).value(counter.BP);
          allSheet.cell(`S${allRowIndex}`).value(Math.round(counter.BP * 10000 / counter.total) / 100)
          allSheet.range(`A${allRowIndex}`, `S${allRowIndex}`).style({ horizontalAlignment: 'center', bold: true, border: true });
          allRowIndex += 1;
          allSheet.range(`A${allRowIndex}`, `B${allRowIndex + 5}`).merged(true);
          allSheet.range(`A${allRowIndex}`, `B${allRowIndex + 5}`).style({
            border: false,
            bold: true,
            horizontalAlignment: "center",
            verticalAlignment: "center"
          });
          allSheet.cell(`A${allRowIndex}`).value("Kết quả");

          allSheet.range(`C${allRowIndex}`, `C${allRowIndex + 1}`).merged(true);
          allSheet.cell(`C${allRowIndex}`).value("Bình thường (BT): ");

          allSheet.range(`D${allRowIndex}`, `S${allRowIndex}`).merged(true);
          allSheet.cell(`D${allRowIndex}`).value(`Số trẻ đạt BT cân nặng/số trẻ được cân, tỷ lệ: ${counter.weight.BT || counter.weight['BT+'] ? ` ${weightBT}/${counter.total}; ${Math.round(weightBT * 10000 / counter.total) / 100}%` : ''}`);

          allSheet.range(`D${allRowIndex + 1}`, `S${allRowIndex + 1}`).merged(true);
          allSheet.cell(`D${allRowIndex + 1}`).value(`Số trẻ đạt BT chiều cao/số trẻ được đo, tỷ lệ: ${counter.height.BT || counter.height['BT+'] ? `${ heightBT }/${counter.total}; ${Math.round(heightBT * 10000 / counter.total) / 100}%` : ''}`);

          allSheet.range(`C${allRowIndex + 2}`, `S${allRowIndex + 2}`).merged(true);
          allSheet.cell(`C${allRowIndex + 2}`).value(`SDD thể nhẹ cân: Số trẻ SDD /số trẻ được cân, tỷ lệ: ${(counter.weight.NC1 || counter.weight.NC2) ? ` ${_.get(counter.weight, "NC1", 0) + _.get(counter.weight, "NC2", 0)}/${counter.total}; ${Math.round((_.get(counter.weight, "NC1", 0) + _.get(counter.weight, "NC2", 0)) * 10000 / counter.total) / 100}%` : ''}`);

          allSheet.range(`C${allRowIndex + 3}`, `S${allRowIndex + 3}`).merged(true);
          allSheet.cell(`C${allRowIndex + 3}`).value(`SDD thể thấp còi: Số trẻ SDD /số trẻ được đo, tỷ lệ: ${(counter.height.TC1 || counter.height.TC2) ? ` ${_.get(counter.height, "TC1", 0) + _.get(counter.height, "TC2", 0)}/${counter.total}; ${Math.round((_.get(counter.height, "TC1", 0) + _.get(counter.height, "TC2", 0)) * 10000 / counter.total) / 100}%` : ''}`);

          allSheet.range(`C${allRowIndex + 4}`, `S${allRowIndex + 4}`).merged(true);
          allSheet.cell(`C${allRowIndex + 4}`).value(`Thừa cân (TC): Số trẻ TC/số trẻ được cân, tỷ lệ: ${counter.DC ? `${counter.DC}/${counter.total}; ${Math.round(counter.DC * 10000 / counter.total) / 100}%` : ''}`);

          allSheet.range(`C${allRowIndex + 5}`, `S${allRowIndex + 5}`).merged(true);
          allSheet.cell(`C${allRowIndex + 5}`).value(`Béo phì (BP): Số trẻ BP/số trẻ được cân, tỷ lệ: ${counter.BP ? ` ${counter.BP}/${counter.total}; ${Math.round(counter.BP * 10000 / counter.total) / 100}%` : ''}`);

          allSheet.range(`A${allRowIndex}`, `S${allRowIndex + 5}`).style({
            border: true,
            bold: true,
            horizontalAlignment: "left",
            verticalAlignment: "center"
          });
          allSheet.cell(`A${allRowIndex}`).style({ horizontalAlignment: 'center' });
          allRowIndex+=6;
          const contentAndSolution = [
            { content: '- Theo dõi số trẻ suy dinh dưỡng nhẹ cân (nhẹ cân mức độ nặng): ', value: counter.weight.NC2 },
            { content: '- Theo dõi số trẻ suy dinh dưỡng thấp còi (thấp còi mức độ nặng): ', value: counter.height.TC2 },
            { content: '- Theo dõi trẻ suy dinh dưỡng thể gầy còm (gầy còm mức độ nặng):  ', value: counter.SDDGCN },
            { content: '- Theo dõi trẻ béo phì: ', value: counter.BP },
            { content: '- Theo dõi trẻ thừa cân: ', value: counter.DC},
            { content: '- Theo dõi trẻ đứng cân thể cân nặng và thể chiều cao:  ' },
            { content: '- Theo dõi trẻ bệnh khác (Hô hấp, tiêu chảy, sâu răng…): ' },
            { content: '- Tuyên truyền phối hợp với phụ huynh: Chế độ ăn ở nhà, hoạt động vui chơi, khám và điều trị chăm sóc tại nhà: ' },
            { content: '- Chăm sóc tại trường. ' },
            { content: '+ Biện pháp tổ chức bồi dưỡng cho nhân viên, cấp dưỡng chế biến thức ăn khoa học, đảm bảo đủ số lượng và chất lượng bửa ăn Theo nhu cầu dinh dưỡng của trẻ ở từng độ tuổi, đảm bảo an toàn vệ sinh thực phẩm:', wrapText: true },
            { content: '+ Có kế hoạch tổ chức hoạt động phát triển thể lực cho trẻ tại nhóm/lớp:  ' },
            { content: '+ Chăm sóc phục hồi sức khỏe cho trẻ sau khi khỏi bệnh:  ' },
          ];
          allSheet.cell(`B${allRowIndex}`).value('*Nội dung và giải pháp').style({ bold: true });
          allRowIndex +=1;
          _.forEach(contentAndSolution, ({ content, value, wrapText }) => {
              if(value) {
                // eslint-disable-next-line no-unused-expressions
                content.length > 25 ? allSheet.cell(`H${allRowIndex}`).value(value).style({ bold: true }) : allSheet.cell(`C${allRowIndex}`).value(value).style({ bold: true });
              }
              if(wrapText) {
                allSheet.range(`B${allRowIndex}`, `H${allRowIndex + 2}`).merged(true)
                allSheet.cell(`B${allRowIndex}`).value(content).style({ horizontalAlignment: 'left', wrapText });
                allRowIndex+=2;
              } else {
                allSheet.cell(`B${allRowIndex}`).value(content).style({ horizontalAlignment: 'left' });
              }
              
              allRowIndex +=1;
          })
          allSheet.pageMargins('bottom', 0);
          sddSheet._node.children[5].attributes.orientation = "landscape";
          return wb.outputAsync().then(data => {
            this.setState({ isExporting: false });
            if (data !== undefined) {
              saveAs(data, "TongHopSK.xlsx");
              this.props.closeHandler();
            } else
              this.exportErrorHandler();
          });
        }, (error) => {
          this.setState({ isExporting: false });
          if (error)
            this.exportErrorHandler();
        });
      }
      req.onerror = () => {
        this.exportErrorHandler();
      }
      req.send();
    } catch (err) {
      this.exportErrorHandler();
    }
  }

  exportHandler = () => {
    this.setState({ isExporting: true });
    const {
      unitID,
      activeYear,
      errorHandler,
      classes
    } = this.props;
    const { month } = this.state;
    const classesData = {};
    _.forEach(classes, classInfo => {
      const { classID, name } = classInfo;
      if (month) {
        this.setState({
          waiting: {
            ...this.state.waiting,
            [classID]: true
          }
        });
        db.ref(`cando/${unitID}/${activeYear}/${classID}/months/${month}`).once("value", snapshot => {
          classesData[classID] = snapshot.val() || {};
          this.setState({
            waiting: {
              ...this.state.waiting,
              [classID]: true
            }
          }, () => {
            if (_.keys(classesData).length === classes.length)
              this.doExport(classesData);
          });
        }, (error) => {
          this.setState({
            waiting: {
              ...this.state.waiting,
              [classID]: true
            }
          });
          if (error)
            errorHandler(`Kết nối đến cơ sở dữ liệu cân đo của lớp ${name} không thành công.`);
        });
      } else {
        classesData[classID] = {};
        if (_.keys(classesData).length === classes.length)
          this.doExport(classesData);
      }
    });
  }

  monthHandler = (month) => {
    const year = this.computeYear(month);
    const { day } = this.state;
    const daysOptions = _.range(moment(`${year}-${month}`, "YYYY-MM").daysInMonth()).map(idx => ({
      key: idx + 1,
      text: idx + 1,
      value: idx + 1
    }));
    this.setState({
      month,
      day: (day <= daysOptions.length) ? day : 1,
      daysOptions
    });
  }

  renderMonths = (classInfo) => {
    const { classID } = classInfo;
    const { month } = this.state;
    const { monthsStatus } = this.props;
    const exists = _.get(monthsStatus, `${classID}.${month}.count`, 0) > 0;
    if (!exists)
      return "Chưa có số liệu";
    return this.getStatus(classID, month);
  }

  renderClass = (classInfo, index) => {
    const style = (index % 2 === 0) ? { background: "beige" } : {};
    const {
      classID,
      grade,
      name
    } = classInfo;
    const { isExporting, waiting } = this.state;
    return (
      <Table.Row key={`${classID}`} style={style}>
        <Table.Cell collapsing>{name}</Table.Cell>
        <Table.Cell collapsing>{_.join(grade.map(dt => readDoTuoi(dt)), ", ")}</Table.Cell>
        {isExporting ? (
          <Table.Cell collapsing>
            {_.get(waiting, classID) ? "Đang đọc dữ liệu..." : ""}
          </Table.Cell>
        ) : <Table.Cell collapsing>{this.renderMonths(classInfo)}</Table.Cell>}
      </Table.Row>
    );
  }

  renderClasses = () => {
    const { classes } = this.props;
    return (
      <Table style={{ background: "transparent" }} celled striped color="brown" unstackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="solieuHeader" collapsing>Tên lớp</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" collapsing>Khối</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" collapsing>Trạng thái</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {classes.map((classInfo, index) => this.renderClass(classInfo, index))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { closeHandler } = this.props;
    const {
      month,
      isExporting,
      day,
      daysOptions,
      useDay
    } = this.state;
    return (
      <div>
        <Modal size="fullscreen" open>
          <Header className="form-header">
            <Header.Content as="h3">
              Tổng hợp theo dõi sức khoẻ
            </Header.Content>
            <Header.Subheader style={{ color: "red" }}>
              <Form>
                <Form.Group inline>
                  <label>Nguyên tắc tính tuổi:</label>
                  <Form.Field>
                    <Radio
                      label='Theo Bộ Giáo dục (chỉ tính tháng)'
                      name='radioGroup'
                      checked={!useDay}
                      onChange={() => this.setState({ useDay: !useDay })}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Theo Viện Dinh dưỡng (dựa trên ngày cân đo)'
                      name='radioGroup'
                      checked={useDay}
                      onChange={() => this.setState({ useDay: !useDay })}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              Thời điểm tổng hợp (ngày/tháng):
              <Dropdown
                key="day"
                style={{ marginLeft: 10 }}
                floating
                inline
                scrolling
                options={daysOptions}
                value={day}
                onChange={(event, { value }) => this.setState({ day: value })}
                placeholder="Ngày thực hiện cân đo"
              />
              /
              <Dropdown
                key="month"
                style={{ marginLeft: 10 }}
                floating
                inline
                scrolling
                options={monthsOptions}
                value={month}
                onChange={(event, { value }) => this.monthHandler(value)}
                placeholder="Tháng thực hiện cân đo"
              />
            </Header.Subheader>
            <Button style={{ float: "right", marginTop: -50 }} onClick={closeHandler}>
              <Icon name="angle left" />
              {' '}
              Quay lại
            </Button>
          </Header>
          <Modal.Content scrolling>
            {this.renderClasses()}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="teal"
              size="small"
              onClick={this.exportHandler}
              loading={isExporting}
            >
              <Icon name="download" />
              Tổng hợp cân đo
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps)(HeathTrack);