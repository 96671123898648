import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Segment,
  Button,
  Dropdown,
  Grid,
  Message,
  Modal,
  Header,
  Icon,
  Dimmer,
  Loader,
  Label,
  Table,
  TableCell
} from "semantic-ui-react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";
import axios from "axios";

// components
import Unit from "./unit";

// services
import { db, app } from "../../libs/firebase";
import {
  readSchoolModel,
  readSchoolType,
  getSchoolModelOptions,
  getSchoolTypeOptions,
  getSchoolModel,
  getSchoolType
} from "../../libs/school";
import { BACKEND_URL } from "../../libs/config";

import "./styles.css";

const uuid = require("uuid/v1");

const cities = require("../../libs/cities.json");

const schoolModels = getSchoolModelOptions(true);
const schoolTypes = getSchoolTypeOptions(true);

class Eduunits extends Component {
  static propTypes = {
    user: PropTypes.shape({
      admin: PropTypes.bool
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      sgd: [],
      selectedSGD: undefined,
      pgd: [],
      wards: [],
      selectedPGD: undefined,
      schools: undefined,
      selectedSchoolModel: "all",
      selectedSchoolType: "all",
      selectedWard: "all"
    }
  }

  componentWillMount() {
    const sgd = [];
    Object.keys(cities).map(key => (
      sgd.push({
        key,
        text: cities[key].name,
        value: key
      })
    ));
    this.setState({ sgd });
  }

  componentWillUnmount() {
    if (this.schoolSearchRef !== undefined)
      this.schoolSearchRef.off("value");
  }

  getUniqueID = (schools, skip) => {
    const newUID = uuid();
    if ((_.find(schools, school => school.key === newUID) !== undefined) || (_.find(skip, id => id === newUID) !== undefined))
      return this.getUniqueID(schools, skip);
    return newUID;
  }

  certificateHandler = (unitID, emails) => {
    db.ref(`certificate/eduunits/${unitID}/staffs`).set(_.join(emails, ";"), (err) => {
      console.log("certificateHandler", unitID, err);
    });
    emails.forEach(email => {
      db.ref(`certificate/email/${btoa(_.trim(email))}/eduunits/${unitID}`).set(false, error => {
        console.log("certificateHandler", email, unitID, error);
      });
    });
  }

  firebaseSnapshotPreprocessing = (snapshot, nodup) => {
    if (snapshot === null || snapshot.val() === null || snapshot.numChildren() === 0)
      return null;
    if (nodup && snapshot.numChildren() > 1)
      this.errorHandler("Phát hiện ra có hai đơn vị giống nhau. Xin liên hệ Admin về sự cố này.");
    else
      return snapshot;
    return null;
  }

  backupCando = () => {
    this.setState({
      isWaiting: true
    });

    const onError = (err, extra = "") => {
      console.log(err, extra);
    }
    const year = moment().year();
    app.auth().currentUser.getIdToken(true).then(token => {
      db.ref("eduunits/").orderByKey().once("value", allUnits => {
        const candos = {};
        allUnits.forEach(unit => {
          const { plugins } = unit.val();
          if (_.get(plugins, "cando"))
            candos[unit.key] = unit.val();
        });
        let count = 0;
        const size = _.keys(candos).length;

        _.forEach(candos, (unit, unitID) => {
          const { sgd, classes } = unit;
          const data = {
            isSupport: true,  // TODO: get from user
            sgd,
            unitID,
            year: `${year - 1}-${year}`,
            classes: classes || {}
          };
          axios.post(`${BACKEND_URL}/userRequest`, {
            token,
            project: "rebot",
            type: "backup",
            data: JSON.stringify(data)
          }).then((res) => {
            count += 1;
            if (res.status !== 200)
              onError(res, unitID);
            console.log(count, size);
            if (count === size)
              this.setState({ isWaiting: false });
          }).catch(err => {
            count += 1;
            onError(err, unitID);
            if (count === size)
              this.setState({ isWaiting: false });
          });
        });
      }, onError);
    }).catch(onError);;
  }

  importHandler = (files) => {
    if (files.length === 0)
      return;

    const reader = new FileReader();
    reader.onload = () => {
      const wb = XLSX.read(reader.result, { type: "binary" });
      const excelData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: ["A", "B", "C", "D", "E", "F"], skipHeader: true, blankrows: true });
      const units = {};
      const staffs = {};
      const init = {
        phone: "",
        note: "",
        search: "th94_941",
        type: "th",
        duoi7tre: false,
        chuacapphep: false,
        xadatchuan: false,
        xakhokhan: false,
        xanongthonmoi: false,
        ward: "94_941_31498",
        sgd: "soctrang",
        pgd: "soctrang_soctrang",
        plugins: { cando: true }
      };
      _.range(1, excelData.length).forEach(index => {
        if (_.every(["A", "B", "C", "D", "E"], col => (excelData[index][col] !== undefined && excelData[index][col] !== ""))) {
          const newid = this.getUniqueID(this.state.schools.options, _.keys(units));
          units[newid] = {
            name: excelData[index].A,
            address: excelData[index].B,
            schoolModel: getSchoolModel(excelData[index].D),
            schoolType: getSchoolType(excelData[index].E)
          };
          staffs[newid] = _.compact(_.split(excelData[index].F, ";"));
        }
      });
      _.keys(units).forEach(unitID => {
        db.ref(`eduunits/${unitID}`).update({
          ...init,
          ...units[unitID]
        }, (error) => {
          console.log(unitID, error);
          this.setState({ isWaiting: false });
          db.ref(`eduunits/${init.pgd}/units`).update({ [unitID]: true }, err => {
            console.log("units", unitID, err);
          });
        });
      });
      _.keys(staffs).forEach(unitID => this.certificateHandler(unitID, staffs[unitID]));
    };
    reader.onabort = () => {
    };
    reader.onerror = () => {
      this.errorHandler("Đã có lỗi xảy ra trong quá trình đọc số liệu từ Excel");
    };

    reader.readAsBinaryString(files[0]);
  }

  sgdHandler = (event, { value }) => {
    this.setState({
      selectedSGD: value,
      pgd: [],
      selectedPGD: undefined,
    });
    const pgd = [];
    Object.keys(cities[value].districts).map(key => (
      pgd.push({
        key,
        text: cities[value].districts[key].name,
        value: key
      })
    ));
    this.setState({ pgd });
  }

  pgdHandler = (event, { value }) => {
    this.setState({
      selectedPGD: value
    });
    const wards = [{
      key: "all",
      text: "Tất cả",
      value: "all"
    }];
    Object.keys(cities[this.state.selectedSGD].districts[value].wards).map(key => (
      wards.push({
        key,
        text: cities[this.state.selectedSGD].districts[value].wards[key].name,
        value: key
      })
    ));
    this.setState({ selectedWard: "all", wards });
  }

  removePGDHandler = () => {
    if (this.state.selectedSGD === undefined || this.state.selectedPGD === undefined || cities[this.state.selectedSGD] === undefined || cities[this.state.selectedSGD].districts[this.state.selectedPGD] === undefined)
      return;
    this.setState({ isWaiting: true });
    // Try to find selected PGD
    db.ref("eduunits/").orderByChild("district").equalTo(this.state.selectedPGD).once("value", snapshot => {
      this.removeUnits(snapshot);
    });
  }

  removeUnits = (snapshot) => {
    this.setState({ isWaiting: true });
    const data = this.firebaseSnapshotPreprocessing(snapshot, true);
    if (data !== null) {
      let count = 0;
      data.forEach(row => {
        if (row.val().units !== undefined) {
          let countChilds = 0;
          Object.keys(row.val().units).forEach(unit => {
            db.ref(`eduunits/${unit}`).remove((error) => {
              countChilds += 1;
              if (error)
                this.errorHandler(`Xoá đơn vị ${unit} thất bại.`);
              if (countChilds === Object.keys(row.val().units).length) {
                count += 1;
                db.ref(`eduunits/${row.key}`).remove(err => {
                  this.setState({ isWaiting: false });
                  if (err)
                    this.errorHandler(`Xoá đơn vị ${row.key} thất bại.`);
                  else
                    this.successHandler(`Xoá đơn vị ${row.key} thành công. Những dữ liệu như Certificate, Report vẫn được giữ lại trên hệ thống.`);
                });
                if (count === data.length)
                  this.setState({ isWaiting: false });
              }
            });
          });
        }
      });
    }
  }

  removeSGDHandler = () => {
    if (this.state.selectedSGD === undefined || cities[this.state.selectedSGD] === undefined)
      return;
    // Try to find selected SGD
    db.ref("eduunits/").orderByChild("province").equalTo(this.state.selectedSGD).once("value", snapshot => {
      this.removeUnits(snapshot);
    });
  }

  editSGDHandler = () => {
    if (this.state.selectedSGD === undefined || cities[this.state.selectedSGD] === undefined)
      return;
    this.setState({ isWaiting: true });
    // Try to find selected SGD
    db.ref("eduunits/").orderByChild("province").equalTo(this.state.selectedSGD).once("value", snapshot => {
      let unit = {};
      const data = this.firebaseSnapshotPreprocessing(snapshot, true);
      if (data !== null) {
        data.forEach(row => {
          unit = {
            type: "sgd",
            unitID: row.key,
            search: row.val().search,
            address: row.val().address,
            phone: row.val().phone,
            name: row.val().name,
            note: row.val().note,
            province: row.val().province,
            units: row.val().units
          };
          return true;
        });
      } else {
        // Initialize SGD's information for creating
        unit = {
          type: "sgd",
          search: "sgd",
          name: `Sở Giáo dục và Đào tạo ${cities[this.state.selectedSGD].name}`,
          address: "",
          province: this.state.selectedSGD
        }
      }
      this.setState({
        isWaiting: false,
        showUnit: true,
        unit
      });
    }, (error) => {
      this.setState({ isWaiting: false });
      this.errorHandler(error);
    });
  }

  editPGDHandler = () => {
    if (this.state.selectedSGD === undefined || this.state.selectedPGD === undefined || cities[this.state.selectedSGD] === undefined || cities[this.state.selectedSGD].districts[this.state.selectedPGD] === undefined)
      return;
    this.setState({ isWaiting: true });
    // Try to find selected PGD
    db.ref("eduunits/").orderByChild("district").equalTo(this.state.selectedPGD).once("value", snapshot => {
      const data = this.firebaseSnapshotPreprocessing(snapshot, true);
      if (data !== null) {
        let unit = {};
        snapshot.forEach(row => {
          unit = {
            type: "pgd",
            unitID: row.key,
            search: row.val().search,
            address: row.val().address,
            phone: row.val().phone,
            name: row.val().name,
            note: row.val().note,
            sgd: row.val().sgd,
            district: row.val().district
          };
        });
        this.setState({
          isWaiting: false,
          showUnit: true,
          unit
        });
      } else {
        // In order to initilize new PGD, we need to find its SGD
        db.ref("eduunits/").orderByChild("province").equalTo(this.state.selectedSGD).once("value", sgdSnapshot => {
          const sgdData = this.firebaseSnapshotPreprocessing(sgdSnapshot, true);
          if (sgdData !== null) {
            let unit = {};
            sgdData.forEach(row => {
              unit = {
                type: "pgd",
                search: `pgd${this.state.selectedSGD}`,
                name: `Phòng Giáo dục và Đào tạo ${cities[this.state.selectedSGD].districts[this.state.selectedPGD].name}`,
                address: "",
                district: this.state.selectedPGD,
                sgd: row.key,
                parent: row.key
              };
              return true;
            });
            this.setState({
              isWaiting: false,
              showUnit: true,
              unit
            });
          } else
            this.errorHandler("Không tìm thấy Sở Giáo dục của đơn vị đang chọn.");
        }, (err) => {
          this.setState({ isWaiting: false });
          this.errorHandler(err);
        });
      }
    }, (error) => {
      this.setState({ isWaiting: false });
      this.errorHandler(error);
    });
  }

  editSchoolHandler = (school) => {
    const unit = {
      type: "th",
      unitID: school.schoolID,
      search: school.search,
      address: school.address,
      phone: school.phone,
      name: school.name,
      note: school.note,
      sgd: school.sgd,
      pgd: school.pgd,
      schoolType: school.schoolType,
      schoolModel: school.schoolModel,
      duoi7tre: school.duoi7tre,
      chuacapphep: school.chuacapphep,
      xadatchuan: school.xadatchuan,
      xakhokhan: school.xakhokhan,
      xanongthonmoi: school.xanongthonmoi,
      province: school.ward.slice(0, 2),
      district: school.ward.slice(0, 6),
      ward: school.ward,
      ward2: school.ward2,
      plugins: school.plugins,
      classes: school.classes || {}
    };
    this.setState({
      showUnit: true,
      unit
    });
  }

  searchSchoolHander = (parentType) => {
    if (this.schoolSearchRef !== undefined)
      this.schoolSearchRef.off("value");
    switch (parentType) {
      case "sgd":
        if (this.state.selectedSGD === undefined)
          return;
        this.setState({ isWaiting: true });
        // Need to get SGD information first
        db.ref("eduunits/").orderByChild("province").equalTo(this.state.selectedSGD).once("value", sgdSnapshot => {
          const sgdData = this.firebaseSnapshotPreprocessing(sgdSnapshot, true);
          if (sgdData !== null) {
            // Search all schools which are belong to selected SGD, use key `th${province}`
            this.schoolSearchRef = db.ref("eduunits/").orderByChild("search").equalTo(`th${this.state.selectedSGD}`);
            this.schoolSearchRef.on("value", snapshot => {
              const parent = {};
              sgdData.forEach(row => {
                parent.unitID = row.key;
                parent.name = row.val().name;
                return true;
              });
              const data = this.firebaseSnapshotPreprocessing(snapshot, false);
              // Initialize results information
              const schools = {
                schools: [],
                th: [],
                options: [],                    // for searching on combobox
                selectedSchool: undefined,
                parent: {
                  name: parent.name,
                  unitID: parent.unitID,
                  type: "sgd",
                  province: this.state.selectedSGD
                }
              };
              if (data !== null) {
                // Push all found schools to result
                data.forEach(school => {
                  schools.th.push({
                    ...school.val(),
                    schoolID: school.key
                  });
                  schools.options.push({
                    key: school.key,
                    text: school.val().name,
                    value: school.key
                  });
                  schools.schools.push({
                    ...school.val(),
                    parent: parentType
                  });
                });
              }
              this.setState({
                isWaiting: false,
                schools
              });
            }, (error) => {
              if (error)
                this.errorHandler(error);
            });
          } else
            this.errorHandler("Không tìm thấy đơn vị Sở Giáo dục đang chọn.");
        }, (error) => {
          this.setState({ isWaiting: false });
          this.errorHandler(error);
        });
        break;
      case "pgd":
        if (this.state.selectedPGD === undefined)
          return;
        this.setState({ isWaiting: true });
        // Need to get PGD information first
        db.ref("eduunits/").orderByChild("district").equalTo(this.state.selectedPGD).once("value", pgdSnapshot => {
          const pgdData = this.firebaseSnapshotPreprocessing(pgdSnapshot, true);
          if (pgdData !== null) {
            const parent = {};
            pgdData.forEach(row => {
              parent.unitID = row.key;
              parent.sgd = row.val().sgd;
              parent.name = row.val().name;
              return true;
            });
            // Search all schools which are belong to selected PGD, use key `th${district}`
            this.schoolSearchRef = db.ref("eduunits/").orderByChild("search").equalTo(`th${this.state.selectedPGD}`);
            this.schoolSearchRef.on("value", snapshot => {
              const data = this.firebaseSnapshotPreprocessing(snapshot, false);
              // Initialize results information
              const schools = {
                schools: [],
                th: [],
                options: [],                    // for searching on combobox
                selectedSchool: undefined,
                parent: {
                  name: parent.name,
                  unitID: parent.unitID,
                  sgd: parent.sgd,
                  type: "pgd",
                  district: this.state.selectedPGD
                }
              };
              if (data !== null) {
                // Push all found schools to result
                data.forEach(school => {
                  schools.th.push({
                    ...school.val(),
                    schoolID: school.key
                  });
                  schools.options.push({
                    key: school.key,
                    text: school.val().name,
                    value: school.key
                  });
                  schools.schools.push({
                    ...school.val(),
                    parent: parentType
                  });
                });
              }
              this.setState({
                isWaiting: false,
                schools
              });
            }, (error) => {
              if (error)
                this.errorHandler(error);
            });
          } else
            this.errorHandler("Không tìm thấy đơn vị Phòng Giáo dục đang chọn.");
        }, (error) => {
          this.setState({ isWaiting: false });
          this.errorHandler(error);
        });
        break;
      default:
    }
  }

  newSchoolHander = () => {
    if (this.state.schools.parent !== undefined) {
      if (this.state.schools.parent.type === "pgd" && this.state.schools.parent.sgd === undefined) {
        this.errorHandler("Không tìm thấy mã Sở Giáo dục từ đơn vị Phòng Giáo dục.");
        return;
      }
      // Create search key for new school
      const search = (this.state.schools.parent.type === "sgd") ? `th${this.state.schools.parent.province}` : `th${this.state.schools.parent.district}`;
      this.setState({
        showUnit: true,
        unit: {
          type: "th",
          search,
          name: "Trường",
          address: "",
          province: (this.state.schools.parent.type === "sgd") ? this.state.schools.parent.province : this.state.schools.parent.district.slice(0, 2),
          district: (this.state.schools.parent.type === "pgd") ? this.state.schools.parent.district : undefined,
          parent: this.state.schools.parent.unitID,
          sgd: (this.state.schools.parent.type === "sgd") ? this.state.schools.parent.unitID : this.state.schools.parent.sgd,
          pgd: (this.state.schools.parent.type === "pgd") ? this.state.schools.parent.unitID : undefined
        }
      })
    }
  }

  errorHandler = (message) => {
    this.setState({ isWaiting: false });
    this.notificationSystem.addNotification({
      title: "Lỗi",
      message,
      level: "error",
      position: "tr",
      autoDismiss: 4
    });
  }

  successHandler = (additional = "") => {
    if (additional !== "")
      this.notificationSystem.addNotification({
        title: "Hoàn tất",
        message: additional,
        level: "success",
        position: "tr",
        autoDismiss: 4
      });
    else
      this.notificationSystem.addNotification({
        title: "Hoàn tất",
        message: "Cập nhật thành công",
        level: "success",
        position: "tr",
        autoDismiss: 4
      });
  }

  warningHandler = (title, message) => {
    this.notificationSystem.addNotification({
      title,
      message,
      level: "warning",
      position: "tr",
      autoDismiss: 4
    });
  }

  renderUnitEditor = () => (
    <Modal size="large" className="custom" open={this.state.showUnit} closeIcon={<Icon name="close" onClick={() => this.setState({ showUnit: false, unit: undefined })} />}>
      <Header
        className="form-header"
        as="h3"
        content={(this.state.unit.unitID === undefined) ? `Thêm mới ${this.state.unit.name}` : this.state.unit.name}
        subheader={(this.state.unit.unitID !== undefined) ? (
          <Label color="teal">
            <Icon name="key" />
            {' '}
            {this.state.unit.unitID}
          </Label>
        ) : null}
      />
      <Modal.Content>
        <Unit
          unit={this.state.unit}
          successHandler={this.successHandler}
          errorHandler={this.errorHandler}
          warningHandler={this.warningHandler}
        />
      </Modal.Content>
    </Modal>
  )

  renderSchools = () => {
    const dropdownOptions = {
      search: true,
      selection: true,
      fluid: true,
      multiple: false
    };
    return (
      <Segment stacked>
        <Message info>
          <Button basic color="blue" floated="right" icon="add" content="Tạo mới" onClick={this.newSchoolHander} />
          {this.props.user.admin ? (
            <Dropzone accept=".xls, .xlsx" multiple={false} onDrop={this.importHandler}>
              {({ getRootProps, getInputProps }) => (
                <div style={{ float: "right" }} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button color="green" size="small">
                    <Icon name="file excel outline" />
                    {' '}
Nhập từ Excel
                  </Button>
                </div>
              )}
            </Dropzone>
          ) : null}
          <Message.Header>{this.state.schools.parent.name}</Message.Header>
          <p style={{ width: 300 }}>
            Số trường đã tạo:
            {' '}
            {this.state.schools.schools.length}
            {' '}
          </p>
        </Message>
        <Grid>
          <Grid.Row>
            <Grid.Column style={{ textAlign: "right" }} width={4} verticalAlign="middle">Tìm theo tên: </Grid.Column>
            <Grid.Column width={6}>
              <Dropdown
                {...dropdownOptions}
                options={this.state.schools.options}
                value={this.state.selectedSchool}
                placeholder="Nhập tên Trường"
                onChange={(e, { value }) => this.setState({ selectedSchool: value, selectedSchoolModel: "all", selectedSchoolType: "all", selectedWard: "all" })}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column style={{ textAlign: "right" }} width={4} verticalAlign="top">Lọc theo Phường: </Grid.Column>
            <Grid.Column width={6}>
              <Dropdown
                {...dropdownOptions}
                options={this.state.wards}
                value={this.state.selectedWard}
                placeholder="Lọc theo phường"
                onChange={(e, { value }) => this.setState({ selectedWard: value }, () => console.log(value))}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column style={{ textAlign: "right" }} width={4} verticalAlign="top">Quy mô trường: </Grid.Column>
            <Grid.Column width={6}>
              <Dropdown
                {...dropdownOptions}
                options={schoolModels}
                value={this.state.selectedSchoolModel}
                placeholder="Tìm kiếm theo quy mô trường"
                onChange={(e, { value }) => this.setState({ selectedSchoolModel: value, selectedSchool: undefined })}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column style={{ textAlign: "right" }} width={4} verticalAlign="top">Loại hình trường: </Grid.Column>
            <Grid.Column width={6}>
              <Dropdown
                {...dropdownOptions}
                options={schoolTypes}
                value={this.state.selectedSchoolType}
                placeholder="Tìm kiếm theo loại hình trường"
                onChange={(e, { value }) => this.setState({ selectedSchoolType: value, selectedSchool: undefined })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }

  renderSchoolDescription = (schoolModel, schoolType) => {
    if (schoolModel === undefined)
      return "Cần phải bổ sung quy mô trường";
    if (schoolType === undefined)
      return "Cần phải bổ sung Loại hình trường";
    return `${readSchoolModel(schoolModel)} - ${readSchoolType(schoolType)}`;
  }

  renderResultRow = (school, index) => {
    const detailCityArr = school.ward.split("_");
    const wardName = cities[detailCityArr[0]].districts[`${detailCityArr[0]}_${detailCityArr[1]}`].wards[school.ward].name;
    return (
      <Table.Row key={school.schoolID}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{school.name}</TableCell>
        <TableCell>{this.renderSchoolDescription(school.schoolModel, school.schoolType)}</TableCell>
        <TableCell>{school.address}</TableCell>
        <TableCell>{wardName}</TableCell>
        <TableCell><Button color="brown" floated="right" icon="edit" onClick={() => this.editSchoolHandler(school)} /></TableCell>
      </Table.Row>
    );
  }

  renderResults = () => {
    if (this.state.schools.th === undefined)
      return null;
    const filter = [];
    if (this.state.selectedSchool === undefined) {
      this.state.schools.th.forEach(school => {
        const schoolModelCondition = (this.state.selectedSchoolModel === "all") || (this.state.selectedSchoolModel === school.schoolModel);
        const schoolTypeCondition = (this.state.selectedSchoolType === "all") || (this.state.selectedSchoolType === school.schoolType);
        const wardCondition = (this.state.selectedWard === "all") || (this.state.selectedWard === school.ward);
        if (schoolModelCondition && schoolTypeCondition && wardCondition)
          filter.push(school);
      });
    } else {
      this.state.schools.th.forEach(school => {
        if (school.schoolID === this.state.selectedSchool)
          filter.push(school);
      });
    }

    return (
      <Table unstackable celled striped>
        <Table.Header style={{ textAlign: "center" }}>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Tên đơn vị</Table.HeaderCell>
            <Table.HeaderCell>Quy mô và loại hình</Table.HeaderCell>
            <Table.HeaderCell>Địa chỉ</Table.HeaderCell>
            <Table.HeaderCell>Phường</Table.HeaderCell>
            <Table.HeaderCell>Sửa</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filter && filter.map((school, idx) => this.renderResultRow(school, idx))}
        </Table.Body>
      </Table>

    );
  }

  render() {
    const { user } = this.props;
    return (
      <div>
        {this.state.isWaiting ? (
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
        ) : null}
        {user.admin ? <Button onClick={this.backupCando}>Backup Cân đo</Button> : null}
        {(this.state.unit !== undefined) ? this.renderUnitEditor() : null}
        <Segment stacked>
          <Grid>
            <Grid.Row>
              <Grid.Column style={{ textAlign: "right" }} width={4} verticalAlign="middle">Sở Giáo dục</Grid.Column>
              <Grid.Column width={7}>
                <Dropdown search selection fluid multiple={false} onChange={this.sgdHandler} options={this.state.sgd} value={this.state.selectedSGD} placeholder="Nhập tên Sở Giáo dục" />
              </Grid.Column>
              <Grid.Column width={1}>
                <Button.Group>
                  <Button icon="undo" color="teal" onClick={() => this.setState({ selectedSGD: undefined, pgd: [], selectedPGD: undefined })} />
                  <Button icon="edit" color="brown" onClick={this.editSGDHandler} />
                  <Button icon="search" color="blue" onClick={() => this.searchSchoolHander("sgd")} />
                  {(this.props.user.admin && !this.props.user.admin) ? <Button icon="remove" color="red" onClick={this.removeSGDHandler} /> : null}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column style={{ textAlign: "right" }} width={4} verticalAlign="middle">Phòng Giáo dục</Grid.Column>
              <Grid.Column width={7}>
                <Dropdown search selection fluid multiple={false} onChange={this.pgdHandler} options={this.state.pgd} value={this.state.selectedPGD} placeholder="Nhập tên Phòng Giáo dục" />
              </Grid.Column>
              <Grid.Column width={1}>
                <Button.Group>
                  <Button icon="undo" color="teal" onClick={() => this.setState({ selectedPGD: undefined })} />
                  <Button icon="edit" color="brown" onClick={this.editPGDHandler} />
                  <Button icon="search" color="blue" onClick={() => this.searchSchoolHander("pgd")} />
                  {(this.props.user.admin && !this.props.user.admin) ? <Button icon="remove" color="red" onClick={this.removePGDHandler} /> : null}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {(this.state.schools !== undefined && this.state.schools.parent !== undefined) ? this.renderSchools() : null}
        {(this.state.schools !== undefined && this.state.schools.parent !== undefined) ? this.renderResults() : null}
        <NotificationSystem ref={(ref) => { this.notificationSystem = ref }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Eduunits);