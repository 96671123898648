/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Table,
  Popup,
  Label,
  Dimmer,
  Loader,
  Modal,
  Header,
  Icon,
  Form,
  Radio
} from "semantic-ui-react";
import _ from "lodash";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";

import { convertTimeStamp } from "../../../libs/time";
import { candoClassFetch } from "../../../redux/actions/cando";
import { RESOURCES } from "../../../libs/config";
import {
  evaluateStudents,
  computeBMIPointResult,
  computeHeightPointResult,
  computeWeightLengthPointResult,
  computeWeightPointResult
} from "./helpers";


const colors = {
  NC2: "red",
  NC1: "orange",
  TC2: "red",
  TC1: "orange",
  GC1: "orange",
  GC2: "red",
  BP: "pink",
  DC: "orange",
  BT: "green",
  [`BT+`]: "olive"
};

const description = {
  NC2: "SDD thể nhẹ cân mức độ nặng",
  NC1: "SDD thể nhẹ cân",
  TC2: "SDD thể thấp còi mức độ nặng",
  TC1: "SDD thể thấp còi",
  GC1: "SDD thể gầy còm",
  GC2: "SDD thể gầy còm mức độ nặng",
  BP: "Béo phì",
  DC: "Thừa cân",
  BT: "Bình thường",
  [`BT+`]: "Trên mức bình thường"
};

const grades = ["3-6thangtuoi", "6-12thangtuoi", "12-18thangtuoi", "18-24thangtuoi", "24-36thangtuoi", "3-4tuoi", "4-5tuoi", "5-6tuoi"];

const months = [["8", "9", "10", "11", "12"], ["1", "2", "3", "4", "5", "6", "7"]];

class Viewer extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    candoClassFetch: PropTypes.func.isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      information: PropTypes.shape({
        sgd: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      gender: PropTypes.string
    }).isRequired,
    year: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    solieucando: PropTypes.object.isRequired,
    dantoc: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const { classes } = this.props;
    this.state = {
      classes,
      showSoLieu: undefined,
      useDay: false
    }
  }

  componentWillMount() {
    const { year, candoClassFetch: classHandler, unit } = this.props;
    const { classes } = this.state;
    _.forEach(classes, (v, classID) => {
      if (!v.content)
        axios.get(`${RESOURCES}/rebot/backup/${unit.information.sgd}/${year}/${unit.unitID}/cando/${classID}.json`).then(response => {
          classHandler(year, classID, response.data);
          this.setState({
            classes: {
              ...this.state.classes,
              [classID]: {
                ..._.get(this.state.classes, classID),
                content: response.data
              }
            }
          });
        }).catch(() => {
          this.setState({
            classes: {
              ...this.state.classes,
              [classID]: {
                ..._.get(this.state.classes, classID),
                error: "Không tìm thấy dữ liệu"
              }
            }
          });
        });
    });
  }

  getSortedClasses = () => {
    const { classes } = this.state;
    if (!classes)
      return [];
    const converted = _.map(_.keys(classes), classID => ({
      classID,
      gradeIndex: _.indexOf(classes[classID].grade, grades),
      ...classes[classID]
    }));
    return _.sortBy(converted, ["gradeIndex", "name"]);
  }

  getStatus = (classID, month) => {
    const { classes } = this.state;
    const { status, data } = _.get(classes, `${classID}.content.${month}`, {});
    if (data && status) {
      const { modifiedBy, modifiedTime } = status;
      return `${modifiedBy}. ${convertTimeStamp(modifiedTime)}`;
    }
    return "Chưa có số liệu";
  }

  convertClasses = (classes, reverse = false) => {
    if (classes === undefined)
      return {};
    const converted = {};
    _.keys(classes).forEach(classID => {
      let teachers = reverse ? JSON.stringify(classes[classID].teachers) : JSON.parse((classes[classID].teachers === "") ? "{}" : classes[classID].teachers);
      if (reverse && teachers === undefined)
        teachers = JSON.stringify({});
      converted[classID] = {
        classID,
        ...classes[classID],
        teachers
      };
    });
    return converted;
  }

  readDoTuoi = (dotuoi) => {
    switch (dotuoi) {
      case "3-4tuoi":
        return "3 - 4 tuổi";
      case "4-5tuoi":
        return "4 - 5 tuổi";
      case "5-6tuoi":
        return "5 - 6 tuổi";
      case "3-6thangtuoi":
        return "3 - 6 tháng tuổi";
      case "6-12thangtuoi":
        return "6 - 12 tháng tuổi";
      case "12-18thangtuoi":
        return "12 - 18 tháng tuổi";
      case "18-24thangtuoi":
        return "18 - 24 tháng tuổi";
      case "24-36thangtuoi":
        return "24 - 36 tháng tuổi";
      default:
        return dotuoi;
    }
  }

  computeYear = (month) => {
    const { year } = this.props;
    const yearNo = _.slice(year, 0, 4);
    if (month >= 9)
      return parseFloat(yearNo);
    return parseFloat(yearNo) + 1;
  }

  renderLabel = (point) => (
    <Popup trigger={<Label color={colors[point]}>{point}</Label>} content={description[point]} />
  )

  renderMonths = (classInfo, monthList) => monthList.map(month => {
    const data = _.get(classInfo, `content.${month}.data`);
    const exists = _.keys(data || {}).length > 0;
    const color = exists ? "blue" : "grey";
    return (
      <Popup
        key={month}
        trigger={(
          <Label
            style={{ minWidth: 95 }}
            key={month}
            as="a"
            color={color}
            onClick={() => {
              if (color === "blue")
                this.setState({
                  showSoLieu: {
                    ...classInfo,
                    year: this.computeYear(month),
                    month,
                    day: _.get(classInfo, `content.${month}.status.day`, 1),
                  }
                });
            }}
          >
            Tháng
            {' '}
            {month}
          </Label>
        )}
        content={this.getStatus(classInfo.classID, month)}
      />
    );
  })

  renderClass = (classInfo, index) => {
    const style = (index % 2 === 0) ? { background: "beige" } : {};
    const {
      classID,
      name,
      teachers,
      grade,
      content,
      error
    } = classInfo;
    if (error)
      return <Table.Row error colSpan="4" key={classID}>{error}</Table.Row>;
    const firstRow = (
      <Table.Row key={classID} style={style}>
        <Table.Cell collapsing rowSpan="2">{name}</Table.Cell>
        <Table.Cell collapsing rowSpan="2">{_.join(grade.map(dt => this.readDoTuoi(dt)), ", ")}</Table.Cell>
        <Table.Cell collapsing rowSpan="2">{_.join(teachers || [], ", ")}</Table.Cell>
        {(content === undefined) ?
          <Table.Cell collapsing rowSpan="2">Đang tải dữ liệu</Table.Cell>
          :
          <Table.Cell collapsing>{this.renderMonths(classInfo, months[0])}</Table.Cell>}
      </Table.Row>
    );
    const secondRow = (
      <Table.Row key={`${classID}_2`} style={style}>
        {(content !== undefined) ?
          <Table.Cell collapsing>{this.renderMonths(classInfo, months[1])}</Table.Cell> : null}
      </Table.Row>
    );
    return [firstRow, secondRow];
  }

  renderStudents = (students, month, day) => {
    const {
      year,
      solieucando,
      dantoc
    } = this.props;
    const evaluatedStudents = evaluateStudents(students, parseFloat(month), year, solieucando, day, this.state.useDay).map(student => {
      const weightPoint = (student.weightPoint !== undefined) ? computeWeightPointResult(student.weightPoint) : "";
      const heightPoint = (student.heightPoint !== undefined) ? computeHeightPointResult(student.heightPoint) : "";
      const bmiPoint = (student.bmiPoint !== undefined) ? computeBMIPointResult(student.bmiPoint) : "";
      const weightLengthPoint = (student.weightLengthPoint !== undefined) ? computeWeightLengthPointResult(student.weightLengthPoint) : "";
      let warning = "";
      if (weightPoint === "BT+")
        warning = "Nặng hơn";
      if (heightPoint === "BT+") {
        if (warning === "")
          warning = "Cao hơn so với tuổi";
        else
          warning += " và Cao hơn so với tuổi";
      } else if (weightPoint === "BT+")
        warning += " so với tuổi";
      return ({
        ...student,
        weightPoint,
        heightPoint,
        bmiPoint,
        weightLengthPoint,
        warning
      });
    });
    return (
      <Table celled striped unstackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Họ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Tên</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Ngày sinh</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Nữ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Dân tộc</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Số tháng</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" colSpan="4">Đánh giá Cân nặng - Chiều cao</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" colSpan="3">Đánh giá Dư cân - Béo phì</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Đánh giá chung</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Ghi chú</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className="solieuHeader">Số cân (kg)</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">KQ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">Số đo (cm)</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">KQ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">Cân nặng theo chiều cao</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">Chỉ số BMI</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">KQ</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {evaluatedStudents.map(student => (
            <Table.Row key={student.studentID} warning={student.height === undefined || student.height === "" || student.weight === undefined || student.weight === ""}>
              <Table.Cell collapsing className="solieuValue">{student.lastName}</Table.Cell>
              <Table.Cell collapsing className="solieuValue">{student.firstName}</Table.Cell>
              <Table.Cell collapsing className="solieuValue">
                {student.date}
                /
                {student.month}
                /
                {student.year}
              </Table.Cell>
              <Table.Cell collapsing>{(student.gender === "F") ? <Icon name="female" /> : null}</Table.Cell>
              <Table.Cell collapsing>{_.get(dantoc, _.get(student, "ethnic", "kinh"), "")}</Table.Cell>
              <Table.Cell collapsing className="solieuThang">{student.monthsCount}</Table.Cell>
              <Table.Cell collapsing>{student.weight || ""}</Table.Cell>
              <Table.Cell collapsing>{(student.weightPoint !== "") ? this.renderLabel(student.weightPoint) : null}</Table.Cell>
              <Table.Cell collapsing>{student.height || ""}</Table.Cell>
              <Table.Cell collapsing>{(student.heightPoint !== "") ? this.renderLabel(student.heightPoint) : null}</Table.Cell>
              <Table.Cell collapsing>{(student.weightLengthPoint !== "") ? this.renderLabel(student.weightLengthPoint) : null}</Table.Cell>
              <Table.Cell collapsing>{(student.bmi !== undefined) ? Math.round(student.bmi * 100) / 100 : ""}</Table.Cell>
              <Table.Cell collapsing>{(student.bmiPoint !== "") ? this.renderLabel(student.bmiPoint) : null}</Table.Cell>
              <Table.Cell style={{ minWidth: 200 }}>{student.comment || ""}</Table.Cell>
              {(student.warning !== "") ? (
                <Table.Cell warning>
                  <Icon name="attention" color="brown" />
                  {' '}
                  {student.warning}
                </Table.Cell>
              ) : <Table.Cell />}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  renderSolieu = () => {
    const { showSoLieu, classes } = this.state;
    if (!showSoLieu)
      return null;
    const { month, classID } = showSoLieu;
    const data = _.get(classes, `${classID}.content.${month}.data`, {});
    const status = _.get(classes, `${classID}.content.${month}.status`, {});
    return (
      <Modal
        size="fullscreen"
        className="custom"
        open
        closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState({ showSoLieu: undefined })} />}
      >
        <Header className="form-header">
          <Header.Content as="h3">
            Số liệu cân đo Tháng
            {' '}
            {month}
            . Lớp
            {' '}
            {_.get(classes, `${classID}.name`, "")}
            .
            <Label>
              {`Sĩ số: ${_.keys(data).length}`}
            </Label>
          </Header.Content>
          <Header.Subheader style={{ color: "red" }}>
            {`Ngày tổ chức cân đo: ${status.day} / ${month}`}
          </Header.Subheader>
        </Header>
        <Modal.Content scrolling>
          {this.renderStudents(data, month, status.day)}
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const { useDay } = this.state;
    const { closeHandler, year } = this.props
    const classes = this.getSortedClasses();
    return (
      <Modal
        className="custom"
        size="fullscreen"
        open
        closeIcon={<Icon name="close" color="red" size="large" onClick={closeHandler} />}
      >
        <Header as="h3">
          Số liệu cân đô năm học
          {' '}
          {year}
          <Header.Subheader>
            <Form>
              <Form.Group inline>
                <label>Nguyên tắc tính tuổi:</label>
                <Form.Field>
                  <Radio
                    label='Theo Bộ Giáo dục (chỉ tính tháng)'
                    name='radioGroup'
                    checked={!useDay}
                    onChange={() => this.setState({ useDay: !useDay })}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='Theo Viện Dinh dưỡng (dựa trên ngày cân đo)'
                    name='radioGroup'
                    checked={useDay}
                    onChange={() => this.setState({ useDay: !useDay })}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <Label style={{ marginTop: 5 }} color="grey">Chưa có danh sách</Label>
            <Label style={{ marginTop: 5 }} color="blue">Đã có số liệu cân đo cho tất cả các bé</Label>
          </Header.Subheader>
        </Header>
        <Modal.Content scrolling>
          {!this.state.classes ? (
            <Dimmer active inverted>
              <Loader inverted>Đang kết nối...</Loader>
            </Dimmer>
          ) : null}
          {this.renderSolieu()}
          <PerfectScrollbar>
            <Table
              style={{ background: "transparent" }}
              celled
              striped
              color="brown"
              unstackable
              selectable
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="solieuHeader" collapsing>Tên lớp</Table.HeaderCell>
                  <Table.HeaderCell className="solieuHeader" collapsing>Khối</Table.HeaderCell>
                  <Table.HeaderCell className="solieuHeader" collapsing>Nhân sự</Table.HeaderCell>
                  <Table.HeaderCell className="solieuHeader" collapsing>Tháng</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {classes.map((classInfo, index) => this.renderClass(classInfo, index))}
              </Table.Body>
            </Table>
          </PerfectScrollbar>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = ({ user, unit, cando }, { year }) => ({
  user,
  unit,
  classes: _.get(cando, `${year}.classes`, {})
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  candoClassFetch
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);