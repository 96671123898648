import React, { Component } from "react";
import { Button, Icon, Dimmer, Loader } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import NotificationSystem from "react-notification-system";
import _ from "lodash";
import { saveAs } from "file-saver";


export default class CanDo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false
    }
  }

  errorHandler = (message) => {
    this.setState({ isWaiting: false });
    this.notificationSystem.addNotification({
      title: "Lỗi",
      message,
      level: "error",
      position: "tr",
      autoDismiss: 4
    });
  }

  successHandler = (message) => {
    this.setState({ isWaiting: false });
    this.notificationSystem.addNotification({
      title: "Hoàn tất",
      message,
      level: "success",
      position: "tr",
      autoDismiss: 4
    });
  }

  importHandler = (files) => {
    if (files.length === 0)
      return;
    this.setState({ isWaiting: true });

    const reader = new FileReader();
    reader.onload = () => {
      const wb = XLSX.read(reader.result, { type: "binary" });
      const pointCategory = [-3, -2, 0, 2, 3];

      const weightRows = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"], blankrows: true });
      const weight = {
        male: {},
        female: {}
      };
      _.drop(weightRows, 3).forEach(row => {
        weight.male[row.A] = ["B", "C", "D", "E", "F"].map((col, index) => ({
          weight: parseFloat(row[col]),
          point: pointCategory[index]
        }));
        weight.female[row.A] = ["H", "I", "J", "K", "L"].map((col, index) => ({
          weight: parseFloat(row[col]),
          point: pointCategory[index]
        }));
      });

      const heightRows = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[1]], { header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"], blankrows: true });
      const height = {
        male: {},
        female: {}
      };
      _.drop(heightRows, 3).forEach(row => {
        height.male[row.A] = ["B", "C", "D", "E", "F"].map((col, index) => ({
          height: parseFloat(row[col]),
          point: pointCategory[index]
        }));
        height.female[row.A] = ["H", "I", "J", "K", "L"].map((col, index) => ({
          height: parseFloat(row[col]),
          point: pointCategory[index]
        }));
      });

      const weightLengthRows = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[2]], { header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA"], blankrows: true });
      const weightLength = {
        male: {
          duoi2tuoi: [],
          duoi5tuoi: []
        },
        female: {
          duoi2tuoi: [],
          duoi5tuoi: []
        }
      };
      _.drop(weightLengthRows, 3).forEach(row => {
        weightLength.male.duoi2tuoi.push({
          height: parseFloat(row.A),
          weights: ["B", "C", "D", "E", "F"].map((col, index) => ({
            weight: parseFloat(row[col]),
            point: pointCategory[index]
          }))
        });
        weightLength.female.duoi2tuoi.push({
          height: parseFloat(row.O),
          weights: ["P", "Q", "R", "S", "T"].map((col, index) => ({
            weight: parseFloat(row[col]),
            point: pointCategory[index]
          }))
        });
        if (!isNaN(row.H) || !isNaN(row.V)) {
          weightLength.male.duoi5tuoi.push({
            height: parseFloat(row.H),
            weights: ["I", "J", "K", "L", "M"].map((col, index) => ({
              weight: parseFloat(row[col]),
              point: pointCategory[index]
            }))
          });
          weightLength.female.duoi5tuoi.push({
            height: parseFloat(row.V),
            weights: ["W", "X", "Y", "Z", "AA"].map((col, index) => ({
              weight: parseFloat(row[col]),
              point: pointCategory[index]
            }))
          });
        }
      });

      const bmiRows = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[3]], { header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"], blankrows: true });
      const bmi = {
        male: {},
        female: {}
      };
      _.drop(bmiRows, 3).forEach(row => {
        bmi.male[row.A] = ["B", "C", "D", "E", "F"].map((col, index) => ({
          bmi: parseFloat(row[col]),
          point: pointCategory[index]
        }));
        bmi.female[row.A] = ["H", "I", "J", "K", "L"].map((col, index) => ({
          bmi: parseFloat(row[col]),
          point: pointCategory[index]
        }));
      });

      const result = {
        height,
        weight,
        weightLength,
        bmi
      };

      const blobData = new Blob([JSON.stringify(result)], { type: "application/json" });
      saveAs(blobData, "solieucando.json");

      this.setState({ isWaiting: false });
    };
    reader.onabort = () => {
      this.setState({ isWaiting: false });
    };
    reader.onerror = () => {
      this.setState({ isWaiting: false }, () => this.errorHandler("Đã có lỗi xảy ra trong quá trình đọc số liệu từ Excel"));
    };

    reader.readAsBinaryString(files[0]);
  }

  render() {
    return (
      <div>
        {this.state.isWaiting ? (
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
        ) : null}
        <Dropzone accept=".xls, .xlsx" multiple={false} onDrop={this.importHandler}>
          {({ getRootProps, getInputProps }) => (
            <div style={{ float: "right" }} {...getRootProps()}>
              <input {...getInputProps()} />
              <Button color="green" size="small">
                Nhập từ Excel
                {' '}
                <Icon name="file excel outline" />
              </Button>
            </div>
          )}
        </Dropzone>
        <NotificationSystem ref={(ref) => { this.notificationSystem = ref }} />
      </div>
    );
  }
}