import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import moment from "moment";
import _ from "lodash";
import { createStore, compose } from "redux";
import { Provider } from "react-redux";
import WebFont from "webfontloader";

import "react-table/react-table.css";
import "react-perfect-scrollbar/dist/css/styles.css";

// components
import App from "./App";

// services
import registerServiceWorker from "./registerServiceWorker";
import reducer from "./redux/reducers";


moment.locale("vi", {
  months: _.range(12).map(i => `Tháng ${i + 1}`),
  monthsShort: _.range(12).map(i => `Tháng ${i + 1}`),
  monthsParseExact: true,
  weekdays: ["Chủ nhật", "Thứ hai", "Thứ ba", "Thứ tư", "Thứ năm", "Thứ sáu", "Thứ bảy"],
  weekdaysShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  weekdaysMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm"
  },
  calendar: {
    sameDay: "[Hôm nay lúc] LT",
    nextDay: "[Ngày mai lúc] LT",
    nextWeek: "dddd [tuần tới] LT",
    lastDay: "[Hôm qua lúc] LT",
    lastWeek: "dddd [tuần trước] LT",
    sameElse: "L"
  },
  relativeTime: {
    future: "trong %s",
    past: "%s trước",
    s: "vài giây",
    m: "một phút",
    mm: "%d phút",
    h: "1 tiếng",
    hh: "%d tiếng",
    d: "một ngày",
    dd: "%d ngày",
    M: "một tháng",
    MM: "%d tháng",
    y: "một năm",
    yy: "%d năm"
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4  // The week that contains Jan 4th is the first week of the year.
  }
});

WebFont.load({
  google: {
    families: ["Roboto", "sans-serif"]
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers());

ReactDOM.render(<Provider store={store}><IntlProvider locale="en"><App /></IntlProvider></Provider>, document.getElementById("root"));

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Đã có bản mới! Thầy/Cô xin vui lòng ấn nút OK để cập nhật.')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  },
};

registerServiceWorker(configuration);
