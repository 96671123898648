import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Header, Button } from "semantic-ui-react";


export default class ConfirmModal extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    confirmHandler: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    positiveNext: PropTypes.bool
  };

  static defaultProps = {
    positiveNext: false
  };

  render() {
    const { closeHandler, confirmHandler, icon, title, content, positiveNext } = this.props;
    return (
      <Modal size="small" open onClose={closeHandler}>
        <Header icon={icon} content={title} />
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>
          <Button positive={!positiveNext} negative={positiveNext} onClick={closeHandler}>Không</Button>
          <Button positive={positiveNext} negative={!positiveNext} onClick={confirmHandler}>Có</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}