import Excel from "exceljs";
import _ from "lodash";
import {
  evaluateStudents,
  computeBMIPointResult,
  computeHeightPointResult,
  computeWeightLengthPointResult,
  computeWeightPointResult,
  round,
} from "../helpers";
import {
  writeHeaders,
  writeReport,
  writeCell,
  writeRange,
  writeInfos,
} from "./common";

const tonghopsk = (
  allMonthData,
  activeYear,
  solieucando,
  name,
  useDay,
  errorHandler,
  closeHandler,
  onComplete,
  sgd
) => {
  const monthsOfYear = ["9", "10", "11", "12", "1", "2", "3", "4", "5"];
  const wb = new Excel.Workbook();
  const sheet = wb.addWorksheet("sheet 1", {
    pageSetup: {
      fitToPage: true,
      fitToWidth: 1,
      orientation: "landscape",
      paperSize: 9,
      horizontalCentered: true,
      margins: {
        left: 0.32,
        right: 0.25,
        top: 0.85,
        bottom: 0.28,
        header: 0.2,
        footer: 0.24,
      },
    },
  });
  sheet.getRow(1).height = 18.75;
  sheet.getRow(2).height = 18.75;
  sheet.getRow(4).height = 22.5;
  sheet.getRow(5).height = 22.5;
  sheet.getRow(6).height = 22.5;
  sheet.getRow(7).height = 30;
  sheet.getRow(8).height = 37.5;
  const rows = [];
  _.forEach(monthsOfYear, (month) => {
    const monthData = allMonthData[month];
    const data = {
      NC1: 0,
      NC2: 0,
      TC1: 0,
      TC2: 0,
      GC1: 0,
      GC2: 0,
      BP: 0,
      DC: 0,
      BTWeight: 0,
      BTHeight: 0,
      BTBmiWeiHei: 0,
      month: `Tháng ${month}`,
      total: 0,
      female: 0,
      checked: 0,
      NCTC: 0,
    };
    _.forEach(monthData, (values) => {
      const { day, students } = values;
      data.total += _.keys(students).length;
      data.checked += _.filter(
        students,
        (student) => student.weight && student.height
      ).length;
      data.female += _.filter(
        students,
        (student) => student.gender === "F"
      ).length;
      const evaluatedStudents = evaluateStudents(
        students,
        parseFloat(month),
        activeYear,
        solieucando,
        day,
        useDay,
        sgd
      ).map((student) => {
        const weightPoint =
          student.weightPoint !== undefined
            ? computeWeightPointResult(student.weightPoint)
            : "";
        const heightPoint =
          student.heightPoint !== undefined
            ? computeHeightPointResult(student.heightPoint)
            : "";
        const bmiPoint =
          student.bmiPoint !== undefined
            ? computeBMIPointResult(student.bmiPoint)
            : "";
        const weightLengthPoint =
          student.weightLengthPoint !== undefined
            ? computeWeightLengthPointResult(student.weightLengthPoint)
            : "";
        return {
          ...student,
          weightPoint,
          heightPoint,
          bmiPoint,
          weightLengthPoint,
        };
      });
      _.forEach(
        evaluatedStudents,
        ({ weightPoint, heightPoint, bmiPoint, weightLengthPoint }) => {
          if (weightPoint === "BT" || weightPoint === "BT+") data.BTWeight += 1;
          else if (_.has(data, weightPoint)) data[weightPoint] += 1;
          if (heightPoint === "BT" || heightPoint === "BT+") data.BTHeight += 1;
          else if (_.has(data, heightPoint)) data[heightPoint] += 1;
          if ((weightLengthPoint || bmiPoint) === "BT") data.BTBmiWeiHei += 1;
          else if (_.has(data, weightLengthPoint) || _.has(data, bmiPoint))
            data[weightLengthPoint || bmiPoint] += 1;
          if (_.has(data, weightPoint) || _.has(data, heightPoint))
            data.NCTC += 1;
        }
      );
    });
    const { total, NC1, NC2, TC1, TC2, GC1, GC2, BP, DC, NCTC } = data;
    if (![9, 5, 1].includes(parseFloat(month))) {
      const BTWeight = total - NC1 - NC2;
      const BTHeight = total - TC1 - TC2;
      const BTBmiWeiHei = total - GC1 - GC2 - BP - DC - NCTC;
      _.set(data, "BTWeight", BTWeight);
      _.set(data, "BTHeight", BTHeight);
      _.set(data, "BTBmiWeiHei", BTBmiWeiHei);
    }
    [
      "BTWeight",
      "NC1",
      "NC2",
      "BTHeight",
      "TC1",
      "TC2",
      "BTBmiWeiHei",
      "DC",
      "BP",
      "GC1",
      "GC2",
    ].forEach((key) => {
      const ratio = Math.abs(round((data[key] / total) * 100));
      _.set(data, `${key}Ratio`, ratio);
    });
    rows.push(data);
  });
  const begin =
    rows.find(({ month, ...rest }) => Object.values(rest).some((val) => val)) ??
    {};
  const middle = rows[4];
  const final = _.last(rows);
  const midYearCompare = {
    month: "So với đầu năm",
    middleChecked: middle.checked,
  };
  const yearEndCompare = {
    month: "So với đầu năm",
    finalChecked: final.checked,
  };
  _.forEach(
    [
      "NC1",
      "NC2",
      "TC1",
      "TC2",
      "GC1",
      "GC2",
      "BP",
      "DC",
      "BTWeight",
      "BTHeight",
      "BTBmiWeiHei",
      "DC",
      "NCTC",
      "total",
      "female",
      "checked",
      "total",
    ],
    (key) => {
      const midYearValue = _.keys(_.get(allMonthData, 1)).length
        ? middle[key] - begin[key]
        : 0;
      const yearEndvalue = _.keys(_.get(allMonthData, 5)).length
        ? final[key] - begin[key]
        : 0;
      _.set(midYearCompare, key, midYearValue);
      _.set(yearEndCompare, key, yearEndvalue);
      if (sgd === "lamdong") {
        _.set(
          midYearCompare,
          `${key}Ratio`,
          round(middle[`${key}Ratio`] - begin[`${key}Ratio`])
        );
        _.set(
          yearEndCompare,
          `${key}Ratio`,
          round(final[`${key}Ratio`] - begin[`${key}Ratio`])
        );
      }
    }
  );

  rows.splice(5, 0, midYearCompare);
  rows.push(yearEndCompare);
  writeInfos(sheet, 1, 1, [
    { title: "Trường", value: name },
    { title: "Năm học", value: activeYear },
  ]);
  writeRange(
    sheet,
    1,
    7,
    "BẢNG TỔNG HỢP TÌNH HÌNH SỨC KHỎE TRẺ TOÀN TRƯỜNG",
    "G1:T2",
    { bold: true, fontSize: 14 }
  );
  writeHeaders(
    sheet,
    [
      { name: "THÁNG", width: 10, wrapText: true },
      { name: "TỔNG SỐ HỌC SINH", width: 7.1, wrapText: true },
      { name: "NỮ", width: 7.1, wrapText: true },
      { name: "SỐ HỌC SINH ĐƯỢC CÂN ĐO", width: 7.1, wrapText: true },
    ],
    4,
    0,
    5
  );
  writeRange(sheet, 4, 5, "ĐÁNH GIÁ CHẤT LƯỢNG CHĂM SÓC", "E4:Z4", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 5, 5, "CÂN NẶNG (Kg)", "E5:J5", {
    border: true,
    bold: true,
  });

  writeRange(sheet, 5, 11, "CHIỀU CAO (m)", "K5:P5", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 5, 17, "CÂN NẶNG THEO CHIỀU CAO HOẶC BMI", "Q5:Z5", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 6, 5, "BÌNH THƯỜNG", "E6:F7", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 6, 7, "SDD NHẸ CÂN", "G6:J6", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 7, 7, "SDD NHẸ CÂN", "G7:H7", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 7, 9, "SDD NHẸ CÂN\n(nặng)", "I7:J7", {
    border: true,
    bold: true,
    wrapText: true,
  });
  writeRange(sheet, 6, 11, "BÌNH THƯỜNG", "K6:L7", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 6, 13, "SDD THẤP CÒI", "M6:P6", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 7, 13, "SDD THẤP CÒI", "M7:N7", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 7, 15, "SDD THẤP CÒI\n(nặng)", "O7:P7", {
    border: true,
    bold: true,
    wrapText: true,
  });
  writeRange(sheet, 6, 17, "BÌNH THƯỜNG", "Q6:R7", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 6, 19, "THỪA CÂN", "S6:T7", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 6, 21, "BÉO PHÌ", "U6:V7", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 6, 23, "SDD GẦY CÒM", "W6:X7", {
    border: true,
    bold: true,
  });
  writeRange(sheet, 6, 25, "SDD GẦY CÒM\n(nặng)", "Y6:Z7", {
    border: true,
    bold: true,
    wrapText: true,
  });
  const soTreTyleHeaders = [];
  _.forEach(_.range(1, 12), () => {
    soTreTyleHeaders.push(
      { name: "Số trẻ", width: 6.4, wrapText: true },
      { name: "Tỉ lệ\n%", width: 7, wrapText: true }
    );
  });
  writeHeaders(sheet, soTreTyleHeaders, 8, 4, 1);
  sheet.getRow(9).height = 13.5;
  writeHeaders(
    sheet,
    _.map(_.range(1, 27), (idx) => ({ name: idx })),
    9,
    0,
    1,
    { fill: "FFD9D9D9" }
  );
  let rowIndex = 10;
  const addPrefix = (val, prefix = "") => {
    return parseFloat(val) > 0 ? `${prefix}${val}` : val;
  };
  _.forEach(
    rows,
    ({
      month,
      total,
      checked,
      female,
      middleChecked,
      finalChecked,
      ...rest
    }) => {
      const splitedStyles = {};
      sheet.getRow(rowIndex).height = 30;
      let prefix = "";
      if (month === "So với đầu năm") {
        splitedStyles.border = "thin";
        splitedStyles.color = "FFFF0000";
        splitedStyles.bold = true;
        prefix = "+";
      }
      writeCell(sheet, rowIndex, 1, month, {
        border: {
          bottom: splitedStyles.border || "dotted",
          top: splitedStyles.border || "dotted",
        },
        wrapText: true,
        color: splitedStyles.color,
        bold: splitedStyles.bold,
        fontSize: 10,
      });
      writeCell(sheet, rowIndex, 2, addPrefix(total, prefix), {
        border: {
          bottom: splitedStyles.border || "dotted",
          top: splitedStyles.border || "dotted",
        },
        fontSize: 10,
        color: splitedStyles.color,
        bold: splitedStyles.bold,
      });
      writeCell(sheet, rowIndex, 3, addPrefix(female, prefix), {
        border: {
          bottom: splitedStyles.border || "dotted",
          top: splitedStyles.border || "dotted",
        },
        fontSize: 10,
        color: splitedStyles.color,
        bold: splitedStyles.bold,
      });
      writeCell(sheet, rowIndex, 4, addPrefix(checked, prefix), {
        border: {
          bottom: splitedStyles.border || "dotted",
          top: splitedStyles.border || "dotted",
        },
        fontSize: 10,
        color: splitedStyles.color,
        bold: splitedStyles.bold,
      });
      let colIdx = 5;
      let comparedNumber = total;
      if (middleChecked) comparedNumber = middle.total;
      if (finalChecked) comparedNumber = final.total;
      _.forEach(
        [
          "BTWeight",
          "NC1",
          "NC2",
          "BTHeight",
          "TC1",
          "TC2",
          "BTBmiWeiHei",
          "DC",
          "BP",
          "GC1",
          "GC2",
        ],
        (key) => {
          writeCell(sheet, rowIndex, colIdx, addPrefix(rest[key], prefix), {
            border: {
              bottom: splitedStyles.border || "dotted",
              top: splitedStyles.border || "dotted",
            },
            fontSize: 10,
            color: splitedStyles.color,
            bold: splitedStyles.bold,
          });
          colIdx += 1;
          const ratio =
            (middleChecked || finalChecked) && sgd === "lamdong"
              ? addPrefix(round(rest[`${key}Ratio`]).toFixed(2), prefix)
              : Math.abs(round((rest[key] / comparedNumber) * 100));
          writeCell(
            sheet,
            rowIndex,
            colIdx,
            rest[key] || rest[`${key}Ratio`] ? ratio : "",
            {
              border: {
                bottom: splitedStyles.border || "dotted",
                top: splitedStyles.border || "dotted",
              },
              fontSize: 10,
              color: splitedStyles.color,
              bold: splitedStyles.bold,
              numberFormat: "0.00",
            }
          );
          colIdx += 1;
        }
      );
      rowIndex += 1;
    }
  );
  return wb.xlsx.writeBuffer().then((data) => {
    if (data && _.keys(rows).length) {
      writeReport(
        new Blob([data]),
        "Tong hop suc khoe toan truong.xlsx",
        closeHandler
      );
    } else {
      errorHandler("Xuất báo cáo không thành công, vui lòng kiểm tra lại");
      onComplete();
    }
  });
};

export default tonghopsk;
