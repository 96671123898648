import Excel from 'exceljs';
import { intToExcelCol } from 'excel-column-name';
import _ from 'lodash';
import {
  evaluateStudents,
  computeBMIPointResult,
  computeHeightPointResult,
  computeWeightLengthPointResult,
  computeWeightPointResult,
  round,
} from '../helpers';
import {
  writeHeaders,
  writeReport,
  writeCell,
  writeRange,
  writeRanges,
} from './common';

const theodoisuckhoe = (
  allMonthData,
  activeYear,
  solieucando,
  unitName,
  useDay,
  gradesList,
  errorHandler,
  closeHandler,
  onComplete,
  sgd,
  monthsOfMamChoiLa
) => {
  const monthsOfNhaTre = ['9', '10', '11', '12', '1', '2', '3', '4', '5'];
  const mappingDescs = {
    NC2: 'SDDNC(n)',
    NC1: 'SDDNC',
    TC2: 'SDDTC(n)',
    TC1: 'SDDTC',
    GC1: 'SDDGC',
    GC2: 'SDDGC(n)',
    BP: 'BP',
    DC: 'ThC',
    BT: 'BT',
    [`BT+`]: 'BT',
    NC1TC1: 'SDDNC /TC',
    NC1TC2: 'SDDNC /TC(n)',
    NC2TC1: 'SDDNC(n)/TC',
    NC2TC2: 'SDDNC(n)/TC(n)',
  };
  const addPrefix = (val) => {
    return val > 0 ? `+${val}` : val;
  };
  const wb = new Excel.Workbook();
  
  _.forEach(gradesList, ({ grade, name, classID }) => {
    const isNhaTre = () => {
      if (
        !grade.includes('24-36thangtuoi') &&
        !grade.includes('3-4tuoi') &&
        !grade.includes('4-5tuoi') &&
        !grade.includes('5-6tuoi')
      ) return true;
      return false;
    }
    const isLamDong4t6t = sgd === 'lamdong' && ( grade.includes('4-5tuoi') || grade.includes('5-6tuoi'));
    const printSetUp = {};
    if(isNhaTre()) {
      _.set(printSetUp, 'fitToPage', true);
      _.set(printSetUp, 'fitToWidth', 1);
      _.set(printSetUp, 'fitToHeight', 0);
    } else _.set(printSetUp, 'scale', 54);
    const sheet = wb.addWorksheet(`${name}`, {
      pageSetup: {
        orientation: 'landscape',
        paperSize: 9,
        horizontalCentered: true,
        margins : {
          left: 0.32,
          right: 0.25,
          top: 0.35,
          bottom: 0.28,
          header: 0.2,
          footer: 0.24,
        },
        ...printSetUp
      },
    });

    sheet.getRow(4).height = 9.5;
    sheet.getRow(6).height = 67.5;
    const infos = [
      { title: 'Trường', value: unitName },
      { title: 'Lớp', value: name },
      { title: 'Năm học', value: activeYear },
  ];
  _.forEach(
    infos,
    ({ title, value }, idx) => {
      writeRange(sheet, idx + 1, 1, title, `A${idx + 1}:B${idx + 1}`, {
        bold: true,
        horizontal: 'left',
      });
      writeCell(sheet, idx + 1, 3, value, {
        color: 'FFFF0000',
        bold: true,
        horizontal: 'left',
      });
    }
  );
    if (isNhaTre()) {
      sheet.getColumn(2).width = 5.4;
      sheet.getColumn(3).width = 16.4;
      sheet.getColumn(37).width = 5.4;
      sheet.getColumn(38).width = 17.1;
      writeRange(sheet, 1, 7, 'THEO DÕI SỨC KHỎE TRẺ', 'G1:AA3', {
        bold: true,
        fontSize: 12,
      });
      writeRange(sheet, 1, 43, 'THEO DÕI SỨC KHỎE TRẺ', 'AQ1:BF3', {
        bold: true,
        fontSize: 12,
      });
      const nhaTreLeftHeaders = [
        { name: 'NỮ', width: 4.3, wrapText: true },
        { name: 'Ngày sinh', width: 11.4, wrapText: true },
      ];
      _.forEach(['09', '10', '11', '12', '01'], (month) => {
        nhaTreLeftHeaders.push({
          name: `Tháng ${month}`,
          subHeaders: [
            { name: 'Cân nặng', width: 5.1, vertical: true },
            { name: 'Kết quả', width: 7.4, vertical: true },
            { name: 'Chiều cao', width: 5.7, vertical: true },
            { name: 'Kết quả', width: 6.4, vertical: true },
            {
              name: 'Cân nặng theo chiều cao',
              width: 7.6,
              vertical: true,
              wrapText: true,
            },
            { name: 'Đánh giá', width: 11.7, vertical: true },
          ],
        });
      });
      const nhaTreRightHeaders = [
        { name: 'NỮ', width: 4.3, wrapText: true },
        { name: 'Ngày sinh', width: 11.4, wrapText: true },
      ];
      _.forEach(['02', '03', '04', '05'], (month) => {
        nhaTreRightHeaders.push({
          name: `Tháng ${month}`,
          subHeaders: [
            { name: 'Cân nặng', width: 5.1, vertical: true },
            { name: 'Kết quả', width: 7.4, vertical: true },
            { name: 'Chiều cao', width: 5.7, vertical: true },
            { name: 'Kết quả', width: 6.4, vertical: true },
            {
              name: 'Cân nặng theo\nchiều cao',
              width: 7.6,
              vertical: true,
              wrapText: true,
            },
            { name: 'Đánh giá', width: 11.7, vertical: true },
          ],
        });
      });
      writeHeaders(
        sheet,
        [{ name: 'STT', wrapText: true, width: 3.4 }],
        5,
        0,
        2
      );
      writeRange(sheet, 5, 2, 'Họ và tên', 'B5:C6', {
        bold: true,
        border: true,
      });
      writeHeaders(sheet, nhaTreLeftHeaders, 5, 3, 2);
      writeHeaders(sheet, [{ name: 1, wrapText: true, width: 3.4 }], 7, 0, 1, {
        fill: 'FFD9D9D9',
      });
      writeRange(sheet, 7, 2, 2, 'B7:C7', {
        bold: true,
        border: true,
        fill: 'FFD9D9D9',
      });
      writeHeaders(
        sheet,
        _.map(_.range(3, 35), (idx) => ({ name: idx })),
        7,
        3,
        1,
        { fill: 'FFD9D9D9' }
      );
      writeHeaders(
        sheet,
        [{ name: 'STT', wrapText: true, width: 3.9 }],
        5,
        35,
        2
      );
      writeRange(sheet, 5, 37, 'Họ và tên', 'AK5:AL6', {
        bold: true,
        border: true,
      });
      writeHeaders(sheet, nhaTreRightHeaders, 5, 38, 2);

      writeHeaders(sheet, [{ name: 1, wrapText: true }], 7, 35, 1, {
        fill: 'FFD9D9D9',
      });
      writeRange(sheet, 7, 37, 2, 'AK7:AL7', {
        bold: true,
        border: true,
        fill: 'FFD9D9D9',
      });
      writeHeaders(
        sheet,
        _.map(_.range(3, 29), (idx) => ({ name: idx })),
        7,
        38,
        1,
        { fill: 'FFD9D9D9' }
      );
    } else {
      writeRange(sheet, 1, 4, 'THEO DÕI SỨC KHỎE TRẺ', 'D1:W3', {
        bold: true,
        fontSize: 16,
      });
      sheet.getColumn(2).width = 6.3;
      sheet.getColumn(3).width = 18.7;
      const mamChoiLaHeaders = [
        { name: 'NỮ', width: 4.3, wrapText: true },
        { name: 'Ngày sinh', width: 11.4, wrapText: true },
      ];
      _.forEach(monthsOfMamChoiLa, (month) => {
        const bmi = grade.includes('4-5tuoi') || grade.includes('5-6tuoi') ? '/BMI' : '';
        const  subHeaders= [
          { name: 'Cân nặng', width: 5.1, vertical: true },
          { name: 'Kết quả', width: 7.4, vertical: true },
          { name: 'Chiều cao', width: 5.7, vertical: true },
          { name: 'Kết quả', width: 6.4, vertical: true },
          {
            name: `Cân nặng theo\nchiều cao${bmi}`,
            width: 7.6,
            vertical: true,
            wrapText: true,
          },
          { name: 'Đánh giá', width: 11.7, vertical: true },
        ];
        if(isLamDong4t6t) subHeaders.splice(4, 0, { name: 'BMI', width: 6.4, vertical: true });
        mamChoiLaHeaders.push({
        name: `Tháng ${month}`,
        subHeaders,
      }); 
      });
      writeHeaders(
        sheet,
        [{ name: 'STT', wrapText: true, width: 4.3 }],
        5,
        0,
        2
      );
      writeRange(sheet, 5, 2, 'Họ và tên', 'B5:C6', {
        bold: true,
        border: true,
      });
      writeHeaders(sheet, mamChoiLaHeaders, 5, 3, 2);
      writeHeaders(sheet, [{ name: 1, wrapText: true }], 7, 0, 1, {
        fill: 'FFD9D9D9',
      });
      writeRange(sheet, 7, 2, 2, 'B7:C7', {
        bold: true,
        border: true,
        fill: 'FFD9D9D9',
      });
      if(isLamDong4t6t) {
        writeHeaders(
          sheet,
          _.map(_.range(3, 26), (idx) => ({ name: idx })),
          7,
          3,
          1,
          { fill: 'FFD9D9D9' }
        );
      } else {
        writeHeaders(
          sheet,
          _.map(_.range(3, 23), (idx) => ({ name: idx })),
          7,
          3,
          1,
          { fill: 'FFD9D9D9' }
        );
      }
    }
    const allStudents = {};
    _.forEach(allMonthData, (mth) => {
      const monthStudents = _.get(
        _.find(mth, (obj, id) => classID === id),
        'students',
        {}
      );
      _.forEach(
        monthStudents,
        (
          { firstName, lastName, date, month: childMonth, year, gender },
          id
        ) => {
          if (!allStudents[id]) {
            allStudents[id] = {
              firstName,
              lastName,
              dayOfBth: `${date}/${childMonth}/${year}`,
              gender,
              studentID: id,
            };
          }
        }
      );
    });
    const allStudentsArr = _.map(allStudents, (student) => student);
    const evaluatedYearStudents = [];
    const monthToCal = isNhaTre() ? monthsOfNhaTre : monthsOfMamChoiLa;
    _.forEach(monthToCal, (mth) => {
      const { students: mthStudents, day } = _.get(
        allMonthData[mth],
        classID,
        {}
      );
      const counter = {
        NC1: 0,
        NC2: 0,
        TC1: 0,
        TC2: 0,
        GC1: 0,
        GC2: 0,
        BP: 0,
        DC: 0,
        BTWeight: 0,
        BTHeight: 0,
        BTBmiWeiHei: 0,
        FNC2: 0,
        FTC1: 0,
        FNC1: 0,
        FTC2: 0,
        FGC1: 0,
        FGC2: 0,
        FBP: 0,
        FDC: 0,
        FBTWeight: 0,
        FBTHeight: 0,
        FBTBmiWeiHei: 0,
        total: 0,
        female: 0,
        checked: 0,
      };
      counter.checked += _.filter(
        mthStudents,
        (student) => student.weight && student.height 
      ).length;
      counter.female += _.filter(mthStudents, (student) => student.gender === 'F' && student.weight  && student.height ).length;
      counter.total += _.keys(mthStudents).length;
      const evaluatedMthStudents = evaluateStudents(
        mthStudents,
        parseFloat(mth),
        activeYear,
        solieucando,
        day,
        useDay,
        sgd
      ).map((student) => {
        const weightPoint =
          student.weightPoint !== undefined
            ? computeWeightPointResult(student.weightPoint)
            : '';
        const heightPoint =
          student.heightPoint !== undefined
            ? computeHeightPointResult(student.heightPoint)
            : '';
        const bmiPoint =
          student.bmiPoint !== undefined
            ? computeBMIPointResult(student.bmiPoint)
            : '';
        const weightLengthPoint =
          student.weightLengthPoint !== undefined
            ? computeWeightLengthPointResult(student.weightLengthPoint)
            : '';
        return {
          ...student,
          weightPoint,
          heightPoint,
          bmiPoint,
          weightLengthPoint,
        };
      });
      _.forEach(
        evaluatedMthStudents,
        ({ weightPoint, heightPoint, bmiPoint, weightLengthPoint, gender }) => {
          if(weightPoint === 'BT' || weightPoint === 'BT+') {
            if(gender === 'F') counter.FBTWeight += 1;
            counter.BTWeight += 1;
          }
          else if(_.has(counter, weightPoint)) {
            if(gender === 'F') counter[`F${weightPoint}`] +=1;
            counter[weightPoint] += 1;
          };
          if(heightPoint === 'BT' || heightPoint === 'BT+') {
            if(gender === 'F') counter.FBTHeight += 1;
            counter.BTHeight += 1;
          }
          else if(_.has(counter, heightPoint)) {
            if(gender === 'F') counter[`F${heightPoint}`] +=1;
            counter[heightPoint] += 1;
          }
          if((weightLengthPoint || bmiPoint) === 'BT') {
            if(gender === 'F') counter.FBTBmiWeiHei += 1;
            counter.BTBmiWeiHei += 1;
          }
          else if(_.has(counter, weightLengthPoint || bmiPoint)) {
            if(gender === 'F') counter[`F${weightLengthPoint|| bmiPoint}`] += 1;
            counter[weightLengthPoint || bmiPoint] += 1;
          }
        }
      );
      evaluatedYearStudents.push({
        students: evaluatedMthStudents,
        mth,
        counter,
        order: monthsOfNhaTre.indexOf(mth),
      });
    });
    let rowIndex = 8;
    if (isNhaTre()) {
      _.forEach(
        _.sortBy(allStudentsArr, ['firstName']),
        ({ firstName, lastName, dayOfBth, gender, studentID }, idx) => {
          writeCell(sheet, rowIndex, 1, idx + 1, {
            border: { bottom: 'dotted', top: 'dotted' },
          });
          writeRange(
            sheet,
            rowIndex,
            2,
            `${lastName} ${firstName}`,
            `B${rowIndex}:C${rowIndex}`,
            {
              border: { bottom: 'dotted', top: 'dotted' },
              horizontal: 'left'
            }
          );
          writeCell(sheet, rowIndex, 4, `${gender === 'F' ? 'Nữ' : ''}`, {
            border: { bottom: 'dotted', top: 'dotted' },
          });
          writeCell(
            sheet,
            rowIndex,
            5,
            dayOfBth, {
            border: { bottom: 'dotted', top: 'dotted' },
            horizontal: 'left'
          });
          let colIndex = 6;
          _.forEach(['9', '10', '11', '12', '1'], (mth) => {
            const  students  = _.get(_.find(
              evaluatedYearStudents,
              ({ mth: mthData }) => mth === mthData
            ), 'students')
            const {
              heightPoint,
              weightPoint,
              height,
              weight,
              weightLengthPoint,
              bmi,
              bmiPoint,
            } = _.find(students, ({ studentID: id }) => studentID === id) || {};
            let remark = '';
            if (
              weightPoint !== 'NC1' &&
              weightPoint !== 'NC2' &&
              heightPoint !== 'TC1' &&
              heightPoint !== 'TC2'
            ) {
              remark = mappingDescs[bmiPoint || weightLengthPoint];
            } else {
              if (
                (heightPoint === 'BT' || heightPoint === 'BT+') &&
                (weightPoint === 'NC1' || weightPoint === 'NC2')
              )
                remark = mappingDescs[weightPoint];
              if (
                (weightPoint === 'BT' || weightPoint === 'BT+') &&
                (heightPoint === 'TC1' || heightPoint === 'TC2')
              )
                remark = mappingDescs[heightPoint];
              if (
                heightPoint !== 'BT' &&
                heightPoint !== 'BT+' &&
                weightPoint !== 'BT' &&
                weightPoint !== 'BT+'
              )
                remark = mappingDescs[`${weightPoint}${heightPoint}`];
            }
            writeCell(sheet, rowIndex, colIndex, weight, {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, mappingDescs[weightPoint], {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, height, {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, mappingDescs[heightPoint], {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            if (weightLengthPoint) {
              writeCell(
                sheet,
                rowIndex,
                colIndex,
                mappingDescs[weightLengthPoint],
                {
                  border: { bottom: 'dotted', top: 'dotted' },
                }
              );
              colIndex += 1;
              writeCell(sheet, rowIndex, colIndex, remark, {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
            } else {
              writeCell(sheet, rowIndex, colIndex, round(bmi, 2), {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
              writeCell(sheet, rowIndex, colIndex, remark, {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
            }
          });
          writeCell(sheet, rowIndex, colIndex, idx + 1, {
            border: { bottom: 'dotted', top: 'dotted' },
          });
          colIndex += 1;
          writeRange(
            sheet,
            rowIndex,
            colIndex,
            `${lastName} ${firstName}`,
            `AK${rowIndex}:AL${rowIndex}`,
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          );
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex + 1,
            `${gender === 'F' ? 'Nữ' : ''}`,
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          );
          colIndex += 2;
          writeCell(sheet, rowIndex, colIndex, dayOfBth, {
            border: { bottom: 'dotted', top: 'dotted' },
          });
          colIndex += 1;
          _.forEach(['2', '3', '4', '5'], (mth) => {
            const students = _.get(_.find(
              evaluatedYearStudents,
              ({ mth: mthData }) => mth === mthData
            ), 'students');
            const {
              heightPoint,
              weightPoint,
              height,
              weight,
              weightLengthPoint,
              bmi,
              bmiPoint,
            } = _.find(students, ({ studentID: id }) => studentID === id) || {};
            let remark = '';
            if (
              weightPoint !== 'NC1' &&
              weightPoint !== 'NC2' &&
              heightPoint !== 'TC1' &&
              heightPoint !== 'TC2'
            ) {
              remark = mappingDescs[bmiPoint || weightLengthPoint];
            } else {
              if (
                (heightPoint === 'BT' || heightPoint === 'BT+') &&
                (weightPoint === 'NC1' || weightPoint === 'NC2')
              )
                remark = mappingDescs[weightPoint];
              if (
                (weightPoint === 'BT' || weightPoint === 'BT+') &&
                (heightPoint === 'TC1' || heightPoint === 'TC2')
              )
                remark = mappingDescs[heightPoint];
              if (
                heightPoint !== 'BT' &&
                heightPoint !== 'BT+' &&
                weightPoint !== 'BT' &&
                weightPoint !== 'BT+'
              )
                remark = mappingDescs[`${weightPoint}${heightPoint}`];
            }
            writeCell(sheet, rowIndex, colIndex, weight, {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, mappingDescs[weightPoint], {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, height, {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, mappingDescs[heightPoint], {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            if (weightLengthPoint) {
              writeCell(
                sheet,
                rowIndex,
                colIndex,
                mappingDescs[weightLengthPoint],
                {
                  border: { bottom: 'dotted', top: 'dotted' },
                }
              );
              colIndex += 1;
              writeCell(sheet, rowIndex, colIndex, remark, {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
            } else {
              writeCell(sheet, rowIndex, colIndex, round(bmi, 2), {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
              writeCell(sheet, rowIndex, colIndex, remark, {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
            }
          });

          rowIndex += 1;
        }
      );
      writeRange(
        sheet,
        rowIndex,
        1,
        `Tổng số: ${allStudentsArr.length} trẻ`,
        `A${rowIndex}:C${rowIndex}`,
        { border: true, bold: true }
      );
      writeRange(
        sheet,
        rowIndex,
        36,
        `Tổng số: ${allStudentsArr.length} trẻ`,
        `AJ${rowIndex}:AL${rowIndex}`,
        { border: true, bold: true }
      );
      _.forEach([4, 5, 39, 40], (col) =>
        writeCell(sheet, rowIndex, col, '', { border: true })
      );

      _.forEach([6, 12, 18, 24, 30, 41, 47, 53, 59], (col) => {
        writeRange(
          sheet,
          rowIndex,
          col,
          '',
          `${intToExcelCol(col)}${rowIndex}:${intToExcelCol(
            col + 5
          )}${rowIndex}`,
          { border: true }
        );
      });
      rowIndex += 1;
      const bmi = grade.includes('4-5tuoi') || grade.includes('5-6tuoi') ? '/BMI' : '';
      const ranges = [
        {
          row: rowIndex,
          col: 1,
          val: 'TỔNG HỢP',
          range: `A${rowIndex}:B${rowIndex + 12}`,
          style: { bold: true, border: true },
        },

        {
          row: rowIndex + 13,
          col: 1,
          val: 'SO VỚI ĐẦU NĂM',
          range: `A${rowIndex + 13}:B${rowIndex + 23}`,
          style: { bold: true, border: true, wrapText: true, fontSize: 10 },
        },

        {
          row: rowIndex,
          col: 3,
          val: '',
          range: `C${rowIndex}:E${rowIndex}`,
          style: { fill: 'FFD9D9D9', border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 1,
          col: 3,
          val: 'Tổng số trẻ cân đo',
          range: `C${rowIndex + 1}:E${rowIndex + 1}`,
          style: { fill: 'FFD9D9D9', bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 2,
          col: 3,
          val: 'Cân nặng',
          range: `C${rowIndex + 2}:C${rowIndex + 4}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 5,
          col: 3,
          val: 'Chiều cao',
          range: `C${rowIndex + 5}:C${rowIndex + 7}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 8,
          col: 3,
          val: `Cân nặng theo chiều cao${bmi}`,
          range: `C${rowIndex + 8}:C${rowIndex + 12}`,
          style: { bold: true, wrapText: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 13,
          col: 3,
          val: 'Cân nặng',
          range: `C${rowIndex + 13}:C${rowIndex + 15}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 16,
          col: 3,
          val: 'Chiều cao',
          range: `C${rowIndex + 16}:C${rowIndex + 18}`,
          style: { bold: true, border: true, fontSize: 10 },
        },
        
        {
          row: rowIndex + 19,
          col: 3,
          val: `Cân nặng theo chiều cao${bmi}`,
          range: `C${rowIndex + 19}:C${rowIndex + 23}`,
          style: { bold: true, wrapText: true, border: true, fontSize: 10 },
        },
      ];
      let tempColIdx = 6;
      _.forEach(
        [
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
        ],
        ({ val }) => {
          ranges.push({
            row: rowIndex,
            col: tempColIdx,
            val,
            range: `${intToExcelCol(tempColIdx)}${rowIndex}:${intToExcelCol(
              tempColIdx + 1
            )}${rowIndex}`,
            style: {
              wrapText: true,
              border: true,
              fill: 'FFD9D9D9',
              bold: true,
              fontSize: 10,
            },
          });
          tempColIdx += 2;
        }
      );
      ranges.push(
        {
          row: rowIndex,
          col: tempColIdx,
          val: 'TỔNG HỢP',
          range: `AJ${rowIndex}:AK${rowIndex + 12}`,
          style: { bold: true, border: true },
        },

        {
          row: rowIndex + 13,
          col: tempColIdx,
          val: 'SO VỚI ĐẦU NĂM',
          range: `AJ${rowIndex + 13}:AK${rowIndex + 23}`,
          style: { bold: true, border: true, wrapText: true, fontSize: 10 },
        },

        {
          row: rowIndex,
          col: tempColIdx + 2,
          val: '',
          range: `AL${rowIndex}:AN${rowIndex}`,
          style: { fill: 'FFD9D9D9', border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 1,
          col: tempColIdx + 2,
          val: 'Tổng số trẻ cân đo',
          range: `AL${rowIndex + 1}:AN${rowIndex + 1}`,
          style: { fill: 'FFD9D9D9', bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 2,
          col: tempColIdx + 2,
          val: 'Cân nặng',
          range: `AL${rowIndex + 2}:AL${rowIndex + 4}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 5,
          col: tempColIdx + 2,
          val: 'Chiều cao',
          range: `AL${rowIndex + 5}:AL${rowIndex + 7}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 8,
          col: tempColIdx + 2,
          val: 'Cân nặng theo chiều cao',
          range: `AL${rowIndex + 8}:AL${rowIndex + 12}`,
          style: { bold: true, wrapText: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 13,
          col: tempColIdx + 2,
          val: 'Cân nặng',
          range: `AL${rowIndex + 13}:AL${rowIndex + 15}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 16,
          col: tempColIdx + 2,
          val: 'Chiều cao',
          range: `AL${rowIndex + 16}:AL${rowIndex + 18}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 19,
          col: tempColIdx + 2,
          val: 'Cân nặng theo chiều cao',
          range: `AL${rowIndex + 19}:AL${rowIndex + 23}`,
          style: { bold: true, wrapText: true, border: true, fontSize: 10 },
        }
      );
      let tempRowIdx = rowIndex + 2;
      _.forEach(
        [
          { val: 'Bình thường' },
          { val: 'SDD Nhẹ cân (SDDNC)' },
          { val: 'SDD Nhẹ cân MĐ nặng (SDDNC(n))' },
          { val: 'Bình thường' },
          { val: 'SDD Thấp còi (TC)' },
          { val: 'SDD Thấp còi MĐ nặng (TC(n))' },
          { val: 'Bình thường (BT)' },
          { val: 'Thừa cân (ThC)' },
          { val: 'Béo phì (BP)' },
          { val: 'SDD thể gầy còm (SDDGC)' },
          { val: 'SDD thể gầy còm MĐ nặng (SDDGC(n))' },
          { val: 'Bình thường' },
          { val: 'SDD Nhẹ cân (SDDNC)' },
          { val: 'SDD Nhẹ cân MĐ nặng (SDDNC(n))' },
          { val: 'Bình thường' },
          { val: 'SDD Thấp còi (TC)' },
          { val: 'SDD Thấp còi MĐ nặng (TC(n))' },
          { val: 'Bình thường (BT)' },
          { val: 'Thừa cân (ThC)' },
          { val: 'Béo phì (BP)' },
          { val: 'SDD thể gầy còm (SDDGC)' },
          { val: 'SDD thể gầy còm MĐ nặng (SDDGC(n))' },
        ],
        ({ val }) => {
          if (val.length >= 20) sheet.getRow(tempRowIdx).height = 26.25;
          else sheet.getRow(tempRowIdx).height = 18.75;
          const border =
            val === 'SDD thể gầy còm MĐ nặng (SDDGC(n))'
              ? { top: 'dotted' }
              : { top: 'dotted', bottom: 'dotted' };
          ranges.push({
            row: tempRowIdx,
            col: 4,
            val,
            range: `D${tempRowIdx}:E${tempRowIdx}`,
            style: { wrapText: true, border, horizontal: 'left' },
          });
          ranges.push({
            row: tempRowIdx,
            col: tempColIdx + 3,
            val,
            range: `AM${tempRowIdx}:AN${tempRowIdx}`,
            style: { wrapText: true, border, horizontal: 'left' },
          });
          tempRowIdx += 1;
        }
      );
      _.forEach(
        [
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
        ],
        ({ val }) => {
          ranges.push({
            row: rowIndex,
            col: tempColIdx + 5,
            val,
            range: `${intToExcelCol(tempColIdx + 5)}${rowIndex}:${intToExcelCol(
              tempColIdx + 6
            )}${rowIndex}`,
            style: {
              wrapText: true,
              border: true,
              fill: 'FFD9D9D9',
              bold: true,
              fontSize: 10,
            },
          });
          tempColIdx += 2;
        }
      );
      writeRanges(sheet, ranges);
      rowIndex += 1;
      let totalColIdx = 6;
      _.forEach(['9', '10', '11', '12', '1'], (mth) => {
        const counter = _.get(_.find(
          evaluatedYearStudents,
          ({ mth: mthData }) => mth === mthData
        ), 'counter');
        writeRange(
          sheet,
          rowIndex,
          totalColIdx,
          _.get(counter, 'checked'),
          `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(
            totalColIdx + 1
          )}${rowIndex}`,
          { border: true }
        );
        totalColIdx += 2;
        writeRange(
          sheet,
          rowIndex,
          totalColIdx,
          _.get(counter, 'female'),
          `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(
            totalColIdx + 1
          )}${rowIndex}`,
          { border: true }
        );
        totalColIdx += 2;
        const ratio =
          (_.get(counter, 'checked', 0) / _.get(counter, 'total', 0)) * 100;
        writeRange(
          sheet,
          rowIndex,
          totalColIdx,
          round(ratio, 2),
          `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(
            totalColIdx + 1
          )}${rowIndex}`,
          { border: true, numberFormat: '0.00' }
        );
        totalColIdx += 2;
      });
      rowIndex += 1;
      const { counter: firstMonthCounter, mth: firstMth } = evaluatedYearStudents.find(({ students }) => Object.keys(students).length) ?? {};
      _.forEach(
        [
          'BTWeight',
          'NC1',
          'NC2',
          'BTHeight',
          'TC1',
          'TC2',
          'BTBmiWeiHei',
          'DC',
          'BP',
          'GC1',
          'GC2',
        ],
        (key) => {
          let tempColIdx2 = 6;
          _.forEach(['9', '10', '11', '12', '1'], (mth) => {
            const counter = _.get(_.find(
              evaluatedYearStudents,
              ({ mth: mthData }) => mth === mthData
            ), 'counter');
            const border =
              key === 'GC2'
                ? { top: 'dotted' }
                : { top: 'dotted', bottom: 'dotted' };
            writeRange(
              sheet,
              rowIndex,
              tempColIdx2,
              _.get(counter, key),
              `${intToExcelCol(tempColIdx2)}${rowIndex}:${intToExcelCol(
                tempColIdx2 + 1
              )}${rowIndex}`,
              { border }
            );
            if (mth === firstMth) {
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2,
                '',
                `${intToExcelCol(tempColIdx2)}${rowIndex + 11}:${intToExcelCol(
                  tempColIdx2 + 1
                )}${rowIndex + 11}`,
                { border }
              );
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2 + 2,
                '',
                `${intToExcelCol(tempColIdx2 + 2)}${rowIndex +
                  11}:${intToExcelCol(tempColIdx2 + 3)}${rowIndex + 11}`,
                { border }
              );
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2 + 4,
                '',
                `${intToExcelCol(tempColIdx2 + 4)}${rowIndex +
                  11}:${intToExcelCol(tempColIdx2 + 5)}${rowIndex + 11}`,
                { border }
              );
            } else {
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2,
                _.get(counter, 'checked', 0)
                  ? addPrefix(
                      _.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)
                    )
                  : 0,
                `${intToExcelCol(tempColIdx2)}${rowIndex + 11}:${intToExcelCol(
                  tempColIdx2 + 1
                )}${rowIndex + 11}`,
                { border }
              );
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2 + 2,
                _.get(counter, 'checked', 0)
                  ? addPrefix(
                      _.get(counter, `F${key}`, 0) -
                        _.get(firstMonthCounter, `F${key}`, 0)
                    )
                  : 0,
                `${intToExcelCol(tempColIdx2 + 2)}${rowIndex +
                  11}:${intToExcelCol(tempColIdx2 + 3)}${rowIndex + 11}`,
                { border }
              );
              const comparedRatio = _.get(counter, 'checked')
                ? Math.abs(
                    (_.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)) /
                      _.get(counter, 'total', 0)
                  )
                : 0;
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2 + 4,
                round(comparedRatio * 100, 2),
                `${intToExcelCol(tempColIdx2 + 4)}${rowIndex +
                  11}:${intToExcelCol(tempColIdx2 + 5)}${rowIndex + 11}`,
                { border, numberFormat: '0.00' }
              );
            }
            tempColIdx2 += 2;
            writeRange(
              sheet,
              rowIndex,
              tempColIdx2,
              _.get(counter, `F${key}`),
              `${intToExcelCol(tempColIdx2)}${rowIndex}:${intToExcelCol(
                tempColIdx2 + 1
              )}${rowIndex}`,
              { border }
            );
            tempColIdx2 += 2;
            const ratio =
              (_.get(counter, key, 0) / _.get(counter, 'total', 0)) * 100;
            writeRange(
              sheet,
              rowIndex,
              tempColIdx2,
              round(ratio, 2),
              `${intToExcelCol(tempColIdx2)}${rowIndex}:${intToExcelCol(
                tempColIdx2 + 1
              )}${rowIndex}`,
              { border, numberFormat: '0.00' }
            );
            tempColIdx2 += 2;
          });
          rowIndex += 1;
        }
      );
      let rightRowIdx = allStudentsArr.length + 10;
      totalColIdx += 5;
      _.forEach(['2', '3', '4', '5'], (mth) => {
        const counter = _.get(_.find(
          evaluatedYearStudents,
          ({ mth: mthData }) => mth === mthData
        ), 'counter');
        writeRange(
          sheet,
          rightRowIdx,
          totalColIdx,
          _.get(counter, 'checked'),
          `${intToExcelCol(totalColIdx)}${rightRowIdx}:${intToExcelCol(
            totalColIdx + 1
          )}${rightRowIdx}`,
          { border: true }
        );
        totalColIdx += 2;
        writeRange(
          sheet,
          rightRowIdx,
          totalColIdx,
          _.get(counter, 'female'),
          `${intToExcelCol(totalColIdx)}${rightRowIdx}:${intToExcelCol(
            totalColIdx + 1
          )}${rightRowIdx}`,
          { border: true }
        );
        totalColIdx += 2;
        const ratio =
          (_.get(counter, 'checked', 0) / _.get(counter, 'total', 0)) * 100;
        writeRange(
          sheet,
          rightRowIdx,
          totalColIdx,
          round(ratio, 2),
          `${intToExcelCol(totalColIdx)}${rightRowIdx}:${intToExcelCol(
            totalColIdx + 1
          )}${rightRowIdx}`,
          { border: true, numberFormat: '0.00' }
        );
        totalColIdx += 2;
      });
      rightRowIdx += 1;
      _.forEach(
        [
          'BTWeight',
          'NC1',
          'NC2',
          'BTHeight',
          'TC1',
          'TC2',
          'BTBmiWeiHei',
          'DC',
          'BP',
          'GC1',
          'GC2',
        ],
        (key) => {
          let tempColIdx2 = 41;
          _.forEach(['2', '3', '4', '5'], (mth) => {
            const counter = _.get(_.find(
              evaluatedYearStudents,
              ({ mth: mthData }) => mth === mthData
            ), 'counter');
            const border =
              key === 'GC2'
                ? { top: 'dotted' }
                : { top: 'dotted', bottom: 'dotted' };
            writeRange(
              sheet,
              rightRowIdx,
              tempColIdx2,
              _.get(counter, key),
              `${intToExcelCol(tempColIdx2)}${rightRowIdx}:${intToExcelCol(tempColIdx2 + 1)}${rightRowIdx}`,
              { border }
            );
            writeRange(
              sheet,
              rightRowIdx + 11,
              tempColIdx2,
              _.get(counter, 'checked', 0) ? addPrefix( _.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)) : 0,
              `${intToExcelCol(tempColIdx2)}${rightRowIdx + 11}:${intToExcelCol(tempColIdx2 + 1)}${rightRowIdx + 11}`,
              { border }
            );
            tempColIdx2 += 2;
            writeRange(
              sheet,
              rightRowIdx,
              tempColIdx2,
              _.get(counter, `F${key}`),
              `${intToExcelCol(tempColIdx2)}${rightRowIdx}:${intToExcelCol(tempColIdx2 + 1)}${rightRowIdx}`,
              { border }
            );
            writeRange(
              sheet,
              rightRowIdx + 11,
              tempColIdx2,
              _.get(counter, 'checked', 0) ? addPrefix(_.get(counter, `F${key}`, 0) - _.get(firstMonthCounter, `F${key}`, 0)) : 0,
              `${intToExcelCol(tempColIdx2)}${rightRowIdx +11}:${intToExcelCol(tempColIdx2 + 1)}${rightRowIdx + 11}`,
              { border }
            );
            tempColIdx2 += 2;
            const ratio = (_.get(counter, key, 0) / _.get(counter, 'total', 0)) * 100;
            const comparedRatio = _.get(counter, 'checked') ? (_.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)) / _.get(counter, 'total', 0) : 0;
            writeRange(
              sheet,
              rightRowIdx,
              tempColIdx2,
              round(ratio, 2),
              `${intToExcelCol(tempColIdx2)}${rightRowIdx}:${intToExcelCol( tempColIdx2 + 1 )}${rightRowIdx}`,
              { border, numberFormat: '0.00' }
            );
            writeRange(
              sheet,
              rightRowIdx + 11,
              tempColIdx2,
              round(comparedRatio * 100, 2),
              `${intToExcelCol(tempColIdx2)}${rightRowIdx +11}:${intToExcelCol(tempColIdx2 + 1)}${rightRowIdx + 11}`,
              { border, numberFormat: '0.00' }
            );
            tempColIdx2 += 2;
          });
          rightRowIdx += 1;
        }
      );
    } else {
      _.forEach(
        _.sortBy(allStudentsArr, ['firstName']),
        ({ firstName, lastName, dayOfBth, gender, studentID }, idx) => {
          writeCell(sheet, rowIndex, 1, idx + 1, {
            border: { bottom: 'dotted', top: 'dotted' },
          });
          writeRange(
            sheet,
            rowIndex,
            2,
            `${lastName} ${firstName}`,
            `B${rowIndex}:C${rowIndex}`,
            {
              border: { bottom: 'dotted', top: 'dotted' },
              horizontal: 'left'
            }
          );
          writeCell(sheet, rowIndex, 4, `${gender === 'F' ? 'Nữ' : ''}`, {
            border: { bottom: 'dotted', top: 'dotted' },
          });
          writeCell(sheet, rowIndex, 5, dayOfBth, {
            border: { bottom: 'dotted', top: 'dotted' },
            horizontal: 'left'
          });
          let colIndex = 6;
          _.forEach(evaluatedYearStudents, ({ students }) => {
            const {
              heightPoint,
              weightPoint,
              height,
              weight,
              weightLengthPoint,
              bmi,
              bmiPoint,
            } = _.find(students, ({ studentID: id }) => studentID === id) || {};
            let remark = '';
            if (
              weightPoint !== 'NC1' &&
              weightPoint !== 'NC2' &&
              heightPoint !== 'TC1' &&
              heightPoint !== 'TC2'
            ) {
              remark = mappingDescs[bmiPoint || weightLengthPoint];
            } else {
              if (
                (heightPoint === 'BT' || heightPoint === 'BT+') &&
                (weightPoint === 'NC1' || weightPoint === 'NC2')
              )
                remark = mappingDescs[weightPoint];
              if (
                (weightPoint === 'BT' || weightPoint === 'BT+') &&
                (heightPoint === 'TC1' || heightPoint === 'TC2')
              )
                remark = mappingDescs[heightPoint];
              if (
                heightPoint !== 'BT' &&
                heightPoint !== 'BT+' &&
                weightPoint !== 'BT' &&
                weightPoint !== 'BT+'
              )
                remark = mappingDescs[`${weightPoint}${heightPoint}`];
            }
            writeCell(sheet, rowIndex, colIndex, weight, {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, mappingDescs[weightPoint], {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, height, {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            writeCell(sheet, rowIndex, colIndex, mappingDescs[heightPoint], {
              border: { bottom: 'dotted', top: 'dotted' },
            });
            colIndex += 1;
            if (weightLengthPoint) {
              if(isLamDong4t6t) colIndex +=1;
              writeCell(
                sheet,
                rowIndex,
                colIndex,
                mappingDescs[weightLengthPoint],
                {
                  border: { bottom: 'dotted', top: 'dotted' },
                }
              );
              colIndex += 1;
              writeCell(sheet, rowIndex, colIndex, remark, {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
            } else {
              writeCell(sheet, rowIndex, colIndex, round(bmi, 2), {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
             if(isLamDong4t6t) {
              writeCell(sheet, rowIndex, colIndex, bmiPoint, {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
             }
              writeCell(sheet, rowIndex, colIndex, remark, {
                border: { bottom: 'dotted', top: 'dotted' },
              });
              colIndex += 1;
            }
          });
          rowIndex += 1;
        }
      );
      writeRange(
        sheet,
        rowIndex,
        1,
        `Tổng số: ${allStudentsArr.length} trẻ`,
        `A${rowIndex}:C${rowIndex}`,
        { border: true, bold: true }
      );
      writeCell(
        sheet,
        rowIndex,
        4,
        '',
        { border: true}
      )
      writeCell(
        sheet,
        rowIndex,
        5,
        '',
        { border: true}
      )
      if(isLamDong4t6t) {
        _.forEach([6,13,20], (col) => {
          writeRange(
            sheet,
            rowIndex,
            col,
            '',
            `${intToExcelCol(col)}${rowIndex}:${intToExcelCol(col+6)}${rowIndex}`,
            { border: true}
          )
        })
      } else {
        _.forEach([6,12,18], (col) => {
          writeRange(
            sheet,
            rowIndex,
            col,
            '',
            `${intToExcelCol(col)}${rowIndex}:${intToExcelCol(col+5)}${rowIndex}`,
            { border: true}
          )
        })
      }
      rowIndex += 1;
      const bmi = grade.includes('4-5tuoi') || grade.includes('5-6tuoi') ? '/BMI' : '';
      const ranges = [
        {
          row: rowIndex,
          col: 1,
          val: 'TỔNG HỢP',
          range: `A${rowIndex}:B${rowIndex + 12}`,
          style: { bold: true, border: true },
        },

        {
          row: rowIndex + 13,
          col: 1,
          val: 'SO VỚI ĐẦU NĂM',
          range: `A${rowIndex + 13}:B${rowIndex + 23}`,
          style: { bold: true, border: true, wrapText: true, fontSize: 10 },
        },

        {
          row: rowIndex,
          col: 3,
          val: '',
          range: `C${rowIndex}:E${rowIndex}`,
          style: { fill: 'FFD9D9D9', border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 1,
          col: 3,
          val: 'Tổng số trẻ cân đo',
          range: `C${rowIndex + 1}:E${rowIndex + 1}`,
          style: { fill: 'FFD9D9D9', bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 2,
          col: 3,
          val: 'Cân nặng',
          range: `C${rowIndex + 2}:C${rowIndex + 4}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 5,
          col: 3,
          val: 'Chiều cao',
          range: `C${rowIndex + 5}:C${rowIndex + 7}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 8,
          col: 3,
          val: `Cân nặng theo chiều cao${bmi}`,
          range: `C${rowIndex + 8}:C${rowIndex + 12}`,
          style: { bold: true, wrapText: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 13,
          col: 3,
          val: 'Cân nặng',
          range: `C${rowIndex + 13}:C${rowIndex + 15}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 16,
          col: 3,
          val: 'Chiều cao',
          range: `C${rowIndex + 16}:C${rowIndex + 18}`,
          style: { bold: true, border: true, fontSize: 10 },
        },

        {
          row: rowIndex + 19,
          col: 3,
          val: `Cân nặng theo chiều cao${bmi}`,
          range: `C${rowIndex + 19}:C${rowIndex + 23}`,
          style: { bold: true, wrapText: true, border: true, fontSize: 10 },
        },
      ];
      let tempColIdx = 6;
      _.forEach(
        [
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
          { val: 'Số trẻ' },
          { val: 'Nữ' },
          { val: 'Tỷ lệ' },
        ],
        ({ val }) => {
          ranges.push({
            row: rowIndex,
            col: tempColIdx,
            val,
            range: `${intToExcelCol(tempColIdx)}${rowIndex}:${intToExcelCol(
              tempColIdx + (val === 'Tỷ lệ' && isLamDong4t6t ? 2 : 1)
            )}${rowIndex}`,
            style: {
              wrapText: true,
              border: true,
              fill: 'FFD9D9D9',
              bold: true,
              fontSize: 10,
            },
          });
          tempColIdx += val === 'Tỷ lệ' && isLamDong4t6t  ? 3 : 2;
        }
      );
      let tempRowIdx = rowIndex + 2;
      _.forEach(
        [
          { val: 'Bình thường' },
          { val: 'SDD Nhẹ cân (SDDNC)' },
          { val: 'SDD Nhẹ cân MĐ nặng (SDDNC(n))' },
          { val: 'Bình thường' },
          { val: 'SDD Thấp còi (TC)' },
          { val: 'SDD Thấp còi MĐ nặng (TC(n))' },
          { val: 'Bình thường (BT)' },
          { val: 'Thừa cân (ThC)' },
          { val: 'Béo phì (BP)' },
          { val: 'SDD thể gầy còm (SDDGC)' },
          { val: 'SDD thể gầy còm MĐ nặng (SDDGC(n))' },
          { val: 'Bình thường' },
          { val: 'SDD Nhẹ cân (SDDNC)' },
          { val: 'SDD Nhẹ cân MĐ nặng (SDDNC(n))' },
          { val: 'Bình thường' },
          { val: 'SDD Thấp còi (TC)' },
          { val: 'SDD Thấp còi MĐ nặng (TC(n))' },
          { val: 'Bình thường (BT)' },
          { val: 'Thừa cân (ThC)' },
          { val: 'Béo phì (BP)' },
          { val: 'SDD thể gầy còm (SDDGC)' },
          { val: 'SDD thể gầy còm MĐ nặng (SDDGC(n))' },
        ],
        ({ val }) => {
          if (val.length >= 20) sheet.getRow(tempRowIdx).height = 26.25;
          else sheet.getRow(tempRowIdx).height = 18.75;
          const border =
            val === 'SDD thể gầy còm MĐ nặng (SDDGC(n))'
              ? { top: 'dotted' }
              : { top: 'dotted', bottom: 'dotted' };
          ranges.push({
            row: tempRowIdx,
            col: 4,
            val,
            range: `D${tempRowIdx}:E${tempRowIdx}`,
            style: { wrapText: true, border, horizontal: 'left' },
          });
          tempRowIdx += 1;
        }
      );
      writeRanges(sheet, ranges);
      rowIndex += 1;
      let totalColIdx = 6;
      _.forEach(evaluatedYearStudents, ({ counter }) => {
        writeRange(
          sheet,
          rowIndex,
          totalColIdx,
          _.get(counter, 'checked'),
          `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(totalColIdx + 1)}${rowIndex}`,
          { border: true }
        );
        totalColIdx += 2;
        writeRange(
          sheet,
          rowIndex,
          totalColIdx,
          _.get(counter, 'female'),
          `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(totalColIdx + 1)}${rowIndex}`,
          { border: true }
        );
        totalColIdx += 2;
        const ratio =
          (_.get(counter, 'checked', 0) / _.get(counter, 'total', 0)) * 100;
        if(isLamDong4t6t) {
          writeRange(
            sheet,
            rowIndex,
            totalColIdx,
            round(ratio, 2),
            `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(totalColIdx + 2)}${rowIndex}`,
            { border: true, numberFormat: '0.00' }
          );
          totalColIdx += 3;
        } else {
          writeRange(
            sheet,
            rowIndex,
            totalColIdx,
            round(ratio, 2),
            `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(totalColIdx + 1)}${rowIndex}`,
            { border: true, numberFormat: '0.00' }
          );
          totalColIdx += 2;
        }
      });
      rowIndex += 1;
      const { counter: firstMonthCounter, mth: firstMth } = _.sortBy(evaluatedYearStudents, ['order']).find(({ students }) => Object.keys(students).length) ?? {};
      _.forEach(
        [
          'BTWeight',
          'NC1',
          'NC2',
          'BTHeight',
          'TC1',
          'TC2',
          'BTBmiWeiHei',
          'DC',
          'BP',
          'GC1',
          'GC2',
        ],
        (key) => {
          let tempColIdx2 = 6;
          _.forEach(evaluatedYearStudents, ({ counter, mth }) => {
            const border = key === 'GC2' ? { top: 'dotted' } : { top: 'dotted', bottom: 'dotted' };
            writeRange(
              sheet,
              rowIndex,
              tempColIdx2,
              _.get(counter, key),
              `${intToExcelCol(tempColIdx2)}${rowIndex}:${intToExcelCol(tempColIdx2 + 1)}${rowIndex}`,
              { border }
            );
            if (mth === firstMth) {
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2,
                '',
                `${intToExcelCol(tempColIdx2)}${rowIndex + 11}:${intToExcelCol(tempColIdx2 + 1)}${rowIndex + 11}`,
                { border }
              );
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2 + 2,
                '',
                `${intToExcelCol(tempColIdx2 + 2)}${rowIndex + 11}:${intToExcelCol(tempColIdx2 + 3)}${rowIndex + 11}`,
                { border }
              );
              if(isLamDong4t6t) {
                writeRange(
                  sheet,
                  rowIndex + 11,
                  tempColIdx2 + 4,
                  '',
                  `${intToExcelCol(tempColIdx2 + 4)}${rowIndex + 11}:${intToExcelCol(tempColIdx2 + 6)}${rowIndex + 11}`,
                  { border }
                );
              } else {
                writeRange(
                  sheet,
                  rowIndex + 11,
                  tempColIdx2 + 4,
                  '',
                  `${intToExcelCol(tempColIdx2 + 4)}${rowIndex + 11}:${intToExcelCol(tempColIdx2 + 5)}${rowIndex + 11}`,
                  { border }
                );
              }
            } else {
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2,
                _.get(counter, 'checked', 0)
                  ? addPrefix(_.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)): 0,
                `${intToExcelCol(tempColIdx2)}${rowIndex + 11}:${intToExcelCol(tempColIdx2 + 1)}${rowIndex + 11}`,
                { border }
              );
              writeRange(
                sheet,
                rowIndex + 11,
                tempColIdx2 + 2,
                _.get(counter, 'checked', 0) ? addPrefix(_.get(counter, `F${key}`, 0) - _.get(firstMonthCounter, `F${key}`, 0)) : 0,
                `${intToExcelCol(tempColIdx2 + 2)}${rowIndex + 11}:${intToExcelCol(tempColIdx2 + 3)}${rowIndex + 11}`,
                { border }
              );
              const comparedRatio = _.get(counter, 'checked')
                ? Math.abs(
                    (_.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)) /
                      _.get(counter, 'total', 0)
                  )
                : 0;

              if(isLamDong4t6t) {
                writeRange(
                  sheet,
                  rowIndex + 11,
                  tempColIdx2 + 4,
                  round(comparedRatio * 100, 2),
                  `${intToExcelCol(tempColIdx2 + 4)}${rowIndex +11}:${intToExcelCol(tempColIdx2 + 6)}${rowIndex + 11}`,
                  { border, numberFormat: '0.00' }
                );
              } else {
                writeRange(
                  sheet,
                  rowIndex + 11,
                  tempColIdx2 + 4,
                  round(comparedRatio * 100, 2),
                  `${intToExcelCol(tempColIdx2 + 4)}${rowIndex +11}:${intToExcelCol(tempColIdx2 + 5)}${rowIndex + 11}`,
                  { border, numberFormat: '0.00' }
                );
              }
            }
            tempColIdx2 += 2;
            writeRange(
              sheet,
              rowIndex,
              tempColIdx2,
              _.get(counter, `F${key}`),
              `${intToExcelCol(tempColIdx2)}${rowIndex}:${intToExcelCol(tempColIdx2 + 1)}${rowIndex}`,
              { border }
            );
            tempColIdx2 += 2;
            const ratio =
              (_.get(counter, key, 0) / _.get(counter, 'total', 0)) * 100;
            if(isLamDong4t6t) {
              writeRange(
                sheet,
                rowIndex,
                tempColIdx2,
                round(ratio, 2),
                `${intToExcelCol(tempColIdx2)}${rowIndex}:${intToExcelCol(tempColIdx2 + 2)}${rowIndex}`,
                { border, numberFormat: '0.00' }
              );
              tempColIdx2 += 3;
            } else {
              writeRange(
                sheet,
                rowIndex,
                tempColIdx2,
                round(ratio, 2),
                `${intToExcelCol(tempColIdx2)}${rowIndex}:${intToExcelCol(tempColIdx2 + 1)}${rowIndex}`,
                { border, numberFormat: '0.00' }
              );
              tempColIdx2 += 2;
            }
          });
          rowIndex += 1;
        }
      );
    }
  });
  return wb.xlsx.writeBuffer().then((data) => {
    if (data) {
      writeReport(new Blob([data]), 'Theo doi suc khoe.xlsx', closeHandler);
    }
    else {
      errorHandler("Xuất báo cáo không thành công, vui lòng kiểm tra lại");
      onComplete()
    }
    
  });
};

export default theodoisuckhoe;
