/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Button,
  Modal,
  Header,
  Dimmer,
  Loader,
  Table,
  Label,
  Input,
  Form,
  Message,
  Dropdown,
  Checkbox,
} from "semantic-ui-react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";

// services
import { RESOURCES } from "../../../libs/config";
import { db } from "../../../libs/firebase";
import {
  readHealth
} from './helpers';
import theodoisuckhoelop from './reports/theodoisuckhoelop';

const healthKeys = [
  'BT',
  'SDD',
  'DC-BP',
  'TuH',
  'HH',
  'TH',
  'T-TN',
  'TK-TT',
  'M',
  'RHM',
  'TMH',
  'CXK',
  'BK',
  'TM'
]


class ClassHealthRecords extends Component {
  constructor(props) {
    super(props);
    this.changed = false;
    const { classInfo } = this.props;
    const days = (classInfo.period === 1 ?
      _.range(0, 30).map(index => ({
        key: index + 1,
        text: index + 1,
        value: index + 1
      })) :
      _.range(0, 31).map(index => ({
        key: index + 1,
        text: index + 1,
        value: index + 1
      })));
    this.state = {
      isWaiting: false,
      showAskForSave: false,
      // day: false,
      students: {},
      days,
      dantoc: (this.props.dantoc === undefined) ? [] : _.keys(this.props.dantoc).map(dt => ({
        key: dt,
        text: this.props.dantoc[dt],
        value: dt
      })),
      day: classInfo.day ? classInfo.day : 1,
    };
  }

  componentWillMount() {
    this.getHealthRecords();
  }

  saveStatus = (students) => {
    const { day } = this.state;
    this.changed = false;
    const {
      unit: {
        unitID,
        activeYear,
      },
      classInfo: {
        classID,
        period,
        name
      },
      errorHandler,
      successHandler,
      user,
    } = this.props;
    if (day) {
      db.ref(`suckhoe/${unitID}/${activeYear}/classes/${classID}/status/${period}`).set({
        count: (students === undefined) ? 0 : _.keys(students).length,
        unfilled: (students === undefined) ? 0 : _.filter(_.keys(students), student => (students[student].healthRecords === undefined || students[student].healthRecords === "")).length,
        className: name,
        day: (students === undefined) ? 0 : this.state.day,
        modifiedBy: user.email,
        modifiedTime: new Date().getTime()
      }, error => {
        this.setState({ isSaving: false });
        if (error)
          errorHandler("Cập nhật số liệu sức khỏe không thành công");
        else
          successHandler("Cập nhật số liệu sức khỏe thành công");
      });
    } else {
      errorHandler("Cập nhật không thành công, vui lòng thử lại")
    }
  }

  closeHandler = () => {
    if (this.changed)
      this.setState({ showAskForSave: true });
    else
      this.props.closeHandler();
  }

  getHealthRecords = async () => {
    this.setState({ isWaiting: true });
    const {
      unit: { unitID, activeYear },
      classInfo: { classID, period },
    } = this.props;
    let students = {};
   
    await db.ref(`suckhoe/${unitID}/${activeYear}/classes/${classID}/periods/${period}`).once('value', (snapshot) => {
      if (snapshot.val()) {
        students = snapshot.val();
        this.setState({ students });
      };
    }, (error) => console.log(error));
    if(!Object.keys(students).length) {
      await db.ref(`cando/${unitID}/${activeYear}/${classID}/status`).once('value', (snapshot) => {
        if(snapshot.val()) {
          this.setState({ monthsStatus: snapshot.val()})
        }
      });
    }
    this.setState({ isWaiting: false });
  }

  getCanDoData = async () =>  {
    const {
      classInfo: {
        classID,
        teachers,
        grade,
      },
      unit: {
        unitID,
        activeYear,
      },
      errorHandler
    } = this.props;
    const { candoMonth } = this.state;
    const res = {};
    if(candoMonth) {
    this.setState({ isWaiting: true });
      await db.ref(`cando/${unitID}/${activeYear}/${classID}/months/${candoMonth}`).once("value", snapshot => {
        if (snapshot.val()) {
          _.forEach(teachers, (teacherEmail, idx) => {
            db.ref('users').once('value', (snap) => {
              if (_.find(snap.val(), ({ email }) => email === teacherEmail)) _.set(
                res,
                `${classID}.teachers.${idx}`,
                _.find(snap.val(), ({ email }) => email === teacherEmail)
              );
            })
          })
          _.set(res, `${classID}.students`, snapshot.val());
          _.set(res, `${classID}.name`, name);
          _.set(res, `${classID}.grade`, grade);
        }
      }, (err) => console.log(err));
      const canDoStudents = {};
      _.forEach(_.get(res, `${classID}.students`), (child, id) => _.set(canDoStudents, id, { ...child, healthRecords: ['BT'] }));
      this.setState({ students: canDoStudents, isWaiting: false });
    } else  errorHandler("Chưa chọn tháng muốn lấy danh sách học sinh!");
  }

  saveHandler = () => {
    this.setState({ isSaving: true, showAskForSave: false });
    const {
      students,
    } = this.state;
    const {
      unit: {
        activeYear,
        unitID
      },
      classInfo: {
        classID,
        period
      },
      errorHandler,

    } = this.props;


    const ref = db.ref(`suckhoe/${unitID}/${activeYear}/classes/${classID}/periods/${period}`);
    if (students === undefined)
      ref.remove(error => {
        if (error) {
          this.setState({ isSaving: false });
          errorHandler("Cập nhật số liệu không thành công");
        } else
          this.saveStatus(students);
      });
    else
      ref.set(students, error => {
        if (error) {
          this.setState({ isSaving: false });
          errorHandler("Cập nhật số liệu không thành công");
        }
        else
          this.saveStatus(students);
      });
  }

  exportErrorHandler = () => {
    this.setState({
      isWaiting: false
    });
    this.props.errorHandler("Xuất báo cáo không thành công.");
  }

  exportHandler = () => {
    const { students } = this.state;
    const onComplete = () => {
      this.setState({ isWaiting: false })
    };
    const {
      closeHandler,
      errorHandler,
    } = this.props;
    if (!_.keys(students).length) { this.props.errorHandler("không lấy được danh sách học sinh, vui lòng thử lại!") }
    this.setState({ isWaiting: true });
    theodoisuckhoelop(
      students,
      errorHandler,
      closeHandler,
      onComplete,
    )

  }


  importHandler = (files) => {
    if (files.length === 0)
      return;
    this.setState({ isWaiting: true });
    const reader = new FileReader();
    reader.onload = () => {
      const wb = XLSX.read(reader.result, { type: "binary" });
      const excelData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: ["A", "B", "C", "D", "E", "F", "G", "H", "I"], skipHeader: true, blankrows: true });
      this.setState({ isWaiting: false });
      const { students } = this.state;
      _.range(3, excelData.length).forEach(index => {
        if (_.every(["A", "B", "C", "D", "E", "F"], col => (excelData[index][col] !== undefined && excelData[index][col] !== ""))) {
          const gender = (excelData[index].G !== undefined && excelData[index].G !== "") ? "F" : "M";
          if (_.every(["A", "D", "E", "F"], col => (!isNaN(excelData[index][col])))) {
            const key = _.camelCase(`${excelData[index].B}${excelData[index].C}${excelData[index].D}${excelData[index].E}${excelData[index].F}${gender}`);
            let ethnic = "kinh";
            if (excelData[index].H !== undefined && excelData[index].H !== "") {
              const found = _.find(this.state.dantoc, dt => _.toLower(dt.text) === _.toLower(excelData[index].H));
              if (found !== undefined)
                ethnic = found.key;
            }
            students[key] = {
              order: excelData[index].A,
              lastName: excelData[index].B,
              firstName: excelData[index].C,
              date: excelData[index].D,
              month: excelData[index].E,
              year: excelData[index].F,
              gender,
              ethnic,
              healthRecords: ["BT"],
            }
          }
        }
      });
      this.setState({ isWaiting: false, students });
    };
    reader.onabort = () => {
      this.setState({ isWaiting: false });
    };
    reader.onerror = () => {
      this.setState({ isWaiting: false }, () => this.props.errorHandler("Đã có lỗi xảy ra trong quá trình đọc số liệu từ Excel"));
    };

    reader.readAsBinaryString(files[0]);
  }

  validateNumber = (value) => {
    if (isNaN(value)) {
      this.props.errorHandler("Giá trị không hợp lệ", "Giá trị nhập vào phải là số.");
      return undefined;
    }
    return value;
  }

  updateEthnicHandler = (student, value) => {
    const { students } = this.state
    this.changed = true;
    this.setState({
      students: {
        ...students,
        [student.studentID]: {
          ...students[student.studentID],
          ethnic: value
        }
      }
    });
  }

  updateNumberHandler = (student, value, field) => {
    const verified = this.validateNumber(value);
    if (verified === undefined)
      return;
    let number;
    if (verified.endsWith('.'))
      number = `${parseFloat(verified)}.`;
    else
      number = verified;
    this.updateValueHandler(student, number, field);
  }

  updateValueHandler = (student, value, field) => {
    this.changed = true;
    this.setState({
      students: {
        ...this.state.students,
        [student.studentID]: {
          ...this.state.students[student.studentID],
          [field]: value
        }
      }
    });
  }

  removeStudent = (studentID) => {
    const clone = _.cloneDeep(this.state.students);
    delete clone[studentID];
    this.setState({ editor: undefined, students: clone });
  }

  sortABCHandler = () => {
    const { students } = this.state;
    const newStudents = {};
    const converted = Object.entries(students || {}).map(([studentID, values]) => ({ studentID, ...values }));
    const sorted = converted.sort((a, b) => (a?.firstName ?? '').localeCompare(b?.firstName ?? '', 'vi'));
    let order = 1;
    sorted.forEach(({ studentID, ...rest }) => {
      newStudents[studentID] = {
        ...rest,
        order
      }
      order += 1;
    })
    this.setState({ students: newStudents });
  }

  updateInfoHandler = () => {
    const { errorHandler } = this.props;
    const { editor, students } = this.state;
    if (editor.firstName === "") {
      errorHandler("Tên không được bó trống");
      return;
    }
    if (editor.lastName === "") {
      errorHandler("Họ không được bó trống");
      return;
    }
    if (editor.date === "") {
      errorHandler("Ngày sinh không được bó trống");
      return;
    }
    if (editor.month === "") {
      errorHandler("Tháng sinh không được bó trống");
      return;
    }
    if (editor.year === "") {
      errorHandler("Năm sinh không được bó trống");
      return;
    }
    if (!moment(`${editor.date}/${editor.month}/${editor.year}`, "D/M/YYYY").isValid()) {
      errorHandler("Ngày tháng năm sinh không hợp lệ");
      return;
    }
    const id = _.camelCase(`${editor.lastName}${editor.firstName}${editor.date}${editor.month}${editor.year}${editor.gender}`);
    const { order, firstName, lastName, date, month, year, gender } = editor;
    const updateObj = { order, firstName, lastName, date, month, year, gender };
    if (editor.weight !== undefined)
      updateObj.weight = editor.weight;
    if (editor.height !== undefined)
      updateObj.height = editor.height;
    if (editor.comment !== undefined)
      updateObj.comment = editor.comment;
    if (editor.studentID === id)
      this.setState((prevState) => ({
        students: {
          ...prevState.students,
          [editor.studentID]: updateObj
        },
        editor: undefined
      }));
    else {
      const newStudents = students;
      if (editor.studentID !== "")
        delete newStudents[editor.studentID];
      newStudents[id] = updateObj;
      this.setState({
        students: newStudents,
        editor: undefined
      });
    }
  }

  renderEditor = () => {
    const { editor } = this.state;
    if (editor === undefined)
      return null;
    return (
      <Modal size="small" className="custom" open closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState({ editor: undefined })} />}>
        <Header className="form-header">{(editor.studentID === "") ? "Thêm mới" : "Chỉnh sửa"}</Header>
        <Modal.Content scrolling>
          <Form>
            <Form.Group width="equal">
              <Form.Field>
                <label>Họ</label>
                <Input
                  value={editor.lastName}
                  onChange={(e, { value }) => {
                    this.setState({ editor: { ...editor, lastName: value } });
                  }}
                  placeholder="Nhập họ và tên lót của học sinh"
                />
              </Form.Field>
              <Form.Field>
                <label>Tên</label>
                <Input
                  value={editor.firstName}
                  onChange={(e, { value }) => {
                    this.setState({ editor: { ...editor, firstName: value } });
                  }}
                  placeholder="Nhập tên của học sinh"
                />
              </Form.Field>
              <Form.Field />
              <Form.Field>
                <Checkbox
                  style={{ marginTop: 30 }}
                  label="Nữ"
                  checked={editor.gender === "F"}
                  onChange={() => {
                    this.setState({ editor: { ...editor, gender: (editor.gender === "M") ? "F" : "M" } });
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group width="equal" inline>
              <Form.Field>
                <label>Ngày</label>
                <Input
                  value={editor.date}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...editor, date: verified } });
                  }}
                  placeholder="Ngày sinh"
                />
              </Form.Field>
              <Form.Field>
                <label>Tháng</label>
                <Input
                  value={editor.month}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...editor, month: verified } });
                  }}
                  placeholder="Tháng sinh"
                />
              </Form.Field>
              <Form.Field>
                <label>Năm</label>
                <Input
                  value={editor.year}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...editor, year: verified } });
                  }}
                  placeholder="Năm sinh"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            size="small"
            onClick={() => {
              this.removeStudent(editor.studentID);
            }}
          >
            <Icon name="trash" />
            {' '}
            Xoá
          </Button>
          <Button
            color="blue"
            size="small"
            onClick={() => {
              this.updateInfoHandler();
            }}
          >
            {(editor.studentID === "") ? "Thêm" : "Sửa"}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  };

  renderStudents = () => {
    const {
      students,
      day,
      monthsStatus,
      candoMonth,
    } = this.state;
    const months = Object.keys(monthsStatus ?? {}).map((month) => ({
      key: month,
      text: month,
      value: parseFloat(month),
    }));
    const sortedStudents = _.sortBy(_.map(students, (student, studentID) => ({
      ...student,
      studentID
    })), ['order'], ['asc']);
    if (_.keys(students).length === 0)
      return (
        <Message warning>
          Chưa có dữ liệu học sinh.
          {
           months.length ? (
             <>
               <Button
                 size="mini"
                 color="teal"
                 onClick={() => this.getCanDoData()}
               >
                 <Icon name="copy" />
                 Lấy danh sách học sinh từ cân đo
               </Button>
               <Dropdown
                 inline
                 scrolling
                 style={{ marginLeft: 5 }}
                 options={months}
                 value={candoMonth}
                 onChange={(event, { value }) => {
               this.setState({ candoMonth: value });
             }}
               /> 
             </>
          ) : null
         }
        </Message>
      );

    if (!day)
      return <Message warning>Chưa chọn ngày tổ chức khám sức khỏe.</Message>;
    return (
      <Table celled striped unstackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="solieuHeader" rowSpan="2" colSpan="2">Họ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Tên</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Ngày sinh</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Nữ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Dân tộc</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Đánh giá sức khỏe</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Ghi chú</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.keys(students).length ? (_.map(sortedStudents, (student) => {
            const {
              lastName,
              firstName,
              gender,
              date,
              month,
              year,
              studentID,
            } = student;
            return (
              <Table.Row key={studentID}>
                <Table.Cell collapsing className="solieuValue">
                  <Icon
                    name="edit"
                    color="blue"
                    onClick={() => this.setState({
                      editor: {
                        ...student
                      }
                    })}
                  />
                </Table.Cell>
                <Table.Cell collapsing className="solieuValue">{lastName}</Table.Cell>
                <Table.Cell collapsing className="solieuValue">{firstName}</Table.Cell>
                <Table.Cell collapsing className="solieuValue">
                  {date}
                  /
                  {month}
                  /
                  {year}
                </Table.Cell>
                <Table.Cell collapsing>{(gender === "F") ? <Icon name="female" /> : null}</Table.Cell>
                <Table.Cell collapsing>
                  <Dropdown
                    placeholder="Kinh"
                    fluid
                    search
                    selection
                    options={this.state.dantoc}
                    value={_.get(student, "ethnic", "kinh")}
                    onChange={(e, { value }) => this.updateEthnicHandler(student, value)}
                  />
                </Table.Cell>
                <Table.Cell collapsing className="solieuValue">
                  <Dropdown
                    // disabled={!allowEdit}
                    value={_.get(student, 'healthRecords', [])}
                    onChange={(event, { value }) => this.setState((prevState) => ({
                      students: {
                        ...prevState.students,
                        [`${studentID}`]: {
                          ...prevState.students[`${studentID}`],
                          healthRecords: value
                        }
                      }
                    }))}
                    selection
                    multiple
                    options={healthKeys.map(key => ({
                      key,
                      text: readHealth(key),
                      value: key
                    }))}
                    fluid
                    placeholder="sức khỏe trẻ"
                  />
                </Table.Cell>
                <Table.Cell collapsing className="solieuValue">
                  <Input
                    fluid
                    value={_.get(student, 'note', '')}
                    onChange={(event, { value }) => this.setState((prevState) => ({
                      students: {
                        ...prevState.students,
                        [`${studentID}`]: {
                          ...prevState.students[`${studentID}`],
                          note: value
                        }
                      }
                    }))}
                  />
                </Table.Cell>
              </Table.Row>
            )
          })) : null}
        </Table.Body>
      </Table>
    );
  }

  renderActions = () => (
    <Modal.Actions>
      {(!_.keys(this.state.students).length) ? (
        <Button
          style={{ float: "left" }}
          size="small"
          onClick={() => {
            setTimeout(() => {
              const response = { file: `${RESOURCES}/rebot/file_mau_sk_hs.xls` };
              window.open(response.file);
            }, 100);
          }}
        >
          <Icon name="download" />
          File mẫu
        </Button>
      ) : null}
      {(_.keys(this.state.students).length === 0) ? (
        <Dropzone accept=".xls, .xlsx" multiple={false} onDrop={this.importHandler}>
          {({ getRootProps, getInputProps }) => (
            <div style={{ float: "left" }} {...getRootProps()}>
              <input {...getInputProps()} />
              <Button color="green" size="small">
                <Icon name="file excel outline" />
                Nhập từ Excel
              </Button>
            </div>
          )}
        </Dropzone>
      ) : null}
      {(_.keys(this.state.students).length > 0) ? (
        <Button style={{ float: "left" }} color="brown" size="small" onClick={this.sortABCHandler}>
          <Icon name="sort alphabet ascending" />
          Sắp xếp tên
        </Button>
      ) : null}
      <Button
        size="small"
        color="green"
        onClick={() => this.setState({ editor: { order: 1, studentID: "", firstName: "", lastName: "", date: "", month: "", year: "", gender: "M" } })}
      >
        <Icon name="add" />
        Thêm HS
      </Button>
      {(_.keys(this.state.students).length > 0) ? (
        <Button
          color="red"
          size="small"
          onClick={() => this.setState({ students: {} })}
        >
          <Icon name="remove" />
          Xoá tất cả
        </Button>
      ) : null}
      <Button color="blue" size="small" onClick={this.saveHandler}>
        <Icon name="save" />
        Lưu
      </Button>
    </Modal.Actions>
  )

  renderAskForSave = () => (
    <Modal size="small" open>
      <Header className="form-header">Thông báo</Header>
      <Modal.Content scrolling>
        {(this.props.user.gender === "M") ? "Thầy" : "Cô"}
        {' '}
        có muốn lưu lại những thay đổi vừa rồi không?
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" size="small" onClick={this.saveHandler}>
          <Icon name="checkmark" />
          {' '}
          Có
        </Button>
        <Button color="red" size="small" onClick={() => this.setState({ showAskForSave: false }, this.props.closeHandler)}>Không</Button>
      </Modal.Actions>
    </Modal>
  )

  render() {
    const {
      students,
      days,
      day,
    } = this.state;
    return (
      <div>
        <Modal size="fullscreen" open>
          <Header className="form-header">
            <Header.Content as="h3">
              Số liệu sức khỏe
              . Lớp
              {' '}
              {/* {this.props.classInfo.name} */}
              .
              {(this.changed ? "(Chưa lưu)" : "")}
              <Label>
                {(_.keys(students).length > 0) ? `Sĩ số: ${_.keys(students).length}` : "Chưa có học sinh"}
              </Label>
            </Header.Content>
            <Header.Subheader style={{ color: "red" }}>
              <Icon name="help circle" color="blue" onClick={() => this.setState({ showGuide: true })} />
              Ngày tổ chức khám sức khỏe:
              <Dropdown
                style={{ marginLeft: 10 }}
                floating
                inline
                scrolling
                options={days}
                value={(day === 0) ? "" : day}
                onChange={(event, { value }) => this.setState({ day: parseFloat(value) })}
                placeholder="Chọn ngày khám sức khỏe"
              />
            </Header.Subheader>
            <Button style={{ float: "right", marginTop: -50 }} onClick={this.closeHandler}>
              <Icon name="angle left" />
              {' '}
              Quay lại
            </Button>
          </Header>
          <Modal.Content scrolling>
            {this.state.isWaiting ? (
              <Dimmer active>
                <Loader>Đang kết nối...</Loader>
              </Dimmer>
            ) : null}
            {this.state.isSaving ? (
              <Dimmer active>
                <Loader>Đang kết nối...</Loader>
              </Dimmer>
            ) : null}
            {this.state.showAskForSave ? this.renderAskForSave() : null}
            {this.renderEditor()}
            {this.renderStudents()}
          </Modal.Content>
          {this.renderActions()}
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  user: state.user
});

ClassHealthRecords.defaultProps = {
  dantoc: undefined,
  terms: {}
}

ClassHealthRecords.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  successHandler: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired
  }).isRequired,
  unit: PropTypes.shape({
    unitID: PropTypes.string.isRequired,
    activeYear: PropTypes.string.isRequired,
    information: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  classInfo: PropTypes.shape({
    period: PropTypes.string.isRequired,
    day: PropTypes.number,
    name: PropTypes.string.isRequired,
    classID: PropTypes.string.isRequired,
    grade: PropTypes.array.isRequired,
    teachers: PropTypes.array.isRequired,
  }).isRequired,
  dantoc: PropTypes.object,
  terms: PropTypes.object,
  periodsStatus: PropTypes.object.isRequired,
}


export default connect(mapStateToProps)(ClassHealthRecords);