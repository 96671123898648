/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Segment } from "semantic-ui-react";
import _ from "lodash";

import Grade from "./grade";


export default class School extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    dinhduongCatalogs: PropTypes.object.isRequired,
    unitID: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    activeYear: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    data: PropTypes.shape({
      nhatre: PropTypes.object,
      maugiao: PropTypes.object
    }).isRequired,
    allowEdit: PropTypes.bool
  };

  static defaultProps = {
    allowEdit: false
  };

  constructor(props) {
    super(props);

    this.state = {
      grade: "nhatre"
    };
  }

  renderMenu = () => {
    const { grade } = this.state;
    return (
      <Menu pointing style={{ marginTop: 10 }}>
        <Menu.Item name="nhatre" active={grade === "nhatre"} onClick={() => this.setState({ grade: "nhatre" })} >Nhà trẻ</Menu.Item>
        <Menu.Item name="maugiao" active={grade === "maugiao"} onClick={() => this.setState({ grade: "maugiao" })} >Mẫu giáo</Menu.Item>
      </Menu>
    );
  }

  render() {
    const { dinhduongCatalogs, unitID, unitName, activeYear, month, allowEdit, data, errorHandler, successHandler } = this.props;
    const notifications = { errorHandler, successHandler };
    const { grade } = this.state;
    return (
      <div>
        {this.renderMenu()}
        <Segment style={{ padding: 10 }}>
          <Grade
            key={grade}
            cocauCatalogs={_.get(dinhduongCatalogs, `cocau.${grade}`, {})}
            unitID={unitID}
            unitName={unitName}
            activeYear={activeYear}
            month={month}
            grade={grade}
            data={_.get(data, grade)}
            allowEdit={allowEdit}
            {...notifications}
          />
        </Segment>
      </div>
    );
  }
}