/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Header, Message, Button, Popup, Feed, Icon, Label, Segment, Form, Dropdown, Modal } from "semantic-ui-react";
import { FormattedNumber } from "react-intl";
import ScrollArea from "react-scrollbar";
import _ from "lodash";
import { connect } from "react-redux";

// components
import PhoCap from "./PhoCap";

// services
import { eventTracking } from "../../libs/ga";
import { db } from "../../libs/firebase";
import { convertTimeStamp } from "../../libs/time";
import { expandLogic } from "../../libs/report";
import { isTruong, isCSMamNonTuThucDocLap, isLopMGDocLap, isNhomTreDocLap } from "../../libs/school";


const cities = require("../../libs/cities.json");

class ThongTinChung extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    thongtinchung: PropTypes.object.isRequired,
    manage: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    user: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      gender: PropTypes.string,
    }).isRequired,
    schema: PropTypes.shape({
      schema: PropTypes.any,
      logic: PropTypes.any
    }).isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      activeMilestone: PropTypes.string,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        district: PropTypes.string,
        province: PropTypes.string,
        dissolvedUnits: PropTypes.object
      }).isRequired
    }).isRequired,
    milestone: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    this.state = {
      isReload: false,
      isSaving: false,
      error: "",
      name: "Thông tin chung về tình hình trẻ em trong địa bàn",
      rows: {},
      data: {},
      selectedUnit: "all",
      sticky: false
    }
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.load();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getOrderedUnits = () => {
    if (!this.verifyInformation())
      return [];
    const orderedUnits = [];
    if (this.props.unit.information.type === "pgd") {
      const province = this.props.unit.information.district.substring(0,2);
      _.forEach(_.keys(cities[province].districts[this.props.unit.information.district].wards), key => {
        orderedUnits.push({ unitID: key, name: cities[province].districts[this.props.unit.information.district].wards[key].name });
      });
    } else if (this.props.unit.information.type === "sgd") {
      if (this.props.thongtinchung !== undefined) {
        _.forEach(_.keys(this.props.thongtinchung), unitID => {
          const name = _.get(this.props.manage, `${unitID}.information.name`, "");
          if (name !== "")
            orderedUnits.push({ unitID, name });
        });
      }
    }
    return orderedUnits;
  }

  getUnitName = (orderedUnits, unitID) => {
    const found = _.find(orderedUnits, item => item.unitID === unitID);
    return (found !== undefined)? found.name : "";
  }

  handleScroll = () => {
    if (this.headerRef !== undefined && this.headerRef.getBoundingClientRect().top - window.pageYOffset < -500) {
      if (!this.state.sticky)
        this.setState({ sticky: true });
    } else if (this.state.sticky)
      this.setState({ sticky: false });
  }

  verifyInformation = () => {
    if (this.props.unit.information.type === "pgd") {
      if (this.props.unit.information.district === undefined)
        return false;
      const province = this.props.unit.information.district.substring(0,2);
      if (_.get(cities, `${province}.districts.${this.props.unit.information.district}`, undefined) === undefined)
        return false;
    } else if (this.props.unit.information.province === undefined)
        return false;
    return true;
  }

  // convert all PGDs reports to wards reports
  convertReports = () => {
    const reports = {};
    const reportsData = _.get(this.props, `data.${this.props.unit.unitID}`, undefined);
    if (reportsData !== undefined) {
      _.forEach(_.keys(reportsData), reportKey => {
        reports[reportKey] = { data: {} };
        const data = _.get(reportsData, `${reportKey}.data`, undefined);
        if (data !== undefined) {
          _.forEach(_.keys(data), schoolType => {
            _.forEach(_.keys(data[schoolType]), unitID => {
              const information = _.get(this.props.manage, `${unitID}.information`, undefined);
              if (information !== undefined) {
                _.forEach(_.keys(data[schoolType][unitID]), key => {
                  if (reports[reportKey].data[key] === undefined)
                    reports[reportKey].data[key] = { [information.ward]: 0 };
                  else if (reports[reportKey].data[key][information.ward] === undefined)
                    reports[reportKey].data[key][information.ward] = 0;
                  reports[reportKey].data[key][information.ward] += isNaN(data[schoolType][unitID][key])? 0 : data[schoolType][unitID][key]; 
                });
              }
            });
          });
        }
      });
    }
    return reports;
  }

  load = () => {
    try {
      if (!this.verifyInformation()) {
        this.setState({
          error: "Lỗi hệ thống."
        });
        return;
      }
      this.data = (this.props.unit.information.type === "pgd")? this.props.thongtinchung.data : this.props.thongtinchung;
      this.logic = this.props.schema.logic;
      this.ratioLogic = {};
      _.forEach(_.keys(this.logic), key => {
        if (this.logic[key].ratio !== undefined)
          this.ratioLogic[key] = {
            eval: this.logic[key].ratio
          };
      });
      this.maxDepth = 0;
      const orderedUnits = this.getOrderedUnits();
      const rows = this.extractNode(this.props.schema.schema, 0, orderedUnits);
      const data = {};
      _.forEach(_.keys(rows), k => {
        data[rows[k].key] = rows[k].value;
      });
      this.setState({ rows, data: this.computeSum(data) });
    } catch (error) {
      this.setState({
        error: "Lỗi hệ thống."
      });
    }
  }

  computeSum = (data) => {
    const result = {...data};
    _.forEach(_.keys(data), key => {
      result[key].sum = 0;
      _.forEach(_.keys(data[key]), unitID => {
        if (!isNaN(data[key][unitID]) && unitID !== "sum")
          result[key].sum += data[key][unitID];
      });
    });
    const expandedLogic = expandLogic({}, result, this.ratioLogic, [], "sum");
    _.forEach(_.keys(expandedLogic), item => {
      result[item].sum = expandedLogic[item];
    });
    return result;
  }

  extractNode = (node, depth, orderedUnits) => {
    if (this.maxDepth < depth)
      this.maxDepth = depth;

    if (node.key !== undefined) {
      const value = {};
      if (this.props.unit.information.type === "pgd") {
        _.forEach(orderedUnits, unit => {
          value[unit.unitID] = (this.data !== undefined && this.data[node.key] !== undefined && !isNaN(this.data[node.key][unit.unitID]))? this.data[node.key][unit.unitID] : "";
        });
      } else {
        _.forEach(orderedUnits, unit => {
          value[unit.unitID] = _.get(this, `data.${unit.unitID}.data.${node.key}.sum`, 0);
        });
      }

      return [{
        parents: [],
        header: node.name,
        key: node.key,
        value,
        depth
      }];
    }

    const rows = [];
    _.forEach(_.keys(node), item => {
      if (item === "name")
        return;
      const tmp = this.extractNode(node[item], depth + 1, orderedUnits);
      if (tmp.length > 0)
        rows.push(...tmp);
    });
    if (rows.length > 0 && node.name !== undefined) {
      rows[0].parents.unshift({
        name: node.name,
        span: rows.length
      });
    }
    
    return rows;
  }

  updatePhoCapHandler = () => {
    if (this.phocap !== undefined) {
      this.setState({ 
        data: this.phocap.getData(), 
        showPhoCap: undefined,
        name: (this.state.name.indexOf("Chưa lưu") === -1)? `${this.state.name}* (Chưa lưu)` : this.state.name
      }, () => {this.phocap = undefined;});
    }
  }

  saveHandler = () => {
    if (this.state.isSaving)
      return;
    this.setState({
      isSaving: true
    });

    // preprocess data
    const { data } = this.state;
    _.keys(data).map(ward => {
      _.keys(data[ward]).map(key => {
        if (isNaN(data[ward][key]) || data[ward][key] === "")
          data[ward][key] = 0;
        else
          data[ward][key] = parseFloat(data[ward][key]);
        return key;
      });
      return data[ward];
    });
    eventTracking("report", "thontinchung", 1, this.props.unit.unitID);
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/thongtinchung/${this.props.unit.unitID}`).update({
      modifiedBy: {
        email: this.props.user.email,
        displayName: this.props.user.displayName,
        gender: this.props.user.gender
      },
      modifiedTime: (new Date()).getTime(),
      data
    }, this.saveComplete);
  }

  reloadHandler = (orderedUnits) => {
    this.setState({ isReload: true });

    const data = {
      ...this.state.data,
      A: {},
      B: {},
      C: {},
      D: {},
      E: {},
      F: {},
      G: {},
      H: {},
      I: {},
      J: {},
      K: {}
    }
    orderedUnits.forEach(unit => {
      data.A[unit.unitID] = 0;
      data.B[unit.unitID] = 0;
      data.C[unit.unitID] = 0;
      data.D[unit.unitID] = 0;
      data.E[unit.unitID] = 0;
      data.F[unit.unitID] = 0;
      data.G[unit.unitID] = 0;
      data.H[unit.unitID] = 0;
      data.I[unit.unitID] = 0;
      data.J[unit.unitID] = 0;
      data.K[unit.unitID] = 0;
    });

    _.forEach(_.keys(this.props.manage), unit => {
      if (_.get(this.props.unit.information, `dissolvedUnits.${unit}`, undefined) === undefined) {
        const { ward, schoolModel } = this.props.manage[unit].information;
        if (ward !== undefined) {
          if (isTruong(schoolModel))
            data.A[ward] += 1;
          if (isCSMamNonTuThucDocLap(schoolModel))
            data.B[ward] += 1;
          else if (isNhomTreDocLap(schoolModel)) {
            if (_.get(this.props.manage[unit].information, "chuacapphep", false))
              data.E[ward] += 1;
            else {
              data.C[ward] += 1;
              if (_.get(this.props.manage[unit].information, "duoi7tre", false))
                data.D[ward] += 1;
            }
          }
          else if (isLopMGDocLap(schoolModel)) {
            if (_.get(this.props.manage[unit].information, "chuacapphep", false))
              data.H[ward] += 1;
            else {
              data.F[ward] += 1;
              if (_.get(this.props.manage[unit].information, "duoi7tre", false))
                data.G[ward] += 1;
            }
          }
          if (_.get(this.props.manage[unit].information, "xadatchuan", false)) {
            data.I[ward] = 1;
            if (this.props.manage[unit].information.ward2 !== undefined && data.I[this.props.manage[unit].information.ward2] !== undefined)
              data.I[this.props.manage[unit].information.ward2] = 1;
          }
          if (_.get(this.props.manage[unit].information, "xakhokhan", false))
            data.J[ward] = 1;
          if (_.get(this.props.manage[unit].information, "xanongthonmoi", false))
            data.K[ward] = 1;
        }
      }
    });

    const allReports = this.convertReports();
    let count = 0;
    _.forEach(orderedUnits, unit => {
      count += 1;
      const expandedLogic = expandLogic(allReports, data, this.logic, [], unit.unitID);
      _.forEach(_.keys(expandedLogic), item => {
        data[item][unit.unitID] = expandedLogic[item];
      });
      if (count === orderedUnits.length) {
        this.setState({ 
          data: this.computeSum(data), 
          isReload: false,
          name: (this.state.name.indexOf("Chưa lưu") === -1)? `${this.state.name}* (Chưa lưu)` : this.state.name });
      }
    });
  }

  saveComplete = (error) => {
    this.setState({
      isSaving: false
    });
    if (error) {
      this.props.errorHandler("Lưu báo cáo thất bại.");
    } else {
      this.props.successHandler("Lưu báo cáo thành công.");
      this.setState({ name: "Thông tin chung về tình hình trẻ em trong địa bàn" });
    }
  }

  readerAdvanceHeader = () => (
    <Table fixed celled unstackable style={{width: "500px", float: "left"}}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="row-header" colSpan={this.maxDepth}><div ref={(ref) => {this.headerRef = ref;}}>{this.renderStatus()}</div></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.rows.map((row) => this.renderAdvanceRowHeader(row))}
      </Table.Body>
    </Table>
  )

  renderPhoCap = (orderedUnits) => (
    <Modal dimmer={false} size="fullscreen" open={this.state.showPhoCap}>
      <Header className="form-header" as="h3" icon="file excel outline" content="Số liệu trích xuất từ Phổ Cập" />
      <Modal.Content scrolling>
        <PhoCap units={orderedUnits} data={this.state.data} ref={(ref) => {this.phocap = ref}} logic={this.logic}  />
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="brown" onClick={this.updatePhoCapHandler}>Cập nhật số liệu</Button>
        <Button basic color="grey" onClick={() => { this.phocap = undefined; this.setState({ showPhoCap: undefined })}}>Đóng</Button>
      </Modal.Actions>
    </Modal>
  )

  renderAdvanceRowHeader = (row) => {
    const span = (this.maxDepth > row.depth)? (this.maxDepth - row.depth + 1) : 1;
    return (
      <Table.Row key={row.key}>
        {row.parents.map((parent, k) => <Table.Cell key={k} className={`thongtin-column-header ${(row.parents.length === row.depth-1)? "groupBorder" : "" }`} rowSpan={parent.span}>{parent.name}</Table.Cell>)}
        <Table.Cell className={`thongtin-column-header ${(row.parents.length === row.depth-1)? "groupBorder" : "" }`} colSpan={span}>{row.header}</Table.Cell>
      </Table.Row>
    );
  }
    
  renderAdvanceRow = (row, index, orderedUnits) => (
    <Table.Row key={row.key}>
      <Table.Cell className={`tongcong-value ${(row.parents.length === row.depth-1)? "groupBorder" : "" }`}><FormattedNumber value={this.state.data[row.key].sum} /></Table.Cell>
      {orderedUnits.map((item, cellIndex) => {
        const val = (this.state.data[row.key][item.unitID] !== undefined && this.state.data[row.key][item.unitID] !== "")? this.state.data[row.key][item.unitID] : 0;
        if (this.state.selectedUnit === "all" || this.state.selectedUnit === item.unitID) {
          let className = (cellIndex%2 === 0)? "thongtinchung-even-value" : "thongtinchung-odd-value";
          if (this.state.selectedUnit === item.unitID)
            className = "thongtinchung-value";
          className = (row.parents.length === row.depth-1)? `${className} groupBorder` : className;
          if (this.logic[row.key] !== undefined)
            return (
              <Table.Cell key={`${row.key}${item.unitID}`} className={className}>
                <Popup 
                  trigger={<FormattedNumber value={val} />} 
                  content={(this.logic[row.key].description !== undefined)? this.logic[row.key].description : ""} 
                />
              </Table.Cell>
            );
          return (
            <Table.Cell key={`${row.key}${item.unitID}`} className={className}><FormattedNumber value={val} /></Table.Cell>
          );
        }
        return null;
      })}
      <Table.Cell className={`thongtinchung-stt ${(row.parents.length === row.depth-1)? "groupBorder" : "" }`}>{index + 1}</Table.Cell>
    </Table.Row>
  )

  renderStatus = () => {
    if (this.props.thongtinchung === undefined || this.props.thongtinchung.modifiedBy === undefined)
      return null;
    return (
      <Feed>
        <Feed.Event>
          <Feed.Label>
            <Icon name="save" />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>
              Lần cập nhật gần nhất được thực hiện bởi {(this.props.thongtinchung.modifiedBy.gender === "M")? "Thầy " : "Cô "}
              <Popup 
                trigger={
                  <Label as='a'>
                    <Icon name={(this.props.thongtinchung.modifiedBy.gender === "M")? "male" : "female"} />
                    {this.props.thongtinchung.modifiedBy.displayName}
                  </Label>
                }
                content={this.props.thongtinchung.modifiedBy.email}
              />.
              <Feed.Date>{convertTimeStamp(this.props.thongtinchung.modifiedTime)}</Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    );
  }

  renderAdvanceReport = (orderedUnits) => (
    <div>
      {this.readerAdvanceHeader()}
      <ScrollArea
        contentStyle={{ width: (this.state.selectedUnit === "all")? (orderedUnits.length*105 + 130) : 250 }}
        verticalScrollbarStyle={{borderRadius: 5}}
        verticalContainerStyle={{borderRadius: 5}}
        horizontalScrollbarStyle={{borderRadius: 5}}
        horizontalContainerStyle={{borderRadius: 5}}
        smoothScrolling
        minScrollSize={40}
        onScroll={this.handleScroll}
        swapWheelAxes
      >
        <Table fixed celled unstackable>
          {(this.state.sticky)?
            <Table.Header className="sticky">
              <Table.Row>
                <Table.HeaderCell style={{height: "110px"}} className="tongcong">Tổng cộng</Table.HeaderCell>
                {(this.state.selectedUnit === "all")?
                  orderedUnits.map((item, index) => <Table.HeaderCell key={item.unitID} className={`thongtinchung-${(index%2 === 0)? "even" : "odd"}`}>{item.name}</Table.HeaderCell>)
                  : <Table.HeaderCell key={this.state.selectedUnit} className="normal" style={{height: "110px"}}>{this.getUnitName(orderedUnits, this.state.selectedUnit)}</Table.HeaderCell>}
              </Table.Row>
            </Table.Header> : null
          }
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{height: "110px"}} className="tongcong">Tổng cộng</Table.HeaderCell>
              {(this.state.selectedUnit === "all")?
                orderedUnits.map((item, index) => <Table.HeaderCell key={item.unitID} className={`thongtinchung-${(index%2 === 0)? "even" : "odd"}`}>{item.name}</Table.HeaderCell>)
                : <Table.HeaderCell key={this.state.selectedUnit} className="normal" style={{height: "110px"}}>{this.getUnitName(orderedUnits, this.state.selectedUnit)}</Table.HeaderCell>}
              <Table.HeaderCell className="thongtinchung-stt">STT</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.rows.map((row, index) => this.renderAdvanceRow(row, index, orderedUnits))}
          </Table.Body>
        </Table>
      </ScrollArea>
    </div>
  )

  renderMilestone = () => {
    if (this.props.milestone !== undefined && this.props.milestone[this.props.unit.activeMilestone] !== undefined)
      return <Message color="teal">{this.props.milestone[this.props.unit.activeMilestone].description}</Message>;
    return null;
  }

  render() {
    if (this.state.error !== "")
      return (
        <Message
          error
          header={this.state.error}
          list={[
            "Khởi động lại trang web hoặc truy cập vào thời điểm khác.",
            "Liên hệ đến nhân viên XBOT để được hỗ trợ.",
          ]}
        />
      );

    const orderedUnits = this.getOrderedUnits();
    const options = [{
      key: "all",
      text: "Tất cả",
      value: "all"
    }];
    orderedUnits.forEach(unit => {
      options.push({
        key: unit.unitID,
        text: unit.name,
        value: unit.unitID
      });
    });

    this.inputs = [];
    return (
      <div>
        {this.renderMilestone()}
        {(this.state.showPhoCap !== undefined)? this.renderPhoCap(orderedUnits) : null}
        {(this.state.viewer !== undefined)? this.renderViewer() : null}
        <Header className="report-header" as="h3">{this.state.name}</Header>
        <Segment stacked style={{ background: "transparent" }}>
          <Form.Group inline>
            <label>Cột muốn hiển thị: </label>
            <Dropdown
              color="brown"
              selection
              search
              multiple={false}
              onChange={(event, { value }) => this.setState({ selectedUnit: value })}
              options={options}
              defaultValue="all"
              placeholder="Chọn phường muốn hiển thị"
            />
          </Form.Group>
          {(this.props.unit.information.type === "pgd")?
            <Button.Group className="right-top-button">
              <Button color="teal" icon="retweet" content="Tính toán số liệu" onClick={() => this.reloadHandler(orderedUnits)} loading={this.state.isReload} />
              <Button color="green" icon="file excel outline" content="Số liệu PC" onClick={() => this.setState({ showPhoCap: true })} />
              <Button color="blue" icon="save" content="Lưu báo cáo" onClick={this.saveHandler} loading={this.state.isSaving} />
            </Button.Group>
            : null
          }
        </Segment>
        <div style={(this.props.unit.information.type === "pgd")? {marginRight: 40} : {}}>
          {this.renderAdvanceReport(orderedUnits)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  unit: state.unit,
  schema: state.reports.thongtinchung,
  data: state.data,
  manage: state.manage,
  thongtinchung: state.data.thongtinchung,
  milestone: state.milestone
});

export default connect(mapStateToProps)(ThongTinChung);