/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Message, Button, Popup, Header, Dimmer, Loader, Modal, Icon, Form, Checkbox, Segment, TextArea, Divider, Dropdown, Label, Input } from "semantic-ui-react";
import Calendar from "react-calendar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";

// components
import DeadlineForm from "../../components/DeadlineForm";
import ReportViewer from "../../components/ReportViewer";

// services
import { eventTracking } from "../../libs/ga";
import { db } from "../../libs/firebase";
import { convertTimeStamp } from "../../libs/time";
import { getSchoolTypeOptions, readSchoolType, readNationalStandard, readEvaluationLevels, isTruong } from "../../libs/school";
import { unitFetchInformation } from "../../redux/actions/units";
import { RESOURCES } from "../../libs/config";


const schoolTypes = getSchoolTypeOptions(true);

const statusType = [{
  label: "Tất cả",
  value: "all"
}, {
  label: "Chưa nộp",
  value: "none"
}, {
  label: "Chưa duyệt",
  value: "waiting"
}, {
  label: "Đã duyệt",
  value: "accepted"
}, {
  label: "Không duyệt",
  value: "rejected"
}];

class Manage extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    unitFetchInformation: PropTypes.func.isRequired,
    user: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      gender: PropTypes.string
    }).isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      activeMilestone: PropTypes.string,
      deadline: PropTypes.object,
      notification: PropTypes.object,
      information: PropTypes.shape({
        name: PropTypes.string.isRequired,
        rootID: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        dissolvedUnits: PropTypes.object
      }).isRequired
    }).isRequired,
    milestone: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    manage: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      selectedSchoolType: "all",
      selectedStatusType: "all",
      showResponse: false,
      responseContent: "",
      selectedMilestone: this.props.unit.activeMilestone,
      isProgressing: false,
      showProgress: false
    }
  }

  componentWillMount() {
    const units = _.map(_.keys(this.props.manage), unit => ({
      key: unit,
      text: this.props.manage[unit].information.name,
      value: unit
    }));
    this.setState({
      units,
      selectedUnit: ""
    });
  }

  getStatus = (status) => {
    switch (status) {
      case "waiting":
        return "Chưa duyệt";
      case "accepted":
        return "Đã duyệt";
      case "rejected":
        return "Không duyệt";
      default:
        return "";
    }
  }

  getAddress = (info) => info.address

  getReadableDate = (date) => moment(date).format("D - M - YYYY")

  milestoneHandler = (event, { value }) => {
    this.setState({ selectedMilestone: value, manage: {} }, () => {
      if (value !== this.props.unit.activeMilestone) {
        this.setState({ isWaiting: true });
        let count = 0;
        _.forEach(_.keys(this.props.manage), childID => {
          count += 1;
          db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.state.selectedMilestone}/final/${childID}/lasted`).once("value", (data) => {
            this.setState({
              manage: {
                ...this.state.manage,
                [childID]: {
                  information: this.props.manage[childID].information,
                  status: (data !== null && data.val() !== null) ? data.val() : undefined
                }
              }
            });
          });
          if (_.keys(this.props.manage).length === count)
            this.setState({ isWaiting: false });
        });
      } else
        this.setState({ manage: undefined }); // load active milestone
    });
  }

  unitHandler = (event, { value }) => {
    this.setState({
      selectedUnit: value,
      selectedSchoolType: "all",
      selectedStatusType: "all"
    });
  }

  /**
   * Reject unit's report
   *
   * @param  {Object} [data] report's content
   * @param  {Object} [unit] units information
   */
  responseHandler = (data, unit) => {
    this.setState({ isWaiting: true });
    eventTracking("manage", "response", 1, this.props.unit.unitID);
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${unit.unitID}/lasted`).update({
      status: "rejected",
      response: {
        message: this.state.responseContent,
        time: new Date().getTime()
      }
    }, (error) => {
      this.setState({ isWaiting: false, showResponse: false });
      if (error)
        this.props.errorHandler("Không gửi được phản hồi đến đơn vị.");
      else {
        // remove approved data if exists
        _.forEach(_.keys(data), reportKey => {
          if (this.props.unit.information.type === "sgd") {
            getSchoolTypeOptions(false).forEach(schoolType => {
              db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${reportKey}/data/${schoolType.value}/${unit.unitID}`).remove();
            });
          }
          else
            db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${reportKey}/data/${unit.schoolType}/${unit.unitID}`).remove();
        });
        this.props.successHandler("Gửi phản hồi thành công.");
      }
    });
  }

  updateResponseToChild = (unit) => {
    eventTracking("manage", "accepted", 1, this.props.unit.unitID);
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${unit.unitID}/lasted`).update({
      status: "accepted",
      response: {
        time: new Date().getTime()
      }
    }, (fail) => {
      this.setState({ isWaiting: false, viewer: undefined });
      if (fail)
        this.props.errorHandler("Không gửi được phản hồi đến đơn vị.");
      else {
        this.props.successHandler("Gửi phản hồi thành công.");
      }
    });
  }

  updatePGDReport = (author, data, unit) => {
    let error = ""; // avoid sending a bunch of error messages
    let count = 0; // number of processed reports
    _.forEach(_.keys(data), reportKey => {
      if (error)
        return;
      if (data[reportKey].data) {
        eventTracking("report", "update", 1, this.props.unit.unitID);
        db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${reportKey}/data/${unit.schoolType}`).update({
          [unit.unitID]: data[reportKey].data
        }, (err) => {
          error = err;
          if (err) {
            this.setState({ isWaiting: false });
            this.props.errorHandler("Đã có lỗi xảy ra khi gửi phản hồi đến đơn vị. Xin vui lòng thử lại.");
          } else {
            if (data[reportKey].detail)
              db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${reportKey}/detail`).update({
                [unit.unitID]: data[reportKey].detail
              });
            db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${reportKey}`).update({
              modifiedTime: new Date().getTime(),
              modifiedBy: author
            });
            count += 1;
            if (_.keys(data).length === count)
              this.updateResponseToChild(unit);
          }
        });
      } else
        count += 1;
    });
  }

  updateSGDReport = (author, data, unit) => {
    let error = ""; // avoid sending a bunch of error messages
    let countReports = 0; // number of processed reports
    _.forEach(_.keys(data), reportKey => {
      if (error)
        return;
      if (data[reportKey].data) {
        let countSchoolTypes = 0; // number of processed school types
        _.forEach(_.keys(data[reportKey].data), schoolType => {
          if (error)
            return;
          const childsData = {};
          _.forEach(_.keys(data[reportKey].data[schoolType]), childUnit => {
            childsData[childUnit] = data[reportKey].data[schoolType][childUnit];
          });
          eventTracking("report", "update", 1, this.props.unit.unitID);
          db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${reportKey}/data/${schoolType}`).update({
            [unit.unitID]: childsData
          }, (err) => {
            error = err;
            countSchoolTypes += 1;
            if (err) {
              this.setState({ isWaiting: false });
              this.props.errorHandler("Đã có lỗi xảy ra khi gửi phản hồi đến đơn vị. Xin vui lòng thử lại.");
            } else {
              if (data[reportKey].detail)
                db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${reportKey}/detail`).update({
                  [unit.unitID]: data[reportKey].detail
                });
              if (_.keys(data[reportKey].data).length === countSchoolTypes) {
                countReports += 1;
                db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${reportKey}`).update({
                  modifiedTime: new Date().getTime(),
                  modifiedBy: author
                });
              }
              if (_.keys(data).length === countReports) {
                db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${unit.unitID}/units`).once("value", (units) => {
                  if (units.val() !== null)
                    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/units`).update(
                      units.val(),
                      (failure) => {
                        if (failure) {
                          this.setState({ isWaiting: false });
                          this.props.errorHandler("Đã có lỗi xảy ra khi gửi phản hồi đến đơn vị. Xin vui lòng thử lại.");
                        } else
                          this.updateResponseToChild(unit);
                      }
                    );
                  else
                    this.updateResponseToChild(unit);
                }, (failure) => {
                  if (failure) {
                    this.setState({ isWaiting: false });
                    this.props.errorHandler("Đã có lỗi xảy ra khi gửi phản hồi đến đơn vị. Xin vui lòng thử lại.");
                  } else
                    this.updateResponseToChild(unit);
                });
              }
            }
          });
        });
      } else
        countReports += 1;
    });
  }

  /**
   * Accept unit's report
   *
   * @param  {Object} [data] report's content
   * @param  {Object} [unit] units information
   */
  acceptHandler = (data, unit) => {
    this.setState({ isWaiting: true });

    // update childs information for reports
    const childsInformation = (this.props.unit.information.type === "pgd" || data.information === undefined) ? {} : data.information;
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/information/`).update({
      ...childsInformation,
      [unit.unitID]: unit.name
    }, (childsInformationError) => {
      if (childsInformationError) {
        this.setState({ isWaiting: false });
        this.props.errorHandler("Đã có lỗi xảy ra khi gửi phản hồi đến đơn vị. Xin vui lòng thử lại.");
      } else {
        const author = {
          displayName: this.props.user.displayName,
          email: this.props.user.email,
          gender: this.props.user.gender
        };
        if (this.props.unit.information.type === "pgd")
          this.updatePGDReport(author, data, unit);
        else
          this.updateSGDReport(author, data, unit);
      }
    });
  }

  updateHandler = (content, deadline, time) => {
    try {
      const times = time.split(":");
      if (times.length !== 2)
        this.props.errorHandler("Thất bại", "Thời gian không hợp lệ. Xin vui lòng nhập thời gian theo cú pháp Giờ:Phút");
      else {
        const datetime = new Date(deadline.getFullYear(), deadline.getMonth(), deadline.getDate(), _.trim(times[0]), _.trim(times[1]));
        this.setState({ isWaiting: true });
        db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/notification/${this.props.unit.unitID}`).update({
          unitName: this.props.unit.information.name,
          deadline: datetime.getTime(),
          message: content,
          author: {
            displayName: this.props.user.displayName,
            email: this.props.user.email,
            gender: this.props.user.gender
          }
        }, (err) => {
          if (err)
            this.props.errorHandler("Thất bại", "Tạo hạn nộp báo cáo thất bại. Xin vui lòng thử lại vào lúc khác.");
          else
            this.props.successHandler("Tạo hạn nộp báo cáo thành công.");
          this.setState({ openDeadlineForm: false, isWaiting: false });
        });
      }
    } catch (error) {
      this.props.errorHandler("Thất bại", "Tạo hạn nộp báo cáo thất bại. Xin vui lòng thử lại vào lúc khác.");
    }
  }

  dissolveHandler = (unitID, date = undefined) => {
    this.setState({ isWaiting: true, showDissolveConfirm: false, dissolveData: false });
    if (date === undefined) {
      db.ref(`eduunits/${this.props.unit.unitID}/dissolvedUnits/${unitID}`).remove(error => {
        if (error)
          this.props.errorHandler("Kết nối đến cơ sở dữ liệu thất bại. Vui lòng thử lại sau.");
        else {
          db.ref(`eduunits/${this.props.unit.unitID}`).once("value", (value) => {
            this.props.successHandler("Cập nhật thông tin thành công.");
            this.setState({ isWaiting: false });
            if (value.val() !== null)
              this.props.unitFetchInformation(this.props.unit.unitID, value.val());
          });
        }
      });
    } else {
      db.ref(`eduunits/${this.props.unit.unitID}/dissolvedUnits`).update({ [unitID]: date }, error => {
        if (error)
          this.props.errorHandler("Kết nối đến cơ sở dữ liệu thất bại. Vui lòng thử lại sau.");
        else {
          db.ref(`eduunits/${this.props.unit.unitID}`).once("value", (value) => {
            this.props.successHandler("Cập nhật thông tin thành công.");
            this.setState({ isWaiting: false });
            if (value.val() !== null)
              this.props.unitFetchInformation(this.props.unit.unitID, value.val());
          });
        }
      });
    }
  }

  /**
   * Show reports detail of selected unit
   *
   * @param  {String} [information] selected unit's information
   * @param  {String} [date] /final/unitID/lasted.time
   * @param  {String} [unitID] selected unit's ID
   * @param  {String} [dataKey] /final/unitID/lasted.data
   */
  showReport = (information, date, unitID, dataKey) => {
    this.setState({ isWaiting: true });
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.state.selectedMilestone}/final/${unitID}/data/${dataKey}`).once("value", snapshot => {
      if (snapshot === null || snapshot.val() === null)
        this.props.errorHandler("Không tìm thấy dữ liệu của báo cáo trên hệ thống.");
      else {
        this.setState({
          viewer: {
            date: convertTimeStamp(date, true),
            data: JSON.parse(snapshot.val()),
            reports: this.props.reports[this.props.unit.information.rootID],
            unit: {
              name: information.name,
              unitID,
              schoolType: information.schoolType
            }
          }
        });
      }
      this.setState({ isWaiting: false });
    });
  }

  checkFilter = (schoolType, reportStatus) => {
    const passStatus = (this.state.selectedStatusType === "all"
      || (reportStatus !== null && reportStatus !== undefined && this.state.selectedStatusType === reportStatus.status)
      || (this.state.selectedStatusType === "none" && reportStatus === undefined));
    const passSchoolType = (this.state.selectedSchoolType === "all" || this.state.selectedSchoolType === schoolType);
    return passStatus && passSchoolType;
  }

  showDissolveConfirm = (unitID, unitInformation, dissolveDate = undefined) => {
    this.setState({
      showDissolveConfirm: true,
      dissolveData: {
        unitID,
        unitInformation,
        dissolveDate: (dissolveDate === undefined) ? new Date() : new Date(dissolveDate),
        showRemoveDisolve: dissolveDate !== undefined
      }
    });
  }

  showProgress = (milestone) => {
    this.setState({ isProgressing: true, progress: undefined, showProgress: false });
    const { rootID } = this.props.unit.information;
    db.ref(`/analysis/rebot/${rootID}`).once("value", snapshot => {
      if (snapshot === null || snapshot.val() === null || snapshot.val()[milestone] === undefined) {
        this.props.errorHandler("Chưa có thống kê về tình hình gửi duyệt báo cáo cho đợt này.");
        this.setState({ isProgressing: false });
      } else
        this.setState({
          isProgressing: false,
          showProgress: true,
          progress: {
            time: snapshot.val().time,
            units: snapshot.val()[milestone]
          }
        });
    }, (error) => {
      if (!error)
        this.props.errorHandler("Đọc thông tin thất bại. Xin vui lòng thử lại sau.");
      this.setState({ isProgressing: false });
    });
  }

  exportQC = () => {
    this.setState({ isExporting: true });
    try {
      const req = new XMLHttpRequest();
      req.open("GET", `${RESOURCES}/rebot/export/qc.xlsx`, true);
      req.responseType = "arraybuffer";
      req.onload = () => {
        XlsxPopulate.fromDataAsync(req.response).then(workbook => {
          workbook.sheet("qc").cell("A1").value(_.get(this.props.unit, "information.name", ""));
          const units = _.map(_.get(this.props, "manage", {}), value => _.get(value, "information", {}));
          let index = 0;
          _.forEach(_.orderBy(units, ["name"], ["asc"]), unit => {
            const { name, nationalStandard, evaluation, schoolModel } = unit;
            if (isTruong(schoolModel)) {
              const rowIndex = index + 9;
              workbook.sheet("qc").cell(`A${rowIndex}`).value(index + 1);
              workbook.sheet("qc").cell(`B${rowIndex}`).value(name);
              if (nationalStandard) {
                const { level, year } = nationalStandard;
                workbook.sheet("qc").cell(`C${rowIndex}`).value(readNationalStandard(level || ""));
                workbook.sheet("qc").cell(`D${rowIndex}`).value(year || "");
              }
              if (evaluation) {
                const { selfEvaluated, evaluated } = evaluation;
                workbook.sheet("qc").cell(`E${rowIndex}`).value(readEvaluationLevels(selfEvaluated || ""));
                workbook.sheet("qc").cell(`F${rowIndex}`).value(readEvaluationLevels(evaluated || ""));
              }
              index += 1;
            }
          });
          workbook.outputAsync().then(data => {
            this.setState({ isExporting: false });
            if (data !== undefined)
              saveAs(data, "ThongKe_ChatLuong_Truong.xlsx");
            else
              this.errorHandler("Xuất thống kê không thành công. Xin vui lòng thử lại sau.");
          });
        }, (error) => {
          this.setState({ isExporting: false });
          if (error)
            this.exportErrorHandler();
        });
      }
      req.onerror = () => {
        this.exportErrorHandler();
      }
      req.send();
    } catch (err) {
      this.exportErrorHandler();
    }
  }

  exportErrorHandler = () => {
    this.errorHandler("Xuất thống kê không thành công. Xin vui lòng thử lại sau.");
    this.setState({ isExporting: false });
  }

  countRow = (unitID, row) => {
    if (this.state.selectedUnit !== "" && this.state.selectedUnit !== unitID)
      return 0;
    if (this.checkFilter(row.information.schoolType, row.status))
      return 1;
    return 0;
  }

  countRows = () => {
    let counter = 0;
    if (this.state.manage !== undefined) { _.forEach(_.keys(this.state.manage), unitID => { counter += this.countRow(unitID, this.state.manage[unitID]) }) }
    if (this.props.manage !== undefined && this.props.manage !== null) { _.forEach(_.keys(this.props.manage), unitID => { counter += this.countRow(unitID, this.props.manage[unitID]) }) }
    return counter;
  }

  renderViewer = () => (
    <div>
      <Message warning>
        <Message.Content>
          <Header className="unitHeader" as="h3">{`Đơn vị ${this.state.viewer.unit.name}`}</Header>
          <Header className="unitSubHeader" as="h4">
Gửi ngày
{' '}
{this.state.viewer.date}
</Header>
          <Button.Group className="right-top-button">
            {(this.props.unit.activeMilestone === this.state.selectedMilestone) ? (
              <Button
                loading={this.state.isWaiting}
                color="red"
                onClick={() => this.setState({ showResponse: true, responseContent: "" })}
              >
                Phản hồi
              </Button>
            ) : null}
            {(this.props.unit.activeMilestone === this.state.selectedMilestone) ? (
              <Button
                loading={this.state.isWaiting}
                color="green"
                icon="checkmark"
                content="Duyệt"
                labelPosition="right"
                onClick={() => this.acceptHandler(this.state.viewer.data, this.state.viewer.unit)}
              />
            ) : null}
            <Button color="grey" icon="close" onClick={() => this.setState({ viewer: undefined })} />
          </Button.Group>
        </Message.Content>
      </Message>
      {(this.state.showResponse) ? this.renderResponse() : null}
      <ReportViewer
        validateRules={this.props.reports.validate}
        verify={this.props.unit.information.type === "pgd"}
        data={this.state.viewer.data}
        reports={this.state.viewer.reports}
        unitType={(this.props.unit.information.type === "pgd") ? "th" : "pgd"}
      />
    </div>
  )

  renderProgress = () => (
    <Modal open={this.state.showProgress} closeIcon={<Icon name="close" onClick={() => this.setState({ showProgress: false, progress: undefined })} />}>
      <Header className="form-header" as="h3">
        <Icon name="desktop" />
        <Header.Content>
          Tổng hợp tình hình gửi và duyệt báo cáo ở các đơn vị Phòng Giáo dục
          <Header.Subheader>
            Cập nhật lúc 
{' '}
{this.state.progress.time}
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Modal.Content>
        <Table unstackable celled striped color="brown">
          <Table.Header style={{ textAlign: "center" }}>
            <Table.Row>
              <Table.HeaderCell>Tên đơn vị</Table.HeaderCell>
              <Table.HeaderCell>Tỷ lệ nhập liệu (%)</Table.HeaderCell>
              <Table.HeaderCell>Tỷ lệ gửi (%)</Table.HeaderCell>
              <Table.HeaderCell>Tỷ lệ duyệt (%)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(this.state.progress.units).map((unitID) => (
              <Table.Row key={unitID}>
                <Table.Cell>{this.state.progress.units[unitID].name}</Table.Cell>
                <Table.Cell>
{this.state.progress.units[unitID].saveReport}
/
{this.state.progress.units[unitID].total}
=
{(this.state.progress.units[unitID].total !== 0) ? Math.round(this.state.progress.units[unitID].saveReport * 100 / this.state.progress.units[unitID].total) : 0}
%
</Table.Cell>
                <Table.Cell>
{this.state.progress.units[unitID].sendReport}
/
{this.state.progress.units[unitID].total}
=
{(this.state.progress.units[unitID].total !== 0) ? Math.round(this.state.progress.units[unitID].sendReport * 100 / this.state.progress.units[unitID].total) : 0}
%
</Table.Cell>
                <Table.Cell>
{(this.state.progress.units[unitID].total !== 0) ? Math.round(this.state.progress.units[unitID].approved * 100 / this.state.progress.units[unitID].total) : 0}
%
</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  )

  renderDissolveConfirm = () => (
    <Modal size="small" open={this.state.showDissolveConfirm} closeIcon={<Icon name="close" onClick={() => this.setState({ showDissolveConfirm: undefined, dissolveData: undefined })} />}>
      <Header className="form-header" as="h3">
        <Icon name="warning sign" />
        <Header.Content>
          {`Đơn vị ${this.state.dissolveData.unitInformation.name}`}
          <Header.Subheader>
            Sau khi giải thể, số liệu của đơn vị này sẽ không được đếm vô báo cáo Thông tin chung khi cập nhật dữ liệu.
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Modal.Content>
        <Form.Field>
          <label>Ngày giải thể:  </label>
          <Popup
            trigger={(
              <Input
                value={this.getReadableDate(this.state.dissolveData.dissolveDate)}
                icon="calendar"
                placeholder="Ngày"
                iconPosition="left"
              />
            )}
            on="click"
            hideOnScroll
            position="left center"
          >
            <Calendar
              minDetail="year"
              value={new Date(this.state.dissolveData.dissolveDate)}
              onChange={(date) => this.setState({ dissolveData: { ...this.state.dissolveData, dissolveDate: date.getTime() } })}
              locale="vi-VI"
            />
          </Popup>
        </Form.Field>
      </Modal.Content>
      <div className="profile-buttons">
        <Button basic color="brown" onClick={() => this.dissolveHandler(this.state.dissolveData.unitID, this.state.dissolveData.dissolveDate)}>Cập nhật</Button>
        {this.state.dissolveData.showRemoveDisolve ? <Button basic color="grey" onClick={() => this.dissolveHandler(this.state.dissolveData.unitID)}>Bỏ giải thể</Button> : null}
      </div>
    </Modal>
  )

  renderMilestones = () => {
    const options = [];
    _.keys(this.props.milestone).map(milestone => (
      options.push({
        key: milestone,
        text: this.props.milestone[milestone].description,
        value: milestone
      })
    ));
    return (
      <Dropdown
        selection
        multiple={false}
        onChange={this.milestoneHandler}
        options={options}
        defaultValue={this.state.selectedMilestone}
        placeholder="Chọn đợt báo cáo"
      />
    )
  }

  renderRow = (unitID, row) => {
    if (this.state.selectedUnit !== "" && this.state.selectedUnit !== unitID)
      return null;
    if (this.checkFilter(row.information.schoolType, row.status)) {
      const showGiaiThe = this.props.unit.information.type === "pgd";
      let giaithe = <Table.Cell width="1"><Button color="red" icon="remove" onClick={() => this.showDissolveConfirm(unitID, row.information)} /></Table.Cell>;
      if (this.props.unit.information.dissolvedUnits !== undefined && this.props.unit.information.dissolvedUnits[unitID] !== undefined)
        giaithe = <Table.Cell width="1"><Button color="red" icon="write" onClick={() => { this.showDissolveConfirm(unitID, row.information, this.props.unit.information.dissolvedUnits[unitID]) }} /></Table.Cell>;

      this.count += 1;
      if (row.status !== undefined)
        return (
          <Table.Row key={unitID} warning={row.status.status === "waiting"} positive={row.status.status === "accepted"} negative={row.status.status === "rejected"}>
            <Table.Cell width="4">
              {(showGiaiThe && this.props.unit.information.dissolvedUnits !== undefined && this.props.unit.information.dissolvedUnits[unitID] !== undefined) ? <Label color="red" ribbon>
Giải thể:
{' '}
{this.getReadableDate(new Date(this.props.unit.information.dissolvedUnits[unitID]))}
</Label> : null}
              {row.information.name}
            </Table.Cell>
            <Table.Cell width="1" style={{ textAlign: "center" }}><Button basic icon="braille" onClick={() => this.showReport(row.information, row.status.time, unitID, row.status.data)} /></Table.Cell>
            {(this.props.unit.information.type === "pgd") ? <Table.Cell width="2">{readSchoolType(row.information.schoolType)}</Table.Cell> : null}
            <Table.Cell width="3">{this.getAddress(row.information)}</Table.Cell>
            <Table.Cell width="1">{this.getStatus(row.status.status)}</Table.Cell>
            <Table.Cell width="3">{convertTimeStamp(row.status.time)}</Table.Cell>
            <Table.Cell width="2">{row.status.author.displayName}</Table.Cell>
            <Table.Cell width="2">{row.status.author.email}</Table.Cell>
            {showGiaiThe ? giaithe : null}
          </Table.Row>
        );
      return (
        <Table.Row negative key={unitID}>
          <Table.Cell width="4">
            {(showGiaiThe && this.props.unit.information.dissolvedUnits !== undefined && this.props.unit.information.dissolvedUnits[unitID] !== undefined) ? <Label color="red" ribbon>
Giải thể:
{' '}
{this.getReadableDate(new Date(this.props.unit.information.dissolvedUnits[unitID]))}
</Label> : null}
            {row.information.name}
          </Table.Cell>
          <Table.Cell width="1" />
          {(this.props.unit.information.type === "pgd") ? <Table.Cell width="2">{readSchoolType(row.information.schoolType)}</Table.Cell> : null}
          <Table.Cell width="3">{this.getAddress(row.information)}</Table.Cell>
          <Table.Cell width="1">Chưa nộp</Table.Cell>
          <Table.Cell width="3" />
          <Table.Cell width="2" />
          <Table.Cell width="2" />
          {showGiaiThe ? giaithe : null}
        </Table.Row>
      );
    }
    return null;
  }

  renderGetButtonContent = () => {
    if (this.props.unit.deadline !== undefined)
      return "Cập nhật hạn nộp";
    return "Tạo hạn nộp";
  }

  renderResponse = () => (
    <Modal size="small" open={this.state.showResponse} closeIcon={<Icon name="close" onClick={() => this.setState({ showResponse: false, responseContent: "" })} />}>
      <Modal.Header className="form-header">Nhập nội dung phản hồi (nếu có)</Modal.Header>
      <Modal.Content>
        <Form>
          <TextArea autoHeight placeholder="Nội dung phản hồi" value={this.state.responseContent} onChange={(e, { value }) => this.setState({ responseContent: value })} />
        </Form>
        <Divider />
        <center className="report-buttons">
          <Button basic color="brown" onClick={() => this.responseHandler(this.state.viewer.data, this.state.viewer.unit)}>Gửi</Button>
        </center>
      </Modal.Content>
    </Modal>
  )

  renderResult = () => {
    if (this.state.manage !== undefined)
      return (
        <Table.Body>
          {Object.keys(this.state.manage).map((unitID) => this.renderRow(unitID, this.state.manage[unitID]))}
        </Table.Body>
      );
    if (this.props.manage !== undefined && this.props.manage !== null)
      return (
        <Table.Body>
          {Object.keys(this.props.manage).map((unitID) => this.renderRow(unitID, this.props.manage[unitID]))}
        </Table.Body>
      );
    return null;
  }

  render() {
    if (this.state.viewer !== undefined)
      return this.renderViewer();
    return (
      <div>
        {this.state.isWaiting ? (
          <Dimmer active inverted>
            <Loader inverted>Đang cập nhật thông tin...</Loader>
          </Dimmer>
        ) : null}
        {(this.state.showDissolveConfirm) ? this.renderDissolveConfirm() : null}
        {(this.state.showResponse) ? this.renderResponse() : null}
        {(this.state.progress !== undefined) ? this.renderProgress() : null}
        <Message warning>
          <Message.Content>
            <Header className="unitHeader" as="h3">Quản lý và theo dõi tiến độ gửi báo cáo của các đơn vị</Header>
            <Header className="unitSubHeader" as="h4">
{`Năm học ${this.props.unit.activeYear}`}
.
{' '}
{this.props.milestone[this.props.unit.activeMilestone].description}
</Header>
            {(this.props.unit.deadline !== undefined) ? `Hạn nộp đã tạo: ${convertTimeStamp(this.props.unit.deadline.deadline, true)}` : "Chưa tạo hạn nộp báo cáo"}
.
<Button.Group className="right-top-button">
              {(this.props.unit.activeMilestone === this.state.selectedMilestone) ? (
                <Popup
                  open={this.state.openDeadlineForm}
                  trigger={<Button onClick={() => this.setState({ openDeadlineForm: true })} basic color="brown" floated="right" icon="clock" content={this.renderGetButtonContent()} />}
                  on="click"
                >
                  <DeadlineForm
                    deadline={(this.props.unit.notification !== undefined && this.props.unit.notification.deadline !== undefined) ? this.props.unit.notification.deadline : undefined}
                    closeHandler={() => this.setState({ openDeadlineForm: false })}
                    updateHandler={this.updateHandler}
                    selectedDate={(this.props.unit.deadline !== undefined) ? this.props.unit.deadline.deadline : undefined}
                  />
                </Popup>
              ) : null}
              {(this.props.unit.information.type === "sgd") ? <Button color="grey" basic icon="desktop" content="Theo dõi tiến độ" onClick={() => this.showProgress(this.state.selectedMilestone)} loading={this.state.isProgressing} /> : null}
              {(this.props.unit.information.type === "pgd") ? <Button color="grey" basic icon="certificate" content="Thống kê chất lượng" onClick={this.exportQC} loading={this.state.isExporting} /> : null}
            </Button.Group>
          </Message.Content>
        </Message>
        <Segment stacked style={{ background: "transparent" }}>
          <Form>
            <Form.Group inline>
              <label>Các đợt báo cáo: </label>
              {this.renderMilestones()}
            </Form.Group>
            <Form.Group inline>
              <label>Tìm theo tên: </label>
              <Dropdown
                search
                selection
                multiple={false}
                onChange={this.unitHandler}
                options={this.state.units}
                value={this.state.selectedUnit}
                placeholder="Gõ tên đơn vị"
                noResultsMessage="Không tìm thấy đơn vị."
              />
              <Button basic icon="refresh" onClick={() => this.setState({ selectedUnit: "" })} />
            </Form.Group>
            <Form.Group inline>
              <label>Trạng thái báo cáo: </label>
              {statusType.map((type, index) => (
                <Form.Field key={index}>
                  <Checkbox
                    radio
                    label={type.label}
                    nane="statusType"
                    value={type.value}
                    checked={type.value === this.state.selectedStatusType}
                    onChange={(e, { value }) => this.setState({ selectedStatusType: value })}
                  />
                </Form.Field>
              ))}
            </Form.Group>
            {(this.props.unit.information.type === "pgd") ? (
              <Form.Group inline>
                <label>Loại hình: </label>
                {schoolTypes.map((type, index) => (
                  <Form.Field key={index}>
                    <Checkbox
                      radio
                      label={type.text}
                      nane="schoolType"
                      value={type.value}
                      checked={type.value === this.state.selectedSchoolType}
                      onChange={(e, { value }) => this.setState({ selectedSchoolType: value })}
                    />
                  </Form.Field>
                ))}
              </Form.Group>
            )
              : null}
            <Form.Group inline>
              <label>Số đơn vị đang hiển thị: </label>
{this.countRows()}
            </Form.Group>
          </Form>
        </Segment>
        <Table unstackable celled striped color="brown" style={{ background: "transparent" }}>
          <Table.Header style={{ textAlign: "center" }}>
            <Table.Row>
              <Table.HeaderCell className="stt-school">Tên đơn vị</Table.HeaderCell>
              <Table.HeaderCell className="stt-school">Xem chi tiết</Table.HeaderCell>
              {(this.props.unit.information.type === "pgd") ? <Table.HeaderCell className="stt-school">Loại hình trường</Table.HeaderCell> : null}
              <Table.HeaderCell className="stt-school">Địa chỉ</Table.HeaderCell>
              <Table.HeaderCell className="stt-school">Trạng thái</Table.HeaderCell>
              <Table.HeaderCell className="stt-school">Ngày gửi</Table.HeaderCell>
              <Table.HeaderCell className="stt-school">Người gửi</Table.HeaderCell>
              <Table.HeaderCell className="stt-school">Email</Table.HeaderCell>
              {(this.props.unit.information.type === "pgd") ? <Table.HeaderCell className="stt-school">Giải thể</Table.HeaderCell> : null}
            </Table.Row>
          </Table.Header>

          {this.renderResult()}
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  unit: state.unit,
  status: state.status,
  manage: state.manage,
  reports: state.reports,
  milestone: state.milestone
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ unitFetchInformation }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Manage);