import { UNIT_FETCH, UNIT_ACTIVE_YEAR, UNIT_NOTIFICATION, UNIT_DEADLINE, UNIT_ACTIVE_MILESTONE } from "./constants";


/**
 * Fetch detail information of a unit
 * 
 * @module Redux.Actions.Units
 * @ngdoc method
 * @name $reportsFetch
 * @param  {String} [unitID]
 * @param  {Object} [information] json format like firebase
 */
export function unitFetchInformation(unitID, information) {
  // use additional rootID to identify where is the location of rebot
  let rootID = "";
  if (information.type === "sgd")
    rootID = unitID;
  else
    rootID = information.sgd;
  return {
    type: UNIT_FETCH,
    unitID,
    information: {
      ...information,
      rootID
    }
  };
}

/**
 * Set active year for current unit
 * 
 * @module Redux.Actions.Units
 * @ngdoc method
 * @name $unitFetchActiveYear
 * @param  {String} [unitID]
 * @param  {String} [year]
 */
export function unitFetchActiveYear(unitID, year) {
  return {
    type: UNIT_ACTIVE_YEAR,
    unitID,
    year
  };
}

/**
 * Set active milestone for current unit
 * 
 * @module Redux.Actions.Units
 * @ngdoc method
 * @name $unitFetchActiveMilestone
 * @param  {String} [unitID]
 * @param  {String} [milestoneID]
 */
export function unitFetchActiveMilestone(unitID, milestoneID) {
  return {
    type: UNIT_ACTIVE_MILESTONE,
    unitID,
    milestone: milestoneID
  };
}

/**
 * Fetch notification information from a unit's parent
 * 
 * @module Redux.Actions.Units
 * @ngdoc method
 * @name $unitNotification
 * @param  {String} [unitID]
 * @param  {Object} [notification] json format like firebase, deadline of its parent
 */
export function unitNotification(unitID, notification) {
  return {
    type: UNIT_NOTIFICATION,
    unitID,
    notification
  };
}

/**
 * Fetch notification information of a unit
 * 
 * @module Redux.Actions.Units
 * @ngdoc method
 * @name $unitDeadline
 * @param  {String} [unitID]
 * @param  {Object} [deadline] notification of current unit, only for pgd and sgd
 */
export function unitDeadline(unitID, deadline) {
  return {
    type: UNIT_DEADLINE,
    unitID,
    deadline
  };
}