import { YEAR_FETCH } from "./constants";


/**
 * Add school year
 * 
 * @module Redux.Actions.Years
 * @ngdoc method
 * @name $yearFetch
 * @param  {String} [unitID]
 * @param  {String} [year]
 * @param  {Bool} [active] default
 */
export function yearFetch(unitID, year, active) {
  return {
    type: YEAR_FETCH,
    unitID,
    year,
    active
  };
}