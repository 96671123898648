import { REPORTS_FETCH, REPORTS_FETCHING, REPORTS_FETCH_ERROR, SIGN_OUT, REPORT_THONGTINCHUNG, REPORT_EXPORTS, REPORT_VALIDATE } from "../actions/constants";


const initialState = {
};

export default function user(state = initialState, action) {
  switch(action.type) {
    case REPORTS_FETCH:
      // keep time status when update reports, remove waiting and error status
      return {
        ...state,
        [action.unitID]: action.reports
      };
    case REPORTS_FETCHING:
      // show waiting status and set time
      return {
        ...state,
        [action.unitID]: {
          waiting: true
        }
      };
    case REPORTS_FETCH_ERROR:
      // remove waiting status and update error message
      return {
        ...state,
        [action.unitID]: {
          error: action.error
        }
      };
    case REPORT_THONGTINCHUNG:
      return {
        ...state,
        thongtinchung: action.thongtinchung
      };
    case REPORT_VALIDATE:
      return {
        ...state,
        validate: action.validate
      };
    case REPORT_EXPORTS:
      return {
        ...state,
        exports: action.exports
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}