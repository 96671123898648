import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";

import { connect } from "react-redux";


class PrivateRoute extends Component {
  render() {
    const {
      user,
      component: Comp,
      path
    } = this.props;
    if (user.email !== "")
      return (
        <Route
          exact
          path={path}
          render={(props) => (<Comp {...props} />)}
        />
      );
    return (
      <Route
        exact
        path={path}
        render={(props) => (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
      />
    );
  }
}

PrivateRoute.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.object,
};

PrivateRoute.defaultProps = {
  component: undefined,
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));