import { saveAs } from 'file-saver';
import _ from 'lodash';
import excelColumnName from 'excel-column-name';

const FONT = 'Tahoma';
export const writeHeaders = (
  sheet,
  headers,
  row,
  col,
  depth = 1,
  styles = {}
) => {
  let colIndex = col;

  _.forEach(headers, (header) => {
    colIndex += 1;
    const column = excelColumnName.intToExcelCol(colIndex);
    const {
        name,
        subHeaders,
        width,
        wrapText,
        vertical,
    } = header;
    const {
      fill
    } = styles;
    // eslint-disable-next-line no-param-reassign
    if (width) sheet.getColumn(column).width = width;
    const cell = sheet.getCell(`${column}${row}`);
    cell.value = name;
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    cell.font = {
      name: FONT,
      bold: true,
      size: 10,
    };
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      textRotation: vertical ? 90 : 0,
      wrapText,
    };
    if(fill) cell.fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb: fill || 'FFFFFFFF'},
      bgColor:{argb: fill || 'FFFFFFFF'}
    }
    if (subHeaders) {
      writeHeaders(sheet, subHeaders, row + 1, colIndex - 1, depth - 1);
      colIndex += subHeaders.length - 1;
      sheet.mergeCells(
        `${column}${row}:${excelColumnName.intToExcelCol(colIndex)}${row}`
      );
    } else if (depth > 1) {
      sheet.mergeCells(`${column}${row}:${column}${row + depth - 1}`);
    }
  });
};
export const writeCell = (
  sheet,
  row,
  col,
  value,
  style = {}
) => {
  const cell = sheet.getCell(`${excelColumnName.intToExcelCol(col)}${row}`);
  cell.value = value || '';
  const {
    border,
    bold,
    vertical,
    horizontal,
    wrapText,
    fontSize,
    color,
    fill,
    numberFormat
  } = style;
  if (border)
    cell.border = {
      top: { style: border.top ||'thin' },
      left: { style: border.left ||'thin' },
      bottom: { style: border.bottom ||'thin' },
      right: { style: border.right ||'thin' },
    };
  cell.font = {
    name: FONT,
    size: fontSize || 10,
    bold: bold || false,
    color: {
        argb: color || 'FF000000'
    }
  };
  cell.alignment = {
    vertical: vertical || 'middle',
    horizontal: horizontal || 'center',
    wrapText: wrapText || false,
  }
  if(numberFormat) {
    cell.numFmt = numberFormat;
  }
  if (fill) cell.fill = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb: fill || 'FFFFFFFF'},
    bgColor:{argb: fill || 'FFFFFFFF'}
  }
};
export const writeRange = (
    sheet,
    row,
    col,
    value,
    range,
    style,
) => {
    writeCell(
    sheet,
    row,
    col,
    value,
    style,
    )
    sheet.mergeCells(range);
}
export const writeInfos = (
  sheet,
  row,
  col,
  infos
) => {
  _.forEach(infos, ({ title, value }, idx) => {
    writeCell(
      sheet,
      row + idx,
      col,
      title,
      { bold: true, horizontal: 'left'  }
    );
    writeCell(
      sheet,
      row + idx,
      col + 1,
      value,
      { bold: true, color: 'FFFF0000', horizontal: 'left'  }
    )
  })
}
export const writeRanges = (
  sheet,
  ranges
) => {
  _.forEach(ranges, ({
    row,
    col,
    val,
    range,
    style
  }) => {
    writeRange(
      sheet,
      row,
      col,
      val,
      range,
      style
    )
  })

}
export const writeReport = (data, file, onComplete) => {
  saveAs(data, file);
  onComplete();
};

export const round = (value, dec = 2) => Number((value || 0).toFixed(dec));