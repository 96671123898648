/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types, no-restricted-globals, no-eval */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Table,
  Header,
  Input,
  Message,
  Segment,
  Form,
  Button,
  Popup,
  Feed,
  Icon,
  Label,
  Checkbox,
  Modal,
  Menu
} from "semantic-ui-react";
import { FormattedNumber } from "react-intl";
import ScrollArea from "react-scrollbar";
import { connect } from "react-redux";
import _ from "lodash";
import ReactTable from 'react-table';

// components
import SimpleViewer from "../../components/ReportViewer/SimpleViewer";
import DetailViewer from '../../components/ReportViewer/DetailViewer';

// services
import { eventTracking } from "../../libs/ga";
import { db } from "../../libs/firebase";
import { convertTimeStamp } from "../../libs/time";
import {
  getCustomLogic,
  expandLogic,
  expandExp,
  computeFromDetail
} from "../../libs/report";
import { getSchoolTypeOptions, readSchoolType } from "../../libs/school";


const schoolTypes = getSchoolTypeOptions(true);

class Report extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    workingReportHandler: PropTypes.func.isRequired,
    reportKey: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired,
    user: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      gender: PropTypes.string,
    }).isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      activeMilestone: PropTypes.string,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        schoolModel: PropTypes.string,
        schoolType: PropTypes.string,
        xakhokhan: PropTypes.bool
      }).isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      isSaving: false,
      selectedSchoolType: "all",
      error: "",
      name: "",
      rows: {},
      data: {},
      detail: {},
      sticky: false
    }

    if (this.props.unit.information.type === "th")
      this.logic = getCustomLogic(this.props.unit.information.type, this.props.unit.information.schoolModel, this.props.unit.information.schoolType, "", {}, { unit: this.props.unit.information });
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.load(this.props, !this.state.edit);
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps, !this.state.edit);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  onBlurHandler = (reportKey, key, value) => {
    if (value.length > 0) {
      this.updateValue(reportKey, key, parseFloat(value));
    }
  }

  handleScroll = () => {
    if (this.headerRef !== undefined && this.headerRef.getBoundingClientRect().top - window.pageYOffset < -500) {
      if (!this.state.sticky)
        this.setState({ sticky: true });
    } else if (this.state.sticky)
      this.setState({ sticky: false });
  }

  load = (propsData, force) => {
    try {
      this.typesData = {};
      const report = propsData.reports[propsData.unit.information.rootID][propsData.reportKey];
      if (this.state.rows[propsData.reportKey] === undefined || this.state.data[propsData.reportKey] === undefined || force) {
        this.data = undefined;
        this.maxDepth = 0;
        this.reportKey = propsData.reportKey;
        if (_.get(propsData.data, `${propsData.unit.unitID}.${propsData.reportKey}`)) {
          if (propsData.data[propsData.unit.unitID][propsData.reportKey].waiting === true) {
            this.setState({
              name: report.name,
              description: report.description
            });
            return;
          }
          // for school's report: data has type of array({[key]: value})
          // for other's report (pgd and sgd), data has type of array({[schoolType]: array({[childID]: array({[key]: value})})})
          this.data = propsData.data[propsData.unit.unitID][propsData.reportKey].data;
        }
        this.autoComputation = {};
        const rows = this.extractNode(report.schema, 0, report.logic);
        let data = {};
        _.forEach(_.keys(rows), k => {
          data[rows[k].key] = rows[k].value;
        });
        // update data base on report's logic
        if (report.logic !== undefined && propsData.unit.information.type === "th") {
          const externalData = {
            ...propsData.data[propsData.unit.unitID],
            unit: propsData.unit.information
          };
          this.logic = getCustomLogic(propsData.unit.information.type, propsData.unit.information.schoolModel, propsData.unit.information.schoolType, propsData.reportKey, data, externalData);
          data = { ...data, ...expandLogic(propsData.data[propsData.unit.unitID], data, report.logic, this.logic) };
        }
        this.setState({
          edit: false,
          name: report.name,
          description: report.description,
          rows: {
            ...this.state.rows,
            [propsData.reportKey]: rows
          },
          data: {
            ...this.state.data,
            [propsData.reportKey]: data
          },
          logic: {
            ...this.state.logic,
            [propsData.reportKey]: report.logic
          },
          detail: {
            show: _.get(propsData.unit, 'information.type', '') === 'th',
            schema: report.detail,
            data: _.get(propsData.data, `${propsData.unit.unitID}.${propsData.reportKey}.detail`, [])
          }
        });
      } else
        this.setState({
          edit: false,
          name: report.name,
          description: report.description
        });
    } catch (error) {
      this.setState({
        error: "Lỗi hệ thống."
      });
    }
  }

  extractNode = (node, depth, logic = {}) => {
    const valid = node.permission === undefined || node.permission.indexOf(this.props.unit.information.type) !== -1;
    if (this.maxDepth < depth && valid)
      this.maxDepth = depth;

    if (node.key !== undefined) {
      let value = 0;
      if (valid) {
        if (this.props.unit.information.type === "th")
          value = _.get(this.data, node.key, "");
        else {
          value = {
            cl: (node.type === "text") ? "" : 0,
            dl: (node.type === "text") ? "" : 0,
            tt: (node.type === "text") ? "" : 0
          };
          ["cl", "dl", "tt"].forEach((schoolType) => {
            if (this.data !== undefined && schoolType !== undefined && this.data[schoolType] !== undefined) {
              _.forEach(_.keys(this.data[schoolType]), unitID => {
                if (this.props.unit.information.type === "sgd") {
                  value[`${schoolType}-${unitID}`] = (node.type === "text") ? "" : 0;
                  _.forEach(_.keys(this.data[schoolType][unitID]), childsID => {
                    value[childsID] = _.get(this.data[schoolType][unitID][childsID], node.key, (node.type === "text") ? "" : 0);
                    if (node.type !== "text") {
                      value[`${schoolType}-${unitID}`] += _.get(this.data[schoolType][unitID][childsID], node.key, 0);
                      value[schoolType] += value[childsID];
                    }
                  });
                } else {
                  value[unitID] = _.get(this.data[schoolType][unitID], node.key, (node.type === "text") ? "" : 0);
                  if (node.type !== "text")
                    value[schoolType] += value[unitID];
                }
              });
            }
          });
          value.sum = (node.type === "text") ? 0 : (value.cl + value.dl + value.tt);
        }
      }
      this.typesData[node.key] = node.type;
      if (node.compute)
        this.autoComputation[node.key] = {
          enable: node.enable,
          compute: node.compute
        };
      return [{
        valid,
        parents: [],
        header: node.name,
        key: node.key,
        type: node.type,
        enable: node.enable,
        value,
        depth
      }];
    }

    const rows = [];
    _.forEach(_.keys(node), item => {
      if (item === "name" || item === "permission")
        return;
      const tmp = this.extractNode(node[item], depth + 1, logic);
      if (tmp.length > 0)
        rows.push(...tmp);
    });
    if (rows.length > 0 && node.name !== undefined) {
      rows[0].parents.unshift({
        valid,
        name: node.name,
        span: rows.length
      });
    }

    return rows;
  }

  handleKeyDown = (e, index) => {
    if (e.keyCode === 13 || e.keyCode === 40) {
      let focus = false;
      _.keys(this.inputs).some(idx => {
        if (focus) {
          this.inputs[idx].focus();
          return true;
        }
        if (idx === index) {
          focus = true;
        }
        return false;
      });
    } else if (e.keyCode === 38) {
      let prev;
      _.keys(this.inputs).some(idx => {
        if (idx === index) {
          if (prev !== undefined)
            prev.focus();
          return true;
        }
        prev = this.inputs[idx];
        return false;
      });
    }
  }

  schoolTypeHandler = (e, { value }) => {
    this.setState({ selectedSchoolType: value });
  }

  updateValue = (reportKey, key, value) => {
    let data = {
      ...this.state.data[reportKey],
      [key]: value
    };
    const externalData = {
      ...this.props.data[this.props.unit.unitID],
      unit: this.props.unit.information
    };
    this.logic = getCustomLogic(this.props.unit.information.type, this.props.unit.information.schoolModel, this.props.unit.information.schoolType, reportKey, data, externalData);
    if (this.props.reports[this.props.unit.information.rootID][reportKey] !== undefined && this.props.reports[this.props.unit.information.rootID][reportKey].logic !== undefined)
      data = { ...data, ...expandLogic(this.props.data[this.props.unit.unitID], data, this.props.reports[this.props.unit.information.rootID][reportKey].logic, this.logic) };
    this.setState({
      edit: true,
      name: (this.state.name[this.state.name.length - 1] !== "*") ? `${this.state.name}*` : this.state.name,
      data: {
        ...this.state.data,
        [reportKey]: data
      }
    });
  }

  updateValueHandler = (reportKey, key, value, type) => {
    if (type === "text") {
      this.updateValue(reportKey, key, value.value);
      return;
    }
    if (isNaN(value.value)) {
      this.props.errorHandler("Giá trị không hợp lệ", "Giá trị nhập vào phải là số.");
      return;
    }
    this.props.workingReportHandler(reportKey, "working");
    let finalValue;
    if (value.value.endsWith('.'))
      finalValue = `${parseFloat(value.value)}.`;
    else
      finalValue = value.value;
    this.updateValue(reportKey, key, finalValue);
  }

  saveHandler = () => {
    const { isSaving, detail } = this.state;
    if (isSaving)
      return;
    this.setState({
      isSaving: true
    });

    // preprocess data
    const data = this.state.data[this.props.reportKey];
    Object.keys(data).map(key => {
      if (this.typesData[key] === "text")
        return key;
      if (isNaN(data[key]) || data[key] === "")
        data[key] = 0;
      else
        data[key] = parseFloat(data[key]);
      return key;
    });

    eventTracking("report", "save", 1, this.props.unit.unitID);
    const updateContent = {
      modifiedBy: {
        email: this.props.user.email,
        displayName: this.props.user.displayName,
        gender: this.props.user.gender
      },
      modifiedTime: (new Date()).getTime(),
      data
    };
    if (detail.schema)
      updateContent.detail = _.get(detail, 'data', []);
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}/${this.props.reportKey}`).update(updateContent, this.saveComplete);
  }

  saveComplete = (error) => {
    this.setState({
      isSaving: false
    });
    if (error) {
      this.props.errorHandler("Lưu báo cáo thất bại.");
    } else {
      this.setState({
        name: this.state.name.replace(/\*([^*]*)$/, "$1")
      });
      this.props.workingReportHandler(this.props.reportKey, "done");
      this.props.successHandler("Lưu báo cáo thành công.");
    }
  }

  undoHandler = () => {
    if (this.state.isSaving)
      return;
    this.setState({
      name: this.state.name.replace(/\*([^*]*)$/, "$1")
    });
    this.props.workingReportHandler(this.props.reportKey, "done");
    this.load(this.props, true);
  }

  showReport = (unit) => {
    if (this.state.selectedSchoolType === "all")
      return;
    switch (this.props.unit.information.type) {
      case "sgd":
        this.setState({
          showViewer: true,
          viewer: {
            unit: unit.name,
            data: this.data[this.state.selectedSchoolType][unit.unitID],
            information: this.props.data[this.props.unit.unitID].information
          }
        });
        break;
      case "pgd":
        if (_.get(this.state, 'detail.schema'))
          this.setState({ showDetail: unit.unitID });
        break;
      default:
    }
  }

  readerAdvanceHeader = () => (
    <Table celled unstackable style={{ width: "400px", float: "left" }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="row-header" colSpan={this.maxDepth}><div ref={(ref) => { this.headerRef = ref; }}>{this.renderStatus()}</div></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(this.state.rows[this.props.reportKey] !== undefined) ? this.state.rows[this.props.reportKey].map((row) => this.renderAdvanceRowHeader(row)) : null}
      </Table.Body>
    </Table>
  )

  renderAdvanceRowHeader = (row) => {
    if (!row.valid)
      return null;
    const span = (this.maxDepth > row.depth) ? (this.maxDepth - row.depth + 1) : 1;
    return (
      <Table.Row key={row.key}>
        {row.parents.map((parent, k) => {
          if (parent.valid)
            return <Table.Cell key={k} className={`column-header ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1" rowSpan={parent.span}>{parent.name}</Table.Cell>;
          return null;
        })}
        <Table.Cell className={`column-header ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1" colSpan={span}>{row.header}</Table.Cell>
      </Table.Row>
    );
  }

  renderRow = (row, index) => {
    const {
      key,
      valid,
      parents,
      depth,
      type,
      header,
      enable
    } = row;
    if (!valid)
      return null;
    const { reportKey, data } = this.props;
    const span = (this.maxDepth > depth) ? (this.maxDepth - depth + 1) : 1;
    const renderEditor = () => {
      if (enable) {
        const exp = expandExp(data, _.get(data, reportKey, {}), enable, this.logic);
        if (!eval(exp))
          return <Table.Cell id={key} width="1" className={`logic ${(parents.length === depth - 1) ? "groupBorder" : ""}`} />
      }
      return (
        <Table.Cell id={key} className={`input-school ${(parents.length === depth - 1) ? "groupBorder" : ""}`} width="1">
          <Input
            fluid
            placeholder={(type === "text") ? "" : "0"}
            value={this.state.data[reportKey][key]}
            onChange={(event, value) => { this.updateValueHandler(reportKey, key, value, type); }}
            onBlur={(e) => {
              if (type !== "text")
                this.onBlurHandler(reportKey, key, e.target.value);
            }}
            ref={ref => { this.inputs[index] = ref }}
            onKeyDown={(e) => this.handleKeyDown(e, `${index}`)}
          />
        </Table.Cell>
      );
    }
    return (
      <Table.Row key={key}>
        {parents.map((parent, k) => {
          if (parent.valid)
            return <Table.Cell key={k} className={`column-header-school ${(parents.length === depth - 1) ? "groupBorder" : ""}`} width="1" rowSpan={parent.span}>{parent.name}</Table.Cell>;
          return null;
        })}
        <Table.Cell className={`column-header-school ${(parents.length === depth - 1) ? "groupBorder" : ""}`} width="1" colSpan={span}>{header}</Table.Cell>
        {_.get(this.state.logic, `${reportKey}.${key}.eval`) ?
          <Table.Cell id={key} width="1" className={`logic ${(parents.length === depth - 1) ? "groupBorder" : ""}`}>
            <Popup trigger={<FormattedNumber value={this.state.data[reportKey][key]} />} content={_.get(this.state.logic, `${reportKey}.${key}.description`, "")} />
          </Table.Cell>
          :
          renderEditor()
        }
        <Table.Cell className={`stt-school ${(parents.length === depth - 1) ? "groupBorder" : ""}`} width="1">{index + 1}</Table.Cell>
      </Table.Row>
    );
  }

  renderAdvanceRow = (row, index, orderedUnits) => {
    if (!row.valid)
      return null;
    if (row.type === "text")
      return (
        <Table.Row key={row.key}>
          <Table.Cell key="sum" className={`tongcong-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} />
          {orderedUnits.map(item => {
            if (this.props.unit.information.type === "pgd") {
              return (
                <Table.Cell id={item.unitID} key={item.unitID} className={`${item.schoolType}-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>
                  {(row.value[item.unitID] !== undefined && row.value[item.unitID] !== 0) ? row.value[item.unitID] : ""}
                </Table.Cell>
              );
            }
            else if (this.props.unit.information.type === "sgd") {
              if (this.state.selectedSchoolType === "all") {
                return (
                  <Table.Cell id={item.unitID} key={item.unitID} className={`${item.schoolType}-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} />
                );
              }
              return (
                <Table.Cell id={item.unitID} key={item.unitID} className={`${item.schoolType}-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>
                  {(row.value[`${this.state.selectedSchoolType}-${item.unitID}`] !== undefined && row.value[`${this.state.selectedSchoolType}-${item.unitID}`] !== 0) ? row.value[`${this.state.selectedSchoolType}-${item.unitID}`] : ""}
                </Table.Cell>
              );
            }
            return <Table.Cell className={`${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} />;
          })}
          <Table.Cell className={`stt ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>{index + 1}</Table.Cell>
        </Table.Row>
      );
    return (
      <Table.Row key={row.key}>
        <Table.Cell key="sum" className={`tongcong-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>
          {(this.state.selectedSchoolType === "all") ?
            <FormattedNumber value={(row.value.sum !== undefined && row.value.sum !== 0) ? row.value.sum : ""} />
            :
            <FormattedNumber value={(row.value[this.state.selectedSchoolType] !== undefined && row.value[this.state.selectedSchoolType] !== 0) ? row.value[this.state.selectedSchoolType] : ""} />
          }
        </Table.Cell>
        {orderedUnits.map(item => {
          if (this.props.unit.information.type === "pgd") {
            return (
              <Table.Cell id={item.unitID} key={item.unitID} className={`${item.schoolType}-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>
                <FormattedNumber value={(row.value[item.unitID] !== undefined && row.value[item.unitID] !== 0) ? row.value[item.unitID] : ""} />
              </Table.Cell>
            );
          }
          else if (this.props.unit.information.type === "sgd") {
            if (this.state.selectedSchoolType === "all") {
              return (
                <Table.Cell id={item.unitID} key={item.unitID} className={`${item.schoolType}-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>
                  <FormattedNumber value={(row.value[item.unitID] !== undefined && row.value[item.unitID] !== 0) ? row.value[item.unitID] : ""} />
                </Table.Cell>
              );
            }
            return (
              <Table.Cell id={item.unitID} key={item.unitID} className={`${item.schoolType}-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>
                <FormattedNumber value={(row.value[`${this.state.selectedSchoolType}-${item.unitID}`] !== undefined && row.value[`${this.state.selectedSchoolType}-${item.unitID}`] !== 0) ? row.value[`${this.state.selectedSchoolType}-${item.unitID}`] : ""} />
              </Table.Cell>
            );
          }
          return <Table.Cell className={`${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} />;
        })}
        <Table.Cell className={`stt ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>{index + 1}</Table.Cell>
      </Table.Row>
    );
  }

  renderStatus = () => {
    if (this.props.data[this.props.unit.unitID] === undefined
      || this.props.data[this.props.unit.unitID][this.props.reportKey] === undefined
      || this.props.data[this.props.unit.unitID][this.props.reportKey].modifiedBy === undefined)
      return null;
    if (this.props.data[this.props.unit.unitID][this.props.reportKey].waiting === true)
      return <p><Icon loading name="spinner" /> Đang đọc dữ liệu</p>;
    return (
      <Feed>
        <Feed.Event>
          <Feed.Label>
            <Icon name="save" />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>
              Lần gần nhất báo cáo được cập nhật bởi {(this.props.data[this.props.unit.unitID][this.props.reportKey].modifiedBy.gender === "M") ? "Thầy " : "Cô "}
              <Popup
                trigger={
                  <Label as='a'>
                    <Icon name={(this.props.data[this.props.unit.unitID][this.props.reportKey].modifiedBy.gender === "M") ? "male" : "female"} />
                    {this.props.data[this.props.unit.unitID][this.props.reportKey].modifiedBy.displayName}
                  </Label>
                }
                content={this.props.data[this.props.unit.unitID][this.props.reportKey].modifiedBy.email}
              />.
              <Feed.Date>{convertTimeStamp(this.props.data[this.props.unit.unitID][this.props.reportKey].modifiedTime)}</Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    );
  }

  renderSchoolReport = () => {
    const { detail } = this.state;
    if (detail.show && detail.schema) {
      const firstDepth = [];
      _.forEach(detail.schema, col => {
        if (!col.parents || !_.get(col, 'parents[0]', ''))
          firstDepth.push({
            type: 'column',
            column: col
          });
        else if (_.findIndex(firstDepth, ele => ele.type === 'parent' && ele.name === _.get(col, 'parents[0]', '')) === -1)
          firstDepth.push({
            type: 'parent',
            name: _.get(col, 'parents[0]', '')
          });
      });
      const columns = [
        {
          columns: [
            {
              Header: () => (
                <Icon
                  size="large"
                  name="add"
                  color="green"
                  onClick={() => {
                    const initData = {};
                    _.forEach(detail.schema, col => {
                      const { key, type } = col;
                      switch (type) {
                        case 'number':
                          initData[key] = 0;
                          break;
                        default:
                          initData[key] = '';
                      }
                    });
                    this.setState({
                      detail: {
                        ...detail,
                        data: _.concat(detail.data || [], [initData]),
                        editor: {
                          index: (detail.data || []).length,
                          data: _.cloneDeep(initData)
                        }
                      }
                    });
                  }}
                />
              ),
              headerClassName: 'tableHeader',
              Cell: (row) => (
                <div style={{ height: '100%' }}>
                  <Icon
                    size="large"
                    style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                    name="edit"
                    color="blue"
                    onClick={() => this.setState({ detail: { ...detail, editor: { index: row.index, data: _.cloneDeep(_.get(detail.data || [], `[${row.index}]`, {})) } } })}
                  />
                  <Icon
                    size="large"
                    style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                    name="trash"
                    color="red"
                    onClick={() => {
                      const newData = [];
                      _.forEach(_.get(detail, 'data', []), (d, index) => {
                        if (index !== row.index)
                          newData.push(_.cloneDeep(d));
                      });
                      this.setState({
                        detail: _.set(detail, 'data', newData),
                        data: {
                          ...this.state.data,
                          [this.props.reportKey]: computeFromDetail(
                            this.state.data[this.props.reportKey],
                            this.autoComputation,
                            newData,
                            this.state.data,
                            this.logic
                          )
                        }
                      });
                    }}
                  />
                </div>
              ),
              id: 'actions'
            }
          ],
          Header: '',
          headerClassName: 'tableHeader',
          maxWidth: 100
        }
      ];
      _.forEach(firstDepth, col => {
        const { type: colType, name: parentsName, column } = col;
        if (colType === 'column') {
          const { key, name, type } = column;
          columns.push({
            columns: [
              {
                id: key,
                Header: name,
                headerClassName: 'tableHeader',
                accessor: key,
                Cell: row => {
                  if (type === 'number')
                    return <FormattedNumber value={row.value || 0} />;
                  if (type === 'bool')
                    return <Checkbox checked={!!row.value} />;
                  return <p>{row.value || ''}</p>;
                }
              }
            ],
            headerClassName: 'tableHeader',
            Header: ''
          });
        } else {
          columns.push({
            Header: parentsName,
            headerClassName: 'parentTableHeader',
            columns: _.map(_.filter(detail.schema, colSchema => _.indexOf(_.get(colSchema, 'parents', []), parentsName) !== -1), colSchema => {
              const { key, name, type } = colSchema;
              return {
                id: key,
                Header: name,
                headerClassName: 'tableHeader',
                accessor: key,
                Cell: row => {
                  if (type === 'number')
                    return <FormattedNumber value={row.value || 0} />;
                  return <p>{row.value || ''}</p>;
                }
              }
            })
          });
        }
      });
      return (
        <ReactTable
          style={(_.get(detail, 'data', []).length > 5) ? { height: 700, width: '100%' } : { width: '100%' }}
          className="-striped -highlight"
          loadingText="Đang tải..."
          noDataText="Chưa có dữ liệu"
          collapseOnDataChange={false}
          showPagination={false}
          pageSize={_.get(detail, 'data', []).length}
          data={_.get(detail, 'data', [])}
          columns={columns}
        />
      );
    }
    return (
      <Table fixed celled unstackable style={{ background: "transparent" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="row-header-school" colSpan={this.maxDepth + 1}>{this.renderStatus()}</Table.HeaderCell>
            <Table.HeaderCell className="stt-school">STT</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(this.state.rows[this.props.reportKey] !== undefined) ? this.state.rows[this.props.reportKey].map((row, index) => this.renderRow(row, index)) : null}
        </Table.Body>
      </Table>
    );
  }

  renderAdvanceReport = (orderedUnits) => (
    <div>
      {this.readerAdvanceHeader()}
      <ScrollArea
        contentStyle={{ width: orderedUnits.length * 120 + 150 }}
        verticalScrollbarStyle={{ borderRadius: 5 }}
        verticalContainerStyle={{ borderRadius: 5 }}
        horizontalScrollbarStyle={{ borderRadius: 5 }}
        horizontalContainerStyle={{ borderRadius: 5 }}
        smoothScrolling
        minScrollSize={40}
        swapWheelAxes
      >
        <Table fixed celled unstackable style={{ background: "transparent" }}>
          {(this.state.sticky) ?
            <Table.Header className="sticky">
              <Table.Row>
                <Table.HeaderCell style={{ height: "100px" }} className="tongcong">Tổng cộng</Table.HeaderCell>
                {orderedUnits.map(item => <Table.HeaderCell key={item.unitID} className={`${item.schoolType}`} onClick={() => this.showReport(item)}>{item.name}</Table.HeaderCell>)}
              </Table.Row>
            </Table.Header> : null
          }
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ height: "100px" }} className="tongcong">Tổng cộng</Table.HeaderCell>
              {orderedUnits.map(item => <Table.HeaderCell key={item.unitID} className={`${item.schoolType}`} onClick={() => this.showReport(item)}><div ref={(ref) => { this.test = ref; }}>{item.name}</div></Table.HeaderCell>)}
              {/* <Table.HeaderCell className={this.state.selectedSchoolType}>{readSchoolType(this.state.selectedSchoolType)}</Table.HeaderCell> */}
              <Table.HeaderCell className="stt" style={{ height: 110 }}>STT</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(this.state.rows[this.props.reportKey] !== undefined) ? this.state.rows[this.props.reportKey].map((row, index) => this.renderAdvanceRow(row, index, orderedUnits)) : null}
          </Table.Body>
        </Table>
      </ScrollArea>
    </div>
  )

  renderViewer = () => (
    <Modal size="fullscreen" open={this.state.showViewer} closeIcon={<Icon name="close" onClick={() => this.setState({ showViewer: false, viewer: undefined })} />}>
      <Header className="form-header" as="h3">
        <Icon name="braille" />
        <Header.Content>
          {`Đơn vị ${this.state.viewer.unit}`}
          <Header.Subheader>
            Loại hình đang xem: {readSchoolType(this.state.selectedSchoolType)}
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Modal.Content>
        <SimpleViewer
          name={this.state.name}
          description={this.state.description}
          schema={this.props.reports[this.props.unit.information.rootID][this.props.reportKey].schema}
          data={this.state.viewer.data}
          information={this.state.viewer.information}
          schoolType={this.state.selectedSchoolType}
        />
      </Modal.Content>
    </Modal>
  )

  renderDetailEditor = () => {
    const { detail } = this.state;
    if (detail.editor) {
      const { index, data } = detail.editor;
      return (
        <Modal
          className="custom"
          open
          closeIcon={(
            <Icon
              name="close"
              onClick={() => this.setState({ detail: { ...detail, editor: undefined } })}
            />
          )}
        >
          <Header
            className="form-header"
            as="h3"
            content="Nhập thông tin"
          />
          <Modal.Content scrolling>
            <Form>
              {_.map(detail.schema || [], col => {
                const { key, name, type } = col;
                let defaultValue = '';
                if (type === 'number' || type === 'bool')
                  defaultValue = 0;
                return (
                  <Form.Field key={key}>
                    <label>{name}</label>
                    {(type === 'bool') ? (
                      <Checkbox
                        checked={!!_.get(data, key, defaultValue)}
                        onChange={() => {
                          const newVal = _.get(data, key, defaultValue) ? 0 : 1;
                          this.setState({
                            detail: {
                              ...detail,
                              editor: _.set(detail.editor, `data.${key}`, newVal)
                            }
                          });
                        }}
                      />
                    ) :
                      (
                        <Input
                          value={_.get(data, key, defaultValue)}
                          onChange={(event, value) => {
                            let finalValue = value.value;
                            if (type === 'number') {
                              if (isNaN(finalValue)) {
                                this.props.errorHandler("Giá trị không hợp lệ", "Giá trị nhập vào phải là số.");
                                return;
                              }
                              this.props.workingReportHandler(this.props.reportKey, "working");
                              if (finalValue.endsWith('.'))
                                finalValue = `${parseFloat(finalValue)}.`;
                            }
                            this.setState({
                              detail: {
                                ...detail,
                                editor: _.set(detail.editor, `data.${key}`, finalValue)
                              }
                            });
                          }}
                        />
                      )
                    }
                  </Form.Field>
                )
              })}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              onClick={() => {
                this.setState({
                  detail: {
                    ...detail,
                    data: _.set(detail.data, `[${index}]`, _.cloneDeep(data)),
                    editor: undefined
                  },
                  data: {
                    ...this.state.data,
                    [this.props.reportKey]: computeFromDetail(
                      this.state.data[this.props.reportKey],
                      this.autoComputation,
                      _.get(detail, 'data', []),
                      this.state.data,
                      this.logic
                    )
                  }
                })
              }}
            >
              Tiếp tục
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  }

  renderDetail = () => {
    const { showDetail, detail } = this.state;
    if (!showDetail)
      return null;
    const { schema } = detail;
    return (
      <DetailViewer
        closeHandler={() => this.setState({ showDetail: '' })}
        schema={schema}
        data={_.get(detail, `data.${showDetail}`, [])}
      />
    );
  }

  render() {
    const {
      error,
      selectedSchoolType,
      viewer,
      detail
    } = this.state;
    if (error)
      return (
        <Message
          error
          header={error}
          list={[
            "Khởi động lại trang web hoặc truy cập vào thời điểm khác.",
            "Liên hệ đến nhân viên XBOT để được hỗ trợ.",
          ]}
        />
      );

    const { unit, data } = this.props;
    const orderedUnits = [];
    let renderReport = null;
    if (unit.information.type === "th")
      renderReport = this.renderSchoolReport();
    else {
      if (selectedSchoolType === "all") {
        orderedUnits.push({ unitID: "cl", name: "Công lập", schoolType: "cl" });
        orderedUnits.push({ unitID: "dl", name: "Dân lập", schoolType: "dl" });
        orderedUnits.push({ unitID: "tt", name: "Tư thục", schoolType: "tt" });
      } else if (data[unit.unitID].information !== undefined && this.data !== undefined && this.data[selectedSchoolType] !== undefined)
        _.keys(this.data[selectedSchoolType]).map((unitID) => {
          orderedUnits.push({ unitID, name: (data[unit.unitID].information[unitID] !== undefined) ? data[unit.unitID].information[unitID] : "", schoolType: selectedSchoolType });
          return unitID;
        });
      renderReport = this.renderAdvanceReport(orderedUnits);
    }

    this.inputs = [];
    return (
      <div style={(unit.information.type === "th" && !detail.show) ? { maxWidth: 800 } : {}}>
        {this.renderDetail()}
        {(unit.information.type === 'th' && detail.schema) ? (
          <Menu secondary color="blue">
            <Menu.Item active={!detail.show} onClick={() => this.setState({ detail: { ...detail, show: false } })}>Tổng hợp</Menu.Item>
            <Menu.Item active={detail.show} onClick={() => this.setState({ detail: { ...detail, show: true } })}>Chi tiết</Menu.Item>
          </Menu>
        ) : null
        }
        {this.renderDetailEditor()}
        {(viewer !== undefined) ? this.renderViewer() : null}
        <Header className="report-header" as="h3">
          {this.state.name}
          {this.state.description ? <Header.Subheader>{this.state.description}</Header.Subheader> : null}
        </Header>
        {(unit.information.type !== "th") ?
          <Segment stacked style={{ background: "transparent" }}>
            <Form>
              <label>Chọn loại hình trường: </label>
              <Form.Group inline>
                {schoolTypes.map((type, index) => <Form.Field key={index}><Checkbox radio label={<label className={`${type.value}-radio`}>{type.text}</label>} nane="schoolType" value={type.value} checked={type.value === this.state.selectedSchoolType} onChange={this.schoolTypeHandler} /></Form.Field>)}
              </Form.Group>
            </Form>
          </Segment>
          : null
        }
        <div style={(unit.information.type === "th") ? { marginRight: 50 } : {}}>
          {renderReport}
        </div>
        {(unit.information.type === "th") ?
          <div style={{ right: 40, position: "relative", float: "right" }}>
            <div className="action-stick">
              <Button.Group vertical icon>
                {/* <Popup trigger={<Button loading={this.state.isSaving} icon="undo" size="large" color="teal" onClick={this.undoHandler} />} content="Cập nhật nội dung mới nhất" /> */}
                <Popup trigger={<Button loading={this.state.isSaving} icon="save" size="large" color="blue" onClick={this.saveHandler} />} content="Lưu lại (chỉ nội bộ trong trường)" position="bottom right" />
              </Button.Group>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  unit: state.unit,
  reports: state.reports,
  data: state.data
});

export default connect(mapStateToProps)(Report);