import { REBOT_MILESTONE, CLEAR_MILESTONE, SIGN_OUT } from "../actions/constants";


const initialState = {
};

export default function status(state = initialState, action) {
  switch(action.type) {
    case REBOT_MILESTONE:
      return {
        ...state,
        [action.key]: action.milestone
      };
    case CLEAR_MILESTONE:
      return initialState;
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}