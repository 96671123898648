import _ from "lodash";


/**
 * Convert schools type to readable string
 *
 * @param  {String} [type] schools type
 * @return {String}
 */
export const readSchoolType = (type) => {
  switch (type) {
    case "cl":
      return "Công lập";
    case "tt":
      return "Tư thục";
    case "dl":
      return "Dân lập";
    case "all":
      return "Tổng cộng";
    default:
      return type;
  }
}

/**
 * Convert schools model to readable string
 *
 * @param  {String} [model] schools model
 * @return {String} schools type
 */
export const readSchoolModel = (model) => {
  switch (model) {
    case "mn":
      return "Trường Mầm non";
    case "mg":
      return "Trường Mẫu giáo";
    case "nt":
      return "Nhóm trẻ Độc lập";
    case "lmg":
      return "Lớp Mẫu giáo Độc lập";
    case "csmn":
      return "Cơ sở Mầm non Độc lập";
    default:
      return model;
  }
}

export const readSchoolTypeOrModel = (mixed) => {
  switch (mixed) {
    case "cl":
      return "Công lập";
    case "tt":
      return "Tư thục";
    case "dl":
      return "Dân lập";
    case "all":
      return "Tổng cộng";
    case "mn":
      return "Trường Mầm non";
    case "mg":
      return "Trường Mẫu giáo";
    case "nt":
      return "Nhóm trẻ Độc lập";
    case "lmg":
      return "Lớp Mẫu giáo Độc lập";
    case "csmn":
      return "Cơ sở Mầm non Độc lập";
    default:
      return mixed;
  }
}

export const readNationalStandard = (standard) => {
  switch (standard) {
    case "lv1":
      return "Mức độ 1";
    case "lv2":
      return "Mức độ 2";
    default:
      return standard;
  }
}

export const readEvaluationLevels = (level) => {
  switch (level) {
    case "lv1":
      return "Mức 1";
    case "lv2":
      return "Mức 2";
    case "lv3":
      return "Mức 3";
    case "lv4":
      return "Mức 4";
    default:
      return level;
  }
}

export const getNationalStandardsOptions = () => ([
  {
    key: "",
    text: "",
    value: ""
  },
  {
    key: "lv1",
    text: "Mức độ 1",
    value: "lv1"
  }, {
    key: "lv2",
    text: "Mức độ 2",
    value: "lv2"
  }
])

export const getEvaluationLevelsOptions = () => ([
  {
    key: "",
    text: "",
    value: ""
  },
  {
    key: "lv1",
    text: "Mức 1",
    value: "lv1"
  },
  {
    key: "lv2",
    text: "Mức 2",
    value: "lv2"
  },
  {
    key: "lv3",
    text: "Mức 3",
    value: "lv3"
  },
  {
    key: "lv4",
    text: "Mức 4",
    value: "lv4"
  }
])

export const getSchoolTypeOptions = (showAll = true) => {
  const condition = showAll ? [{ key: "all", text: "Tất cả", value: "all" }] : [];
  return condition.concat([{
    key: "cl",
    text: "Công lập",
    value: "cl"
  }, {
    key: "tt",
    text: "Tư thục",
    value: "tt"
  }, {
    key: "dl",
    text: "Dân lập",
    value: "dl"
  }]);
}

export const getSchoolModelOptions = (showAll = true) => {
  const condition = showAll ? [{ key: "all", text: "Tất cả", value: "all" }] : [];
  return condition.concat([{
    key: "mn",
    text: "Trường Mầm non",
    value: "mn"
  }, {
    key: "mg",
    text: "Trường Mẫu giáo",
    value: "mg"
  }, {
    key: "nt",
    text: "Nhóm trẻ Độc lập",
    value: "nt"
  }, {
    key: "lmg",
    text: "Lớp Mẫu giáo Độc lập",
    value: "lmg"
  }, {
    key: "csmn",
    text: "Cơ sở Mầm non Độc lập",
    value: "csmn"
  }]);
}

export const isNotAdminAndStaff = (information, user) => {
  if (information !== undefined) {
    if (information.admin !== undefined && information.admin === user.email)
      return false;
    if (information.staffs[user.uid] !== undefined)
      return false;
    return true;
  }
  return true;
}

export const getSchoolType = (text) => _.get(_.find(getSchoolTypeOptions(), item => _.isEqual(item.text, _.trim(text))), "key", "")
export const getSchoolModel = (text) => _.get(_.find(getSchoolModelOptions(), item => _.isEqual(item.text, _.trim(text))), "key", "")


export const isTruong = (schoolModel) => (schoolModel === "mn" || schoolModel === "mg")
export const isMN = (schoolModel) => (schoolModel === "mn")
export const isMG = (schoolModel) => (schoolModel === "mg")
export const isCSMamNonTuThucDocLap = (schoolModel) => (schoolModel === "csmn")
export const isNhomTreDocLap = schoolModel => (schoolModel === "nt")
export const isLopMGDocLap = schoolModel => (schoolModel === "lmg")
export const isCL = (schoolType) => (schoolType === "cl")
export const isDL = (schoolType) => (schoolType === "dl")
export const isTT = (schoolType) => (schoolType === "tt")
export const isCSMGNT = (schoolModel) => (schoolModel !== "mn" && schoolModel !== "mg")
export const isMGCL = (schoolModel, schoolType) => (isMG(schoolModel) && isCL(schoolType))
export const isMGTT = (schoolModel, schoolType) => (isMG(schoolModel) && isTT(schoolType))
export const isMGDL = (schoolModel, schoolType) => (isMG(schoolModel) && isDL(schoolType))
export const isMNCL = (schoolModel, schoolType) => (isMN(schoolModel) && isCL(schoolType))
export const isMNTT = (schoolModel, schoolType) => (isMN(schoolModel) && isTT(schoolType))
export const isMNDL = (schoolModel, schoolType) => (isMN(schoolModel) && isDL(schoolType))
