import { REBOT_DATA_REPORT, REBOT_DATA_READING, REBOT_DATA_READ_ERROR, SIGN_OUT, REBOT_THONGTINCHUNG, REBOT_NHANSU } from "../actions/constants";


const initialState = {
};

export default function data(state = initialState, action) {
  switch(action.type) {
    case REBOT_DATA_REPORT:
      // keep time status when update reports, remove waiting and error only
      if (state[action.unitID] !== undefined && state[action.unitID][action.reportKey])
        return {
          ...state,
          [action.unitID]: {
            ...state[action.unitID],
            [action.reportKey]: action.data
          }
        };
      // this case is unexpected since REBOT_DATA_READING should be called first
      return {
        ...state,
        [action.unitID]: {
          ...state[action.unitID],
          [action.reportKey]: action.data
        }
      };
    case REBOT_THONGTINCHUNG:
      return {
        ...state,
        thongtinchung: action.data
      };
    case REBOT_NHANSU:
      return {
        ...state,
        nhansu: action.data
      };
    case REBOT_DATA_READING:
      // show waiting status and set time
      return {
        ...state,
        [action.unitID]: {
          ...state[action.unitID],
          [action.key]: {
            waiting: true
          }
        }
      };
    case REBOT_DATA_READ_ERROR:
      // toggle waiting status and update error message
      return {
        ...state,
        [action.unitID]: {
          ...state[action.unitID],
          [action.key]: {
            error: action.error
          }
        }
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}