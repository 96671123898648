import Excel from "exceljs";
import _ from "lodash";
import {
    writeHeaders,
    writeReport,
    writeCell,
    writeRange,
    round,
} from "./common";

const theodoisuckhoenhanvien = (
    healthRecords,
    unit,
    errorHandler,
    closeHandler,
    onComplete
) => {
    const readHealth = (healthKey) => {
        switch (healthKey) {
            case "BT":
                return "Bình thường";
            case "NK":
                return "Bệnh nội khoa";
            case "M":
                return "Bệnh mắt";
            case "TMH":
                return "Bệnh tai-mũi-họng";
            case "RHM":
                return "Bệnh răng hàm mặt";
            case "DL":
                return "Bệnh da liễu";
            case "CXN":
                return "Có xét nghiệm";
            case "BK":
                return "Bệnh khác";
            default:
                return 'Bệnh khác';
        }
    };
    const mappingHealthList = {
        BT: "Sức khỏe bình thường:",
        NK: "Bệnh nội khoa",
        M: "Bệnh về mắt",
        RHM: "Bệnh về răng hàm mặt",
        TMH: "Bệnh về tai mũi họng",
        DL: "Bệnh về da liễu",
        CXN: "số người thực hiện xét nghiệm",
        BK: "Bệnh khác",
    };
    const wb = new Excel.Workbook();
    if (!_.isEmpty(healthRecords)) {
        const sheet = wb.addWorksheet('kết quả khám sức khoẻ nhân viên', {
            pageSetup: {
                paperSize: 9,
                horizontalCentered: true,
                margins: {
                    left: 1.3,
                    right: 1.3,
                    top: 1.2,
                    bottom: 1.1,
                    header: 0.7,
                    footer: 0.8,
                },
                scale: 91
            },
        });
        sheet.getColumn(2).width = 3.8;
        sheet.getColumn(3).width = 24.3;

        const healthList = [];
        const rows = {};
        const counter = {
            BT: 0,
            SDD: 0,
            [`DC-BP`]: 0,
            TuH: 0,
            HH: 0,
            TH: 0,
            [`T-TN`]: 0,
            [`TK-TT`]: 0,
            M: 0,
            RHM: 0,
            TMH: 0,
            CXK: 0,
            BK: 0,
            total: 0,
            checked: 0
        };
        counter.total = _.keys(healthRecords).length;
        counter.checked = _.filter(healthRecords, ({ healthRecords: healthData }) => healthData).length;
        _.forEach(
            healthRecords,
            ({ healthRecords: healthData, ...rest }, staffID) => {
                _.forEach(healthData, (health) => {
                    if (!healthList.includes(health)) healthList.push(health);
                    if(_.keys(counter).includes(health)) counter[health] += 1;
                });
                _.set(rows, `${staffID}`, {
                    ...rest,
                    healthData
                });
            }
        );
        const infos = [
            { title: "Trường", value: _.get(unit, 'information.name', '') },
            { title: "Năm học", value: _.get(unit, 'activeYear', '') },
        ];
        _.forEach(infos, ({ title, value }, idx) => {
            writeRange(
                sheet,
                idx + 1,
                1,
                title,
                `A${idx + 1}:B${idx + 1}`,
                {
                    bold: true,
                    horizontal: "left",
                }
            );
            writeCell(
                sheet,
                idx + 1,
                3,
                value,
                {
                    color: "FFFF0000",
                    bold: true,
                    horizontal: "left",
                });
            });
        writeRange(
            sheet,
            1,
            4,
            "TỔNG HỢP TÌNH TRẠNG SỨC KHỎE NGƯỜI LAO ĐỘNG",
            "D1:G3",
            {
                bold: true,
                fontSize: 16,
                wrapText: true
            }
        );
        writeHeaders(
            sheet,
            [{ name: "STT", width: 4.9, wrapText: true }],
            5,
            0,
            1
        );
        writeRange(
            sheet,
            5,
            2,
            "Họ và tên",
            "B5:C5",
            {
                bold: true,
                border: true,
            }
        );
        writeHeaders(
            sheet,
            [
                { name: "Ngày sinh", width: 14.3, wrapText: true },
                { name: "Nữ", width: 7.1, wrapText: true },
                { name: "Chức vụ", width: 12.8, wrapText: true },
                { name: 'Kết quả khám sức khoẻ ', width: 33.3, },
            ],
            5,
            3,
            1
        );
        writeHeaders(
            sheet,
            [{ name: 1, wrapText: true }],
            6,
            0,
            1,
            {
                fill: "FFD9D9D9",
            }
        );
        writeRange(
            sheet,
            6,
            2,
            2,
            "B6:C6",
            {
                bold: true,
                border: true,
                fill: "FFD9D9D9",
            }
        );
        writeHeaders(
            sheet,
            _.map(_.range(3, 7), (idx) => ({ name: idx })),
            6,
            3,
            1,
            { fill: "FFD9D9D9" }
        );
        let rowIndex = 7;
        let stt = 0;
        _.forEach(rows, (data) => {
            stt += 1;
            const {
                firstName,
                lastName,
                gender,
                date,
                month,
                year,
                position,
                healthData
            } = data;
            writeCell(sheet, rowIndex, 1, stt, {
                border: true,
            });
            writeRange(
                sheet,
                rowIndex,
                2,
                `${lastName} ${firstName}`,
                `B${rowIndex}:C${rowIndex}`,
                {
                    border: true,
                    horizontal: "left",
                }
            );
            writeCell(sheet, rowIndex, 4, `${date}/${month}/${year}`, {
                border: true,
            });
            writeCell(sheet, rowIndex, 5, gender !== "M" ? "Nữ" : "", {
                border: true,
            });
            writeCell(sheet, rowIndex, 6, position , {
                border: true,
            });
            writeCell(
                sheet,
                rowIndex,
                7,
                healthData
                    ? _.join(
                        healthData.map((health) =>
                            readHealth(health)
                        ),
                        ", "
                    )
                    : "",
                {
                    border: true,
                    wrapText: true,
                }
            );
            rowIndex += 1;
        });
        writeRange(
            sheet,
            rowIndex,
            2,
            "Tổng hợp",
            `B${rowIndex}:F${rowIndex}`,
            {
                border: true,
                bold: true,
            }
        );
        rowIndex += 1;
        writeRange(
            sheet,
            rowIndex,
            2,
            "Tổng số trẻ khám sức khoẻ",
            `B${rowIndex}:E${rowIndex}`,
            {
                border: true,
                bold: true,
                horizontal: "left",
            }
        );
        writeCell(
            sheet,
            rowIndex,
            6,
            counter.checked,
            {
                border: true,
                bold: true,
                horizontal: "right",
            });
        rowIndex += 1;
        writeRange(
            sheet,
            rowIndex,
            2,
            "Tỷ lệ",
            `B${rowIndex}:E${rowIndex}`,
            {
                border: true,
                bold: true,
                horizontal: "left",
            });
        const totalRatio = (counter.checked / counter.total) * 100;
        writeCell(
            sheet,
            rowIndex,
            6,
            round(totalRatio, 2),
            {
                border: true,
                bold: true,
                numberFormat: '0.00',
                horizontal: "right",
            });
        rowIndex += 1;
        _.forEach(healthList, (healthKey) => {
            writeRange(
                sheet,
                rowIndex,
                2,
                _.get(mappingHealthList, healthKey),
                `B${rowIndex}:E${rowIndex}`,
                {
                    border: true,
                    bold: true,
                    horizontal: "left",
                }
            );
            writeCell(
                sheet,
                rowIndex,
                6,
                _.get(counter, healthKey),
                {
                    border: true,
                    horizontal: "right",
                    bold: true,
                }
            );
            const ratio = (_.get(counter, healthKey) / counter.total) * 100;
            rowIndex += 1;
            writeRange(
                sheet,
                rowIndex,
                2,
                'Tỷ lệ:',
                `B${rowIndex}:E${rowIndex}`,
                {
                    border: true,
                    bold: true,
                    horizontal: "left",
                }
            );
            writeCell(
                sheet,
                rowIndex,
                6,
                round(ratio, 2),
                {
                    border: true,
                    bold: true,
                    horizontal: "right",
                    numberFormat: '0.00',
                }
            );
            rowIndex += 1;
        })
    }
    return wb.xlsx.writeBuffer().then((data) => {
        if (data && _.keys(healthRecords).length) {
            writeReport(
                new Blob([data]),
                `TheoDoiKhamSucKhoe-GV-CB-NV ${_.get(unit, 'activeYear')}.xlsx`,
                closeHandler
            );
        } else {
            errorHandler("Xuất báo cáo không thành công, vui lòng kiểm tra lại");
            onComplete();
        }
    });
};

export default theodoisuckhoenhanvien;
