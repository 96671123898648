import Excel from "exceljs";
import _ from "lodash";
import {
  writeHeaders,
  writeReport,
  writeCell,
  writeRange,
  round,
} from "./common";

import {
  readHealth
} from '../helpers';

const theodoisuckhoelop = (
  classListArr,
  healthRecords,
  unit,
  errorHandler,
  closeHandler,
  onComplete,
  pickedPeriod
) => {
  const mappingHealthList = {
    BT: "Trẻ có sức khỏe bình thường:",
    SDD: "Trẻ Suy dinh dưỡng",
    [`DC-BP`]: "Trẻ Thừa cân, béo phì",
    TuH: "Bệnh về Tuần hoàn",
    HH: "Bệnh về hô hấp",
    TH: "Bệnh về tiêu hoá",
    [`T-TN`]: "Bệnh về thận, tiết niệu",
    [`TK-TT`]: "Bệnh về thần kinh tâm thần",
    M: "Bệnh về mắt",
    RHM: "Bệnh về răng hàm mặt",
    TMH: "Bệnh về tai mũi họng",
    CXK: "Bệnh về cơ xương khớp",
    TM: 'Bệnh về tim mạch',
    BK: "Bệnh khác",
  };
  const wb = new Excel.Workbook();
  _.forEach(classListArr, ({ grade, name, classID }) => {
    const classData = _.get(healthRecords, classID);
    if (!_.isEmpty(classData)) {
      const sheet = wb.addWorksheet(`${name}`, {
        pageSetup: {
          paperSize: 9,
          horizontalCentered: true,
          margins: {
            left: 0.5,
            right: 0.5,
            top: 1.2,
            bottom: 1.1,
            header: 0.7,
            footer: 0.8,
          },
          scale: 91
        },
      });
      sheet.getColumn(2).width = 4.1;
      sheet.getColumn(3).width = 21.4;
      sheet.getRow(5).height = 25.5;
      sheet.getRow(6).height = 12.75;
      const healthList = [];
      const rows = {};
      const counter = {
        BT: 0,
        SDD: 0,
        [`DC-BP`]: 0,
        TuH: 0,
        HH: 0,
        TH: 0,
        [`T-TN`]: 0,
        [`TK-TT`]: 0,
        M: 0,
        RHM: 0,
        TMH: 0,
        CXK: 0,
        BK: 0,
        TM: 0,
        total: 0,
        checked: 0
      };
      if(pickedPeriod === 'lan2') {
        if (_.has(classData, 'lan2')) {
          const classCount = _.get(classData, "lan2");
          const { students } = classCount;
          counter.total = _.keys(students).length;
          counter.checked = _.filter(students, ({ healthRecords: healthData } ) => healthData).length;
          _.forEach(students, ({ healthRecords: healthData }) => {
            _.forEach(healthData, (health) => {
              if (_.keys(counter).includes(health)) counter[health] += 1;
            });
          });
        } else {
          const classCount = _.get(classData, "lan1");
          const { students } = classCount;
          counter.total = _.keys(students).length;
          counter.checked = _.filter(students, ({ healthRecords: healthData } ) => healthData).length;
          _.forEach(students, ({ healthRecords: healthData }) => {
            _.forEach(healthData, (health) => {
              if (_.keys(counter).includes(health)) counter[health] += 1;
            });
          });
        }
      } else {
        const classCount = _.get(classData, "lan1");
          const { students } = classCount;
          counter.total = _.keys(students).length;
          counter.checked = _.filter(students, ({ healthRecords: healthData } ) => healthData).length;
          _.forEach(students, ({ healthRecords: healthData }) => {
            _.forEach(healthData, (health) => {
              if (_.keys(counter).includes(health)) counter[health] += 1;
            });
          });
      }
      if(pickedPeriod === 'lan2') {
        _.forEach(classData, (classHealthRecord, period) => {
          if (classHealthRecord) {
            const { students, day } = classHealthRecord;
            _.forEach(
              students,
              ({ healthRecords: healthData, ...rest }, studentID) => {
                _.forEach(healthData, (health) => {
                  if (!healthList.includes(health)) healthList.push(health);
                });
                _.set(rows, `${studentID}.info`, rest);
                _.set(rows, `${studentID}.${period}`, {
                  healthData,
                  day,
                });
              }
            );
          }
        });
      } else {
        const classHealthRecord = _.get(classData, 'lan1');
        if (classHealthRecord) {
          const { students, day } = classHealthRecord;
          _.forEach(
            students,
            ({ healthRecords: healthData, ...rest }, studentID) => {
              _.forEach(healthData, (health) => {
                if (!healthList.includes(health)) healthList.push(health);
              });
              _.set(rows, `${studentID}.info`, rest);
              _.set(rows, `${studentID}.lan1`, {
                healthData,
                day,
              });
            }
          );
        }
      }
      const infos = [
        { title: "Trường", value: _.get(unit, 'information.name', '') },
        { title: "Lớp", value: name },
        { title: "Năm học", value: _.get(unit, 'activeYear', '') },
      ];
      _.forEach(infos, ({ title, value }, idx) => {
        writeRange(sheet, idx + 1, 1, title, `A${idx + 1}:B${idx + 1}`, {
          bold: true,
          horizontal: "left",
        });
        writeCell(sheet, idx + 1, 3, value, {
          color: "FFFF0000",
          bold: true,
          horizontal: "left",
        });
      });
      const { activeYear } = unit;
      const firstDay = _.get(_.get(classData, "lan1"), 'day', '');
      const secondDay = _.get(_.get(classData, "lan2"), 'day', '');
      const firstYear = parseFloat(activeYear);
      const secondYear = parseFloat(activeYear)  + 1;
      writeRange(
        sheet,
        1,
        4,
        "THEO DÕI TÌNH TRẠNG SỨC KHỎE HỌC SINH",
        "D1:G3",
        {
          bold: true,
          fontSize: 16,
        }
      );
      writeHeaders(
        sheet,
        [{ name: "STT", width: 4.8, wrapText: true }],
        5,
        0,
        1
      );
      writeRange(
        sheet,
        5,
        2,
        "Họ và tên",
        "B5:C5",
        {
          bold: true,
          border: true,
        }
      );
      writeHeaders(
        sheet,
        [
          { name: "Ngày sinh", width: 12.8, wrapText: true },
          { name: "Nữ", width: 4.8, wrapText: true },
          { name: `Kết quả khám sức khoẻ lần 1 ${firstDay ? `ngày ${firstDay}/9/${firstYear}` : ''}`, width: 25.7, wrapText: true },
          { name: `Kết quả khám sức khoẻ lần 2 ${secondDay ? `ngày ${secondDay}/5/${secondYear}` : ''}`, width: 25.7, wrapText: true },
        ],
        5,
        3,
        1
      );
      writeHeaders(sheet, [{ name: 1, wrapText: true }], 6, 0, 1, {
        fill: "FFD9D9D9",
      });
      writeRange(sheet, 6, 2, 2, "B6:C6", {
        bold: true,
        border: true,
        fill: "FFD9D9D9",
      });
      writeHeaders(
        sheet,
        _.map(_.range(3, 7), (idx) => ({ name: idx })),
        6,
        3,
        1,
        { fill: "FFD9D9D9" }
      );
      let rowIndex = 7;
      let stt = 0;
      _.forEach(rows, (data) => {
        stt += 1;
        const {
          info: { firstName, lastName, gender, date, month, year },
        } = data;
        const firstHealthRecord = _.get(data, "lan1");
        const secondHealthRecord = _.get(data, "lan2");
        writeCell(sheet, rowIndex, 1, stt, {
          border: true,
        });
        writeRange(
          sheet,
          rowIndex,
          2,
          `${lastName} ${firstName}`,
          `B${rowIndex}:C${rowIndex}`,
          {
            border: true,
            horizontal: "left",
          }
        );
        writeCell(sheet, rowIndex, 4, `${date}/${month}/${year}`, {
          border: true,
        });
        writeCell(sheet, rowIndex, 5, gender !== "M" ? "Nữ" : "", {
          border: true,
        });
        writeCell(
          sheet,
          rowIndex,
          6,
          firstHealthRecord && firstHealthRecord.healthData
            ? _.join(
                firstHealthRecord.healthData.map((health) =>
                  readHealth(health)
                ),
                ", "
              )
            : "",
          {
            border: true,
            wrapText: true,
          }
        );
        writeCell(
          sheet,
          rowIndex,
          7,
          secondHealthRecord && secondHealthRecord.healthData
            ? _.join(
                secondHealthRecord.healthData.map((health) =>
                  readHealth(health)
                ),
                ", "
              )
            : "",
          {
            border: true,
            wrapText: true,
          }
        );
        rowIndex += 1;
      });
      writeRange(
        sheet,
        rowIndex,
        2,
        "Tổng hợp",
        `B${rowIndex}:F${rowIndex}`,
        {
          border: true,
          bold: true,
        }
      );
      rowIndex += 1;
      writeRange(
        sheet,
        rowIndex,
        2,
        "Tổng số trẻ khám sức khoẻ",
        `B${rowIndex}:E${rowIndex}`,
        {
          border: true,
          bold: true,
          horizontal: "left",
        }
      );
      writeCell(
        sheet, 
        rowIndex, 
        6,
        counter.checked,
        {
          border: true,
          bold: true,
          horizontal: 'right'
        });
      rowIndex += 1;
      writeRange(
        sheet, 
        rowIndex, 
        2, 
        "Tỷ lệ", 
        `B${rowIndex}:E${rowIndex}`,
        {
          border: true,
          bold: true,
          horizontal: "left",
        });
      const totalRatio = (counter.checked / counter.total) * 100;
      writeCell(
        sheet, 
        rowIndex, 
        6,
        round(totalRatio, 2),
        {
          border: true,
          bold: true,
          numberFormat: '0.00',
          horizontal: 'right'
        });
      rowIndex += 1;

      _.forEach(healthList, (healthKey) => {
          writeRange(
            sheet,
            rowIndex,
            2,
            _.get(mappingHealthList, healthKey),
            `B${rowIndex}:E${rowIndex}`,
            { 
              border: true,
              bold: true,
              horizontal: "left",
            }
          );
          writeCell(
            sheet,
            rowIndex,
            6,
            _.get(counter, healthKey),
            { 
              border: true,
              bold: true,
              horizontal: 'right'
            }
          );
          const ratio = (_.get(counter, healthKey) / counter.total) * 100;
          rowIndex += 1;
          writeRange(
            sheet,
            rowIndex,
            2,
            'Tỷ lệ:',
            `B${rowIndex}:E${rowIndex}`,
            { 
              border: true,
              bold: true,
              horizontal: "left",
            }
          );
          writeCell(
            sheet,
            rowIndex,
            6,
            round(ratio, 2),
            { 
              border: true,
              bold: true,
              numberFormat: '0.00',
              horizontal: 'right'

            }
          );
          rowIndex += 1;
      })
    }
  });
  return wb.xlsx.writeBuffer().then((data) => {
    if (data && _.keys(classListArr).length) {
      writeReport(
        new Blob([data]),
        `TheoDoiKhamSucKhoe - Lop ${_.get(unit, 'activeYear', '')}.xlsx`,
        closeHandler
      );
    } else {
      errorHandler("Xuất báo cáo không thành công, vui lòng kiểm tra lại");
      onComplete();
    }
  });
};

export default theodoisuckhoelop;
