import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Button, Divider, TextArea } from "semantic-ui-react";


export default class MilestoneForm extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    updateHandler: PropTypes.func,
    insertHandler: PropTypes.func,
    milestoneID: PropTypes.string,
    description: PropTypes.string
  };

  static defaultProps = {
    milestoneID: "",
    description: "",
    updateHandler: () => {},
    insertHandler: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      content: this.props.description
    };
  }

  render() {
    return (
      <div style={{margin: "auto", right: 0, left: 0, borderRadius: 3}}>
        <Form>
          <Form.Field required>
            <label>Mô tả</label>
            <TextArea 
              placeholder="Mô tả của đợt nộp báo cáo" 
              value={this.state.content}
              onChange={(e, { value }) => this.setState({content: value})}
            />
          </Form.Field>
          <Divider />        
          <center style={{float: "right", paddingBottom: 10}}>
            {(this.props.description === "")?
              <Button basic color="brown" onClick={() => this.props.insertHandler(this.state.content)}>Tạo mới</Button> 
              :
              <Button basic color="brown" onClick={() => this.props.updateHandler(this.props.milestoneID, this.state.content)}>Cập nhật</Button> 
            }
            <Button basic color="grey" onClick={this.props.closeHandler}>Đóng</Button> 
          </center>
        </Form>
      </div>
    );
  }
}