import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// components
import Eduunits from "./eduunits";
import UserHeader from "../../components/UserHeader";
import CanDo from "./CanDo";

// services
import { db } from "../../libs/firebase";
import { userAdmin } from "../../redux/actions/users";


class Xbot extends Component {
  static propTypes = {
    userAdmin: PropTypes.func.isRequired,
    user: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      gender: PropTypes.string,
      admin: PropTypes.bool
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      error: "",
      menuActive: "units"
    };
  }

  componentWillMount() {
    db.ref(`staffs/${this.props.user.uid}`).once("value", (snapshot) => {
      if (snapshot === null || snapshot.val() === null)
        this.setState({
          error: `Chưa được cấu hình là tài khoản nhân viên. Xin liên hệ admin cùng với mã tài khoản để được cấu hình. Mã Tài Khoản: ${this.props.user.uid}`
        });
    }, () => {
      this.setState({
        error: `Chưa được cấu hình là tài khoản nhân viên. Xin liên hệ admin cùng với mã tài khoản để được cấu hình. Mã Tài Khoản: ${this.props.user.uid}`
      });
    });
    db.ref(`admins/${this.props.user.uid}`).once("value", (snapshot) => {
      if (snapshot !== null && snapshot.val() !== null)
        this.props.userAdmin();
    });
  }

  profileHandler = () => {
    this.notificationSystem.addNotification({
      title: "Thất bại",
      message: "Tính năng này không hỗ trợ ở màn hình quản lý.",
      level: "error",
      position: "tr",
      autoDismiss: 4
    });
  }

  errorHandler = (message) => {
    this.notificationSystem.addNotification({
      title: "Lỗi",
      message,
      level: "error",
      position: "tr",
      autoDismiss: 4
    });
  }

  successHandler = (message) => {
    this.notificationSystem.addNotification({
      title: "Hoàn tất",
      message,
      level: "success",
      position: "tr",
      autoDismiss: 4
    });
  }

  unitsHandler = () => {
    this.setState({
      menuActive: "units"
    });
  }

  renderMenu = () => (
    <Menu className="smallwrap form-header" secondary pointing icon="labeled" color="brown">
      <Menu.Item name="units" active={this.state.menuActive === "units"} onClick={this.unitsHandler}>
        <Icon name="write" />Đơn vị
      </Menu.Item>
      {this.props.user.admin?
        <Menu.Item name="cando" active={this.state.menuActive === "cando"} onClick={() => this.setState({ menuActive: "cando" })}>
          <Icon name="chart line" />Số liệu cân đo
        </Menu.Item> : null
      }
      <Menu.Menu position="right">
        <Menu.Item><UserHeader profileHandler={this.profileHandler} /></Menu.Item>
      </Menu.Menu>
    </Menu>
  )

  render() {
    if (this.state.error !== "")
      return <div>{this.state.error}</div>;
    return (
      <div>
        {this.renderMenu()}
        <div className="smallwrap">
          {(this.state.menuActive === "units")? <Eduunits /> : null}
          {(this.state.menuActive === "cando")? <CanDo /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ userAdmin }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Xbot);