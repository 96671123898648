import _ from "lodash";

import { CANDO, CANDO_CLASS, SIGN_OUT } from "../actions/constants";


const initialState = {
};

export default function year(state = initialState, action) {
  switch (action.type) {
    case CANDO:
      return {
        ...state,
        [action.year]: action.classes
      };
    case CANDO_CLASS:
      return {
        ...state,
        [action.year]: {
          ..._.get(state, action.year, {}),
          classes: {
            ..._.get(state, `${action.year}.classes`, {}),
            [action.id]: {
              ..._.get(state, `${action.year}.classes.${action.id}`, {}),
              content: action.data
            }
          }
        }
      }
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}