/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Table,
  Header,
  Segment,
  Form,
  Checkbox,
  Button,
} from "semantic-ui-react";
import { FormattedNumber } from "react-intl";
import ScrollArea from "react-scrollbar";
import _ from "lodash";

import DetailViewer from './DetailViewer';

import { getSchoolTypeOptions } from "../../libs/school";


const schoolTypes = getSchoolTypeOptions(true);

export default class Item extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    schema: PropTypes.array.isRequired,
    data: PropTypes.object,
    detail: PropTypes.shape({
      schema: PropTypes.arrayOf(PropTypes.object),
      data: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    information: PropTypes.object,
    unitType: PropTypes.string.isRequired
  };

  static defaultProps = {
    information: undefined,
    data: undefined
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedSchoolType: "all",
      sticky: false
    }
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);

    this.load(this.props.schema, this.props.data);
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps.schema, nextProps.data);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.headerRef !== undefined && this.headerRef.getBoundingClientRect().top - window.pageYOffset < -500) {
      this.setState({ sticky: true });
    } else
      this.setState({ sticky: false });
  }

  load = (schema, data) => {
    if (schema) {
      this.maxDepth = 0;
      const rows = this.extractNode(schema, data, 0);
      this.setState({ rows });
    }
  }

  extractNode = (node, data, depth) => {
    if (node.permission !== undefined && node.permission.indexOf(this.props.unitType) === -1)
      return [];
    if (this.maxDepth < depth)
      this.maxDepth = depth;

    if (node.key !== undefined) {
      let value;
      if (this.props.unitType === "th")
        value = _.get(data, node.key, "");
      else {
        value = {
          cl: (node.type === "text") ? "" : 0,
          dl: (node.type === "text") ? "" : 0,
          tt: (node.type === "text") ? "" : 0
        };
        ["cl", "dl", "tt"].forEach(schoolType => {
          if (data !== undefined && schoolType !== undefined && data[schoolType] !== undefined) {
            Object.keys(data[schoolType]).forEach(unitID => {
              value[unitID] = _.get(data[schoolType][unitID], node.key, (node.type === "text") ? "" : 0);
              if (node.type !== "text")
                value[schoolType] += value[unitID];
            })
          }
        });
        if (node.type !== "text")
          value.sum = value.cl + value.dl + value.tt;
      }
      return [{
        parents: [],
        header: node.name,
        key: node.key,
        type: node.type,
        value,
        depth
      }];
    }

    const rows = [];
    Object.keys(node).forEach((item) => {
      if (item === "name" || item === "permission")
        return;
      const tmp = this.extractNode(node[item], data, depth + 1);
      if (tmp.length > 0)
        rows.push(...tmp);
    });
    if (rows.length > 0 && node.name !== undefined) {
      rows[0].parents.unshift({
        name: node.name,
        span: rows.length
      });
    }

    return rows;
  }

  schoolTypeHandler = (e, { value }) => {
    this.setState({ selectedSchoolType: value });
  }

  readerAdvanceHeader = () => (
    <Table fixed celled unstackable style={{ width: "400px", float: "left" }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="row-header" colSpan={this.maxDepth}><div ref={(ref) => { this.headerRef = ref; }} /></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.rows.map((row) => this.renderAdvanceRowHeader(row))}
      </Table.Body>
    </Table>
  )

  renderAdvanceRowHeader = (row) => {
    const span = (this.maxDepth > row.depth) ? (this.maxDepth - row.depth + 1) : 1;
    return (
      <Table.Row key={row.key}>
        {row.parents.map((parent, k) => <Table.Cell key={k} className={`column-header ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1" rowSpan={parent.span}>{parent.name}</Table.Cell>)}
        <Table.Cell className={`column-header ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1" colSpan={span}>{row.header}</Table.Cell>
      </Table.Row>
    );
  }

  renderRow = (row, index) => {
    const span = (this.maxDepth > row.depth) ? (this.maxDepth - row.depth + 1) : 1;
    return (
      <Table.Row key={row.key}>
        {row.parents.map((parent, k) => <Table.Cell key={k} className={`column-header-school ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="2" rowSpan={parent.span}>{parent.name}</Table.Cell>)}
        <Table.Cell className={`column-header-school ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1" colSpan={span}>{row.header}</Table.Cell>
        <Table.Cell className={`${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} id={row.key} width="1" >
          {(row.type === "text") ? row.value : <FormattedNumber value={(row.value !== 0) ? row.value : ""} />}
        </Table.Cell>
        <Table.Cell className={`stt-school ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1">{index + 1}</Table.Cell>
      </Table.Row>
    );
  }

  renderAdvanceRow = (row, index, orderedUnits) => (
    <Table.Row key={row.key}>
      {(row.type === "text") ? <Table.Cell key="sum" className={`tongcong-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} /> : (
        <Table.Cell key="sum" className={`tongcong-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`}>
          {(this.state.selectedSchoolType === "all") ?
            <FormattedNumber value={(row.value.sum !== undefined && row.value.sum !== 0) ? row.value.sum : ""} />
            :
            <FormattedNumber value={(row.value[this.state.selectedSchoolType] !== undefined && row.value[this.state.selectedSchoolType] !== 0) ? row.value[this.state.selectedSchoolType] : ""} />
          }
        </Table.Cell>
      )}
      {orderedUnits.map(item => (
        <Table.Cell id={item.unitID} key={item.unitID} className={`${item.schoolType}-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1">
          {(row.type === "text") ? _.get(row.value, item.unitID, "") : (
            <FormattedNumber
              value={(row.value[item.unitID] !== undefined && row.value[item.unitID] !== 0) ? row.value[item.unitID] : ""}
            />
          )}
        </Table.Cell>
      ))}
      <Table.Cell className={`stt ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1">{index + 1}</Table.Cell>
    </Table.Row>
  )

  renderSchoolReport = () => (
    <Table celled fixed unstackable style={{ background: "transparent" }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="row-header-school" colSpan={this.maxDepth + 1} />
          <Table.HeaderCell className="stt-school">STT</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.rows.map((row, index) => this.renderRow(row, index))}
      </Table.Body>
    </Table>
  )

  renderAdvanceReport = (orderedUnits) => (
    <div>
      {this.readerAdvanceHeader()}
      <ScrollArea
        contentStyle={{ width: orderedUnits.length * 120 + 150 }}
        verticalScrollbarStyle={{ borderRadius: 5 }}
        verticalContainerStyle={{ borderRadius: 5 }}
        horizontalScrollbarStyle={{ borderRadius: 5 }}
        horizontalContainerStyle={{ borderRadius: 5 }}
        smoothScrolling
        minScrollSize={40}
        onScroll={this.handleScroll}
        swapWheelAxes
      >
        <Table fixed celled unstackable style={{ background: "transparent" }}>
          {(this.state.sticky) ?
            <Table.Header className="sticky">
              <Table.Row>
                <Table.HeaderCell style={{ height: "100px" }} className="tongcong">Tổng cộng</Table.HeaderCell>
                {orderedUnits.map(item => <Table.HeaderCell key={item.unitID} className={`${item.schoolType}`}>{item.name}</Table.HeaderCell>)}
              </Table.Row>
            </Table.Header> : null
          }
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ height: "100px" }} className="tongcong">Tổng cộng</Table.HeaderCell>
              {orderedUnits.map(item => <Table.HeaderCell key={item.unitID} className={`${item.schoolType}`}>{item.name}</Table.HeaderCell>)}
              {/* <Table.HeaderCell className={this.state.selectedSchoolType}>{readSchoolType(this.state.selectedSchoolType)}</Table.HeaderCell> */}
              <Table.HeaderCell className="stt" style={{ height: 110 }}>STT</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.rows.map((row, index) => this.renderAdvanceRow(row, index, orderedUnits))}
          </Table.Body>
        </Table>
      </ScrollArea>
    </div>
  )

  renderDetail = () => {
    const { showDetail } = this.state;
    if (!showDetail)
      return null;
    const { detail } = this.props;
    return (
      <DetailViewer
        closeHandler={() => this.setState({ showDetail: false })}
        schema={detail.schema}
        data={_.get(detail, 'data', [])}
      />
    );
  }

  render() {
    const {
      unitType,
      information,
      data,
      detail
    } = this.props;
    const orderedUnits = [];
    if (unitType !== "th") {
      if (this.state.selectedSchoolType === "all") {
        orderedUnits.push({ unitID: "cl", name: "Công lập", schoolType: "cl" });
        orderedUnits.push({ unitID: "dl", name: "Dân lập", schoolType: "dl" });
        orderedUnits.push({ unitID: "tt", name: "Tư thục", schoolType: "tt" });
      } else if (information !== undefined && data !== undefined && data[this.state.selectedSchoolType] !== undefined) {
        Object.keys(data[this.state.selectedSchoolType]).map((unitID) => {
          orderedUnits.push({ unitID, name: (information[unitID] !== undefined) ? information[unitID] : "", schoolType: this.state.selectedSchoolType });
          return unitID;
        });
      }
    }
    return (
      <div style={(this.props.unitType === "th") ? { maxWidth: 800 } : {}}>
        {(unitType === 'th' && detail.schema && _.get(detail, 'data', []).length) ? (
          <Button
            color="teal"
            onClick={() => this.setState({ showDetail: true })}
          >
            Xem danh sách
          </Button>
        ) : null}
        {this.renderDetail()}
        <Header className="report-header" as="h3">{this.props.name}</Header>
        {(this.props.unitType !== "th") ?
          <Segment stacked>
            <Form>
              <label>Chọn loại hình trường: </label>
              <Form.Group inline>
                {schoolTypes.map((type, index) => <Form.Field key={index}><Checkbox radio label={<label className={`${type.value}-radio`}>{type.text}</label>} nane="schoolType" value={type.value} checked={type.value === this.state.selectedSchoolType} onChange={this.schoolTypeHandler} /></Form.Field>)}
              </Form.Group>
            </Form>
          </Segment>
          : null
        }
        {(this.props.unitType === "th") ? this.renderSchoolReport() : this.renderAdvanceReport(orderedUnits)}
      </div>
    );
  }
}