import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Button, Input, Dimmer, Loader, Divider, TextArea, Popup } from "semantic-ui-react";
import Calendar from "react-calendar";
import moment from "moment";


export default class DeadlineForm extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    updateHandler: PropTypes.func.isRequired,
    selectedDate: PropTypes.number,
    deadline: PropTypes.number
  };

  static defaultProps = {
    selectedDate: undefined,
    deadline: undefined
  }

  constructor(props) {
    super(props);

    this.state = {
      content: "Gửi báo cáo",
      deadline: (this.props.selectedDate !== undefined)? new Date(this.props.selectedDate) : new Date(),
      time: "17:00"
    };
  }

  getDateColor = (date) => {
    const d1 = new Date(date);
    const tmp = new Date(this.props.selectedDate);
    if (tmp !== undefined && d1.getFullYear() === tmp.getFullYear() && d1.getMonth() === tmp.getMonth() && d1.getDate() === tmp.getDate() )
      return "#559FFF";
    return "rgb(146, 81, 39)";
  }

  render() {
    return (
      <div style={{margin: "auto", right: 0, left: 0, borderRadius: 3}}>
        <center>
          {this.state.isWaiting? 
            <Dimmer active inverted>
              <Loader inverted>Đang kết nối...</Loader>
            </Dimmer> 
            : null
          }
        </center>
        <Form>
          <Form.Field required>
            <label>Nội dung đính kèm</label>
            <TextArea 
              placeholder="Nội dung đính kèm thông báo nộp báo cáo" 
              value={this.state.content}
              onChange={(e, { value }) => this.setState({content: value})}
            />
          </Form.Field>
          <Form.Field>
            <label>Hạn nộp</label>
            <Popup 
              trigger={
                <Input
                  value={moment(this.state.deadline).format("D-M-YYYY")}
                  icon="calendar"
                  placeholder="Ngày"
                  iconPosition="left"
                />
              } 
              on="click"
              hideOnScroll
              position="left center"
            >
              <Calendar
                minDate={new Date()}
                maxDate={(this.props.deadline !== undefined)? new Date(this.props.deadline) : undefined}
                minDetail="year"
                value={this.state.deadline}
                onChange={(date) => this.setState({ deadline: date })}
                locale="vi-VI"
              />
            </Popup>
            <Input
              value={this.state.time}
              onChange={(e, { value }) => this.setState({time: value})}
              icon="clock"
              placeholder="Giờ:Phút"
              iconPosition="left"
            />
          </Form.Field>

          <Divider />
           
          <center style={{float: "right", paddingBottom: 10}}>
            {this.state.isWaiting?
              <Button secondary>Đang cập nhật...</Button> 
              :
              <Button basic color="brown" onClick={() => this.props.updateHandler(this.state.content, this.state.deadline, this.state.time)}>Cập nhật</Button> 
            }
            <Button basic color="grey" onClick={this.props.closeHandler}>Đóng</Button> 
          </center>
        </Form>
      </div>
    );
  }
}