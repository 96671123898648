/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dimmer, Input, Button, Divider, Form, Loader, Dropdown, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

// services
import { eventTracking } from "../../libs/ga";
import { db } from "../../libs/firebase";
import { readSchoolModel, readSchoolType, getNationalStandardsOptions, getEvaluationLevelsOptions, isCL } from "../../libs/school";
import { unitFetchInformation } from "../../redux/actions/units";


const cities = require("../../libs/cities.json");

const standardsOptions = getNationalStandardsOptions();
const levelsOptions = getEvaluationLevelsOptions();

class ThongTinTruong extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    unitFetchInformation: PropTypes.func.isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        note: PropTypes.string,
        phone: PropTypes.string,
        type: PropTypes.string.isRequired,
        ward: PropTypes.string,
        ward2: PropTypes.string,
        schoolModel: PropTypes.string,
        schoolType: PropTypes.string,
        duoi7tre: PropTypes.bool,
        chuacapphep: PropTypes.bool,
        xadatchuan: PropTypes.bool,
        xakhokhan: PropTypes.bool,
        xanongthonmoi: PropTypes.bool,
        nationalStandard: PropTypes.shape({
          level: PropTypes.string.isRequired,
          year: PropTypes.string.isRequired,
          new: PropTypes.bool.isRequired
        }),
        evaluation: PropTypes.shape({
          selfEvaluated: PropTypes.string.isRequired,
          evaluated: PropTypes.string.isRequired
        }),
        admin: PropTypes.string,
        license: PropTypes.string,
        operationCert: PropTypes.string,
        industrialZone: PropTypes.bool

      }).isRequired
    }).isRequired,
    allowEdit: PropTypes.bool
  };

  static defaultProps = {
    allowEdit: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      name: this.props.unit.information.name,
      address: this.props.unit.information.address,
      admin: _.get(this.props.unit.information, "admin", ""),
      note: _.get(this.props.unit.information, "note", ""),
      phone: _.get(this.props.unit.information, "phone", ""),
      xadatchuan: _.get(this.props.unit.information, "xadatchuan", ""),
      xakhokhan: _.get(this.props.unit.information, "xakhokhan", ""),
      xanongthonmoi: _.get(this.props.unit.information, "xanongthonmoi", ""),
      nationalStandard: _.get(this.props.unit.information, "nationalStandard", undefined),
      evaluation: _.get(this.props.unit.information, "evaluation", undefined),
      license: _.get(this.props.unit.information, "license", ""),
      operationCert: _.get(this.props.unit.information, "operationCert", ""),
      industrialZone: _.get(this.props.unit.information, "industrialZone", false)
    };
  }

  validateInput = () => {
    if (this.state.name === "") {
      this.props.errorHandler("Tên đơn vị không được bỏ trống.");
      return false;
    }
    if (this.state.address === "") {
      this.props.errorHandler("Địa chỉ đơn vị không được bỏ trống.");
      return false;
    }
    return true;
  }

  saveHandler = () => {
    if (this.state.isSaving || !this.validateInput())
      return;
    this.setState({ isSaving: true });

    const { schoolType } = this.props.unit.information;

    const updateContent = {
      name: this.state.name,
      admin: this.state.admin,
      address: this.state.address,
      phone: this.state.phone,
      note: this.state.note,
      xadatchuan: _.get(this.state, "xadatchuan", false),
      xakhokhan: _.get(this.state, "xakhokhan", false),
      xanongthonmoi: _.get(this.state, "xanongthonmoi", false)
    };

    if (!isCL(schoolType)) {
      updateContent.license = _.get(this.state, "license", "");
      updateContent.operationCert = _.get(this.state, "operationCert", "");
      updateContent.industrialZone = _.get(this.state, "industrialZone", false);
    }

    if (this.state.nationalStandard)
      updateContent.nationalStandard = this.state.nationalStandard;
    if (this.state.evaluation)
      updateContent.evaluation = this.state.evaluation;

    eventTracking("report", "thongtintruong", 1, this.props.unit.unitID);
    const successHandler = (error) => {
      this.setState({ isSaving: false });
      if (error)
        this.props.errorHandler(error.message || 'Lỗi');
      else {
        db.ref(`eduunits/${this.props.unit.unitID}`).once("value", (value) => {
          if (value.val() !== null)
            this.props.unitFetchInformation(this.props.unit.unitID, value.val());
        });
        this.props.successHandler("Cập nhật hồ sơ thành công");
      }
    };
    db.ref(`eduunits/${this.props.unit.unitID}`).update(updateContent, (error) => {
      if (!this.state.evaluation) {
        db.ref(`eduunits/${this.props.unit.unitID}/evaluation`).remove(() => {
          if (!this.state.nationalStandard) {
            db.ref(`eduunits/${this.props.unit.unitID}/nationalStandard`).remove(() => {
              successHandler(error);
            });
          } else
            successHandler(error);
        });
      } else if (!this.state.nationalStandard) {
        db.ref(`eduunits/${this.props.unit.unitID}/nationalStandard`).remove(() => {
          successHandler(error);
        });
      } else
        successHandler(error);
    });
  }

  renderAddress = (ward, ward2 = '') => (
    <Form.Group>
      <Form.Field inline>
        <label>Tỉnh: </label>
        <p style={{ fontWeight: "normal" }}>{cities[ward.slice(0, 2)].name}</p>
      </Form.Field>
      <Form.Field inline>
        <label>Quận/Huyện: </label>
        <p style={{ fontWeight: "normal" }}>{cities[ward.slice(0, 2)].districts[ward.slice(0, 6)].name}</p>
      </Form.Field>
      <Form.Field inline>
        <label>Phường/Xã: </label>
        <p style={{ fontWeight: "normal" }}>{cities[ward.slice(0, 2)].districts[ward.slice(0, 6)].wards[ward].name}</p>
      </Form.Field>
      {((cities[ward2.slice(0, 2)])?.districts[ward2.slice(0, 6)]?.wards[ward2]?.name !== undefined) ? (
        <Form.Field inline>
          <label>Liên xã: </label>
          <p style={{ fontWeight: "normal" }}>{cities[ward2.slice(0, 2)].districts[ward2.slice(0, 6)].wards[ward2].name}</p>
        </Form.Field>
      ) : null}
    </Form.Group>
  )

  renderNationalStandard = () => {
    if (this.state.nationalStandard === undefined)
      return (
        <Form.Group inline>
          <Form.Checkbox
            disabled={!this.props.allowEdit}
            checked={false}
            label="Trường đạt chuẩn quốc gia"
            onChange={() => this.setState({ nationalStandard: { level: "", year: "", new: false } })}
          />
        </Form.Group>
      );
    return (
      <div>
        <Form.Checkbox
          disabled={!this.props.allowEdit}
          checked
          label="Trường đạt chuẩn quốc gia"
          onChange={() => this.setState({ nationalStandard: undefined })}
        />
        <Form.Field>
          <label>Mức đạt chuẩn: </label>
          <Dropdown
            search
            selection
            fluid
            multiple={false}
            options={standardsOptions}
            value={this.state.nationalStandard.level}
            onChange={(e, { value }) => this.setState({ nationalStandard: { ...this.state.nationalStandard, level: value } })}
            placeholder="Chọn mức đạt chuẩn"
          />
        </Form.Field>
        <Form.Group inline>
          <Form.Field>
            <label>Năm công nhận: </label>
            <Input
              style={{ maxWidth: 70 }}
              disabled={!this.props.allowEdit}
              placeholder=""
              value={this.state.nationalStandard.year}
              onChange={(event, { value }) => {
                if (isNaN(value)) {
                  this.props.errorHandler("Chỉ nhập năm bắt đầu của năm học. Phải là định dạng số.");
                  return;
                }
                this.setState({ nationalStandard: { ...this.state.nationalStandard, year: value } })
              }}
            />
            {' '}
            <p>{(this.state.nationalStandard.year !== "" && !isNaN(this.state.nationalStandard.year)) ? ` - ${parseFloat(this.state.nationalStandard.year) + 1}` : ""}</p>
          </Form.Field>
          <Form.Checkbox
            disabled={!this.props.allowEdit}
            checked={this.state.nationalStandard.new}
            label="Công nhận mới trong năm học"
            onChange={() => this.setState({ nationalStandard: { ...this.state.nationalStandard, new: !this.state.nationalStandard.new } })}
          />
        </Form.Group>
      </div>
    );
  }

  renderEvaluation = () => {
    if (!this.state.evaluation)
      return (
        <Form.Group inline>
          <Form.Checkbox
            disabled={!this.props.allowEdit}
            checked={false}
            label="Kiểm định chất lượng Giáo dục"
            onChange={() => this.setState({ evaluation: { selfEvaluated: "", evaluated: "" } })}
          />
        </Form.Group>
      );
    return (
      <div>
        <Form.Checkbox
          disabled={!this.props.allowEdit}
          checked
          label="Kiểm định chất lượng Giáo dục"
          onChange={() => this.setState({ evaluation: undefined })}
        />
        <Form.Group inline>
          <Form.Field>
            <label>Hoàn thành tự đánh giá: </label>
            <Dropdown
              search
              selection
              fluid
              multiple={false}
              options={levelsOptions}
              value={this.state.evaluation.selfEvaluated}
              onChange={(e, { value }) => this.setState({ evaluation: { ...this.state.evaluation, selfEvaluated: value } })}
              placeholder="Chọn mức"
            />
          </Form.Field>
          <Form.Field>
            <label>Đánh giá ngoài: </label>
            <Dropdown
              search
              selection
              fluid
              multiple={false}
              options={levelsOptions}
              value={this.state.evaluation.evaluated}
              onChange={(e, { value }) => this.setState({ evaluation: { ...this.state.evaluation, evaluated: value } })}
              placeholder="Chọn mức"
            />
          </Form.Field>
        </Form.Group>
      </div>
    );
  }

  render() {
    const { schoolModel, schoolType, duoi7tre, chuacapphep, ward, ward2 } = this.props.unit.information;
    return (
      <div>
        {this.state.isSaving ? (
          <Dimmer active inverted>
            <Loader inverted>Đang lưu thông tin...</Loader>
          </Dimmer>
        ) : null}
        <Form style={{ marginTop: 10, display: "flow-root" }}>
          <Segment style={{ background: "transparent" }}>
            <Form.Group inline>
              <Form.Field>
                <label>Quy mô trường: </label>
                {' '}
                {readSchoolModel(schoolModel)}
              </Form.Field>
              <Form.Field>
                <label>Loại hình trường: </label>
                {' '}
                {readSchoolType(schoolType)}
              </Form.Field>
              <Form.Checkbox checked={duoi7tre} label="Dưới 7 trẻ" disabled />
              <Form.Checkbox checked={chuacapphep} label="Chưa cấp phép" disabled />
            </Form.Group>
          </Segment>
          <Segment style={{ background: "transparent" }}>
            {this.renderAddress(ward, ward2)}
            <Form.Group inline>
              <Form.Checkbox disabled={!this.props.allowEdit} checked={this.state.xadatchuan} label="Xã đạt chuẩn PCGDMN5T" onChange={() => this.setState({ xadatchuan: !this.state.xadatchuan })} />
              <Form.Checkbox disabled={!this.props.allowEdit} checked={this.state.xakhokhan} label="Xã đặc biệt khó khăn" onChange={() => this.setState({ xakhokhan: !this.state.xakhokhan })} />
              <Form.Checkbox disabled={!this.props.allowEdit} checked={this.state.xanongthonmoi} label="Xã xây dựng nông thôn mới" onChange={() => this.setState({ xanongthonmoi: !this.state.xanongthonmoi })} />
            </Form.Group>
          </Segment>
          <Segment style={{ background: "transparent" }}>{this.renderNationalStandard()}</Segment>
          <Segment style={{ background: "transparent" }}>{this.renderEvaluation()}</Segment>
          <Segment style={{ background: "transparent" }}>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Tên đơn vị</label>
                <Input disabled={!this.props.allowEdit} placeholder="Tên đơn vị" value={this.state.name} onChange={(event, { value }) => this.setState({ name: value })} />
              </Form.Field>
              <Form.Field>
                <label>{isCL(schoolType) ? "Hiệu trưởng" : "Hiệu trưởng/Chủ nhóm, lớp"}</label>
                <Input disabled={!this.props.allowEdit} placeholder={isCL(schoolType) ? "Hiệu trưởng" : "Hiệu trưởng/Chủ nhóm, lớp"} value={this.state.admin} onChange={(event, { value }) => this.setState({ admin: value })} />
              </Form.Field>
            </Form.Group>
            <Form.Field required>
              <label>Địa chỉ</label>
              <Input disabled={!this.props.allowEdit} placeholder="Địa chỉ" value={this.state.address} onChange={(event, { value }) => this.setState({ address: value })} />
            </Form.Field>
            <Form.Field>
              <label>SĐT</label>
              <Input style={{ maxWidth: 300 }} disabled={!this.props.allowEdit} placeholder="SĐT" value={this.state.phone} onChange={(event, { value }) => this.setState({ phone: value })} />
            </Form.Field>
            <Form.Field>
              <label>Ghi chú</label>
              <Form.TextArea disabled={!this.props.allowEdit} placeholder="Ghi chú" value={this.state.note} onChange={(event, { value }) => this.setState({ note: value })} />
            </Form.Field>
            {!isCL(schoolType) ? (
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Giấy phép thành lập số</label>
                  <Input disabled={!this.props.allowEdit} placeholder="Nếu chưa được cấp phép xin vui lòng bỏ trống" value={this.state.license} onChange={(event, { value }) => this.setState({ license: value })} />
                </Form.Field>
                <Form.Field>
                  <label>Giấy phép hoạt động số</label>
                  <Input disabled={!this.props.allowEdit} placeholder="Nếu chưa được cấp phép xin vui lòng bỏ trống" value={this.state.operationCert} onChange={(event, { value }) => this.setState({ operationCert: value })} />
                </Form.Field>
                <Form.Checkbox
                  style={{ marginTop: 25 }}
                  disabled={!this.props.allowEdit}
                  checked={this.state.industrialZone}
                  label="Khu vực có khu công nghiệp"
                  onChange={() => this.setState({ industrialZone: !this.state.industrialZone })}
                />
              </Form.Group>
            ) : null}
          </Segment>
          <Divider />
          {this.props.allowEdit ?
            <Button style={{ float: "right", marginBottom: "20px" }} color="brown" onClick={this.saveHandler}>Cập nhật hồ sơ</Button> : null}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ unit: state.unit });

const mapDispatchToProps = (dispatch) => bindActionCreators({ unitFetchInformation }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ThongTinTruong);