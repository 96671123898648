export const readDoTuoi = (dotuoi) => {
    switch (dotuoi) {
      case "3-4tuoi":
        return "3 - 4 tuổi";
      case "4-5tuoi":
        return "4 - 5 tuổi";
      case "5-6tuoi":
        return "5 - 6 tuổi";
      case "3-6thangtuoi":
        return "3 - 6 tháng tuổi";
      case "6-12thangtuoi":
        return "6 - 12 tháng tuổi";
      case "12-18thangtuoi":
        return "12 - 18 tháng tuổi";
      case "18-24thangtuoi":
        return "18 - 24 tháng tuổi";
      case "24-36thangtuoi":
        return "24 - 36 tháng tuổi";
      default:
        return dotuoi;
    }
}

export const readHealth = (healthKey) => {
    switch (healthKey) {
      case "BT":
        return "Bình thường";
      case "SDD":
        return "Suy dinh dưỡng";
      case "DC-BP":
        return "Thừa cân, béo phì";
      case "TuH":
        return "Bệnh tuần hoàn";
      case "HH":
        return "Bệnh hô hấp";
      case "TH":
        return "Bệnh tiêu hóa";
      case "T-TN":
        return "Bệnh thận, tiết niệu";
      case "TK-TT":
        return "Thần kinh, tâm thần";
      case "M":
        return "Bệnh mắt";
      case "RHM":
        return "Bệnh răng hàm mặt";
      case "TMH":
        return "Bệnh tai mũi họng";
      case "CXK":
        return "Bệnh cơ xương khớp";
      case "TM":
        return "Bệnh tim mạch";
      case "BK":
        return "Bệnh khác";
      default:
        return 'Bệnh khác';
    }
};

export const round = (value, dec = 2) => Number((value || 0).toFixed(dec));