import { REPORT_STATUS } from "./constants";


/**
 * Update report status for a unit
 * 
 * @module Redux.Actions.Status
 * @ngdoc method
 * @name $reportStatus
 * @param  {String} [unitID]
 * @param  {Object} [status] as firebase model
 */
export function reportStatus(unitID, status) {
  return {
    type: REPORT_STATUS,
    unitID,
    status
  };
}