import { ro } from "date-fns/locale";
import { intToExcelCol } from "excel-column-name";
import Excel from "exceljs";
import _ from "lodash";
import {
  writeHeaders,
  writeReport,
  writeCell,
  writeRange,
} from "./common";
import {
    readHealth,
    round
} from '../helpers';

const cities = require("../../../../libs/cities.json")

const tonghopsolieukhamsuckhoe = (
  healthStatusData,
  unit,
  errorHandler,
  closeHandler,
  onComplete
) => {
  const wb = new Excel.Workbook();
  const validHealthStatusData = _.filter(healthStatusData, (data) => !_.isEmpty(data));
  const activeYear = _.get(unit, 'activeYear');
  _.forEach(validHealthStatusData, (data) => {
    const sickStudents = _.get(data, 'sickStudents');
    const healthKey = _.get(data, 'sick');
    if(_.keys(sickStudents).length) {
        const sheet = wb.addWorksheet(readHealth(healthKey), {
            pageSetup: {
              paperSize: 9,
              horizontalCentered: true,
              margins: {
                left: 0.25,
                right: 0.203,
                top: 0.48,
                bottom: 0.43,
                header: 0.28,
                footer: 0.3,
              },
              fitToPage: true,
              fitToWidth: 1
            },
          });
          sheet.getColumn(2).width = 4.1;
          sheet.getColumn(3).width = 21.4;
          sheet.getRow(1).height = 19.5;
          sheet.getRow(2).height = 19.5;
          sheet.getRow(3).height = 19.5;
          sheet.getRow(5).height = 15.75;
          sheet.getRow(6).height = 37.5;
          sheet.getRow(7).height = 11.25;
          const wardID = _.get(unit, 'information.ward');
          const ward = cities[wardID.slice(0, 2)].districts[wardID.slice(0, 6)].name
          const infos = [
            { title: "Huyện", value: ward },
            { title: "Trường", value: _.get(unit, 'information.name', '') },
            { title: "Năm học", value: _.get(unit, 'activeYear', '') },
          ];
          _.forEach(infos, ({ title, value }, idx) => {
            writeRange(
                sheet,
                idx + 1,
                1,
                title,
                `A${idx + 1}:B${idx + 1}`,
                {
                bold: true,
                horizontal: "left",
            });
            writeCell(
                sheet,
                idx + 1,
                3,
                value,
                {
                color: "FFFF0000",
                bold: true,
                horizontal: "left",
                }
            );
          });
          writeRange(
            sheet,
            1,
            4,
            `DANH SÁCH HỌC SINH ${readHealth(healthKey).toUpperCase()}`,
            'D1:K3',
            {
              bold: true,
              fontSize: 16,
              wrapText: true,
            }
          );
          writeHeaders(
              sheet,
              [
                  {name: 'STT', width: 5.4 },
              ],
              5,
              0,
              2,
          );
          writeRange(
              sheet,
              5,
              2,
              'Họ và tên',
              'B5:C6',
              {
                  border: true,
                  bold: true
              }
          )
          writeHeaders(
              sheet,
              [
                  { name: 'Giới tính', subHeaders: [
                      { name: 'Nam', width: 5 },
                      { name: 'Nữ', width: 5 },
                  ]},
                  { name: 'Lớp', width: 7.1 },
                  { name: 'Ngày, tháng, năm phát hiện', width: 14.3, wrapText: true },
                  { name: 'Chuẩn đoán', width: 14.3 },
                  { name: 'Xử trí', subHeaders: [
                      {name: 'Tại trường\n (ghi nội dung xử trí)', width: 16.4, wrapText: true},
                      {name: 'Chuyển đến\n(ghi nơi chuyển đến)', width: 16.4, wrapText: true},
                  ] },
                  { name: 'Ghi chú', width: 9.1 },
              ],
              5,
              3,
              2,
          );
          writeHeaders(
              sheet,
              [{name: 1}],
              7,
              0,
              1,
              {
                  fill: 'FFD9D9D9'
              }
          )
          writeRange(
            sheet,
            7,
            2,
            2,
            'B7:C7',
            {
                border: true,
                bold: true,
                fill: 'FFD9D9D9'
            }
        );
        writeHeaders(
            sheet,
            _.map(_.range(3, 11), (idx) => ({ name: idx })),
            7,
            3,
            1,
            {
                fill: 'FFD9D9D9'
            }
        );
        let rowIndex = 8;
        let stt = 0;
        _.forEach(sickStudents, (student) => {
            const {
                gender,
                firstName,
                lastName,
                className,
                day,
                month,
            } = student
            const year = month === 9 ? parseFloat(activeYear) : parseFloat(activeYear)  + 1;
            stt += 1;
            writeCell(
                sheet,
                rowIndex,
                1,
                stt,
                {
                    border: true
                }
            );
            writeRange(
                sheet,
                rowIndex,
                2,
                `${lastName} ${firstName}`,
                `B${rowIndex}:C${rowIndex}`,
                {
                    border: true,
                    wrapText: true,
                    horizontal: 'left'
                }
            );
            writeCell(
                sheet,
                rowIndex,
                4,
                 '',
                {
                    border: true
                }
            );
            writeCell(
                sheet,
                rowIndex,
                5,
                 gender === 'F' ? 'Nữ' : '',
                {
                    border: true
                }
            );
            writeCell(
                sheet,
                rowIndex,
                6,
                className,
                {
                    border: true
                }
            );
            writeCell(
                sheet,
                rowIndex,
                7,
                `${day}/${month}/${year}`,
                {
                    border: true
                }
            );
            writeCell(
                sheet,
                rowIndex,
                8,
                readHealth(healthKey),
                {
                    border: true,
                    wrapText: true,
                }
            );
            writeCell(
                sheet,
                rowIndex,
                9,
                'Chuyên khoa',
                {
                    border: true,
                    horizontal: 'left'
                }
            );
            writeCell(
                sheet,
                rowIndex,
                10,
                'Chuyên khoa',
                {
                    border: true,
                    horizontal: 'left'
                }
            );
            writeCell(
                sheet,
                rowIndex,
                11,
                '',
                {
                    border: true,
                }
            );
            rowIndex += 1;
        })
        
    }

  })
      
  return wb.xlsx.writeBuffer().then((data) => {
    if (data && _.keys(healthStatusData).length) {
      writeReport(
        new Blob([data]),
        `TongHopTinhTrang ${_.get(unit, 'activeYear')}.xlsx`,
        closeHandler
      );
    } else {
      errorHandler("Xuất báo cáo không thành công, vui lòng kiểm tra lại");
      onComplete();
    }
  });
};

export default tonghopsolieukhamsuckhoe;
