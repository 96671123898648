import { REBOT_DATA_REPORT, REBOT_DATA_READING, REBOT_DATA_READ_ERROR, REBOT_THONGTINCHUNG, REBOT_NHANSU } from "./constants";


/**
 * Update data for a report
 * 
 * @module Redux.Actions.Data
 * @ngdoc method
 * @name $updateData
 * @param  {String} [unitID]
 * @param  {String} [reportKey]
 * @param  {String} [data] json format
 */
export function updateData(unitID, reportKey, data) {
  return {
    type: REBOT_DATA_REPORT,
    unitID,
    reportKey,
    data
  };
}

/**
 * Enable waiting status while reading from server
 * 
 * @module Redux.Actions.Data
 * @ngdoc method
 * @name $dataReading
 * @param  {String} [unitID]
 * @param  {String} [key] reports key
 */
export function dataReading(unitID, key) {
  return {
    type: REBOT_DATA_READING,
    unitID,
    key
  };
}

/**
 * Caught error while reading from server
 * 
 * @module Redux.Actions.Data
 * @ngdoc method
 * @name $dataReading
 * @param  {String} [unitID]
 * @param  {String} [key] reports key
 * @param  {String} [error]
 */
export function dataReadError(unitID, key, error) {
  return {
    type: REBOT_DATA_READ_ERROR,
    unitID,
    key,
    error
  };
}

/**
 * Update data for thongtinchung's report
 * 
 * @module Redux.Actions.Data
 * @ngdoc method
 * @name $updateThongTinChungData
 * @param  {String} [data] json format
 */
export function updateThongTinChungData(data) {
  return {
    type: REBOT_THONGTINCHUNG,
    data
  };
}

/**
 * Update data for nhansu's report
 * 
 * @module Redux.Actions.Data
 * @ngdoc method
 * @name $updateNhanSuData
 * @param  {String} [data] json format
 */
export function updateNhanSuData(data) {
  return {
    type: REBOT_NHANSU,
    data
  };
}