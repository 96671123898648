import React, { Component } from "react";
import PropTypes from "prop-types";
import { Image, Icon, Dropdown, Button, Card, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

// services
import { app } from "../../libs/firebase";
import { signOut } from "../../redux/actions/users";
import { RESOURCES } from "../../libs/config";


// images
const defaultAvatar = `${RESOURCES}/avatar.png`;

class UserHeader extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired,
    profileHandler: PropTypes.func.isRequired,
    user: PropTypes.shape({
      photoURL: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired
    }).isRequired
  };

  getDisplayAvatar = () => {
    if (this.props.user.photoURL !== undefined && this.props.user.photoURL !== "")
      return this.props.user.photoURL;
    return defaultAvatar;
  }

  getDisplayName = () => {
    if (this.props.user.displayName !== "" && this.props.user.displayName !== undefined)
      return this.props.user.displayName;
    return this.props.user.email;
  }

  logoutHandler = () => {
    ReactGA.event({
      category: "User",
      action: "Logout",
      label: this.props.user.uid
    });

    app.auth().signOut().then(() => {
      this.props.signOut();
    });
  }

  render() {
    return (
      <Dropdown icon={<Image src={this.getDisplayAvatar()} size="mini" circular />}>
        <Dropdown.Menu>
          <Card>
            <Card.Content>
              <Image 
                floated="right" 
                size="mini"
                src={this.getDisplayAvatar()} 
              />
              <Card.Header>
                {this.getDisplayName()}
              </Card.Header>
              <Card.Description>
                <Label>
                  <Icon name="mail" />
                  {this.props.user.email}
                </Label>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <div className="ui two buttons">
                <Button basic color="brown" onClick={this.props.profileHandler}>
                  <Icon name="id card outline" className="right floated" />
                  Tài khoản
                </Button>
                <Button basic color="grey" onClick={this.logoutHandler}>
                  <Icon name="sign out" className="right floated" />
                  Đăng xuất
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ signOut }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserHeader));