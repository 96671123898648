import { REPORTS_FETCH, REPORTS_FETCHING, REPORTS_FETCH_ERROR, REPORT_THONGTINCHUNG, REPORT_VALIDATE, REPORT_EXPORTS } from "./constants";


/**
 * Fetch all reports schemas of a unit and clear waiting status
 * 
 * @module Redux.Actions.Reports
 * @ngdoc method
 * @name $reportsFetch
 * @param  {String} [unitID]
 * @param  {Array} [reports] array of reports
 */
export function reportsFetch(unitID, reports) {
  return {
    type: REPORTS_FETCH,
    unitID,
    reports
  };
}

/**
 * Fetch thongtinchung's schema
 * 
 * @module Redux.Actions.Reports
 * @ngdoc method
 * @name $thongtinchungFetch
 * @param  {Object} [thongtinchung] thongtinchung's schema
 */
export function thongtinchungFetch(thongtinchung) {
  return {
    type: REPORT_THONGTINCHUNG,
    thongtinchung
  };
}

/**
 * Fetch export's schema
 * 
 * @module Redux.Actions.Reports
 * @ngdoc method
 * @name $exportsFetch
 * @param  {Object} [exports] exports's schema
 */
export function exportsFetch(exports) {
  return {
    type: REPORT_EXPORTS,
    exports
  };
}

/**
 * Fetch validate's schema
 * 
 * @module Redux.Actions.Reports
 * @ngdoc method
 * @name $thongtinchungFetch
 * @param  {Object} [validate] validate's schema
 */
export function validateFetch(validate) {
  return {
    type: REPORT_VALIDATE,
    validate
  };
}

/**
 * Enable waiting status while waiting response from server
 * 
 * @module Redux.Actions.Reports
 * @ngdoc method
 * @name $reportFetching
 * @param  {String} [unitID]
 */
export function reportsFetching(unitID) {
  return {
    type: REPORTS_FETCHING,
    unitID
  };
}

/**
 * Caught error while reading from server
 * 
 * @module Redux.Actions.Reports
 * @ngdoc method
 * @name $dataReading
 * @param  {String} [unitID]
 * @param  {String} [error]
 */
export function reportsFetchError(unitID, error) {
  return {
    type: REPORTS_FETCH_ERROR,
    unitID,
    error
  };
}