/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tab, Message, Modal, Button, Header, Menu } from "semantic-ui-react";
import _ from 'lodash';

// components
import Item from "./Item";

// services
import { expandLogic } from "../../libs/report";


export default class ReportViewer extends Component {
  static propTypes = {
    validateRules: PropTypes.object.isRequired,
    verify: PropTypes.bool,
    data: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired,
    unitType: PropTypes.string.isRequired // which type of report: school or pgd,sgd
  };

  static defaultProps = {
    verify: false
  };

  constructor(props) {
    super(props);

    this.state = {
      showResultsVerification: false
    };
  }

  componentWillMount() {
    if (this.props.verify)
      this.verifyReport(this.props.data);
  }

  verifyReport = (data) => {
    // validate data in all reports
    const failedValidateRules = {};
    if (this.props.validateRules !== undefined) {
      Object.keys(this.props.validateRules).forEach(reportKey => {
        const reportValidateRules = this.props.validateRules[reportKey]
        const validateResults = expandLogic(data, [], reportValidateRules);  // assume no {lib.X}
        Object.keys(validateResults)
          .filter(key => validateResults[key] === true)
          .forEach(key => delete reportValidateRules[key]);

        const reportKeysValidateRules = Object.keys(reportValidateRules);
        if (reportKeysValidateRules.length > 0) {
          failedValidateRules[reportKey] = {};
          reportKeysValidateRules.forEach(key => {
            failedValidateRules[reportKey][key] = {};
            failedValidateRules[reportKey][key].description = reportValidateRules[key].description;

            const regExp = /\{([^}]+)\}/g;
            const ruleVars = reportValidateRules[key].eval.match(regExp);
            if (ruleVars !== null) {
              ruleVars.forEach(ruleVar => {
                const dotIndex = ruleVar.indexOf('.');
                const expReportKey = ruleVar.slice(1, dotIndex);
                if (expReportKey === reportKey) {
                  const columnKey = ruleVar.slice(dotIndex + 1, ruleVar.length - 1);
                  failedValidateRules[reportKey][key].errorKey = columnKey;
                }
              });
            }
          });
        }
      });
    }
    if (Object.keys(failedValidateRules).length > 0) {
      const results = {};
      Object.keys(failedValidateRules).forEach(report => {
        results[report] = {
          name: (this.props.reports[report] !== undefined) ? this.props.reports[report].shortName : report,
          issues: []
        };
        Object.keys(failedValidateRules[report]).forEach(description => {
          results[report].issues.push(failedValidateRules[report][description].description);
        });
      });
      this.setState({
        showResultsVerification: true,
        resultsVerification: results
      });
      return false;
    }
    return true;
  }

  renderReportResult = (report) => <Message key={report.name} error header={report.name} list={report.issues} />

  renderResultsVerification = () => (
    <Modal dimmer={false} size="tiny" open={this.state.showResultsVerification}>
      <Header className="form-header" as="h3" icon="protect" content="Kết quả kiểm tra số liệu" />
      <Modal.Content>
        {(this.state.resultsVerification !== undefined) ?
          Object.keys(this.state.resultsVerification).map((report) => this.renderReportResult(this.state.resultsVerification[report]))
          :
          <Message positive>Số liệu khớp giữa các báo cáo</Message>}
      </Modal.Content>
      <div style={{ float: "right", paddingBottom: 10 }}>
        <Button basic color="grey" onClick={() => this.setState({ showResultsVerification: false, resultsVerification: undefined })}>Tiếp tục xem</Button>
      </div>
    </Modal>
  )

  render() {
    const panes = [];
    const {
      reports,
      unitType,
      data
    } = this.props;
    Object.keys(reports).forEach(reportKey => {
      if (reports[reportKey].permission !== undefined && reports[reportKey].permission.indexOf(unitType) === -1)
        return;
      if (data[reportKey] === undefined || data[reportKey].data === undefined)
        return;
      panes.push({
        menuItem: <Menu.Item key={reportKey} className="tabSent">{reports[reportKey].shortName}</Menu.Item>,
        render: () => (
          <Tab.Pane style={{ background: "transparent" }}>
            <Item
              name={reports[reportKey].name}
              data={data[reportKey].data}
              schema={reports[reportKey].schema}
              detail={{
                schema: _.get(reports[reportKey], 'detail'),
                data: _.get(data[reportKey], 'detail', [])
              }}
              unitType={unitType}
              information={data.information}
            />
          </Tab.Pane>
        )
      });
    });

    const { showResultsVerification, resultsVerification } = this.state;
    if (showResultsVerification)
      return (
        <div>
          <Header className="form-header" as="h3" icon="protect" content="Kết quả kiểm tra số liệu" />
          <Modal.Content>
            {(resultsVerification !== undefined) ?
              Object.keys(resultsVerification).map((report) => this.renderReportResult(resultsVerification[report]))
              :
              <Message positive>Số liệu khớp giữa các báo cáo</Message>}
          </Modal.Content>
          <div className="profile-buttons">
            <Button basic color="grey" onClick={() => this.setState({ showResultsVerification: false, resultsVerification: undefined })}>Tiếp tục xem</Button>
          </div>
        </div>
      );

    return (
      <Tab
        style={{ background: "transparent" }}
        grid={{ paneWidth: 14, tabWidth: 2 }}
        menu={{ fluid: true, vertical: true, tabular: true, pointing: true, color: "brown" }}
        panes={panes}
      />
    );
  }
}