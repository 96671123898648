import { YEAR_FETCH, SIGN_OUT } from "../actions/constants";


const initialState = {
};

export default function year(state = initialState, action) {
  switch(action.type) {
    case YEAR_FETCH:
      return {
        ...state,
        [action.year]: action.active
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}