import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";


const dev = false;
const config = dev ? {
  apiKey: "AIzaSyDm2CVI4nv8nwPiC8Wb3YRICsTtdzl0unM",
  authDomain: "rebot-dev.firebaseapp.com",
  databaseURL: "https://rebot-dev.firebaseio.com",
  projectId: "rebot-dev",
  storageBucket: "rebot-dev.appspot.com",
  messagingSenderId: "325313344484"
} : {
    apiKey: "AIzaSyDeLRdlQvMtNsd8SjmfHwySlCfUTLscxVQ",
    authDomain: "rebot-release.firebaseapp.com",
    databaseURL: "https://rebot-release.firebaseio.com",
    projectId: "rebot-release",
    storageBucket: "rebot-release.appspot.com",
    messagingSenderId: "79247824808"
  };

const app = firebase.initializeApp(config);
app.auth().languageCode = "vi";

const db = app.database();
const storage = app.storage();

const googleProvider = new firebase.auth.GoogleAuthProvider().setCustomParameters({
  prompt: "select_account"
});

export {
  app,
  db,
  googleProvider,
  storage,
  dev,
}
