/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Header,
  Checkbox,
  Modal,
  Icon
} from "semantic-ui-react";
import { FormattedNumber } from "react-intl";
import _ from "lodash";
import ReactTable from 'react-table';


export default class DetailViewer extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    schema: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    data: []
  };

  render() {
    const { schema, data, closeHandler } = this.props;
    const firstDepth = [];
    _.forEach(schema, col => {
      if (!col.parents || !_.get(col, 'parents[0]', ''))
        firstDepth.push({
          type: 'column',
          column: col
        });
      else if (_.findIndex(firstDepth, ele => ele.type === 'parent' && ele.name === _.get(col, 'parents[0]', '')) === -1)
        firstDepth.push({
          type: 'parent',
          name: _.get(col, 'parents[0]', '')
        });
    });
    const columns = [];
    _.forEach(firstDepth, col => {
      const { type: colType, name: parentsName, column } = col;
      if (colType === 'column') {
        const { key, name, type } = column;
        columns.push({
          columns: [
            {
              id: key,
              Header: name,
              headerClassName: 'tableHeader',
              accessor: key,
              Cell: row => {
                if (type === 'number')
                  return <FormattedNumber value={row.value || 0} />;
                if (type === 'bool')
                  return <Checkbox checked={!!row.value} />;
                return <p>{row.value || ''}</p>;
              }
            }
          ],
          headerClassName: 'tableHeader',
          Header: ''
        });
      } else {
        columns.push({
          Header: parentsName,
          headerClassName: 'parentTableHeader',
          columns: _.map(_.filter(schema, colSchema => _.indexOf(_.get(colSchema, 'parents', []), parentsName) !== -1), colSchema => {
            const { key, name, type } = colSchema;
            return {
              id: key,
              Header: name,
              headerClassName: 'tableHeader',
              accessor: key,
              Cell: row => {
                if (type === 'number')
                  return <FormattedNumber value={row.value || 0} />;
                return <p>{row.value || ''}</p>;
              }
            }
          })
        });
      }
    });
    return (
      <Modal
        className="custom"
        open
        closeIcon={(
          <Icon
            name="close"
            onClick={closeHandler}
          />
        )}
      >
        <Header
          className="form-header"
          as="h3"
          content="Danh sách chi tiết"
        />
        <Modal.Content>
          <ReactTable
            style={(data.length > 5) ? { height: 700, width: '100%' } : { width: '100%' }}
            className="-striped -highlight"
            loadingText="Đang tải..."
            noDataText="Chưa có dữ liệu"
            collapseOnDataChange={false}
            showPagination={false}
            pageSize={data.length}
            data={data}
            columns={columns}
          />
        </Modal.Content>
      </Modal>
    );
  }
}