/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Button,
  Modal,
  Header,
  Table,
  Dropdown,
  Form,
  Dimmer,
  Loader,
  Input,
  Checkbox
} from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";
import Dropzone from "react-dropzone";
import moment from "moment";
import XLSX from "xlsx";
import { db } from "../../../libs/firebase";
import { RESOURCES } from "../../../libs/config";
import theodoisuckhoenhanvien from "./reports/theodoisuckhoenhanvien";


const healthKeys = [
  'BT',
  'NK',
  'M',
  'TMH',
  'RHM',
  'DL',
  'CXN',
  'BK'
]
class StaffsHealthTrack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
      useDay: false,
      waiting: {},
      day: 1,
      month: 9,
      staffs: {},
      dantoc: (this.props.dantoc === undefined) ? [] : _.keys(this.props.dantoc).map(dt => ({
        key: dt,
        text: this.props.dantoc[dt],
        value: dt
      }))
    };
  }

  componentDidMount() {
    const healthRecords = this.getHealthRecords();
    const { errorHandler } = this.props;
    healthRecords.then((staffs) => {
      this.setState({staffs , isWaiting: false})
    }).catch((error) => {
      this.setState({ isWaiting: false});
      errorHandler("không lấy được danh sách nhân sự");
    })
  };

  readHealth = (healthKey) => {
    switch (healthKey) {
      case "BT":
        return "Bình thường";
      case "NK":
        return "Bệnh nội khoa";
      case "M":
        return "Bệnh mắt";
      case "TMH":
        return "Bệnh tai-mũi-họng";
      case "RHM":
        return "Bệnh răng hàm mặt";
      case "DL":
        return "Bệnh da liễu";
      case "CXN":
        return "Có xét nghiệm";
      case "BK":
        return "Bệnh khác";
      default:
        return 'Bệnh khác';
    }
  }

  getHealthRecords = async () => {
    let staffs = {};
    this.setState({ isWaiting: true});
    const {
      errorHandler
    } = this.props
    const {
      unit: {
        unitID,
        activeYear
      }
    } = this.props;
    await db.ref(`suckhoe/${unitID}/${activeYear}/staffs/staffList`).once('value', (snapshot) => {
      if(snapshot.val()) {
         staffs = snapshot.val();

      } 
    }, error => {
      errorHandler('Lấy danh sách nhân sự không thành công');
      console.log(error);
    });
    return staffs;
  }

  updateEthnicHandler = (staff, value) => {
    const { staffs } = this.state
    this.changed = true;
    this.setState({
      staffs: {
        ...staffs,
        [staff.staffID]: {
          ...staffs[staff.staffID],
          ethnic: value
        }
      }
    });
  }

  updateValueHandler = (staff, value, field) => {
    this.changed = true;
    const { staffs } = this.state;
    this.setState({
      staffs: {
        ...staffs,
        [staff.staffID]: {
          ...staffs[staff.staffID],
          [field]: value
        }
      }
    });
  }

  updatePositionHandler = (staff, value, field) => {
    this.changed = true;
    this.setState({
      staffs: {
        ...this.state.staffs,
        [staff.staffID]: {
          ...this.state.staffs[staff.staffID],
          [field]: value
        }
      }
    });
  }

  renderStaffs = () => {
    const staffs = _.get(this.state, 'staffs', {});
    const processedStaffs = _.sortBy(_.map(staffs, (staff, staffID) => ({staffID, ...staff})), ['order'], ['asc']);
    return (
      <Table style={{ background: "transparent" }} celled striped color="brown" unstackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="solieuHeader" colSpan="2" collapsing>Tên cán bộ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" collapsing>Năm sinh</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" collapsing>Nữ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" collapsing>Dân tộc</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" collapsing>Chức vụ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" collapsing>Kết quả khám sức khỏe</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {   
            processedStaffs.length ? _.map(processedStaffs, (staff) => {
              const { 
                lastName,
                firstName,
                gender,
                date,
                month,
                year,
                staffID,
                position,
              } = staff;
              return (
                <Table.Row key={staffID}>
                  <Table.Cell collapsing className="solieuValue">
                    <Icon
                      name="edit"
                      color="blue"
                      onClick={() => this.setState({
                        editor: {
                          ...staff
                        }
                      })}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing className="solieuValue">{`${lastName} ${firstName}`}</Table.Cell>
                  <Table.Cell collapsing className="solieuValue">
                    {date}
                    /
                    {month}
                    /
                    {year}
                  </Table.Cell>
                  <Table.Cell collapsing>{(gender === "F") ? <Icon name="female" /> : null}</Table.Cell>
                  <Table.Cell collapsing>
                    <Dropdown
                      placeholder="Kinh"
                      fluid 
                      search
                      selection
                      options={this.state.dantoc}
                      value={_.get(staff, "ethnic", "kinh")}
                      onChange={(e, { value }) => this.updateEthnicHandler(staff, value)}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {position}
                  </Table.Cell>
                  <Table.Cell collapsing className="solieuValue">
                    <Dropdown
                      // disabled={!allowEdit}
                      selection
                      multiple
                      value={_.get(staff, 'healthRecords', [])}
                      options={healthKeys.map(key => ({
                        key,
                        text: this.readHealth(key),
                        value: key
                      }))}
                      onChange={(event, { value }) => this.setState((prevState) => ({
                        staffs: {
                          ...prevState.staffs,
                          [`${staffID}`]: {
                            ...prevState.staffs[`${staffID}`],
                            healthRecords: value
                          }
                        }
                      }))}
                      fluid
                      search
                      placeholder="sức khỏe cán bộ"
                    />
                  </Table.Cell>
                </Table.Row>
              )
            }) : null
          }
        </Table.Body>
      </Table>
    );
  }

  importHandler = (files) => {
    if (files.length === 0)
      return;
    this.setState({ isWaiting: true });
    const reader = new FileReader();
    reader.onload = () => {
      const wb = XLSX.read(reader.result, { type: "binary" });
      const excelData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: ["A", "B", "C", "D", "E", "F", "G", "H", "I"], skipHeader: true, blankrows: true });
      this.setState({ isWaiting: false });
      const { staffs } = this.state;
      _.range(3, excelData.length).forEach(index => {
        if (_.every(["A", "B", "C", "D", "E", "F"], col => (excelData[index][col] !== undefined && excelData[index][col] !== ""))) {
          const gender = (excelData[index].G !== undefined && excelData[index].G !== "") ? "F" : "M";
          if (_.every(["A", "D", "E", "F"], col => (!isNaN(excelData[index][col])))) {
            const key = _.camelCase(`${excelData[index].B}${excelData[index].C}${excelData[index].D}${excelData[index].E}${excelData[index].F}${gender}`);
            let ethnic = "kinh";
            if (excelData[index].H !== undefined && excelData[index].H !== "") {
              const found = _.find(this.state.dantoc, dt => _.toLower(dt.text) === _.toLower(excelData[index].H));
              if (found !== undefined)
                ethnic = found.key;
            }
            _.set(staffs, key, {
              order: excelData[index].A,
              lastName: excelData[index].B,
              firstName: excelData[index].C,
              date: excelData[index].D,
              month: excelData[index].E,
              year: excelData[index].F,
              gender,
              ethnic,
              position: excelData[index].I !== undefined && excelData[index].I !== ""  ? excelData[index].I : "",
              healthRecords: ["BT"],
            })
          }
        }
      });
      this.setState({ isWaiting: false, staffs });
    };
    reader.onabort = () => {
      this.setState({ isWaiting: false });
    };
    reader.onerror = () => {
      this.setState({ isWaiting: false }, () => this.props.errorHandler("Đã có lỗi xảy ra trong quá trình đọc số liệu từ Excel"));
    };

    reader.readAsBinaryString(files[0]);
  }

  exportHandler = () => {
    const { staffs } = this.state;
    this.setState({ isExporting: true })
    const onComplete = () => {
      this.setState({ isWaiting: false, isExporting: false })
    };
    const { 
      closeHandler,
      errorHandler,
      unit
     } = this.props;
    if(!_.keys(staffs).length)
    errorHandler("không lấy được danh sách nhân sự, vui lòng thử lại!");
    this.setState({ isWaiting: true });
    theodoisuckhoenhanvien(
      staffs,
      unit,
      errorHandler,
      closeHandler,
      onComplete,
    );
  }

  validateNumber = (value) => {
    if (isNaN(value)) {
      this.props.errorHandler("Giá trị không hợp lệ", "Giá trị nhập vào phải là số.");
      return undefined;
    }
    return value;
  }

  updateInfoHandler = () => {
    const { errorHandler } = this.props;
    const { editor, staffs } = this.state;
    if (editor.firstName === "") {
      errorHandler("Tên không được bó trống");
      return;
    }
    if (editor.lastName === "") {
      errorHandler("Họ không được bó trống");
      return;
    }
    if (editor.date === "") {
      errorHandler("Ngày sinh không được bó trống");
      return;
    }
    if (editor.month === "") {
      errorHandler("Tháng sinh không được bó trống");
      return;
    }
    if (editor.year === "") {
      errorHandler("Năm sinh không được bó trống");
      return;
    }
    if (!moment(`${editor.date}/${editor.month}/${editor.year}`, "D/M/YYYY").isValid()) {
      errorHandler("Ngày tháng năm sinh không hợp lệ");
      return;
    }
    const id = _.camelCase(`${editor.lastName}${editor.firstName}${editor.date}${editor.month}${editor.year}${editor.gender}`);
    const { order, firstName, lastName, date, month, year, gender } = editor;
    const updateObj = { order, firstName, lastName, date, month, year, gender };
    if (editor.position !== undefined)
      updateObj.position = editor.position;
    if (editor.staffID === id) {
      this.setState({
        staffs: {
          ...staffs,
          [editor.staffID]: updateObj
        },
        editor: undefined
      });
    }
      
    else {
      const newStaffs = staffs;
      if (editor.staffID !== "")
        delete newStaffs[editor.staffID];
      newStaffs[id] = updateObj;
      this.setState({
        staffs: newStaffs,
        editor: undefined
      });
    }
  }

  saveStatus = (staffs) => {
    this.changed = false;
    const {
      unit: {
        unitID,
        activeYear,
      },
      errorHandler,
      successHandler,
      user,
    } = this.props;
    const { day } = this.state;
    if(day) {
      db.ref(`suckhoe/${unitID}/${activeYear}/status/status`).set({
        count: (staffs === undefined) ? 0 : _.keys(staffs).length,
        unfilled: (staffs === undefined) ? 0 : _.filter(_.keys(staffs), staff => (staffs[staff].healthRecords === undefined || staffs[staff].healthRecords === "")).length,
        day,
        modifiedBy: user.email,
        modifiedTime: new Date().getTime()
      }, error => {
        this.setState({ isWaiting: false });
        if (error)
          errorHandler("Cập nhật số liệu sức khỏe không thành công");
        else
          successHandler("Cập nhật số liệu sức khỏe thành công");
      });
    } else {
      errorHandler("Cập nhật không thành công, vui lòng thử lại.")
    }
  }

  saveHandler = () => {
    const {
      staffs,
    } = this.state;
    const {
      unit: {
        activeYear,
        unitID
      },
      errorHandler,

    } = this.props;

    this.setState({ isWaiting: true, showAskForSave: false });
    const ref = db.ref(`suckhoe/${unitID}/${activeYear}/staffs/staffList`);
    if (staffs === undefined)
      ref.remove(error => {
        if (error) {
          this.setState({ isWaiting: false });
          errorHandler("Cập nhật số liệu sức khoẻ không thành công");
        } else
          this.saveStatus(staffs);
      });
    else
      ref.set(staffs, error => {
        if (error) {
          this.setState({ isWaiting: false });
          errorHandler("Cập nhật số liệu sức khoẻ không thành công");
        }
        else
          this.saveStatus(staffs);
      });
  }

  renderEditor = () => {
    const { editor } = this.state;
    if (editor === undefined)
      return null;
    return (
      <Modal size="small" className="custom" open closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState({ editor: undefined })} />}>
        <Header className="form-header">{(editor.staffID === "") ? "Thêm mới" : "Chỉnh sửa"}</Header>
        <Modal.Content scrolling>
          <Form>
            <Form.Group width="equal">
              <Form.Field>
                <label>Họ</label>
                <Input
                  value={editor.lastName}
                  onChange={(e, { value }) => {
                    this.setState({ editor: { ...editor, lastName: value }});
                  }}
                  placeholder="Nhập họ và tên lót của cán bộ"
                />
              </Form.Field>
              <Form.Field>
                <label>Tên</label>
                <Input
                  value={editor.firstName}
                  onChange={(e, { value }) => {
                    this.setState({ editor: { ...editor, firstName: value }});
                  }}
                  placeholder="Nhập tên của cán bộ"
                />
              </Form.Field>
              <Form.Field />
              <Form.Field>
                <Checkbox
                  style={{ marginTop: 30 }}
                  label="Nữ"
                  checked={editor.gender === "F"}
                  onChange={() => {
                    this.setState({ editor: { ...editor, gender: (editor.gender === "M") ? "F" : "M" }});
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group width="equal" inline>
              <Form.Field>
                <label>Ngày</label>
                <Input
                  value={editor.date}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...editor, date: verified } });
                  }}
                  placeholder="Ngày sinh"
                />
              </Form.Field>
              <Form.Field>
                <label>Tháng</label>
                <Input
                  value={editor.month}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...editor, month: verified } });
                  }}
                  placeholder="Tháng sinh"
                />
              </Form.Field>
              <Form.Field>
                <label>Năm</label>
                <Input
                  value={editor.year}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...editor, year: verified } });
                  }}
                  placeholder="Năm sinh"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Chức vụ</label>
                <Input
                  value={editor.position}
                  onChange={(e, { value }) => {
                    this.setState({ editor: { ...editor, position: value }});
                  }}
                  placeholder="Chức vụ"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            size="small"
            onClick={() =>{
              this.removeStaff(editor.staffID);
          }}
          >
            <Icon name="trash" />
            {' '}
            Xoá
          </Button>
          <Button
            color="blue"
            size="small"
            onClick={() => {
              this.updateInfoHandler();
            }}
          >
            {(editor.staffID === "") ? "Thêm" : "Sửa"}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  };

  removeStaff = (staffID) => {
    const clone = _.cloneDeep(this.state.staffs);
    delete clone[staffID];
    this.setState({ editor: undefined, staffs: clone });
  }

  render() {
    const { closeHandler } = this.props;
    const {
      isExporting,
      staffs,
      isWaiting
    } = this.state;
    return (
      <div>
        <Modal size="fullscreen" open>
          <Header className="form-header">
            <Header.Content as="h3">
              Theo dõi sức khỏe cán bộ
            </Header.Content>
            <Button style={{ float: "right" }} onClick={closeHandler}>
              <Icon name="angle left" />
              Quay lại
            </Button>
          </Header>
          <Modal.Content scrolling>
            {isWaiting ? (
              <Dimmer active>
                <Loader>Đang kết nối...</Loader>
              </Dimmer>
            ) : null}
            {this.renderStaffs()}
            {this.renderEditor()}
          </Modal.Content>
          <Modal.Actions>
            {(!_.keys(staffs).length) ? (
              <Button
                style={{ float: "left" }}
                size="small"
                onClick={() => {
                    setTimeout(() => {
                      const response = { file: `${RESOURCES}/rebot/file_mau_sk_cb.xls` };
                      window.open(response.file);
                    }, 100);
                  }}
              >
                <Icon name="download" />
                File mẫu
              </Button>
            ) : null}
            {(_.keys(staffs).length === 0) ? (
              <Dropzone accept=".xls, .xlsx" multiple={false} onDrop={this.importHandler}>
                {({ getRootProps, getInputProps }) => (
                  <div style={{ float: "left" }} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button color="green" size="small">
                      <Icon name="file excel outline" />
                      Nhập từ Excel
                    </Button>
                  </div>
          )}
              </Dropzone>
      ) : null}
            <Button 
              size="small" 
              color="green" 
              onClick={() => this.setState({ editor: { order: 1, staffID: "", firstName: "", lastName: "", date: "", month: "", year: "", gender: "M" } })}
            >
              <Icon name="add" />
              Thêm CB
            </Button>
            <Button
              color="orange"
              size="small"
              onClick={this.saveHandler}
              loading={isExporting}
            >
              <Icon name="save" />
              Lưu
            </Button>
            <Button
              color="red"
              size="small"
              onClick={() => this.setState({ staffs: {} })}
              loading={isExporting}
            >
              <Icon name="trash" />
              Xóa tất cả
            </Button>
            <Button
              color="green"
              size="small"
              onClick={this.exportHandler}
              loading={isExporting}
            >
              <Icon name="file excel outline" />
              Xuất Excel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  user
});

StaffsHealthTrack.propTypes = {
  errorHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  successHandler: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired
  }).isRequired,
}

export default connect(mapStateToProps)(StaffsHealthTrack);