import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Step, Icon, Message, Modal, Header, Button } from "semantic-ui-react";
import { BrowserRouter, Route, Switch as RouterSwitch, } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import axios from "axios";
import Parser from "html-react-parser";

// components
import Welcome from "./screens/Welcome";
import Workspace from "./screens/Workspace";
import Xbot from "./screens/Xbot";
import PrivateRoute from "./PrivateRoute";

// styles
import "./App.css";

// services
import packageJson from '../package.json';
import { app, db } from "./libs/firebase";
import { initializeGA } from "./libs/ga";
import { userFetchInformation, userIdentify, userAddUnit } from "./redux/actions/users";
import { unitFetchInformation, unitFetchActiveYear, unitFetchActiveMilestone, unitNotification, unitDeadline } from "./redux/actions/units";
import { dataReading, dataReadError, updateData, updateThongTinChungData, updateNhanSuData } from "./redux/actions/data";
import { reportsFetch, reportsFetching, reportsFetchError, thongtinchungFetch, validateFetch, exportsFetch } from "./redux/actions/reports";
import { yearFetch } from "./redux/actions/years";
import { reportStatus } from "./redux/actions/status";
import { reportsManage, unitsManage } from "./redux/actions/manages";
import { fetchMilestone, clearMilestone } from "./redux/actions/milestones";
import { NOTIFICATIONS } from "./libs/config";

const cities = require("./libs/cities.json");


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: false,
      identify: false,
      error: ""
    };
  }

  componentDidMount() {
    this.removeAuthListener = app.auth().onAuthStateChanged((user) => {
      if (user) {
        const { userFetchInformation: userHandler } = this.props;
        initializeGA(user.uid, packageJson.version);
        userHandler(user.displayName, user.email, user.phoneNumber, user.photoURL, user.uid);
        this.uid = user.uid;
        this.setState({ user: true });
        db.ref(`users/${user.uid}`).once("value", this.readUserInfoSuccess, this.readInfoFailed);
      } else
        this.setState({
          user: true,
          identify: true
        });
    });
  }

  componentWillUnmount() {
    this.removeAuthListener();
    if (this.reportsListeners !== undefined) {
      _.keys(this.reportsListeners).forEach(key => {
        this.reportsListeners[key].off();
      });
    }
    if (this.notificationsListener !== undefined)
      this.notificationsListener.off();
    if (this.deadlinesListener !== undefined)
      this.deadlinesListener.off();
    if (this.finalStatusListener !== undefined)
      this.finalStatusListener.off();
    if (this.childsStatusListeners !== undefined) {
      _.keys(this.childsStatusListeners).forEach(key => {
        this.childsStatusListeners[key].off();
      });
    }
    if (this.milestonesListener !== undefined)
      this.milestonesListener.off();
    if (this.thongtinchungsListener !== undefined)
      this.thongtinchungsListener.off();
    if (this.nhansusListener !== undefined)
      this.nhansusListener.off();
  }

  checkNotification = (unitInfo) => {
    const { ward } = unitInfo ?? {};
    let { province, district } = unitInfo ?? {};
    if(ward) {
      [province] = ward.split('_');
      const wardCode = _.last(ward.split('_'));
      district = ward.replace(`_${wardCode}`, '');
    }
    else if(district) { 
      [province] = district.split('_');
    }
    axios.post(NOTIFICATIONS, { product: 'rebot', province, district, ward }).then(({ data }) => {
      if (data?.length) this.setState({ notification: data });
    });
  }

  /**
   * Listen to reports in order to allow real-time writing
   *
   * @param  {String} [sgd]
   * @param  {String} [year]
   * @param  {String} [milestone] currently active milestone
   * @param  {String} [eduunit] unitID
   * @param  {String} [reports] Reports schemas
   */
  readReports = (sgd, year, milestone, eduunit, reports) => {
    const { dataReading: readHandler, updateData: updateHandler, dataReadError: readError } = this.props;
    this.reportsListeners = {};
    _.keys(reports).forEach(key => {
      readHandler(eduunit, key);
      this.reportsListeners[`${eduunit}_${key}`] = db.ref(`rebot/${sgd}/${year}/${milestone}/reports/${eduunit}/${key}`);
      this.reportsListeners[`${eduunit}_${key}`].on("value", (snapshot) => {
        if (snapshot !== null && snapshot.val() !== null) {
          updateHandler(eduunit, key, snapshot.val());
        }
        else {
          updateHandler(eduunit, key, []);
        }
      }, () => readError(eduunit, key, "Kết nối đến cơ sở dữ liệu không thành công. Vui lòng thử lại sau."));
    });

    // read childs information (if exists)
    this.reportsListeners[`${eduunit}_information`] = db.ref(`rebot/${sgd}/${year}/${milestone}/reports/${eduunit}/information`);
    this.reportsListeners[`${eduunit}_information`].on("value", (snapshot) => {
      if (snapshot !== null && snapshot.val() !== null)
        updateHandler(eduunit, "information", snapshot.val());
    });
  }

  /**
   * Read notification from its parent unit
   * SGD does not need to read notification since it is the root unit
   *
   * @name $readNotification
   * @param  {String} [sgd]
   * @param  {String} [year]
   * @param  {String} [milestone]
   * @param  {String} [parent] parentID
   * @param  {String} [eduunit] unitID
   */
  readNotification = (sgd, year, milestone, parent, eduunit) => {
    this.notificationsListener = db.ref(`rebot/${sgd}/${year}/${milestone}/notification/${parent}`);
    this.notificationsListener.on("value", (noti) => {
      const { unitNotification: notiHandler } = this.props;
      if (noti !== null && noti.val() !== null) {
        notiHandler(eduunit, noti.val());
      }
    });
  }

  /**
   * Read deadline information that this unit create for their child unit
   *
   * @param  {String} [sgd]
   * @param  {String} [year]
   * @param  {String} [milestone]
   * @param  {String} [eduunit] unitID
   */
  readDeadline = (sgd, year, milestone, eduunit) => {
    this.deadlinesListener = db.ref(`rebot/${sgd}/${year}/${milestone}/notification/${eduunit}`);
    this.deadlinesListener.on("value", (noti) => {
      if (noti !== null && noti.val() !== null) {
        this.props.unitDeadline(eduunit, noti.val());
      }
    });
  }

  /**
   * Listen to status of its lasted report
   * This is not available for SGD since they do not send report to anyone
   *
   * @param  {String} [sgd]
   * @param  {String} [year]
   * @param  {String} [milestone]
   * @param  {String} [eduunit] unitID
   */
  readFinalStatus = (sgd, year, milestone, eduunit) => {
    this.finalStatusListener = db.ref(`rebot/${sgd}/${year}/${milestone}/final/${eduunit}/lasted`);
    this.finalStatusListener.on("value", (data) => {
      if (data !== null && data.val() !== null) {
        this.props.reportStatus(eduunit, data.val());
      } else {
        this.props.reportStatus(eduunit, {});
      }
    });
  }

  /**
   * Listen to status of all of its child reports
   *
   * @param  {String} [sgd]
   * @param  {String} [year]
   * @param  {String} [milestone]
   * @param  {Array} [childs] childs unitID
   */
  readChildStatus = (sgd, year, milestone, childs) => {
    if (childs === undefined || childs === null)
      return;
    this.childsStatusListeners = {};
    _.keys(childs).forEach(child => {
      // need to fetch all information of its child to display
      db.ref(`eduunits/${child}`).once("value", (info) => {
        if (info !== null && info.val() !== null)
          this.props.unitsManage(child, info.val());
      });
      // listen to status of childs reports
      this.childsStatusListeners[child] = db.ref(`rebot/${sgd}/${year}/${milestone}/final/${child}/lasted`);
      this.childsStatusListeners[child].on("value", (data) => {
        if (data !== null && data.val() !== null)
          this.props.reportsManage(child, data.val());
      });
    });
  }

  /**
   * Read all reports version
   *
   * @param  {String} [sgd]
   * @param  {String} [year]
   * @param  {String} [milestone]
   * @param  {String} [eduunit]
   */
  readReportsHistory = (sgd, year, milestone, eduunit) => {
    db.ref(`rebot/${sgd}/${year}/${milestone}/final/${eduunit}/status`).orderByChild("time").limitToLast(20).once("value", (data) => {
      if (data !== null && data.val() !== null) {
        localStorage.setItem(`${eduunit}${milestone}`, JSON.stringify(data.val()));
      } else
        localStorage.removeItem(`${eduunit}${milestone}`);
    });
  }

  /**
   * Read all data of one miletone
   *
   * @param  {Object} [info] as firebase
   * @param  {String} [rootID] SGDs ID
   * @param  {String} [year]
   * @param  {String} [milestone]
   * @param  {String} [eduunit] unitID
   * @param  {String} [parent] parent ID
   */
  readMilestone = (info, rootID, year, milestone, eduunit, parent) => {
    this.readReportsHistory(rootID, year, milestone, eduunit);
    if (parent !== undefined)
      this.readNotification(rootID, year, milestone, parent, eduunit);
    if (info.type !== "th")
      this.readDeadline(rootID, year, milestone, eduunit);

    // read reports schema and data
    this.props.reportsFetching(rootID);
    db.ref(`rebot/${rootID}/${year}/requirements/`).once("value", (reports) => {
      if (reports === null || reports.val() === null)
        this.props.reportsFetchError(rootID, "Chưa có dữ liệu về hệ thống báo cáo của Sở Giáo dục.");
      else {
        if (reports.val().thongtinchung !== undefined)
          this.props.thongtinchungFetch(reports.val().thongtinchung);
        if (reports.val().validate !== undefined)
          this.props.validateFetch(reports.val().validate);
        if (reports.val().exports !== undefined)
          this.props.exportsFetch(reports.val().exports);
        this.props.reportsFetch(rootID, reports.val().reports);
        this.readReports(rootID, year, milestone, eduunit, reports.val().reports);
      }
    });

    if (info.type !== "th") {
      this.thongtinchungsListener = db.ref(`rebot/${rootID}/${year}/${milestone}/thongtinchung/`);
      if (info.type === "pgd")
        this.thongtinchungsListener = db.ref(`rebot/${rootID}/${year}/${milestone}/thongtinchung/${eduunit}`)
      this.thongtinchungsListener.on("value", (snapshot) => {
        if (snapshot !== null && snapshot.val() !== null) {
          this.props.updateThongTinChungData(snapshot.val());
        }
        else {
          this.props.updateThongTinChungData({});
        }
      }, () => this.props.updateThongTinChungData({}));

      this.nhansusListener = db.ref(`rebot/${rootID}/${year}/${milestone}/nhansu/`);
      if (info.type === "pgd")
        this.nhansusListener = db.ref(`rebot/${rootID}/${year}/${milestone}/nhansu/${eduunit}`)
      this.nhansusListener.on("value", (snapshot) => {
        if (snapshot !== null && snapshot.val() !== null) {
          this.props.updateNhanSuData(snapshot.val());
        }
        else {
          this.props.updateNhanSuData({});
        }
      }, () => this.props.updateNhanSuData({}));
    }
    this.readFinalStatus(rootID, year, milestone, eduunit);
    this.readChildStatus(rootID, year, milestone, info.units);
  }

  /**
   * Only fetch and load data of the active year
   *
   * @param  {Object} [info] units information as firebase
   * @param  {String} [eduunit] $uid
   * @param  {Boolean} [yearsOnly] only fetch schoolYears, skip reports
   */
  readShoolYears = (info, eduunit, yearsOnly = false) => {
    // init with assumption that this unit is SGD
    // so rootID is its ID and parent is undefined
    let rootID = eduunit;
    let parent;
    switch (info.type) {
      case "th":
        rootID = info.sgd;
        if (info.pgd !== undefined)
          parent = info.pgd;
        else
          parent = info.sgd;
        break;
      case "pgd":
        rootID = info.sgd;
        parent = info.sgd;
        break;
      default:
    }
    db.ref(`rebot/${rootID}/schoolYears`).once("value", (years) => {
      // read data for active year only
      years.forEach((year) => {
        this.props.yearFetch(rootID, year.key, year.val());
        if (year.val() === true) {
          this.props.unitFetchActiveYear(eduunit, year.key);

          if (yearsOnly)
            return;
          if (info.type !== "sgd") {
            // Schools and PGD only need to know about current active milestone, any updates should apply via reload webpage
            db.ref(`rebot/${rootID}/${year.key}/milestones/`).orderByChild("time").once("value", (milestones) => {
              if (milestones === null || milestones.val() === null)
                this.props.reportsFetchError(rootID, "Hệ thống báo cáo đang gặp sự cố kết nối. Xin vui lòng liên hệ công ty để được hỗ trợ.");
              else {
                // must have at least one active milestone
                milestones.forEach((milestone) => {
                  this.props.fetchMilestone(milestone.key, milestone.val());
                  if (milestone.val().active) {
                    this.props.unitFetchActiveMilestone(eduunit, milestone.key);
                    this.readMilestone(info, rootID, year.key, milestone.key, eduunit, parent);
                  }
                });
              }
            }, (error) => {
              if (!error)
                this.props.reportsFetchError(rootID, "Hệ thống báo cáo đang gặp sự cố kết nối. Xin vui lòng liên hệ công ty để được hỗ trợ.");
            });
          } else {
            // SGD should be able to up-to-date to active milestone
            this.milestonesListener = db.ref(`rebot/${rootID}/${year.key}/milestones/`).orderByChild("time");
            this.milestonesListener.on("value", (milestones) => {
              this.props.clearMilestone();
              if (milestones === null || milestones.val() === null)
                this.props.reportsFetchError(rootID, "Hệ thống báo cáo đang gặp sự cố kết nối. Xin vui lòng liên hệ công ty để được hỗ trợ.");
              else {
                // must have at least one active milestone
                milestones.forEach((milestone) => {
                  if (milestone.val().active) {
                    this.props.unitFetchActiveMilestone(eduunit, milestone.key);
                    this.readMilestone(info, rootID, year.key, milestone.key, eduunit, parent);
                  }
                  this.props.fetchMilestone(milestone.key, milestone.val());
                });
              }
            }, (error) => {
              if (!error)
                this.props.reportsFetchError(rootID, "Hệ thống báo cáo đang gặp sự cố kết nối. Xin vui lòng liên hệ công ty để được hỗ trợ.");
            });
          }
        }
      });
    });
  }

  readUserInfoSuccess = (snapshot) => {
    const {
      userIdentify: identifyHandler,
      userAddUnit: addUnitHandler,
      unitFetchInformation: unitHandler,
      user
    } = this.props;
    if (snapshot.val() !== null) {
      identifyHandler(snapshot.val().displayName, snapshot.val().phoneNumber, snapshot.val().photoURL, snapshot.val().gender);
      if (snapshot.val().eduunits !== undefined && snapshot.val().eduunits !== null) {
        // try to fetch all units information which user is belonged to
        const allUnits = _.keys(snapshot.val().eduunits);
        let count = 0;
        allUnits.map((eduunit) => {
          // check if user has been removed from units
          db.ref(`certificate/eduunits/${eduunit}`).once("value", (data) => {
            let found = false;
            if (data !== null && data.val() !== null) {
              if (data.val().admin !== undefined && data.val().admin.indexOf(user.email) !== -1)
                found = true;
              if (data.val().staffs !== undefined && data.val().staffs.indexOf(user.email) !== -1)
                found = true;
            }
            if (found) {
              db.ref(`eduunits/${eduunit}`).once("value", (value) => {
                count += 1;
                if (value.val() !== null) {
                  addUnitHandler({ ...value.val(), unitID: eduunit });
                  if (snapshot.val().eduunits[eduunit] === true) {
                    unitHandler(eduunit, value.val());
                    setTimeout(() => {
                      this.loadLiveSupport({
                        ...user,
                        gender: snapshot.val().gender,
                      }, value.val());
                    }, 1000);
                    this.readShoolYears(value.val(), eduunit);
                    const { province, district, ward } = value.val() || {};
                    this.checkNotification({ province, district, ward });
                  }
                }
                if (allUnits.length === count)
                  setTimeout(() => this.setState({ identify: true }), 500);
              });
            }
            else {
              // check if this is teacher
              db.ref(`eduunits/${eduunit}`).once("value", (value) => {
                count += 1;
                if (value.val() !== null && value.val().teachers !== undefined && _.indexOf(JSON.parse(value.val().teachers), user.email) !== -1) {
                  addUnitHandler({ ...value.val(), staffs: {}, admin: "", unitID: eduunit });
                  if (snapshot.val().eduunits[eduunit] === true) {
                    setTimeout(() => {
                      this.loadLiveSupport({
                        ...user,
                        gender: snapshot.val().gender,
                      }, value.val());
                    }, 1000);
                    unitHandler(eduunit, { ...value.val(), staffs: {}, admin: "" });
                    this.readShoolYears(value.val(), eduunit, true);
                    setTimeout(() => this.setState({ identify: true }), 700);
                  }
                } else
                  db.ref(`users/${user.uid}/eduunits/${eduunit}`).remove();
                if (allUnits.length === count)
                  setTimeout(() => this.setState({ identify: true }), 500);
              });
            }
          });
          return eduunit;
        });
      } else
        this.setState({ identify: true });
    } else {
      db.ref(`users/${user.uid}`).set({
        email: user.email,
        displayName: user.displayName,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL
      });
      this.setState({ identify: true });
    }
  }

  readInfoFailed = (error) => {
    this.setState({
      error: `Đọc thông tin người dùng thất bại. Lỗi: ${error}`
    });
  }

  loadLiveSupport = (user, unit) => {

    const gender = (user.gender === 'M') ? 'Thầy' : 'Cô';

    const { ward } = unit;
    const info = _.split(ward, '_');
    const province = `${info[0]}`;
    const district = `${info[0]}_${info[1]}`;
    let provinceName = '';
    let districtName = '';
    let wardName = '';
    let unitInfo = unit?.address;
    if (unitInfo === undefined) { unitInfo = district; } else {
      provinceName = _.get(cities, `${_.split(ward, '_')[0]}.name`, 'Không tìm thấy');
      districtName = _.get(cities, `${_.split(ward, '_')[0]}.districts.${_.join(_.split(ward, '_', 2), '_')}.name`, 'Không tìm thấy');
      wardName = _.get(cities, `${_.split(ward, '_')[0]}.districts.${_.join(_.split(ward, '_', 2), '_')}.wards.${ward}.name`, 'Không tìm thấy');
    }
    if (unitInfo === undefined) { unitInfo = province; } else {
      provinceName = _.get(cities, `${_.split(district, '_')[0]}.name`, 'Không tìm thấy');
      districtName = _.get(cities, `${_.split(district, '_')[0]}.districts.${district}.name`, 'Không tìm thấy');
      wardName = 'Không tìm thấy';
    }

    if(window.fcWidget) {
      window.fwcrm.on('user:created', () => {
        window.fcWidget.setExternalId(user.uid);
        window.fcWidget.user.setFirstName(gender);
        window.fcWidget.user.setLastName(user.displayName);
        window.fcWidget.user.setEmail(user.email);
        window.fcWidget.user.setProperties({
          cf_unit_name: unit?.name,
          cf_unit_id: unit?.id,
          cf_province: provinceName,
          cf_district: districtName,
          cf_ward: wardName,
          cf_role: unit.isAdmin ? 'Admin' : 'Staff',
        });
      });
    }
  }

  renderNotification = () => {
    const { user } = this.props;
    const { notification } = this.state;
    if (!user.email || !(notification || []).length)
      return null;
    const { title, content } = notification?.[0]  ?? {};
    return (
      <Modal className="custom" open closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState((prevState) => prevState.notification.shift())} />}>
        <Header className="form-header">{title}</Header>
        <Modal.Content scrolling>{Parser(content)}</Modal.Content>
      </Modal>
    );
  }

  renderProgress = () => {
    const { user, identify } = this.state;
    return (
      <Container textAlign="center">
        <Step.Group>
          <Step active={!user} completed={user} style={{ background: "transparent" }}>
            <Icon loading={!user} name="server" />
            <Step.Content>
              <Step.Title>Kiểm tra kết nối</Step.Title>
              <Step.Description>Hệ thống đang kết nối đến cơ sở dữ liệu</Step.Description>
            </Step.Content>
          </Step>
          <Step active={!identify} completed={identify} style={{ background: "transparent" }}>
            <Icon loading={!identify} name="user" />
            <Step.Content>
              <Step.Title>Xác thực người dùng</Step.Title>
              <Step.Description>Hệ thống đang kiểm tra thông tin</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      </Container>
    );
  }


  render() {
    const {
      error,
      user,
      identify
    } = this.state;
    const {
      user: userInfo
    } = this.props;
    if (error)
      return (
        <Container textAlign="center">
          <Message
            error
            header={error}
            list={[
              "Truy cập lại hệ thống ở tab khác hoặc vào thời điểm khác.",
              "Liên hệ đến công ty để được hỗ trợ.",
            ]}
          />
        </Container>
      );
    if (!user || !identify)
      return this.renderProgress();

    return (
      <div style={{ minWidth: 930 }}>
        {this.renderNotification()}
        <div className="body">
          <BrowserRouter>
            <RouterSwitch>
              <Route exact path="/" component={Welcome} />
              <PrivateRoute path="/workspace" component={Workspace} />
              <PrivateRoute path="/xbot" component={Xbot} />
            </RouterSwitch>
          </BrowserRouter>
        </div>
        <div className="footer">
          <center>
            {`REBOT v${packageJson.version} - © 2018, XBOT Technology JSC. All Rights Reserved.`}
            {(userInfo?.email) ? (
              <div style={{ float: 'right' }}>
                <Button
                  style={{ cursor: 'pointer' }}
                  size="mini"
                  onClick={() => window.open('https://xbotguide.notion.site/xbotguide/H-ng-d-n-s-d-ng-REBOT-Ph-n-h-C-n-o-s-c-kh-e-19149478701e4012bbfa79872f71603b', 'Sách hướng dẫn', 'location:no')}
                >
                  <Icon name="book" />
                  Sách hướng dẫn
                </Button>
                <Button
                  style={{ cursor: 'pointer' }}
                  color="orange"
                  size="mini"
                  onClick={() => window.open("tel:028 7300 3588")}
                >
                  Hotline: 028 7300 3588
                </Button>
                {window.fcWidget ? (
                  <Button
                    size="mini"
                    color="teal"
                    onClick={() => window.fcWidget.open()}
                  >
                    <Icon name="life ring" />
                    Hỗ trợ
                  </Button>
                ) : null}
              </div>
            ) : null}
          </center>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  userFetchInformation: PropTypes.func.isRequired,
  userIdentify: PropTypes.func.isRequired,
  userAddUnit: PropTypes.func.isRequired,
  unitFetchInformation: PropTypes.func.isRequired,
  unitFetchActiveYear: PropTypes.func.isRequired,
  unitFetchActiveMilestone: PropTypes.func.isRequired,
  unitNotification: PropTypes.func.isRequired,
  unitDeadline: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  updateThongTinChungData: PropTypes.func.isRequired,
  updateNhanSuData: PropTypes.func.isRequired,
  dataReading: PropTypes.func.isRequired,
  dataReadError: PropTypes.func.isRequired,
  reportsFetchError: PropTypes.func.isRequired,
  reportsFetching: PropTypes.func.isRequired,
  reportsFetch: PropTypes.func.isRequired,
  thongtinchungFetch: PropTypes.func.isRequired,
  validateFetch: PropTypes.func.isRequired,
  exportsFetch: PropTypes.func.isRequired,
  yearFetch: PropTypes.func.isRequired,
  reportStatus: PropTypes.func.isRequired,
  reportsManage: PropTypes.func.isRequired,
  unitsManage: PropTypes.func.isRequired,
  fetchMilestone: PropTypes.func.isRequired,
  clearMilestone: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    photoURL: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    eduunits: PropTypes.array.isRequired
  }).isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  userFetchInformation, userIdentify, userAddUnit,
  unitFetchInformation, unitFetchActiveYear, unitFetchActiveMilestone, unitNotification, unitDeadline,
  dataReading, dataReadError, updateData, updateThongTinChungData, updateNhanSuData,
  reportsFetching, reportsFetch, reportsFetchError, thongtinchungFetch, validateFetch, exportsFetch,
  yearFetch,
  reportStatus,
  reportsManage, unitsManage,
  fetchMilestone, clearMilestone
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);