import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import axios from 'axios';

import {
  Table,
  Message,
  Button,
  Popup,
  Input,
  Segment,
  Label,
  Icon,
  Dimmer,
  Loader,
  Dropdown,
  Menu,
} from 'semantic-ui-react';

import { db } from '../../../libs/firebase';
import { convertTimeStamp } from '../../../libs/time';
import { unitFetchInformation } from '../../../redux/actions/units';
import { candoFetch, candoClassFetch } from '../../../redux/actions/cando';
import { RESOURCES } from '../../../libs/config';
import ClassHealthRecords from './ClassHealthRecords';
import StaffsHealthTrack from './StaffsHealthTrack';
import General from './General';


const grades = [
  "3-6thangtuoi",
  "6-12thangtuoi",
  "12-18thangtuoi",
  "18-24thangtuoi",
  "24-36thangtuoi",
  "3-4tuoi",
  "4-5tuoi",
  "5-6tuoi"
];

export class SucKhoe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      email: "",
      classes: this.convertClasses(this.props.unit.information.classes),
      showRecords: undefined,
      showStaffHealhTrack: false,
      showGeneral: false,
      generalType: null
    };
  }

  componentDidMount() {
    const {
      errorHandler,
      unit
    } = this.props;
    axios
      .get(`${RESOURCES}/rebot/dantoc.json`)
      .then((data) => {
        this.setState({ dantoc: data.data });
      })
      .catch(() => errorHandler('Tải danh mục dân tộc không thành công.')
      );
    this.unitRef = db.ref(`eduunits/${unit.unitID}`);
    this.unitRef.on(
      'value',
      (data) => {
        if (data !== null && data.val() !== null) {
          const tmp = data.val();
          if (unit.information.admin !== undefined)
            tmp.admin = unit.information.admin;
          if (
            unit.information.staffs !== undefined &&
            _.keys(unit.information.staffs).length === 0
          )
            tmp.staffs = {};
          this.props.unitFetchInformation(unit.unitID, tmp);
        }
      },
      (error) => {
        if (error)
          errorHandler('Không lấy được thông tin lớp và nhân sự');
      }
    );
  //  this.getPeriodStatus(this.getSortedClasses())
 
  }

  componentWillUnmount() {
    if (this.unitRef !== undefined) this.unitRef.off();
    if (this.periodsStatus !== undefined) {
      _.keys(this.periodsStatus).forEach((classID) =>
        this.periodsStatus[classID].off()
      );
      this.periodsStatus = undefined;
    }
  }

  getStatus = (classID, period) => {
    const { periodsStatus } = this.state;
   if(periodsStatus && periodsStatus[classID]) {
    if ( periodsStatus[classID][period] === undefined)
      return "Chưa có số liệu";
    return `${periodsStatus[classID][period].modifiedBy}. ${convertTimeStamp(periodsStatus[classID][period].modifiedTime)}`;
   }
  }

  readDoTuoi = (dotuoi) => {
    switch (dotuoi) {
      case "3-4tuoi":
        return "3 - 4 tuổi";
      case "4-5tuoi":
        return "4 - 5 tuổi";
      case "5-6tuoi":
        return "5 - 6 tuổi";
      case "3-6thangtuoi":
        return "3 - 6 tháng tuổi";
      case "6-12thangtuoi":
        return "6 - 12 tháng tuổi";
      case "12-18thangtuoi":
        return "12 - 18 tháng tuổi";
      case "18-24thangtuoi":
        return "18 - 24 tháng tuổi";
      case "24-36thangtuoi":
        return "24 - 36 tháng tuổi";
      default:
        return dotuoi;
    }
  }

  addCertificates = (teachers) => {
    teachers.forEach(email => {
      db.ref(`certificate/cando/${btoa(email)}/eduunits`).update({
        [this.props.unit.unitID]: true
      });
    });
  }

  updateTeachers = (teachers) => {
    this.setState({ isWaiting: true });
    const {
      unit: {
        unitID
      },
      errorHandler
    } = this.props;
    db.ref(`eduunits/${unitID}/teachers`).set(
      JSON.stringify(teachers),
      (error) => {
        this.setState({ isWaiting: false });
        if (error)
          errorHandler(
            'Cập nhật danh sách nhân sự không thành công.'
          );
        else {
          this.addCertificates(teachers);
          this.setState({ email: '' });
        }
      }
    );
  };

  getTeachers = () => (this.props.unit.information.teachers !== undefined) ? JSON.parse(this.props.unit.information.teachers) : []

  removeTeacherHandler = (teacher) => {
    this.updateTeachers(_.difference(this.getTeachers(), [teacher]));
  }

  addEmailHandler = () => {
    const { email } = this.state;
    const { errorHandler } = this.props;
    if (email === "" || !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))(\.*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email))) {
      errorHandler("Địa chỉ Email không hợp lệ.");
      return;
    }
    this.updateTeachers(_.uniq(_.concat(this.getTeachers(), [email])));
  };

  yearHandler = (year) => {
    const {
      cando,
      candoFetch: candoHandler,
      errorHandler,
      unit
    } = this.props;
    if (year === unit.activeYear) return;
    if (cando[year]) {
      this.setState({ showYear: year });
    } else {
      this.setState({ isWaiting: true });
      axios
        .get(
          `${RESOURCES}/rebot/backup/${unit.information.sgd}/${year}/${unit.unitID}/cando/meta.json`
        )
        .then((data) => {
          if (_.keys(data.data).length) {
            candoHandler(year, data.data);
            this.setState({
              isWaiting: false,
              showYear: year,
            });
          } else {
            this.setState({ isWaiting: false });
            errorHandler('Không tìm thấy dữ liệu của năm đã chọn.');
          }
        })
        .catch(() => {
          this.setState({ isWaiting: false });
          errorHandler('Không tìm thấy dữ liệu của năm đã chọn.');
        });
    }
  };

  getSortedClasses = () => {
    const { classes } = this.state;
    const { allowEdit, user: { email } } = this.props;
    if (classes === undefined)
      return [];
    const converted = _.map(_.keys(classes), classID => ({
      ...classes[classID],
      classID,
      gradeIndex: _.indexOf(grades, classes[classID].grade[0]),
    }));
    return _.sortBy(_.filter(converted, classItem => allowEdit || (_.indexOf(classItem.teachers, email) !== -1)), ["gradeIndex", "name"]);
  }

  convertClasses = (classes, reverse = false) => {
    if (classes === undefined)
      return {};
    const converted = {};
    _.keys(classes).forEach(classID => {
      let teachers = reverse ? JSON.stringify(classes[classID].teachers) : JSON.parse((classes[classID].teachers === "") ? "{}" : classes[classID].teachers);
      if (reverse && teachers === undefined)
        teachers = JSON.stringify({});
      converted[classID] = {
        ...classes[classID],
        teachers
      };
    });
    return converted;
  }

  renderHealthPeriod = (classInfo) => {
    const { classID  } = classInfo;
    const { 
      periodsStatus
    } = this.state

    return [
      "Khám sức khỏe lần 1",
      "Khám sức khỏe lần 2",
    ].map((period, idx) => {
      const time = idx + 1;
      const exists = periodsStatus && periodsStatus[classID] && periodsStatus[classID][`${time}`] !== undefined && periodsStatus[classID][`${time}`].count && periodsStatus[classID][`${time}`].count > 0;
      let color = exists ? "blue" : "green";
      if(exists && periodsStatus && periodsStatus[classID] && periodsStatus[classID][`${time}`].unfilled && periodsStatus[classID][`${time}`].unfilled > 0)    color= 'brown';
      const day = periodsStatus && periodsStatus[classID] ? _.get(periodsStatus[classID], `${idx+1}.day`) : 1 ;

      return (
        <Popup
          key={idx}
          trigger={(
            <Label
              style={{ minWidth: 200 }}
              key={idx}
              as="a"
              color={color}
              onClick={() => this.setState({
                showRecords: {
                  ...classInfo,
                  period: `${idx + 1}`,
                  day
                }
              })}
            >
              <Icon name={exists ? "edit" : "add"} />
              {' '}
              {period}
            </Label>
          )}
          content={this.getStatus(classID, time)}
        />
      );
    })
  }

  renderClass = (classInfo, index) => {
    const style = (index % 2 === 0) ? { background: "beige" } : {};
    const {
      unit,
      allowEdit,
    } = this.props;
    const { classes } = this.state;
    if (_.get(unit.information, `classes.${classInfo.classID}`, undefined) === undefined)
      return (
        <Table.Row key={classInfo.classID}>
          {allowEdit ? (
            <Table.Cell collapsing>
              <Icon
                color="blue"
                name="edit"
                onClick={() => this.setState({
                  classEditor: {
                    classID: classInfo.classID,
                    name: classInfo.name,
                    grade: classInfo.grade,
                    teachers: classInfo.teachers
                  }
                })}
              />
            </Table.Cell>
          ) : null}
          <Table.Cell collapsing>{classInfo.name}</Table.Cell>
          <Table.Cell collapsing>{_.join(classInfo.grade.map(grade => this.readDoTuoi(grade)), ", ")}</Table.Cell>
          {allowEdit ? (
            <Table.Cell collapsing>
              <Dropdown
                disabled={!allowEdit}
                selection
                multiple
                value={_.isArray(classInfo.teachers) ? classInfo.teachers : []}
                onChange={(event, { value }) => {
                  this.setState({
                    classes: {
                      ...classes,
                      [classInfo.classID]: {
                        ...classInfo,
                        teachers: value
                      }
                    }
                  })
                }}
                options={this.getTeachers().map(email => ({ key: email, text: email, value: email }))}
                placeholder="Chọn giáo viên phụ trách"
              />
            </Table.Cell>
          ) : null}
          <Table.Cell>Cần phải lưu thông tin lớp để có thể thêm dữ liệu cân đo</Table.Cell>
        </Table.Row>
      );
    return (
      <Table.Row key={classInfo.classID} style={style}>
        {allowEdit ? (
          <Table.Cell collapsing>
            <Icon
              color="blue"
              name="edit"
              onClick={() => this.setState({
                classEditor: {
                  classID: classInfo.classID,
                  name: classInfo.name,
                  grade: classInfo.grade,
                  teachers: classInfo.teachers
                }
              })}
            />
          </Table.Cell>
        ) : null}
        <Table.Cell collapsing>{classInfo.name}</Table.Cell>
        <Table.Cell collapsing>{_.join(classInfo.grade.map(grade => this.readDoTuoi(grade)), ", ")}</Table.Cell>
        {allowEdit ? (
          <Table.Cell collapsing>
            <Dropdown
              disabled={!allowEdit}
              selection
              multiple
              value={_.isArray(classInfo.teachers) ? classInfo.teachers : []}
              onChange={(event, { value }) => {
                this.setState({
                  classes: {
                    ...classes,
                    [classInfo.classID]: {
                      ...classInfo,
                      teachers: value
                    }
                  }
                })
              }}
              options={this.getTeachers().map(email => ({ key: email, text: email, value: email }))}
              placeholder="Chọn giáo viên phụ trách"
            />
          </Table.Cell>
        ) : null}
        <Table.Cell collapsing>{this.renderHealthPeriod(classInfo)}</Table.Cell>
      </Table.Row>
    );

  }


  getPeriodStatus = (classes) => {
    if (this.periodsStatus === undefined)
      this.periodsStatus = {};
      const {
        unit
      } = this.props;
    
    classes.forEach(classInfo => {
      if (this.periodsStatus[classInfo.classID] === undefined) {
        this.periodsStatus[classInfo.classID] = db.ref(`suckhoe/${unit.unitID}/${unit.activeYear}/classes/${classInfo.classID}/status`);
        this.periodsStatus[classInfo.classID].on("value", snapshot => {
          if (snapshot.val())
            this.setState({
              periodsStatus: {
                ...this.state.periodsStatus,
                [classInfo.classID]: snapshot.val()
              }
            });
          else
            this.setState({
              periodsStatus: {
                ...this.state.periodsStatus,
                [classInfo.classID]: {}
              }
            });
        });
      }
    });
  }

  render() {
    const {
      year,
      unit,
      successHandler,
      errorHandler,
      allowEdit,
      user,
    } = this.props;
    const {
      email,
      showRecords,
      dantoc,
      showStaffHealhTrack,
      showGeneral,
      periodsStatus,
      generalType,
      isWaiting
    } = this.state;
    const actions = [];
    const classes = this.getSortedClasses();
    this.getPeriodStatus(classes)
    const renderGeneral = () => {
      return (
        <div className="xuathongkeWrapper">
          <Dropdown text='Tổng hợp' className='link item xuathongke'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.setState({ generalType: 'byClass', showGeneral: true })}>Tổng hợp sức khỏe theo lớp</Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ generalType: 'byHealth', showGeneral: true })}>Tổng hợp tình trạng sức khỏe</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )
    }
    if (allowEdit) {
      actions.push(
        <Input
          key="input"
          style={{ width: 600 }}
          type="text"
          placeholder="Nhập Email của nhân sự để cấp quyền thao tác hệ thống sức khỏe"
          value={email}
          onChange={(event, { value }) => this.setState({ email: value })}
        >
          <input />
          <Button color="green" onClick={this.addEmailHandler}>
            <Icon name="add" />
            Thêm
          </Button>
        </Input>
      );
      actions.push(
        <Segment key="teachers" style={{ background: "transparent" }}>
          {this.getTeachers().map(teacher => (
            <Label key={teacher} as="a">
              {teacher}
              {' '}
              <Icon name="delete" onClick={() => this.removeTeacherHandler(teacher)} />
            </Label>
          ))}
        </Segment>
      );
    }
    return (
      <div>
        {(isWaiting || !unit || !dantoc || !periodsStatus ) ? (
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
        ) : null}
        { showStaffHealhTrack ? (
          <StaffsHealthTrack
            closeHandler={() => this.setState({ showStaffHealhTrack: false })}
            errorHandler={errorHandler}
            successHandler={successHandler}
            unit={unit}
            dantoc={dantoc}
          />
        ) : null}
        { showGeneral ? (
          <General
            closeHandler={() => this.setState({ showGeneral: false })}
            unit={unit}
            successHandler={successHandler}
            dantoc={dantoc}
            classes={classes}
            errorHandler={errorHandler}
            periodsStatus={periodsStatus}
            generalType={generalType}
          />
        ) : null}
        { showRecords ? (
          <ClassHealthRecords
            closeHandler={() => this.setState({ showRecords: false })}
            errorHandler={errorHandler}
            successHandler={successHandler}
            unit={unit}
            classInfo={showRecords}
            dantoc={dantoc}
            periodsStatus={periodsStatus}
          />
        ) : null}
        {_.keys(year).length > 1 ? (
          <Menu compact secondary color="brown" style={{ display: 'contents' }}>
            {_.map(year, (active, key) => (
              <Menu.Item
                style={{ float: 'left' }}
                active={active}
                key={key}
                onClick={() => this.yearHandler(key)}
              >
                Năm học
                {' '}
                {key}
              </Menu.Item>
            ))}
          </Menu>
        ) : null}
        {allowEdit ? (
          <Message warning>
            <Message.Header>Hướng dẫn</Message.Header>
            <Message.List>
              <Message.Item>
                {user.gender === 'M' ? 'Thầy' : 'Cô'}
                {' '}
                bổ sung danh sách nhân sự
                bằng cách thêm Email.
              </Message.Item>
              <Message.Item>
                {user.gender === 'M' ? 'Thầy' : 'Cô'}
                {' '}
                tạo lớp và chọn những
                Email nào được quyền cập nhật dữ liệu cân đo cho lớp đó.
              </Message.Item>
            </Message.List>
            <Button.Group className="right-top-button">
              {renderGeneral()}
              <Button
                color="orange"
                size="small"
                onClick={() => this.setState({ showStaffHealhTrack: true })}
              >
                <Icon name="file excel outline" />
                Sức khỏe cán bộ
              </Button>
            </Button.Group>
          </Message>
        ) : (
          <Message
            icon="chart line"
            warning
            header="Hướng dẫn"
            list={[
                `${user.gender === 'M' ? 'Thầy' : 'Cô'
                } bổ sung dữ liệu sức khỏe vào lớp của mình bằng cách ấn vào đợt khám tương ứng.`,
              ]}
          />
          )}
        {actions}
        <Message warning>
          <Message.Header>Chú thích màu</Message.Header>
          <Label style={{ marginTop: 5 }} color="green">
            Chưa có danh sách
          </Label>
          <Label style={{ marginTop: 5 }} color="brown">
            Còn bé chưa có số liệu sức khỏe
          </Label>
          <Label style={{ marginTop: 5 }} color="blue">
            Đã có số liệu sức khỏe cho tất cả các bé
          </Label>
        </Message>
        <Table style={{ background: "transparent" }} celled striped color="brown" unstackable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="solieuHeader" collapsing colSpan={allowEdit ? 2 : 1}>Tên lớp</Table.HeaderCell>
              <Table.HeaderCell className="solieuHeader" collapsing>Khối</Table.HeaderCell>
              {allowEdit ? <Table.HeaderCell className="solieuHeader" collapsing>Nhân sự</Table.HeaderCell> : null}
              <Table.HeaderCell className="solieuHeader" collapsing>Đợt khám sức khỏe</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {classes.map((classInfo, index) => this.renderClass(classInfo, index))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = ({ user, unit, year, cando }) => ({
  user,
  unit,
  year,
  cando,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      unitFetchInformation,
      candoFetch,
      candoClassFetch,
    },
    dispatch
  );

SucKhoe.propTypes = {
  errorHandler: PropTypes.func.isRequired,
  successHandler: PropTypes.func.isRequired,
  unitFetchInformation: PropTypes.func.isRequired,
  candoFetch: PropTypes.func.isRequired,
  unit: PropTypes.shape({
    unitID: PropTypes.string.isRequired,
    activeYear: PropTypes.string.isRequired,
    information: PropTypes.shape({
      rootID: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      teachers: PropTypes.string,
      classes: PropTypes.object,
      staffs: PropTypes.object,
      admin: PropTypes.string,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    gender: PropTypes.string,
  }).isRequired,
  year: PropTypes.object.isRequired,
  allowEdit: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(SucKhoe);
