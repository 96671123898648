/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Button,
  Modal,
  Header,
  Dimmer,
  Loader,
  Table,
  Label,
  Form,
  Radio
} from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";

// services
import { db } from "../../../libs/firebase";
import theodoisuckhoelop from './reports/theodoisuckhoelop';
import tonghopsuckhoelop from './reports/tonghopsuckhoelop';
import tonghopsolieukhamsuckhoe from "./reports/tonghopsolieukhamsuckhoe"

import {
  readHealth,
  readDoTuoi
} from './helpers';

class General extends Component {
  constructor(props) {
    super(props);
    this.changed = false;
    this.state = {
      isWaiting: false,
      classList: {},
      checkAllClasses: false,
      healthRecords: {},
      healthStatus: {},
      healthStatusData: {},
      isSecondPeriod: false,
    };
  }

  componentDidMount(){
    const healthRecords = this.getAllHealthRecords();
    const { errorHandler } = this.props;
    healthRecords.then((data) => {
      this.setState({ healthRecords: data, isWaiting: false });
    }).catch(() => {
       errorHandler("lỗi tải danh sách, vui lòng thử lại.")
      this.setState({ isWaiting: false });
    })
    
  }

  getAllHealthRecords = async () => {
    this.setState({ isWaiting: true });
    const res = {
    }
   const {
     unit: {
      unitID,
      activeYear,
   },
   classes,
   periodsStatus
  } = this.props;
   await db.ref(`suckhoe/${unitID}/${activeYear}/classes/`).once('value', snapshot => {
    if(snapshot.val()) {
      const allData = snapshot.val();
      _.forEach(classes, ({
        classID,
        name,
        grade
      }) => {
        _.forEach([1, 2], (period) => {
          const data = _.get(allData, `${classID}.periods.${period}`)
          if(data){
          _.set(res, `${classID}.lan${period}.students`, data );
          _.set(res, `${classID}.lan${period}.name`, name );
          _.set(res, `${classID}.lan${period}.grade`, grade );
         if(periodsStatus && periodsStatus[classID]) _.set(res, `${classID}.lan${period}.day`,  _.get(periodsStatus[classID][period], "day", 0));
          }
        })
      })
    }
  })
   return res;
  }


  renderAskForSave = () => (
    <Modal size="small" open>
      <Header className="form-header">Thông báo</Header>
      <Modal.Content scrolling>
        {this.props.user.gender === "M" ? "Thầy" : "Cô"}
        {' '}
        có muốn lưu lại những
        thay đổi vừa rồi không?
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" size="small" onClick={this.saveHandler}>
          <Icon name="checkmark" />
          {' '}
          Có
        </Button>
        <Button
          color="red"
          size="small"
          onClick={() =>
            this.setState({ showAskForSave: false }, this.props.closeHandler)}
        >
          Không
        </Button>
      </Modal.Actions>
    </Modal>
  );

  
  getHealthList = () => {
    const healthList = [];
    const { healthRecords, isSecondPeriod } = this.state;
      _.forEach(healthRecords, (ClassHealthRecord) => {
        const classData = _.get(ClassHealthRecord, isSecondPeriod ? 'lan2' : 'lan1');
        const students = _.get(classData, 'students');
        _.forEach(students, ({ healthRecords: healthData }) => {
          _.forEach(healthData, (health) => {
            if (!healthList.includes(health) && health !== 'BT') healthList.push(health);
          });
        });
      });
    return healthList;
  }

  getClassesAndStudentsHasSick = (healthKey) => {
    const { healthRecords, isSecondPeriod } = this.state;
    const sickClasses = [];
    const sickStudents = {};
    _.forEach(healthRecords, (ClassHealthRecord) => {
      const classData = _.get(ClassHealthRecord, isSecondPeriod ? 'lan2' : 'lan1')
      const month = isSecondPeriod ? 5 : 9 ;
      const className = _.get(classData, 'name');
      const day = _.get(classData, 'day');
      const students = _.get(classData, 'students');
      _.forEach(students, ({ healthRecords: healthData, ...data }, studentID) => {
          if(healthData && healthData.includes(healthKey)) {
            _.set(sickStudents, studentID, {
              ...data,
              className,
              day,
              month
            });
            if(!sickClasses.includes(className)) sickClasses.push(className);
          }
      });
    });
    return ({
      sickClasses,
      sickStudents,
      sick: healthKey
    })
  }

  render() {
    const {
      isWaiting, 
      checkAllClasses,
      classList,
      healthRecords,
      checkAllHealthStatus,
      healthStatus,
      healthStatusData,
      isSecondPeriod
    } = this.state;
    const {
      classes,
      closeHandler,
      errorHandler,
      unit,
      generalType,
    } = this.props;
    const classListArr = _.filter(_.map(classList, (selected, key) => {
      const info = _.find(classes, ({ classID }) => classID === key);
      return {
        ...info,
        selected,
      }
    }), ({ selected }) => selected);
    const exportHandler = (reportMode) => {
      this.setState({ isWaiting: true})
      const pickedPeriod = isSecondPeriod ? 'lan2' : 'lan1'
      const onComplete = () => this.setState({ isWaiting: false})
      if(reportMode === 1) {
        theodoisuckhoelop(
          classListArr,
          healthRecords,
          unit,
          errorHandler,
          closeHandler,
          onComplete,
          pickedPeriod,
        )
      } else if(reportMode === 2) {
        tonghopsuckhoelop(
          healthRecords,
          unit,
          errorHandler,
          closeHandler,
          onComplete,
          pickedPeriod
        )
      } else {
        tonghopsolieukhamsuckhoe(
          healthStatusData,
          unit,
          errorHandler,
          closeHandler,
          onComplete,

        )
      }
    }
    const Checkbox = ({
      type = 'checkbox',
      name,
      checked = false,
      onChange,
      label = "Chọn",
      header = false
    }) => (
      <div className="xuathongkeCheckbox">
        <input
          type={type}
          name={name}
          checked={checked}
          onChange={onChange}
          style={{ marginRight: '10px' }}
        />
        <Label className={header ? 'checkboxLabel' : ''}>{label}</Label>
      </div>
    );
    const checkAllHandler = () => {
      if(generalType === 'byClass') {
          const allClasses = {};
        _.forEach(classes, ({ classID }) => {
          _.set(allClasses, classID, !checkAllClasses);
        });
        this.setState((prevState) => ({
          checkAllClasses: !prevState.checkAllClasses,
          classList: allClasses
        }));
      } else {
        const healthList = this.getHealthList();
        const allHealthStatus = {};
        const allHealthStatusData = {}
        _.forEach(healthList, (healthKey) => {
          const classesAndStudentsHasSick = this.getClassesAndStudentsHasSick(healthKey)
          _.set(allHealthStatus, healthKey, !checkAllHealthStatus);
          _.set(allHealthStatusData, healthKey, classesAndStudentsHasSick);
        });
        this.setState((prevState) => ({
          checkAllHealthStatus: !prevState.checkAllHealthStatus,
          healthStatus: allHealthStatus,
          healthStatusData: allHealthStatusData
        }));
      }
    };
    const renderHealthStatus = () => {
      const healthList = this.getHealthList();
      return (
        <>
          <Table
            style={{ background: "transparent" }}
            celled
            striped
            color="brown"
            unstackable
            selectable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Tên bệnh
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Lớp
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  số lượng
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  <Checkbox
                    label="Chọn tất cả"
                    checked={checkAllHealthStatus}
                    onChange={() => checkAllHandler()}
                    header
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(healthList, (healthKey) => {
                const classesAndStudentsHasSick = this.getClassesAndStudentsHasSick(healthKey);
                const sickClasses = _.join(_.get(classesAndStudentsHasSick, 'sickClasses', []).map(sickClass => sickClass, ","));
                const numberOfSickStudent = _.keys(_.get(classesAndStudentsHasSick, 'sickStudents')).length
                return (
                  <Table.Row key={healthKey}>
                    <Table.Cell collapsing className="solieuValue">{readHealth(healthKey)}</Table.Cell>
                    <Table.Cell collapsing className="solieuValue">{sickClasses}</Table.Cell>
                    <Table.Cell collapsing className="solieuValue">{`${numberOfSickStudent} trẻ`}</Table.Cell>
                    <Table.Cell collapsing>
                      <Checkbox
                        name={readHealth(healthKey)}
                        checked={healthStatus[healthKey]}
                        onChange={() => {
                            this.setState((prevState) => (
                              {
                                healthStatus: {
                                  ...prevState.healthStatus,
                                  [healthKey]: !prevState.healthStatus[healthKey],
                                },
                                healthStatusData : {
                                  ...prevState.healthStatusData,
                                  [healthKey]: _.get(prevState, `healthStatus.${healthKey}`) ? {
                                  } : {
                                    ...classesAndStudentsHasSick,
                                  }
                                },
                                checkAllHealthStatus: false
                              }
                            ));
                          }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </>
      );
    }
    const getClassesByPeriod = () => {
      const res = {};
      const pickedPeriod = isSecondPeriod ? 'lan2' : 'lan1';
      _.forEach(healthRecords, (data, id) => {
        if(_.has(data, pickedPeriod)){
          const classInfo = _.find(classes, ({ classID }) => classID === id);
          _.set(res, id, classInfo)
        }
      });
      return res;
    }
    const renderClasses = () => {
      const classesByPeriod = getClassesByPeriod();
      return (
        <>
          <p style={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
            <span>* Chỉ có thể chọn tối đa 20 lớp </span>
            <span>
              {` (Đã chọn: ${_.filter(classList, (val) => val).length}/20)`}
            </span>
          </p>
          <Table
            style={{ background: "transparent" }}
            celled
            striped
            color="brown"
            unstackable
            selectable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Tên lớp
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Khối
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  Nhân sự
                </Table.HeaderCell>
                <Table.HeaderCell className="solieuHeader" collapsing>
                  <Checkbox
                    label="Chọn tất cả"
                    checked={checkAllClasses}
                    onChange={() => checkAllHandler()}
                    header
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(classesByPeriod, ({
              name,
              grade,
              teachers,
              classID
            }) => (
              <Table.Row key={classID}>
                <Table.Cell collapsing className="solieuValue">{name}</Table.Cell>
                <Table.Cell collapsing className="solieuValue">{_.join(grade.map(g => readDoTuoi(g)), ", ")}</Table.Cell>
                <Table.Cell collapsing className="solieuValue">{!_.isEmpty(teachers) ? _.join(teachers.map((teacher) => teacher, ', ')) : ''}</Table.Cell>
                <Table.Cell collapsing>
                  <Checkbox
                    name={name}
                    checked={classList[classID]}
                    onChange={() => {
                          this.setState((prevState) => (
                            {
                              classList: {
                                ...prevState.classList,
                                [classID]: !prevState.classList[classID],
                              },
                              checkAllClasses: false
                            }
                          ));
                        }}
                  />
                </Table.Cell>
              </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      );
    };
    if (isWaiting)
      return (
        <Dimmer active>
          <Loader>Đang xử lý...</Loader>
        </Dimmer>
      );
    return (
      <div>
        <Modal size="fullscreen" open>
          <Header className="form-header">
            <Header.Content as="h3">
              {generalType === 'byClass' ? 'Tổng hợp số liệu sức khoẻ toàn trường.' : 'Tổng hợp tình trạng sức khỏe'}
              {this.changed ? "(Chưa lưu)" : ""}
            </Header.Content>
            <Header.Subheader>
              <Form>
                <Form.Group inline>
                  <Label>Đợt khám sức khỏe</Label>
                  <Form.Field>
                    <Radio
                      label='Đợt 1'
                      name='radioGroup'
                      checked={!isSecondPeriod}
                      onChange={() => this.setState({ isSecondPeriod: !isSecondPeriod })}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Đợt 2'
                      name='radioGroup'
                      checked={isSecondPeriod}
                      onChange={() => this.setState({ isSecondPeriod: !isSecondPeriod })}
                    />
                  </Form.Field>
                </Form.Group>
                <Button
                  style={{ float: "right", marginTop: -50 }}
                  onClick={closeHandler}
                  type="button"
                >
                  <Icon name="angle left" />
                  Quay lại
                </Button>
              </Form>
            </Header.Subheader>
              
          </Header>
          {generalType === 'byClass' ? (
            <>
              <Modal.Content scrolling>
                {renderClasses()}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color="orange"
                  size="small"
                  onClick={() => exportHandler(1)}
                >
                  <Icon name="file excel outline" />
                  Theo dõi sức khoẻ theo lớp
                </Button>
                <Button
                  color="green"
                  size="small"
                  onClick={() => exportHandler(2)}
                >
                  <Icon name="file excel outline" />
                  Tổng hợp theo dõi sức khoẻ
                </Button>
              </Modal.Actions>
            </>
            ): (
              <>
                <Modal.Content> 
                  {renderHealthStatus()}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    color="orange"
                    size="small"
                    onClick={() => exportHandler(3)}
                  >
                    <Icon name="file excel outline" />
                    Xuất Excel
                  </Button>
                </Modal.Actions>
              </>
            )}
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

General.defaultProps = {
  dantoc: undefined,
  terms: {},
};

General.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
  }).isRequired,
  unit: PropTypes.shape({
    unitID: PropTypes.string.isRequired,
    activeYear: PropTypes.string.isRequired,
    information: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  periodsStatus: PropTypes.object.isRequired,
  classes: PropTypes.array.isRequired,
  generalType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(General);

