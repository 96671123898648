import { USER_FETCH, USER_IDENTIFY, USER_UNIT, SIGN_OUT, SYSTEM_ADMIN } from "../actions/constants";


const initialState = {
  displayName: "",
  email: "",
  phoneNumber: "",
  photoURL: "",
  gender: undefined,
  uid: "",
  eduunits: []
};

export default function user(state = initialState, action) {
  switch(action.type) {
    case USER_FETCH:
      return {
        ...state,
        displayName: action.displayName,
        email: action.email,
        phoneNumber: action.phoneNumber,
        photoURL: action.photoURL,
        uid: action.uid
      };
    case USER_IDENTIFY:
      return {
        ...state,
        displayName: action.displayName,
        phoneNumber: action.phoneNumber,
        photoURL: action.photoURL,
        gender: action.gender
      };
    case USER_UNIT:
      return {
        ...state,
        eduunits: [...state.eduunits, action.eduunit]
      };
    case SYSTEM_ADMIN:
      return {
        ...state,
        admin: true
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}