/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Header, Message, Button, Popup, Feed, Icon, Label, Segment, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";

// services
import { eventTracking } from "../../libs/ga";
import { db } from "../../libs/firebase";
import { convertTimeStamp } from "../../libs/time";


class NhanSu extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    nhansu: PropTypes.object.isRequired,
    manage: PropTypes.object.isRequired,
    user: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      gender: PropTypes.string,
    }).isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      activeMilestone: PropTypes.string,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        district: PropTypes.string,
        province: PropTypes.string
      }).isRequired
    }).isRequired,
    milestone: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      error: "",
      name: "Cán bộ quản lý",
      canbo: "",
      bienche: "",
      dantoc: ""
    }
  }

  componentWillMount() {
    if (this.props.unit.information.type === "pgd" && this.props.nhansu.data !== undefined)
      this.setState({
        canbo: (this.props.nhansu.data.canbo !== undefined)? this.props.nhansu.data.canbo  : "",
        bienche: (this.props.nhansu.data.bienche !== undefined)? this.props.nhansu.data.bienche : "",
        dantoc: (this.props.nhansu.data.dantoc !== undefined)? this.props.nhansu.data.dantoc : ""
      });
    else if (this.props.unit.information.type === "sgd" && this.props.nhansu[this.props.unit.unitID] !== undefined && this.props.nhansu[this.props.unit.unitID].data !== undefined)
      this.setState({
        canbo: (this.props.nhansu[this.props.unit.unitID].data.canbo !== undefined)? this.props.nhansu[this.props.unit.unitID].data.canbo : "",
        bienche: (this.props.nhansu[this.props.unit.unitID].data.bienche !== undefined)? this.props.nhansu[this.props.unit.unitID].data.bienche : "",
        dantoc: (this.props.nhansu[this.props.unit.unitID].data.dantoc !== undefined)? this.props.nhansu[this.props.unit.unitID].data.dantoc : "",
        canbodatchuantrolen: (this.props.nhansu[this.props.unit.unitID].data.canbodatchuantrolen !== undefined)? this.props.nhansu[this.props.unit.unitID].data.canbodatchuantrolen : "",
        canbotrenchuan: (this.props.nhansu[this.props.unit.unitID].data.canbotrenchuan !== undefined)? this.props.nhansu[this.props.unit.unitID].data.canbotrenchuan : "",
        canbothieu: (this.props.nhansu[this.props.unit.unitID].data.canbothieu !== undefined)? this.props.nhansu[this.props.unit.unitID].data.canbothieu : ""
      });
  }

  saveComplete = (error) => {
    this.setState({
      isSaving: false
    });
    if (error) {
      this.props.errorHandler("Lưu thông tin nhân sự thất bại.");
    } else {
      this.props.successHandler("Lưu thông tin nhân sự thành công.");
    }
  }

  validateInput = (value) => {
    if (isNaN(value)) {
      this.props.errorHandler("Giá trị không hợp lệ", "Giá trị nhập vào phải là số.");
      return 0;
    }
    return (value === "")? 0 : parseInt(value, 10);
  }

  saveHandler = () => {
    if (this.state.isSaving)
      return;
    this.setState({
      isSaving: true
    });
    const data = {
      canbo: (this.state.canbo === undefined)? "" : this.state.canbo,
      bienche: (this.state.bienche === undefined)? "" : this.state.bienche,
      dantoc: (this.state.dantoc === undefined)? "" : this.state.dantoc
    };
    if (this.props.unit.information.type === "sgd") {
      data.canbodatchuantrolen = (this.state.canbodatchuantrolen === undefined)? "" : this.state.canbodatchuantrolen;
      data.canbotrenchuan = (this.state.canbotrenchuan === undefined)? "" : this.state.canbotrenchuan;
      data.canbothieu = (this.state.canbothieu === undefined)? "" : this.state.canbothieu;
    }
      
    eventTracking("nhansu", "save", 1, this.props.unit.unitID);
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/nhansu/${this.props.unit.unitID}`).update({
      modifiedBy: {
        email: this.props.user.email,
        displayName: this.props.user.displayName,
        gender: this.props.user.gender
      },
      modifiedTime: (new Date()).getTime(),
      data
    }, this.saveComplete);
  }

  renderMilestone = () => {
    if (this.props.milestone !== undefined && this.props.milestone[this.props.unit.activeMilestone] !== undefined)
      return <Message color="teal">{this.props.milestone[this.props.unit.activeMilestone].description}</Message>;
    return null;
  }

  renderStatus = () => {
    if (this.props.nhansu === undefined || this.props.nhansu[this.props.unit.unitID] === undefined || this.props.nhansu[this.props.unit.unitID].modifiedBy === undefined)
      return null;
    return (
      <Feed>
        <Feed.Event>
          <Feed.Label>
            <Icon name="save" />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>
              Lần cập nhật gần nhất được thực hiện bởi {(this.props.nhansu[this.props.unit.unitID].modifiedBy.gender === "M")? "Thầy " : "Cô "}
              <Popup 
                trigger={
                  <Label as='a'>
                    <Icon name={(this.props.nhansu[this.props.unit.unitID].modifiedBy.gender === "M")? "male" : "female"} />
                    {this.props.nhansu[this.props.unit.unitID].modifiedBy.displayName}
                  </Label>
                }
                content={this.props.nhansu[this.props.unit.unitID].modifiedBy.email}
              />.
              <Feed.Date>{convertTimeStamp(this.props.nhansu[this.props.unit.unitID].modifiedTime)}</Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    );
  }

  renderRow = (row) => (
    <Table.Row key={row.unitID}>
      <Table.Cell width="8">{row.name}</Table.Cell>
      <Table.Cell width="2">{row.canbo}</Table.Cell>
      <Table.Cell width="2">{row.bienche}</Table.Cell>
      <Table.Cell width="2">{row.tyle}</Table.Cell>
      <Table.Cell width="2">{row.dantoc}</Table.Cell>
    </Table.Row>
  )

  renderResult = () => {
    const units = [];
    _.forEach(_.keys(this.props.nhansu), unit => {
      if (this.props.unit.unitID !== unit) {
        let tyle = "";
        if (!isNaN(this.props.nhansu[unit].data.canbo) && !isNaN(this.props.nhansu[unit].data.bienche) && this.props.nhansu[unit].data.canbo !== 0)
          tyle = this.props.nhansu[unit].data.bienche/this.props.nhansu[unit].data.canbo*100;
        units.push({
          unitID: unit,
          name: (this.props.manage !== undefined && this.props.manage[unit] !== undefined && this.props.manage[unit].information !== undefined)? this.props.manage[unit].information.name : "",
          canbo: !isNaN(this.props.nhansu[unit].data.canbo)? this.props.nhansu[unit].data.canbo : "",
          bienche: !isNaN(this.props.nhansu[unit].data.bienche)? this.props.nhansu[unit].data.bienche : "",
          tyle: !isNaN(tyle)? Math.round(tyle) : "",
          dantoc: !isNaN(this.props.nhansu[unit].data.dantoc)? this.props.nhansu[unit].data.dantoc : "",
        });
      }
    });
    return (
      <Table.Body>
        {units.map((unit) => this.renderRow(unit))}
      </Table.Body>
    );
  }

  render() {
    if (this.state.error !== "")
      return (
        <Message
          error
          header={this.state.error}
          list={[
            "Khởi động lại trang web hoặc truy cập vào thời điểm khác.",
            "Liên hệ đến nhân viên XBOT để được hỗ trợ.",
          ]}
        />
      );

    this.inputs = [];
    return (
      <div>
        {this.renderMilestone()}
        <Header className="report-header" as="h3">{this.state.name}</Header>
        <Segment stacked style={{ background: "transparent" }}>
          <Header as="h1">{this.renderStatus()}</Header>
          <Form style={{maxWidth:890}}>
            <Form.Group>
              <Form.Input 
                label="Số cán bộ" 
                placeholder="Nhập tổng số cán bộ" 
                width={6} 
                value={this.state.canbo} 
                onChange={(e, { value }) => this.setState({ canbo: this.validateInput(value) })}
              />
              <Form.Input 
                label="Có biên chế" 
                placeholder="Nhập số cán bộ có biên chế" 
                width={5} 
                value={this.state.bienche} 
                onChange={(e, { value }) => this.setState({ bienche: this.validateInput(value) })}
              />
              <Form.Input 
                label="Dân tộc" 
                placeholder="Nhập số cán bộ người dân tộc" 
                width={5} 
                value={this.state.dantoc} 
                onChange={(e, { value }) => this.setState({ dantoc: this.validateInput(value) })}
              />
            </Form.Group>
            {(this.props.unit.information.type === "sgd")?
              <Form.Group>
                <Form.Input 
                  label="CB Sở, Phòng đạt chuẩn trở lên" 
                  placeholder="Nhập số CB đạt chuẩn trở lên" 
                  width={6} 
                  value={this.state.canbodatchuantrolen} 
                  onChange={(e, { value }) => this.setState({ canbodatchuantrolen: this.validateInput(value) })}
                />
                <Form.Input 
                  label="Trên chuẩn" 
                  placeholder="Nhập số CB trên chuẩn" 
                  width={5} 
                  value={this.state.canbotrenchuan} 
                  onChange={(e, { value }) => this.setState({ canbotrenchuan: this.validateInput(value) })}
                />
                <Form.Input 
                  label="CB Sở, Phòng còn thiếu" 
                  placeholder="Nhập số CB còn thiếu (theo quy định)" 
                  width={5} 
                  value={this.state.canbothieu} 
                  onChange={(e, { value }) => this.setState({ canbothieu: this.validateInput(value) })}
                />
              </Form.Group> : null
            }
            <Button style={{marginTop: "10px"}} loading={this.state.isSaving} icon="save" size="small" color="blue" onClick={this.saveHandler} content="Cập nhật" />
          </Form>
        </Segment>
        {(this.props.unit.information.type === "sgd")?
          <Table unstackable celled striped color="brown" style={{ background: "transparent" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="stt">Tên đơn vị</Table.HeaderCell>
                <Table.HeaderCell className="stt">Số cán bộ</Table.HeaderCell>
                <Table.HeaderCell className="stt">Biên chế</Table.HeaderCell>
                <Table.HeaderCell className="stt">Tỷ lệ (%)</Table.HeaderCell>
                <Table.HeaderCell className="stt">Dân tộc</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {this.renderResult()}
          </Table> : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  unit: state.unit,
  manage: state.manage,
  nhansu: state.data.nhansu,
  milestone: state.milestone
});

export default connect(mapStateToProps)(NhanSu);