import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Button, Input, Dimmer, Loader, Dropdown, Divider, Message } from "semantic-ui-react";
import NotificationSystem from "react-notification-system";
import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// services
import { app, db } from "../../libs/firebase";
import { userIdentify, userAddUnit, signOut } from "../../redux/actions/users";


const btoa = require("btoa");

class ProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      isLoading: false,
      forceProfileUpdate: this.props.forceProfileUpdate,
      displayName: this.props.user.displayName,
      phoneNumber: this.props.user.phoneNumber,
      photoURL: this.props.user.photoURL,
      gender: this.props.user.gender,
      activeUnit: this.props.unit.unitID,
      messages: this.props.messages
    };
  }

  validateInformation = () => {
    if (this.state.displayName === "") {
      this.notifyHandler("warning", "Thông tin không hợp lệ", "Họ và tên không được bỏ trống.");
      return false;
    }
    if (this.state.gender === undefined) {
      this.notifyHandler("warning", "Thông tin không hợp lệ", "Giới tính không được bỏ trống.");
      return false;
    }
    if (this.state.activeUnit === undefined) {
      this.notifyHandler("warning", "Chưa kích hoạt đơn vị", "Để sử dụng hệ thống báo cáo cần phải chọn đơn vị sử dụng.");
      return false;
    }
    return true;
  }

  saveHandler = () => {
    if (this.validateInformation()) {
      this.setState({ isWaiting: true });
      const eduunits = {};
      if (this.props.user.eduunits !== undefined)
        this.props.user.eduunits.forEach((unit) => {
          eduunits[unit.unitID] = (unit.unitID === this.state.activeUnit);
        });
      db.ref(`users/${this.props.user.uid}`).update({
        displayName: this.state.displayName,
        phoneNumber: this.state.phoneNumber,
        photoURL: this.state.photoURL,
        gender: this.state.gender,
        eduunits
      }, (this.updateComplete));
    }
  }

  updateComplete = (error) => {
    if (error) {
      this.notifyHandler("error", "Lỗi", error)
      this.setState({ isWaiting: false, messages: undefined });
      return;
    }
    this.props.userIdentify(this.state.displayName, this.state.phoneNumber, this.state.photoURL, this.state.gender);
    this.notifyHandler("success", "Hoàn tất", "Cập nhật thành công.");
    this.setState({ isWaiting: false, forceProfileUpdate: false, messages: undefined });
    if (this.props.unit.unitID !== this.state.activeUnit)
      setTimeout(() => window.location.reload(), 500);
  }

  logoutHandler = () => {
    app.auth().signOut().then(() => {
      this.props.signOut();
    });
  }

  notifyHandler = (type, title, message) => {
    this.notificationSystem.addNotification({
      title,
      message,
      level: type,
      position: "tr"
    });
  }

  validateStaffsUnits = (units) => {
    let index = 0;
    units.forEach(unit => {
      index += 1;
      // check if user's certificate is still valid
      db.ref(`certificate/eduunits/${unit.key}`).once("value", (unitCertificate) => {
        let valid = false;
        if (unitCertificate !== null && unitCertificate.val() !== null) {
          if (unitCertificate.val().admin !== undefined && unitCertificate.val().admin.indexOf(this.props.user.email) !== -1)
            valid = true;
          if (unitCertificate.val().staffs !== undefined && unitCertificate.val().staffs.indexOf(this.props.user.email) !== -1)
            valid = true;
        }
        if (valid) {
          db.ref(`eduunits/${unit.key}/staffs`).update({ [this.props.user.uid]: this.props.user.email }, (err) => {
            if (index === units.numChildren())
              this.setState({ isWaiting: false });
            if (err)
              this.notifyHandler("error", "Lỗi", "Cập nhật thông tin đơn vị không thành công. Xin vui lòng thử tải lại trang Web.");
            else if (this.props.unit.unitID !== unit.key) {
              db.ref(`users/${this.props.user.uid}/eduunits`).update({ [unit.key]: false }, (lastErr) => {
                if (lastErr)
                  this.notifyHandler("error", "Lỗi", "Cập nhật thông tin xác thực không thành công. Xin vui lòng thử tải lại trang Web.");
                else if (_.findIndex(this.props.user.eduunits, (item) => item.unitID === unit.key) === -1)
                  db.ref(`eduunits/${unit.key}/`).once("value", (eduData) => {
                    if (eduData !== null && eduData.val() !== null)
                      this.props.userAddUnit({ ...eduData.val(), unitID: unit.key });
                  }, (fail) => {
                    if (fail)
                      this.notifyHandler("error", "Lỗi", "Cập nhật thông tin xác thực không thành công. Xin vui lòng thử tải lại trang Web.");
                  });
              });
            }
          });
        } else if (index === units.numChildren())
          this.setState({ isWaiting: false });
      }, () => {
        if (index === units.numChildren())
          this.setState({ isWaiting: false });
      });
    });
  }

  validateTeachersUnits = (units) => {
    let index = 0;
    units.forEach(unit => {
      index += 1;
      // check if user's certificate is still valid
      db.ref(`eduunits/${unit.key}/`).once("value", (unitData) => {
        if (unitData !== null && unitData.val() !== null && unitData.val().teachers !== undefined) {
          if ((this.props.unit.unitID !== unit.key) && _.indexOf(JSON.parse(unitData.val().teachers), this.props.user.email) !== -1) {
            db.ref(`users/${this.props.user.uid}/eduunits`).update({ [unit.key]: false }, (error) => {
              if (index === units.numChildren())
                this.setState({ isLoading: false });
              if (error)
                this.notifyHandler("error", "Lỗi", "Cập nhật thông tin không thành công. Xin vui lòng thử tải lại trang Web.");
              else if (_.findIndex(this.props.user.eduunits, (item) => item.unitID === unit.key) === -1)
                this.props.userAddUnit({ ...unitData.val(), unitID: unit.key });
            });
          }
        }
        if (index === units.numChildren())
          this.setState({ isLoading: false });
      });
    });
  }

  updateUnitsHandler = () => {
    this.setState({ isWaiting: true, isLoading: true });
    db.ref(`certificate/email/${btoa(this.props.user.email)}/eduunits`).once("value", (data) => {
      if (data !== null && data.val() !== null) {
        this.setState({ messages: _.filter(this.state.messages, mess => mess.indexOf("Cập nhật danh sách đơn vị") === -1) });
        this.validateStaffsUnits(data);
      } else
        this.setState({ isWaiting: false });
    }, (error) => {
      this.setState({ isWaiting: false });
      if (error)
        this.notifyHandler("error", "Lỗi", "Kết nối tới hệ thống không thành công.");
    });

    db.ref(`certificate/cando/${btoa(this.props.user.email)}/eduunits`).once("value", (data) => {
      if (data !== null && data.val() !== null) {
        this.setState({ messages: _.filter(this.state.messages, mess => mess.indexOf("Cập nhật danh sách đơn vị") === -1) });
        this.validateTeachersUnits(data);
      } else
        this.setState({ isLoading: false });
    }, (error) => {
      this.setState({ isLoading: false });
      if (error)
        this.notifyHandler("error", "Lỗi", "Kết nối tới hệ thống không thành công.");
    });
  }

  render() {
    const eduunits = this.props.user.eduunits.map(unit => ({ key: unit.unitID, text: unit.name, value: unit.unitID }));
    return (
      <div style={{ margin: "auto", right: 0, left: 0, borderRadius: 10 }}>
        <center>
          {(this.state.isWaiting || this.state.isLoading) ?
            <Dimmer active inverted>
              <Loader inverted>Đang kết nối...</Loader>
            </Dimmer>
            : null
          }
        </center>
        {(this.state.messages !== undefined) ?
          this.state.messages.map((message, index) => <Message key={index} color="blue">{message}</Message>) : null
        }
        <Form>
          <Form.Field required>
            <label>Họ và tên</label>
            <Input
              value={this.state.displayName}
              onChange={(e, { value }) => this.setState({ displayName: value })}
              icon="user"
              placeholder="Họ và tên"
              iconPosition="left"
            />
          </Form.Field>
          <Form.Field>
            <label>Số điện thoại</label>
            <Input
              value={this.state.phoneNumber}
              onChange={(e, { value }) => this.setState({ phoneNumber: value })}
              icon="phone"
              placeholder="Số điện thoại"
              iconPosition="left"
            />
          </Form.Field>
          <Form.Field required>
            <label>Giới tính</label>
            <Dropdown
              selection
              multiple={false}
              onChange={(event, { value }) => this.setState({ gender: value })}
              options={[{ key: "M", text: "Nam", value: "M" }, { key: "F", text: "Nữ", value: "F" }]}
              defaultValue={this.state.gender}
              placeholder="Giới tính"
            />
          </Form.Field>
          {(this.props.user.eduunits.length > 0) ?
            <Form.Field required>
              <label>Đơn vị</label>
              <Dropdown
                selection
                multiple={false}
                onChange={(event, { value }) => this.setState({ activeUnit: value })}
                options={eduunits}
                defaultValue={this.state.activeUnit}
                placeholder="Chọn đơn vị sử dụng"
              />
            </Form.Field>
            : null
          }
          <Divider />
          <Button floated="left" onClick={this.updateUnitsHandler}>Cập nhật danh sách đơn vị</Button>
          <div style={{ float: "right", paddingBottom: 10 }}>
            <Button basic color="brown" onClick={this.saveHandler}>Cập nhật</Button>
            {this.state.forceProfileUpdate ?
              <Button basic color="grey" onClick={this.logoutHandler}>Đăng xuất</Button> : <Button basic color="grey" onClick={this.props.closeHandler}>Đóng</Button>
            }
          </div>
        </Form>
        <NotificationSystem ref={(ref) => { this.notificationSystem = ref }} />
      </div>
    );
  }
}

ProfileForm.propTypes = {
  signOut: PropTypes.func.isRequired,
  userIdentify: PropTypes.func.isRequired,
  userAddUnit: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  forceProfileUpdate: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    photoURL: PropTypes.string.isRequired,
    gender: PropTypes.string,
    eduunits: PropTypes.array.isRequired
  }).isRequired,
  unit: PropTypes.shape({
    unitID: PropTypes.string
  }).isRequired
};

ProfileForm.defaultProps = {
  forceProfileUpdate: false,
  messages: undefined
}


const mapStateToProps = (state) => ({
  user: state.user,
  unit: state.unit
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  signOut, userIdentify, userAddUnit
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);