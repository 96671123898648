/* eslint no-eval: 0 */
/* eslint-disable no-restricted-globals */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Modal, Header, Tab, Dimmer, Loader, Segment, Divider, List, Message, Button, Icon, Dropdown, Popup } from "semantic-ui-react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from "file-saver";
import _ from "lodash";

// components
import ThongTinChung from "./ThongTinChung";
import ThongTinTruong from "./ThongTinTruong";
import NhanSu from "./NhanSu";
import UserHeader from "../../components/UserHeader";
import ProfileForm from "../../components/ProfileForm";
import Report from "./report";
import Watch from "./watch";
import Manage from "./manage";
import Settings from "./settings";
import CanDo from "./CanDo";
import DinhDuong from "./DinhDuong";
import SucKhoe from "./SucKhoe";

// services
import { eventTracking } from "../../libs/ga";
import { db } from "../../libs/firebase";
import { reportsFetch } from "../../redux/actions/reports";
import { updateData } from "../../redux/actions/data";
import { computeSGDExportLibs, evaluateReportsBySchoolType, computeSGDExportRows, writeReport } from "../../libs/export";
import { reportStatus } from "../../redux/actions/status";
import { getCustomLogic, expandLogic, expandExp } from "../../libs/report";
import { convertTimeStamp } from "../../libs/time";
import { isNotAdminAndStaff } from "../../libs/school";
import { RESOURCES } from "../../libs/config";

// styles
import "./styles.css";

const restrictMeasurementsSGDs = ['tayninh'];


class Workspace extends Component {
  static propTypes = {
    reportStatus: PropTypes.func.isRequired,
    user: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      gender: PropTypes.string,
      eduunits: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string,
      activeYear: PropTypes.string,
      activeMilestone: PropTypes.string,
      notification: PropTypes.object,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        schoolModel: PropTypes.string,
        schoolType: PropTypes.string,
        xakhokhan: PropTypes.bool,
        units: PropTypes.object,
        province: PropTypes.string,
        district: PropTypes.string,
        admin: PropTypes.string,
        staffs: PropTypes.object.isRequired,
        plugins: PropTypes.shape({
          cando: PropTypes.bool,
          dinhduong: PropTypes.bool
        }),
        sgd: PropTypes.string.isRequired,
      })
    }).isRequired,
    milestone: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    reports: PropTypes.object.isRequired,   // eslint-disable-line react/forbid-prop-types
    data: PropTypes.object.isRequired,      // eslint-disable-line react/forbid-prop-types
    status: PropTypes.object.isRequired,    // eslint-disable-line react/forbid-prop-types
    manage: PropTypes.object.isRequired     // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      showProfileForm: false,
      isExporting: false,
      menuActive: isNotAdminAndStaff(this.props.unit.information, this.props.user) ? "thongtintruong" : "report",
    };
  }

  componentWillMount() {
    let showProfileForm = false;
    let forceProfileUpdate = false;
    const profileMessages = [];
    if (this.props.user.gender === undefined) {
      showProfileForm = true;
      forceProfileUpdate = true;
      profileMessages.push("Xin hãy cập nhật thông tin về giới tính để giúp hệ thống tương tác với bạn tốt hơn.");
    }
    if (this.props.user.eduunits === undefined || this.props.user.eduunits.length === 0) {
      showProfileForm = true;
      forceProfileUpdate = true;
      profileMessages.push("Xin vui lòng ấn nút \"Cập nhật danh sách đơn vị\".");
    }
    else if (this.props.unit.unitID === undefined) {
      showProfileForm = true;
      forceProfileUpdate = true;
      profileMessages.push("Xin vui lòng chọn một đơn vị để sử dụng hệ thống.");
    }

    this.setState({
      showProfileForm,
      forceProfileUpdate,
      profileMessages
    });
  }

  /**
   * Explan why user cannot send report
   *
   * @return  {string} reason
   */
  getSendReportDescription = () => {
    const suggest = `Xin vui lòng liên hệ ${(this.props.unit.information.type === "th") ? "Phòng Giáo dục" : "Sở Giáo dục"} để được hỗ trợ.`;
    if (this.props.unit.notification === undefined)
      return `${(this.props.unit.information.type === "th") ? "Phòng Giáo dục" : "Sở Giáo dục"} chưa có hạn nộp báo cáo cho đợt này. ${suggest}`;
    const status = this.props.status[this.props.unit.unitID];
    if (status !== null && status !== undefined && status.status === "accepted")
      return `${(this.props.unit.information.type === "th") ? "Phòng Giáo dục" : "Sở Giáo dục"} đã duyệt báo cáo nên không thể gửi báo cáo. ${suggest}`;

    if (new Date().getTime() > this.props.unit.notification.deadline)
      return `Đã qua hạn nộp nên không thể gửi báo cáo. ${suggest}`;

    return `Không thể gửi báo cáo. ${suggest}`;
  }

  runSendReport = (data) => {
    const { key } = db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${this.props.unit.unitID}/data`).push(JSON.stringify(data));
    if (key !== null && key !== undefined) {
      // attach author information
      const author = {
        displayName: this.props.user.displayName,
        email: this.props.user.email,
        gender: this.props.user.gender
      };
      const time = new Date().getTime();
      db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${this.props.unit.unitID}/status`).push({
        author,
        time,
        data: key
      }, (error) => {
        if (error)
          this.errorHandler("Gửi báo cáo thất bại. Xin hãy thử lại vào lúc khác.");
        else {
          db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${this.props.unit.unitID}/lasted`).update({
            author,
            time,
            data: key,
            status: "waiting"
          }, (err) => {
            if (err)
              this.errorHandler("Cập nhật trạng thái thất bại. Xin hãy thử lại vào lúc khác.");
            else if (this.props.unit.information.type === "pgd") {
              const compact = {};
              _.forEach(this.props.manage || {}, (childData, childID) => {
                compact[childID] = {
                  name: _.get(childData, "information.name", ""),
                  schoolType: _.get(childData, "information.schoolType", ""),
                  schoolModel: _.get(childData, "information.schoolModel", ""),
                  xadatchuan: _.get(childData, "information.xadatchuan", ""),
                  xakhokhan: _.get(childData, "information.xakhokhan", ""),
                  xanongthonmoi: _.get(childData, "information.xanongthonmoi", "")
                }
              })
              db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${this.props.unit.unitID}/units`).update(
                compact, (unitsErr) => {
                  if (unitsErr)
                    this.errorHandler("Cập nhật trạng thái thất bại. Xin hãy thử lại vào lúc khác.");
                  else
                    this.successHandler("Gửi báo cáo thành công");
                }
              );
            } else
              this.successHandler("Gửi báo cáo thành công");
          });
        }
      });
    } else
      this.errorHandler("Gửi báo cáo thất bại. Xin hãy thử lại vào lúc khác.");
    this.setState({ showResultsVerification: undefined, resultsVerification: undefined, showForceButton: undefined, forceSendData: undefined });
  }

  forceSendReport = (data) => {
    eventTracking("report", "force send", 1, this.props.unit.unitID);
    this.runSendReport(data);
  }

  exportErrorHandler = () => {
    this.errorHandler("Xuất báo cáo thất bại. Xin vui lòng thử lại sau.");
    this.setState({ isExporting: false });
  }

  exportBGDReport = (workbook) => {
    db.ref("eduunits").orderByChild("sgd").equalTo(this.props.unit.unitID).once("value", units => {
      if (units !== null && units.val() !== null && units.numChildren() > 0) {
        const libs = computeSGDExportLibs(this.props.unit.unitID, units, this.props.unit.information.units, this.props.unit.information.province, this.props.data);
        const reports = evaluateReportsBySchoolType(this.props.unit.unitID, this.props.data);
        if (this.props.reports.exports !== undefined) {
          _.keys(this.props.reports.exports).forEach(sheet => {
            if (workbook.sheet(sheet) !== undefined) {
              if (this.props.reports.exports[sheet].cells !== undefined) {
                _.keys(this.props.reports.exports[sheet].cells).forEach(cell => {
                  workbook.sheet(sheet).cell(cell).value(((libs[this.props.reports.exports[sheet].cells[cell].eval] !== undefined) ? libs[this.props.reports.exports[sheet].cells[cell].eval] : 0));
                });
              }
              if (this.props.reports.exports[sheet].rows !== undefined) {
                const evaluatedRows = computeSGDExportRows(this.props.reports.exports[sheet].rows, reports);
                _.keys(evaluatedRows).forEach(row => {
                  workbook.sheet(sheet).cell(`E${row}`).value(evaluatedRows[row].cl);
                  workbook.sheet(sheet).cell(`F${row}`).value(evaluatedRows[row].dl);
                  workbook.sheet(sheet).cell(`G${row}`).value(evaluatedRows[row].tt);
                })
              }
            }
          });
        }
      }
      return workbook.outputAsync().then(data => {
        this.setState({ isExporting: false });
        if (data !== undefined)
          saveAs(data, "Baocao_BGD.xlsx");
        else
          this.errorHandler("Xuất báo cáo thất bại. Xin vui lòng thử lại sau.");
      });
    }, () => {
      this.exportErrorHandler();
    });
  }

  exportSGDReport = (workbook, units = {}) => {
    if (this.props.reports !== undefined && this.props.reports[this.props.unit.information.rootID] !== undefined) {
      _.keys(this.props.reports[this.props.unit.information.rootID]).forEach(report => {
        if (_.get(this.props.data, `${this.props.unit.unitID}.${report}.data`))
          writeReport(
            this.props.unit.information.type,
            workbook,
            this.props.data[this.props.unit.unitID][report].data,
            this.props.reports[this.props.unit.information.rootID][report].export,
            this.props.manage,
            this.props.unit.information.name,
            units
          );
      });
      const schema = _.get(this.props.reports, `${this.props.unit.information.rootID}.dsuckhoemg.export`) || _.get(this.props.reports, `${this.props.unit.information.rootID}.esuckhoent.export`);
      if (schema) {
        schema.sheet = 2;
        const tonghopsk = (this.props.unit.information.type === "th") ? _.get(this.props.data, `${this.props.unit.unitID}.dsuckhoemg.data`, {}) : {
          cl: _.cloneDeep(_.get(this.props.data, `${this.props.unit.unitID}.dsuckhoemg.data.cl`, {})),
          dl: _.cloneDeep(_.get(this.props.data, `${this.props.unit.unitID}.dsuckhoemg.data.dl`, {})),
          tt: _.cloneDeep(_.get(this.props.data, `${this.props.unit.unitID}.dsuckhoemg.data.tt`, {}))
        };
        const esuckhoent = _.cloneDeep(_.get(this.props.data, `${this.props.unit.unitID}.esuckhoent.data`, {}));
        if (this.props.unit.information.type === "th") {
          _.forEach(esuckhoent, (value, key) => {
            tonghopsk[key] = _.get(tonghopsk, key, 0) + value;
          });
        }
        else {
          _.forEach(["cl", "dl", "tt"], schoolType => {
            if (esuckhoent[schoolType]) {
              _.forEach(esuckhoent[schoolType], (unit, unitID) => {
                if (tonghopsk[schoolType][unitID]) {
                  if (this.props.unit.information.type === 'pgd')
                    _.forEach(unit, (value, key) => {
                      tonghopsk[schoolType][unitID][key] = _.get(tonghopsk[schoolType][unitID], key, 0) + value;
                    });
                  else
                    _.forEach(unit, (schoolData, schoolID) => {
                      if (tonghopsk[schoolType][unitID][schoolID])
                        _.forEach(schoolData, (value, key) => {
                          tonghopsk[schoolType][unitID][schoolID][key] = _.get(tonghopsk[schoolType][unitID][schoolID], key, 0) + value;
                        });
                      else
                        tonghopsk[schoolType][unitID][schoolID] = _.cloneDeep(schoolData);
                    });
                } else
                  tonghopsk[schoolType][unitID] = _.cloneDeep(unit);
              });
            }
          });
        }
        writeReport(
          this.props.unit.information.type,
          workbook,
          tonghopsk,
          schema,
          this.props.manage,
          this.props.unit.information.name,
          units
        );
      }
    }
    return workbook.outputAsync().then(data => {
      this.setState({ isExporting: false });
      if (data !== undefined)
        saveAs(data, "Baocao_SGD.xlsx");
      else
        this.errorHandler("Xuất báo cáo thất bại. Xin vui lòng thử lại sau.");
    });
  }

  exportReport = (type) => {
    if (this.state.isExporting)
      return;
    this.setState({ isExporting: true });

    const { information, activeYear, activeMilestone } = this.props.unit;
    const { rootID, type: unitsType } = information;
    const template = (type === "bgd") ? `${rootID}_bgd_export.xlsx` : `${rootID}_sgd_export.xlsx`;

    try {
      const req = new XMLHttpRequest();
      req.open("GET", `${RESOURCES}/rebot/export/${template}`, true);
      req.responseType = "arraybuffer";
      req.onload = () => {
        XlsxPopulate.fromDataAsync(req.response).then(wb => {
          if (type === "bgd")
            this.exportBGDReport(wb);
          else if (type === "sgd") {
            if (unitsType === "sgd") {
              db.ref(`rebot/${rootID}/${activeYear}/${activeMilestone}/units`).once("value", units => {
                this.exportSGDReport(wb, units.val() || {});
              }, () => this.exportSGDReport(wb));
            } else
              this.exportSGDReport(wb);
          } else
            this.exportErrorHandler();
        }, (error) => {
          this.setState({ isWaiting: false });
          if (error)
            this.exportErrorHandler();
        });
      }
      req.onerror = () => {
        this.exportErrorHandler();
      }
      req.send();
    } catch (err) {
      this.exportErrorHandler();
    }
  }

  sendReport = () => {
    if (this.props.unit.information.rootID === undefined || this.props.unit.information.rootID === "" || this.props.unit.activeYear === undefined) {
      this.errorHandler("Thông tin xác thực không đúng. Có thể lỗi phát sinh do quá trình khởi động, xin vui lòng khởi động lại trang Web và thử lại.");
      return;
    }
    if (this.props.data[this.props.unit.unitID] === undefined) {
      this.errorHandler("Mất kết nối dữ liệu. Xin vui lòng khởi động lại trang Web và thử lại.");
      return;
    }

    // expandLogic for all reports
    const data = this.props.data[this.props.unit.unitID];
    if (this.props.unit.information.type === "th")
      Object.keys(data).forEach(reportKey => {
        const customlogic = getCustomLogic(
          this.props.unit.information.type,
          this.props.unit.information.schoolModel,
          this.props.unit.information.schoolType,
          reportKey,
          data[reportKey].data,
          { ...data, unit: this.props.unit.information }
        );
        if (data[reportKey].data !== undefined) {
          data[reportKey].data = { ...data[reportKey].data, ...expandLogic(data, data[reportKey].data, this.props.reports[this.props.unit.information.rootID][reportKey].logic, customlogic) };
        }
      });

    if (this.props.unit.information.type !== "th" || this.verifyReport(data, true))
      this.runSendReport(data);
  }

  isShowReportStatus = () => {
    if (this.props.unit.notification === undefined)
      return false;
    if (new Date().getTime() > this.props.unit.notification.deadline)
      return false;
    const status = this.props.status[this.props.unit.unitID];
    if (status === undefined || status === null || status.status === undefined || status.status === "waiting" || status.status === "rejected")
      return true;
    return false;
  }

  verifyReport = (reportData = undefined, showForceButton = false) => {
    let data = reportData;
    if (data === undefined) {
      // expandLogic for all reports
      data = this.props.data[this.props.unit.unitID];
      _.forEach(_.keys(data), reportKey => {
        const customlogic = getCustomLogic(
          this.props.unit.information.type,
          this.props.unit.information.schoolModel,
          this.props.unit.information.schoolType,
          reportKey,
          data[reportKey].data,
          { ...data, unit: this.props.unit.information }
        );
        if (data[reportKey].data !== undefined) {
          data[reportKey].data = { ...data[reportKey].data, ...expandLogic(data, data[reportKey].data, this.props.reports[this.props.unit.information.rootID][reportKey].logic, customlogic) };
        }
      });
    }

    // validate data in all reports
    const failedValidateRules = {};
    if (this.props.reports.validate !== undefined) {
      const customlogic = getCustomLogic(
        this.props.unit.information.type,
        this.props.unit.information.schoolModel,
        this.props.unit.information.schoolType,
        "",
        {},
        { unit: this.props.unit.information }
      )
      _.keys(this.props.reports.validate).forEach(reportKey => {
        const reportValidateRules = {};
        _.forEach(this.props.reports.validate[reportKey], (val, key) => {
          if (!val.enable)
            reportValidateRules[key] = val;
          else if (eval(expandExp({}, {}, val.enable, customlogic)))
            reportValidateRules[key] = val;
        });
        const validateResults = expandLogic(data, [], reportValidateRules);  // assume no {lib.X}
        _.keys(validateResults).filter(key => validateResults[key] === true).forEach(key => delete reportValidateRules[key]);
        const reportKeysValidateRules = _.keys(reportValidateRules);
        if (reportKeysValidateRules.length > 0) {
          failedValidateRules[reportKey] = {};
          reportKeysValidateRules.forEach(key => {
            failedValidateRules[reportKey][key] = {};
            failedValidateRules[reportKey][key].description = reportValidateRules[key].description;

            const regExp = /\{([^}]+)\}/g;
            const ruleVars = reportValidateRules[key].eval.match(regExp);
            if (ruleVars !== null) {
              ruleVars.forEach(ruleVar => {
                const dotIndex = ruleVar.indexOf('.');
                const expReportKey = ruleVar.slice(1, dotIndex);
                if (expReportKey === reportKey) {
                  const columnKey = ruleVar.slice(dotIndex + 1, ruleVar.length - 1);
                  failedValidateRules[reportKey][key].errorKey = columnKey;
                }
              });
            }
          });
        }
      });
    }
    if (_.keys(failedValidateRules).length > 0) {
      const results = {};
      _.keys(failedValidateRules).forEach(report => {
        results[report] = {
          name: (this.props.reports !== undefined && this.props.reports[this.props.unit.information.rootID] !== undefined && this.props.reports[this.props.unit.information.rootID][report] !== undefined) ? this.props.reports[this.props.unit.information.rootID][report].shortName : report,
          issues: []
        };
        _.keys(failedValidateRules[report]).forEach(description => {
          results[report].issues.push(failedValidateRules[report][description].description);
        });
      });
      this.setState({
        showResultsVerification: true,
        resultsVerification: results,
        showForceButton,
        forceSendData: data
      });
      return false;
    }
    if (this.showForceButton)
      return true;
    this.setState({
      showResultsVerification: true
    });
    return true;
  }

  workingReportHandler = (key, status) => {
    if (status === "done")
      this.setState({
        workingReport: undefined
      });
    else
      this.setState({
        workingReport: key
      });
  }

  profileHandler = () => {
    this.setState({
      showProfileForm: true
    });
  }

  closeHandler = () => {
    this.setState({
      showProfileForm: false,
      forceProfileUpdate: false,
      profileMessages: []
    });
  }

  errorHandler = (message) => {
    this.notificationSystem.addNotification({
      title: "Lỗi",
      message,
      level: "error",
      position: "tr",
      autoDismiss: 4
    });
  }

  successHandler = (message) => {
    this.notificationSystem.addNotification({
      title: "Hoàn tất",
      message,
      level: "success",
      position: "tr",
      autoDismiss: 4
    });
  }

  historyHandler = () => {
    this.setState({
      menuActive: "history"
    });
  }

  manageHandler = () => {
    this.setState({
      menuActive: "manage"
    });
  }

  reportHandler = () => {
    this.setState({
      menuActive: "report"
    });
  }

  thongtinchungHandler = () => {
    this.setState({ menuActive: "thongtinchung" });
  }

  thongtintruongHandler = () => {
    this.setState({ menuActive: "thongtintruong" });
  }

  nhansuHandler = () => {
    this.setState({
      menuActive: "nhansu"
    });
  }

  unitHandler = (event, { value }) => {
    if (this.props.unit.unitID === value)
      return;
    this.setState({ isWaiting: true });
    eventTracking("unit", "change unit", 1, this.props.unit.unitID);

    const eduunits = {};
    if (this.props.user.eduunits !== undefined)
      this.props.user.eduunits.forEach((unit) => {
        eduunits[unit.unitID] = (unit.unitID === value);
      });
    db.ref(`users/${this.props.user.uid}`).update({ eduunits }, (error) => {
      if (error) {
        this.setState({ isWaiting: false });
        this.errorHandler("Kết nối đến hệ thống thất bại.");
      } else
        window.location.reload();
    });
  }

  removeResponseHandler = () => {
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${this.props.unit.unitID}/lasted/response`).remove((err) => {
      if (!err) {
        db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/final/${this.props.unit.unitID}/lasted`).on("value", (data) => {
          if (data !== null || data.val() !== null) {
            this.props.reportStatus(this.props.unit.unitID, data.val());
          }
        });
      }
    });
  }

  renderReportResult = (report) => <Message key={report.name} error header={report.name} list={report.issues} />

  renderResultsVerification = () => (
    <Modal dimmer={false} size="tiny" open={this.state.showResultsVerification}>
      <Header className="form-header" as="h3" icon="protect" content="Kết quả kiểm tra số liệu" />
      <Modal.Content scrolling>
        {(this.state.resultsVerification !== undefined) ?
          Object.keys(this.state.resultsVerification).map((report) => this.renderReportResult(this.state.resultsVerification[report]))
          :
          <Message positive>Số liệu khớp giữa các báo cáo</Message>}
      </Modal.Content>
      <Modal.Actions>
        {this.state.showForceButton ? <Button basic color="brown" onClick={() => this.forceSendReport(this.state.forceSendData)}>Tiếp tục gửi</Button> : null}
        <Button basic color="grey" onClick={() => this.setState({ showResultsVerification: undefined, resultsVerification: undefined, showForceButton: undefined, forceSendData: undefined })}>Đóng</Button>
      </Modal.Actions>
    </Modal>
  )

  renderProfileForm = () => (
    <Modal dimmer={false} size="tiny" open={this.state.showProfileForm}>
      <Header className="form-header" as="h3" icon="id card outline" content="Thông tin tài khoản" />
      <Modal.Content>
        <ProfileForm closeHandler={this.closeHandler} forceProfileUpdate={this.state.forceProfileUpdate} messages={this.state.profileMessages} />
      </Modal.Content>
    </Modal>
  )

  renderAllReports = (reports) => {
    const panes = [];
    reports.forEach((report, stt) => {
      let show = report.enable === true;
      if (report.enable !== true && this.props.data[this.props.unit.unitID] !== undefined) {
        const customlogic = getCustomLogic(
          this.props.unit.information.type,
          this.props.unit.information.schoolModel,
          this.props.unit.information.schoolType,
          "",
          {},
          { unit: this.props.unit.information }
        );
        const enableChecking = expandExp(this.props.data[this.props.unit.unitID], this.props.data[this.props.unit.unitID][report.value], report.enable, customlogic);
        if (eval(enableChecking) === true)
          show = true;
      }
      if (show || this.props.unit.information.type === "pgd" || this.props.unit.information.type === "sgd") {
        const disabled = this.state.workingReport !== undefined && this.state.workingReport !== report.value;
        const title = (this.state.workingReport !== undefined && this.state.workingReport === report.value) ? `${report.text}*` : report.text;
        let className = "tabSent";
        if (this.props.data[this.props.unit.unitID] !== undefined && (this.props.data[this.props.unit.unitID][report.value] === undefined || this.props.data[this.props.unit.unitID][report.value].length === 0))
          className = "tabNotSent";

        if (disabled)
          panes.push({
            menuItem: (
              <Popup
                key={report.value}
                position="right center"
                trigger={(
                  <Menu.Item
                    onClick={() => window.scrollTo(0, 0)}
                    disabled
                    key={report.value}
                    className={className}
                  >
                    {`${stt + 1}. ${title}`}
                  </Menu.Item>
                )}
                content="Báo cáo đang thao tác chưa được Lưu hoặc Cập nhật."
              />
            ),
            render: () => <Tab.Pane style={{ background: "transparent" }}><Report reportKey={report.value} errorHandler={this.errorHandler} successHandler={this.successHandler} workingReportHandler={this.workingReportHandler} /></Tab.Pane>
          });
        else
          panes.push({
            menuItem: (
              <Menu.Item
                onClick={() => window.scrollTo(0, 0)}
                key={report.value}
                className={className}
              >
                {`${stt + 1}. ${title}`}
              </Menu.Item>
            ),
            render: () => <Tab.Pane style={{ background: "transparent" }}><Report reportKey={report.value} errorHandler={this.errorHandler} successHandler={this.successHandler} workingReportHandler={this.workingReportHandler} /></Tab.Pane>
          });
      }
    });

    return (
      <Tab
        style={{ background: "transparent" }}
        grid={{ paneWidth: 14, tabWidth: 2 }}
        menu={{ fluid: true, vertical: true, tabular: true, pointing: true, color: "brown" }}
        panes={panes}
      />
    );
  }

  renderErrorScreen = () => {
    const { showProfileForm, forceProfileUpdate } = this.state;
    if (showProfileForm && forceProfileUpdate)
      return null;
    return (
      <Segment className="error-message" stacked>
        <Header as="h3" icon="frown" content="Hệ thống báo cáo chưa sẵn sàng hoạt động." />
        <Divider />
        <Header as="h4" content="Xin hãy thử các cách sau" />
        <List>
          <List.Item icon="refresh" content="Mở lại trang web ở tab khác hoặc trên trình duyệt khác." />
          <List.Item icon="retweet" content="Đăng xuất ra khỏi hệ thống rồi đăng nhập lại." />
          <List.Item icon="comments outline" content="Liên hệ đến nhân viên hỗ trợ để được tư vấn." />
        </List>
      </Segment>
    );
  }

  renderMenuReportStatus = () => {
    if (this.isShowReportStatus())
      return (
        <List>
          <List.Item className="notificationStatus">
            <List.Icon name="clock" />
            <List.Content>
              <List.Header as='a'>
                Hạn nộp báo cáo:
                {' '}
                {convertTimeStamp(this.props.unit.notification.deadline, true)}
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
      );
    return null;
  }

  renderMenu = () => {
    const { user } = this.props;
    const eduunits = _.map(_.get(user, 'eduunits', []), unit => ({
      key: unit.unitID,
      text: unit.name,
      value: unit.unitID
    }));
    const { type, plugins } = this.props.unit.information;
    const { menuActive } = this.state;
    return (
      <Menu className="smallwrap form-header" secondary pointing icon="labeled" color="brown">
        {(eduunits.length > 0) ? (
          <Menu.Item>
            <Dropdown
              color="brown"
              selection
              search
              multiple={false}
              onChange={this.unitHandler}
              options={eduunits}
              defaultValue={this.props.unit.unitID}
              placeholder="Đơn vị đang thao tác"
            />
          </Menu.Item>
        )
          : null}
        {(type !== "th") ? (
          <Menu.Item name="thongtinchung" active={menuActive === "thongtinchung"} onClick={this.thongtinchungHandler}>
            <Icon name="info" />
            Thông tin chung
          </Menu.Item>
        ) : null}
        {(type !== "th") ? (
          <Menu.Item name="nhansu" active={menuActive === "nhansu"} onClick={this.nhansuHandler}>
            <Icon name="users" />
            Nhân sự
          </Menu.Item>
        ) : null}
        {(type === "th") ? (
          <Menu.Item name="thongtintruong" active={menuActive === "thongtintruong"} onClick={this.thongtintruongHandler}>
            <Icon name="info" />
            Thông tin trường
          </Menu.Item>
        ) : null}
        {!isNotAdminAndStaff(this.props.unit.information, this.props.user) ? (
          <Menu.Item name="report" active={menuActive === "report"} onClick={this.reportHandler}>
            <Icon name="write" />
            Báo cáo
          </Menu.Item>
        ) : null}
        {(type !== "sgd" && !isNotAdminAndStaff(this.props.unit.information, this.props.user)) ? (
          <Menu.Item name="history" active={menuActive === "history"} onClick={this.historyHandler}>
            <Icon name="braille" />
            Lịch sử
          </Menu.Item>
        ) : null}
        {(type === "th" && _.get(plugins, "cando", false)) && !restrictMeasurementsSGDs.includes(this.props?.unit?.information?.sgd) ? (
          <Menu.Item name="cando" active={menuActive === "cando"} onClick={() => this.setState({ menuActive: "cando" })}>
            <Icon name="chart line" />
            Cân đo
          </Menu.Item>
        ) : null}
        {(type === "th" && _.get(plugins, "cando", false))  && !restrictMeasurementsSGDs.includes(this.props?.unit?.information?.sgd) ? (
          <Menu.Item name="suckhoe" active={menuActive === "suckhoe"} onClick={() => this.setState({ menuActive: "suckhoe" })}>
            <Icon name="heart" />
            Sức khỏe
          </Menu.Item>
        ) : null}
        {_.get(plugins, "dinhduong", false) ? (
          <Menu.Item name="dinhduong" active={menuActive === "dinhduong"} onClick={() => this.setState({ menuActive: "dinhduong" })}>
            <Icon name="child" />
            Dinh dưỡng
          </Menu.Item>
        ) : null}
        {(type !== "th") ? (
          <Menu.Item name="manage" active={menuActive === "manage"} onClick={this.manageHandler}>
            <Icon name="desktop" />
            Quản lý
          </Menu.Item>
        )
          : null}
        {(type === "sgd") ? (
          <Menu.Item name="settings" active={menuActive === "settings"} onClick={() => this.setState({ menuActive: "settings" })}>
            <Icon name="settings" />
            Cài đặt
          </Menu.Item>
        ) : null}
        <Menu.Menu position="right">
          <Menu.Item><UserHeader profileHandler={this.profileHandler} /></Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }

  renderStatus = () => {
    let message = "";
    if (this.props.status[this.props.unit.unitID] !== undefined && this.props.status[this.props.unit.unitID] !== null && this.props.status[this.props.unit.unitID].time !== undefined) {
      message = `Lần gửi báo cáo gần nhầt: ${convertTimeStamp(this.props.status[this.props.unit.unitID].time, true)}`;
    }
    if (this.props.unit.notification !== undefined && message === "")
      message = "Chưa gửi báo cáo.";
    if (this.props.status[this.props.unit.unitID] !== undefined && this.props.status[this.props.unit.unitID] !== null && this.props.status[this.props.unit.unitID].status === "waiting")
      return (
        <div>
          {message}
          .
          {' '}
          <p style={{ display: "inline", color: "red" }}> Đang chờ duyệt.</p>
        </div>
);
    return message;
  }

  renderResponse = () => {
    const status = this.props.status[this.props.unit.unitID];
    if (status !== undefined && status !== null && status.response !== undefined && status.response !== null) {
      let title = "";
      let message = "";
      let failed = false;
      switch (status.status) {
        case "rejected":
          title = "Báo cáo đã bị từ chối";
          if (status.response.message !== undefined && status.response.message !== "")
            message = `Lý do: ${status.response.message}`;
          failed = true;
          break;
        case "accepted":
          title = "Báo cáo đã được thông qua";
          break;
        default:
      }
      return (
        <Message success={!failed} negative={failed}>
          {(failed === false) ?
            <Icon name="remove" className="right-top-button" onClick={this.removeResponseHandler} /> : null}
          <Message.Content>
            <Header as="h3">{title}</Header>
            {(status.response.time !== undefined) ?
              <p>{convertTimeStamp(status.response.time)}</p> : null}
            {message}
          </Message.Content>
        </Message>
      );
    }
    return null;
  }

  renderMilestone = () => {
    if (this.props.milestone !== undefined && this.props.milestone[this.props.unit.activeMilestone] !== undefined)
      return (
        <Message color="teal">
          <Message.Header>{this.props.milestone[this.props.unit.activeMilestone].description}</Message.Header>
          <Message.Content>{this.renderMenuReportStatus()}</Message.Content>
        </Message>
      );
    return null;
  }

  render() {
    const reports = [];
    if (this.props.unit.information !== undefined && this.props.unit.information.rootID !== undefined) {
      if (!isNotAdminAndStaff(this.props.unit.information, this.props.user)) {
        let waiting = false;
        if (this.props.reports[this.props.unit.information.rootID] !== undefined && this.props.reports[this.props.unit.information.rootID].waiting === true)
          waiting = true;
        if (Object.keys(this.props.reports).length === 0)
          waiting = true;
        if ((waiting || this.state.isWaiting) && (this.state.menuActive === "report"))
          return (
            <Dimmer active inverted style={{ background: "#FFFeF1" }}>
              <Loader style={{ minWidth: 300 }} inverted>Đang tải báo cáo...</Loader>
            </Dimmer>
          );
        Object.keys(this.props.reports[this.props.unit.information.rootID]).map(key => {
          if (key === "information")
            return key;
          if (this.props.reports[this.props.unit.information.rootID][key].name === undefined)
            return key;
          if (this.props.reports[this.props.unit.information.rootID][key].permission !== undefined && this.props.reports[this.props.unit.information.rootID][key].permission.indexOf(this.props.unit.information.type) === -1)
            return key;
          reports.push({
            value: key,
            name: this.props.reports[this.props.unit.information.rootID][key].name,
            text: this.props.reports[this.props.unit.information.rootID][key].shortName,
            enable: (this.props.reports[this.props.unit.information.rootID][key].enable !== undefined) ? this.props.reports[this.props.unit.information.rootID][key].enable : true
          });
          return key;
        });
      }
    } else
      return (
        <div>
          <Menu className="smallwrap form-header" secondary pointing icon="labeled" color="brown">
            <Menu.Menu position="right">
              <Menu.Item><UserHeader profileHandler={this.profileHandler} /></Menu.Item>
            </Menu.Menu>
          </Menu>
          <div className="smallwrap">{this.renderErrorScreen()}</div>
          {this.renderProfileForm()}
          <NotificationSystem ref={(ref) => { this.notificationSystem = ref }} />
        </div>
      );

    const resultsHandler = { errorHandler: this.errorHandler, successHandler: this.successHandler };
    return (
      <div>
        {this.renderMenu()}
        {this.state.showResultsVerification ? this.renderResultsVerification() : null}
        {(this.state.menuActive === "report") ? (
          <div className="smallwrap">
            {this.renderMilestone()}
            {this.renderResponse()}
            <Message warning>
              {this.isShowReportStatus() ? (
                <Button.Group className="right-top-button">
                  <Button color="brown" icon="send" content="Gửi báo cáo" onClick={this.sendReport} />
                  {(this.props.unit.information.type === "pgd") ? <Button color="teal" icon="file excel outline" content="Xuất báo cáo" onClick={() => this.exportReport("sgd")} loading={this.state.isExporting} /> : null}
                  {(this.props.unit.information.type === "th") ? <Button color="red" icon="protect" content="Kiểm tra" onClick={() => this.verifyReport()} /> : null}
                </Button.Group>
              )
                : (
                  <Button.Group className="right-top-button">
                    {(this.props.unit.information.type === "sgd") ?
                      <Button color="blue" icon="file" content="Xuất báo cáo cấp Bộ" onClick={() => this.exportReport("bgd")} loading={this.state.isExporting} />
                    : (
                      <Modal
                        trigger={<Button color="brown" icon="send" content="Gửi báo cáo" />}
                        header="Thông báo!"
                        content={this.getSendReportDescription()}
                        actions={[{ key: "close", content: "Đóng", positive: true }]}
                      />
                  )}
                    <Button color="teal" icon="file excel outline" content="Xuất báo cáo cấp Sở" onClick={() => this.exportReport("sgd")} loading={this.state.isExporting} />
                    {(this.props.unit.information.type === "th") ? <Button color="red" icon="protect" content="Kiểm tra" onClick={() => this.verifyReport()} /> : null}
                  </Button.Group>
              )}
              <Message.Content>
                <Header className="unitHeader" as="h3">{this.props.unit.information.name}</Header>
                <Header className="unitSubHeader" as="h4">{`Năm học ${this.props.unit.activeYear}`}</Header>
                {this.renderStatus()}
              </Message.Content>
            </Message>
            {this.renderAllReports(reports)}
          </div>
        )
          : null}
        {(this.state.menuActive === "thongtintruong") ?
          <div className="smallwrap"><ThongTinTruong {...resultsHandler} allowEdit={!isNotAdminAndStaff(this.props.unit.information, this.props.user)} /></div> : null}
        {(this.state.menuActive === "thongtinchung") ?
          <div className="smallwrap"><ThongTinChung {...resultsHandler} /></div> : null}
        {(this.state.menuActive === "nhansu") ?
          <div className="smallwrap"><NhanSu {...resultsHandler} /></div> : null}
        {(this.state.menuActive === "history") ?
          <div className="smallwrap"><Watch {...resultsHandler} /></div> : null}
        {(this.state.menuActive === "manage") ?
          <div className="smallwrap"><Manage {...resultsHandler} /></div> : null}
        {(this.state.menuActive === "settings") ?
          <div className="smallwrap"><Settings {...resultsHandler} /></div> : null}
        {(this.state.menuActive === "cando") ?
          <div className="smallwrap"><CanDo {...resultsHandler} allowEdit={!isNotAdminAndStaff(this.props.unit.information, this.props.user)} /></div> : null}
        {(this.state.menuActive === "dinhduong") ?
          <div className="smallwrap"><DinhDuong {...resultsHandler} allowEdit={!isNotAdminAndStaff(this.props.unit.information, this.props.user)} /></div> : null}
        {(this.state.menuActive === "suckhoe") ?
          <div className="smallwrap"><SucKhoe {...resultsHandler} allowEdit={!isNotAdminAndStaff(this.props.unit.information, this.props.user)} /></div> : null}
        {this.renderProfileForm()}
        <NotificationSystem ref={(ref) => { this.notificationSystem = ref }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  unit: state.unit,
  reports: state.reports,
  year: state.year,
  data: state.data,
  status: state.status,
  milestone: state.milestone,
  manage: state.manage
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  reportsFetch,
  updateData,
  reportStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);