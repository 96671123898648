/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Button,
  Modal,
  Header,
  Dimmer,
  Loader,
  Table,
  Label,
  Input,
  Form,
  Message,
  Dropdown,
  Popup,
  Checkbox,
  List,
  Segment,
  Radio
} from "semantic-ui-react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

// services
import { RESOURCES } from "../../../libs/config";
import { db } from "../../../libs/firebase";
import {
  evaluateStudents,
  computeBMIPointResult,
  computeHeightPointResult,
  computeWeightLengthPointResult,
  computeWeightPointResult,
  exportSoLieu
} from "./helpers";


const colors = {
  NC2: "red",
  NC1: "orange",
  TC2: "red",
  TC1: "orange",
  GC1: "orange",
  GC2: "red",
  BP: "pink",
  DC: "orange",
  BT: "green",
  [`BT+`]: "olive"
};

const description = {
  NC2: "SDD thể nhẹ cân mức độ nặng",
  NC1: "SDD thể nhẹ cân",
  TC2: "SDD thể thấp còi mức độ nặng",
  TC1: "SDD thể thấp còi",
  GC1: "SDD thể gầy còm",
  GC2: "SDD thể gầy còm mức độ nặng",
  BP: "Béo phì",
  DC: "Thừa cân",
  BT: "Bình thường",
  [`BT+`]: "Trên mức bình thường"
};

const defaultTerms = {
  NC2: "SDDTNC (nặng)",
  NC1: "SDDTNC",
  TC2: "SDDTTC (nặng)",
  TC1: "SDDTTC",
  GC1: "SDDTGC",
  GC2: "SDDTGCN",
  BP: "BP",
  DC: "ThC",
  BT: "BT",
  [`BT+`]: "BT"
};
const mappingDescs = {
  NC2: "SDDNC(n)",
  NC1: "SDDNC",
  TC2: "SDDTC(n)",
  TC1: "SDDTC",
  GC1: "SDDGC",
  GC2: "SDDGC(n)",
  BP: "BP",
  DC: "ThC",
  BT: "BT",
  [`BT+`]: "BT+",
  NC1TC1: "SDDNC(n)/TC",
  NC1TC2: "SDDNC /TC(n)",
  NC2TC1: "SDDNC(n)/TC",
  NC2TC2: "SDDNC(n)/TC(n)",
};
class SoLieu extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired
    }).isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      information: PropTypes.shape({
        name: PropTypes.string.isRequired,
        sgd: PropTypes.string.isRequired,
      }).isRequired
    }).isRequired,
    classInfo: PropTypes.shape({
      year: PropTypes.number.isRequired,
      month: PropTypes.string.isRequired,
      day: PropTypes.number.isRequired,
      previousMonth: PropTypes.number,
      name: PropTypes.string.isRequired,
      classID: PropTypes.string.isRequired,
      grade: PropTypes.array.isRequired,
    }).isRequired,
    solieucando: PropTypes.object.isRequired,
    dantoc: PropTypes.object,
    terms: PropTypes.object,
    monthsStatus: PropTypes.object.isRequired,
  };

  static defaultProps = {
    dantoc: undefined,
    terms: {}
  }

  constructor(props) {
    super(props);

    this.changed = false;
    this.state = {
      isWaiting: true,
      showAskForSave: false,
      useDay: false,
      day: (this.props.classInfo.day !== undefined) ? this.props.classInfo.day : 1,
      students: {},
      days: _.range(moment(`${this.props.classInfo.year}-${this.props.classInfo.month}`, "YYYY-MM").daysInMonth()).map(idx => ({
        key: idx + 1,
        text: idx + 1,
        value: idx + 1
      })),
      dantoc: (this.props.dantoc === undefined) ? [] : _.keys(this.props.dantoc).map(dt => ({
        key: dt,
        text: this.props.dantoc[dt],
        value: dt
      })),
      previousMonth: this.props.classInfo.previousMonth,
    };
  }

  componentWillMount() {
    db.ref(`cando/${this.props.unit.unitID}/${this.props.unit.activeYear}/${this.props.classInfo.classID}/months/${this.props.classInfo.month}`).once("value", snapshot => {
      if (snapshot !== null && snapshot.val() !== null && snapshot.val() !== undefined)
        this.setState({ isWaiting: false, students: snapshot.val() });
      else
        this.setState({ isWaiting: false });
    }, (error) => {
      this.setState({ isWaiting: false });
      if (error)
        this.props.errorHandler("Kết nối đến cơ sở dữ liệu cân đo không thành công.");
    });
  }

  getPreviousMonthData = () => {
    this.setState({ isWaiting: true });
    db.ref(`cando/${this.props.unit.unitID}/${this.props.unit.activeYear}/${this.props.classInfo.classID}/months/${this.state.previousMonth}`).once("value", snapshot => {
      if (snapshot !== null && snapshot.val() !== null && snapshot.val() !== undefined) {
        const students = snapshot.val();
        _.keys(students).forEach(studentID => {
          delete students[studentID].height;
          delete students[studentID].weight;
          delete students[studentID].comment;
        });
        this.setState({ isWaiting: false, students });
      } else
        this.setState({ isWaiting: false });
    }, (error) => {
      this.setState({ isWaiting: false });
      if (error)
        this.props.errorHandler("Kết nối đến cơ sở dữ liệu cân đo không thành công.");
    });
  }

  saveStatus = (students) => {
    this.changed = false;
    db.ref(`cando/${this.props.unit.unitID}/${this.props.unit.activeYear}/${this.props.classInfo.classID}/status/${this.props.classInfo.month}`).set({
      count: (students === undefined) ? 0 : _.keys(students).length,
      unfilled: (students === undefined) ? 0 : _.filter(_.keys(students), student => (students[student].height === undefined || students[student].height === "" || students[student].weight === undefined || students[student].weight === "")).length,
      className: this.props.classInfo.name,
      day: (students === undefined) ? 0 : this.state.day,
      modifiedBy: this.props.user.email,
      modifiedTime: new Date().getTime()
    }, error => {
      this.setState({ isWaiting: false });
      if (error)
        this.props.errorHandler("Cập nhật số liệu cân đo không thành công");
      else
        this.props.successHandler("Cập nhật số liệu cân đo thành công");
    });
  }

  closeHandler = () => {
    if (this.changed)
      this.setState({ showAskForSave: true });
    else
      this.props.closeHandler();
  }

  saveHandler = () => {
    const { students } = this.state;
    this.setState({ isWaiting: true, showAskForSave: false });
    const ref = db.ref(`cando/${this.props.unit.unitID}/${this.props.unit.activeYear}/${this.props.classInfo.classID}/months/${this.props.classInfo.month}`);
    if (students === undefined)
      ref.remove(error => {
        if (error) {
          this.setState({ isWaiting: false });
          this.props.errorHandler("Cập nhật số liệu cân đo không thành công");
        } else
          this.saveStatus(students);
      });
    else
      ref.set(students, error => {
        if (error) {
          this.setState({ isWaiting: false });
          this.props.errorHandler("Cập nhật số liệu cân đo không thành công");
        }
        else
          this.saveStatus(students);
      });
  }

  abnormalStudents = () => {
    if (this.evaluatedStudents)
      return _.filter(this.evaluatedStudents, student => {
        const { weightPoint, heightPoint, weightLengthPoint } = student;
        if (weightPoint && weightPoint !== "BT" && weightPoint !== "BT+")
          return true;
        if (heightPoint && heightPoint !== "BT" && heightPoint !== "BT+")
          return true;
        if (weightLengthPoint && weightLengthPoint !== "BT" && weightLengthPoint !== "BT+")
          return true;
        return false
      });
    return [];
  }

  exportErrorHandler = () => {
    this.setState({
      isWaiting: false
    });
    this.props.errorHandler("Xuất báo cáo không thành công.");
  }

  exportHandler = (mode = 1) => {
    if (this.evaluatedStudents === undefined) {
      this.props.errorHandler("Không lấy được danh sách học sinh. Xin vui lòng thử lại sau.");
      return;
    }
    this.setState({ isWaiting: true });
    const { unit } = this.props;
    if (mode === 2) {
      const isAbnormal = (weightPoint, heightPoint, weightLengthPoint, bmiPoint) => {
        if (weightPoint && weightPoint !== "BT" && weightPoint !== "BT+")
          return true;
        if (heightPoint && heightPoint !== "BT" && heightPoint !== "BT+")
          return true;
        if (weightLengthPoint && weightLengthPoint !== "BT" && weightLengthPoint !== "BT+")
          return true;
        if (bmiPoint && bmiPoint !== "BT" && bmiPoint !== "BT+")
          return true;
        return false;
      };
      XlsxPopulate.fromBlankAsync().then(wb => {
        const NTSheet = wb.sheet(0);
        const SDDSheet = wb.addSheet("DS trẻ SDD");
        NTSheet.column(1).width(5);
        NTSheet.column(2).width(5);
        NTSheet.column(3).width(23);
        NTSheet.column(4).width(18);
        NTSheet.column(5).width(17);
        NTSheet.column(6).width(15);
        NTSheet.column(7).width(17);
        NTSheet.row(4).height(9.75);
        NTSheet.row(5).height(19.5);
        NTSheet.row(6).height(95.75);
        SDDSheet.column(1).width(5.5);
        SDDSheet.column(2).width(5.5);
        SDDSheet.column(3).width(28);
        SDDSheet.column(4).width(8.6);
        SDDSheet.column(5).width(26.5);
        SDDSheet.column(6).width(8.6);
        SDDSheet.column(7).width(26.5);
        SDDSheet.row(1).height(19.5);
        SDDSheet.row(2).height(19.5);
        SDDSheet.row(3).height(19.5);
        SDDSheet.row(5).height(28.5);
        SDDSheet.row(6).height(51);
        const lastColumName = this.props.classInfo.grade.includes("5-6tuoi") ? 'I' : 'H';
        const { name, month } = this.props.classInfo;
        let year = parseFloat(this.props.unit.activeYear);
        const terms = _.get(this.props.terms, "default.cando", defaultTerms);
        if (month < 8)
          year += 1;
        const counter = {
          total: 0,
          heightBT: 0,
          weightBT: 0,
          TC: 0,
          BP: 0,
          NC: 0,
          DC: 0,
          NC2: 0,
          TC2: 0,
          GC2: 0.
        };
        let rowIndex = 8;
        let SDDrowIndex = 8;

        const NTHeader = (namtuoi = false) => {
          NTSheet.range("D1", `${lastColumName}3`).merged(true);
          NTSheet.range("A1", "B1").merged(true);
          NTSheet.range("A2", "B2").merged(true);
          NTSheet.range("A3", "B3").merged(true);
          NTSheet.range("A5", "A6").merged(true);
          NTSheet.range("B5", "C6").merged(true);
          NTSheet.range("D5", "E5").merged(true);
          NTSheet.range("F5", "G5").merged(true);
          NTSheet.range("B7", "C7").merged(true);
          NTSheet.range("A5", `${lastColumName}6`).style(
            {
              bold: true,
              wrapText: true,
              horizontalAlignment: "center",
              verticalAlignment: "center",
              border: true,
            });
          NTSheet.cell(`${lastColumName}8`).style({ bold: false })
          if (namtuoi) {
            NTSheet.column(8).width(14.5);
            NTSheet.column(9).width(24);

          } else {
            NTSheet.column(8).width(35);

          }
          NTSheet.cell("D10").style({ bold: false })
          NTSheet.cell("F10").style({ bold: false })
          NTSheet.cell("D1").value("BẢNG THEO DÕI SỨC KHỎE CỦA TRẺ").style({
            bold: true,
            fontSize: 16,
            horizontalAlignment: "center",
            verticalAlignment: "center",
          });
          NTSheet.cell("A1").value("Trường");
          NTSheet.cell("A2").value("Lớp");
          NTSheet.cell("A3").value("Thời điểm");
          NTSheet.cell("C1").value(`${this.props.unit.information.name}`).style({ bold: true });
          NTSheet.cell("C2").value(`Lớp ${name}`).style({ bold: true });
          NTSheet.cell("C3").value(`${this.state.day}/${month}/${year}`).style({ bold: true });
          NTSheet.range("A1", "C3").style({ fontSize: 10 });

          NTSheet.cell("A5").value("STT");
          NTSheet.cell("B5").value("Họ và tên");
          NTSheet.cell("D5").value("Tình trạng cân nặng theo tuổi");
          NTSheet.cell("F5").value("Tình trạng chiều cao theo tuổi");
          NTSheet.cell("A7").value(1).style({ fill: "d9d9d9", border: true, horizontalAlignment: 'center' });
          NTSheet.cell("B7").value(2).style({ fill: "d9d9d9", border: true, horizontalAlignment: 'center' });
          _.forEach(["D", "E", "F", "G", "H"], (character, idx) => {
            NTSheet.cell(`${character}7`).value(idx + 3).style({ fill: "d9d9d9", border: true, horizontalAlignment: 'center' });
          })
          if (namtuoi) {
            NTSheet.range("H5", "I5").merged(true);
            NTSheet.cell("H5").value("Tính BMI theo tuổi");
            NTSheet.cell("H6").value("Chỉ số BMI (Kg)");
            NTSheet.cell("I6").value("SDD thể gầy còm nặng (SDDTGCN)\nSDD thể gầy còm (SDDTGC)\nBình thường (BT)\nthừ cân (TC)\nBéo phì (BP)").style({ bold: false });
            NTSheet.cell("I7").value(8).style({ fill: "d9d9d9", border: true, horizontalAlignment: 'center' })
          } else {
            NTSheet.cell("H5").value("Cân nặng theo chiều cao/chiều dài");
            NTSheet.cell("H6").value("SDD thể gầy còm nặng (SDDTGCN)\nSDD thể gầy còm (SDDTGC)\nBình thường (BT)\nthừ cân (TC)\nBéo phì (BP)").style({ bold: false });
          }
          NTSheet.cell("D6").value("Số cân nặng (kg)");
          const { RichText } = XlsxPopulate;
          const d8Cell = NTSheet.cell("E6");
          d8Cell.value(new RichText())
          d8Cell.value().add("SDD thể nhẹ cân (SDDTNC)\nSDD thể nhẹ cân nặng (SDDTNCN)\nBình thường (BT)", { fontFamily: "Tahoma", fontSize: 10 });
          d8Cell.value().add("Xếp loại\n", { bold: true, fontFamily: "Tahoma", fontSize: 10 }, 0);
          NTSheet.cell("F6").value("Chiều cao (cm)");
          const f8Cell = NTSheet.cell("G6");
          f8Cell.value(new RichText())
          f8Cell.value().add("SDD thể thấp còi (SDDTTC)\nSDD thể thấp còi nặng(SDDTTCN)\nBình thường (BT)", { fontFamily: "Tahoma", fontSize: 10 });
          f8Cell.value().add("Xếp loại\n", { bold: true, fontFamily: "Tahoma", fontSize: 10 }, 0);

        };
        const SDDHeader = (namtuoi = false) => {
          SDDSheet.range("D1", `${lastColumName}2`).merged(true);
          SDDSheet.range("D3", `${lastColumName}3`).merged(true);
          SDDSheet.range("A2", "B2").merged(true);
          SDDSheet.range("A3", "B3").merged(true);
          SDDSheet.range("A5", "A6").merged(true);
          SDDSheet.range("B5", "C6").merged(true);
          SDDSheet.range("D5", "E5").merged(true);
          SDDSheet.range("F5", "G5").merged(true);
          SDDSheet.range("B7", "C7").merged(true);
          SDDSheet.range("A1", "B1").merged(true);
          SDDSheet.range("D1", `${lastColumName}3`).style({ bold: true, horizontalAlignment: "center" });
          SDDSheet.range("A5", `${lastColumName}6`).style(
            {
              bold: true,
              wrapText: true,
              horizontalAlignment: "center",
              verticalAlignment: "center",
              border: true,
            });
          SDDSheet.cell("D1").value("DANH SÁCH  THEO DÕI TRẺ SUY DINH DƯỠNG").style({ fontSize: 16, horizontalAlignment: "center", verticalAlignment: "center" });
          SDDSheet.cell("D3").value("THEO DÕI TRẺ SDD, THỪA CÂN, BÉO PHÌ").style({ fontSize: 12 });
          SDDSheet.cell("A1").value("Trường");
          SDDSheet.cell("A2").value("Lớp");
          SDDSheet.cell("A3").value("Thời điểm");
          SDDSheet.cell("C1").value(`${this.props.unit.information.name}`).style({ bold: true });
          SDDSheet.cell("C2").value(`Lớp ${name}`).style({ bold: true });
          SDDSheet.cell("C3").value(`Thời điểm: ${this.state.day}/${month}/${year}`).style({ bold: true });
          SDDSheet.range("A1", "C3").style({ fontSize: 10 });
          SDDSheet.cell("A5").value("STT");
          SDDSheet.cell("B5").value("Họ và tên");
          SDDSheet.cell("D5").value("Tình trạng dinh dưỡng cân nặng");
          SDDSheet.cell("D6").value("Số cân nặng (kg)");
          SDDSheet.cell("E6").value("Xếp loại");
          SDDSheet.cell("F5").value("Tình trạng dinh dưỡng chiều cao");
          SDDSheet.cell("F6").value("Số Chiều cao (cm)");
          SDDSheet.cell("G6").value("Xếp loại");
          SDDSheet.cell("A7").value(1)
          SDDSheet.cell("B7").value(2)
          _.forEach(["D", "E", "F", "G", "H"], (character, idx) => {
            SDDSheet.cell(`${character}7`).value(idx + 3)
          });
          SDDSheet.range("A7", `${lastColumName}7`).style({ fill: "d9d9d9", border: true, horizontalAlignment: 'center' });
          if (namtuoi) {
            SDDSheet.range("H5", "I5").merged(true);
            SDDSheet.cell("H5").value("Tình trạng Cân nặng theo chiều cao/chiều dài hoặc BMI ");
            SDDSheet.cell("H6").value("Chỉ số BMI (Kg)");
            SDDSheet.cell("I6").value("Xếp loại");
            SDDSheet.column(8).width(8.6);
            SDDSheet.column(9).width(25);
            SDDSheet.cell("I7").value(8);
          } else {
            SDDSheet.cell("H5").value("Tình trạng Cân nặng theo chiều cao/chiều dài");
            SDDSheet.cell("H6").value("Xếp loại")
            SDDSheet.column(8).width(26.5);

          }
        }
        if (this.props.classInfo.grade.includes("5-6tuoi")) {
          NTHeader(true);
          NTSheet.name("5T");
          SDDHeader(true)
        } else {
          NTHeader(false);
          NTSheet.name("4T, 3T, NT");
          SDDHeader(false)
        }
        _.forEach(this.evaluatedStudents, (student, index) => {
          const {
            lastName,
            firstName,
            height,
            heightPoint,
            weight,
            weightPoint,
            weightLengthPoint,
            bmiPoint,
            bmi,
          } = student;
          if (isAbnormal(weightPoint, heightPoint, weightLengthPoint, bmiPoint)) {
            SDDSheet.range(`A${SDDrowIndex}`, `${lastColumName}${SDDrowIndex}`).style("border", true);
            SDDSheet.cell(`A${SDDrowIndex}`).value(index + 1).style({ verticalAlignment: 'center', horizontalAlignment: 'center' });
            SDDSheet.range(`B${SDDrowIndex}`, `C${SDDrowIndex}`).merged(true);
            SDDSheet.cell(`B${SDDrowIndex}`).value(`${lastName} ${firstName}`);
            if (weight) {
              SDDSheet.cell(`D${SDDrowIndex}`).value(weight).style({ horizontalAlignment: 'center' });
            }
            if (weightPoint && description[weightPoint])
              SDDSheet.cell(`E${SDDrowIndex}`).value(description[weightPoint]);
            if (height)
              SDDSheet.cell(`F${SDDrowIndex}`).value(height).style({ horizontalAlignment: 'center' });
            if (heightPoint && description[heightPoint])
              SDDSheet.cell(`G${SDDrowIndex}`).value(description[heightPoint]);
            if (weightLengthPoint && description[weightLengthPoint]) {
              if (this.props.classInfo.grade.includes('5-6tuoi')) {
                SDDSheet.cell(`I${SDDrowIndex}`).value(description[weightLengthPoint]);
              } else {
                SDDSheet.cell(`H${SDDrowIndex}`).value(description[weightLengthPoint]);
              }
            }
            else if (bmiPoint && description[bmiPoint]) {
              if (this.props.classInfo.grade.includes('5-6tuoi')) {
                SDDSheet.cell(`H${SDDrowIndex}`).value(bmi).style({ horizontalAlignment: 'center', numberFormat: "0.00" });
                SDDSheet.cell(`I${SDDrowIndex}`).value(description[bmiPoint]);
              }
            }

            SDDrowIndex += 1;
          }
          SDDSheet.range("A1", `${lastColumName}${SDDrowIndex}`).style({ fontFamily: "Tahoma" });
          SDDSheet.range("A5", `${lastColumName}${SDDrowIndex}`).style({ fontSize: 10 });
          if (height && weight) counter.total += 1;
          NTSheet.cell(`A${rowIndex}`).value(index + 1).style({ verticalAlignment: 'center', horizontalAlignment: 'center' });
          NTSheet.range(`B${rowIndex}`, `C${rowIndex}`).merged(true);
          NTSheet.cell(`B${rowIndex}`).value(`${lastName} ${firstName}`);
          if (weight) NTSheet.cell(`D${rowIndex}`).value(weight);
          if (bmi) NTSheet.cell(`H${rowIndex}`).value(bmi).style({ numberFormat: '0.00' });
          if (weightPoint && terms[weightPoint]) {
            NTSheet.cell(`E${rowIndex}`).value(terms[weightPoint]);
            if (weightPoint === "BT" || weightPoint === "BT+")
              counter.weightBT += 1;
            else if (weightPoint === "NC1")
              counter.NC += 1;
            else if (weightPoint === "NC2") {
              counter.NC += 1;
              counter.NC2 += 1;
            }
          }
          if (height) NTSheet.cell(`F${rowIndex}`).value(height);
          if (heightPoint && terms[heightPoint]) {
            NTSheet.cell(`G${rowIndex}`).value(terms[heightPoint]);
            if (heightPoint === "BT" || heightPoint === "BT+")
              counter.heightBT += 1;
            if (heightPoint === "TC1") counter.TC += 1;
            if (heightPoint === "TC2") {
              counter.TC += 1;
              counter.TC2 += 1;
            }
          }
          if ((weightLengthPoint && terms[weightLengthPoint]) || (bmiPoint && terms[bmiPoint])) {
            if (this.props.classInfo.grade.includes("5-6tuoi")) {
              NTSheet.cell(`I${rowIndex}`).value(terms[weightLengthPoint || bmiPoint]);
            } else {
              NTSheet.cell(`H${rowIndex}`).value(terms[weightLengthPoint || bmiPoint]);
            }
            if ((weightLengthPoint || bmiPoint) === "DC")
              counter.DC += 1;
            if ((weightLengthPoint || bmiPoint) === "BP")
              counter.BP += 1;
            if ((weightLengthPoint || bmiPoint) === "GC2")
              counter.GC2 += 1;
          }
          rowIndex += 1;
        });
        NTSheet.range("D8", `${lastColumName}${rowIndex}`).style({ horizontalAlignment: 'center' });
        if (counter.total) {
          const contentAndSolution = [
            { content: '- Theo dõi số trẻ suy dinh dưỡng nhẹ cân (nhẹ cân mức độ nặng): ', value: counter.NC2 },
            { content: '- Theo dõi số trẻ suy dinh dưỡng thấp còi (thấp còi mức độ nặng): ', value: counter.TC2, },
            { content: '- Theo dõi trẻ suy dinh dưỡng thể gầy còm (gầy còm mức độ nặng):  ', value: counter.GC2 },
            { content: '- Theo dõi trẻ béo phì: ', value: counter.BP },
            { content: '- Theo dõi trẻ thừa cân: ', value: counter.DC },
            { content: '- Theo dõi trẻ đứng cân thể cân nặng và thể chiều cao: ' },
            { content: '- Theo dõi trẻ bệnh khác (Hô hấp, tiêu chảy, sâu răng…): ' },
            { content: '- Tuyên truyền phối hợp với phụ huynh: Chế độ ăn ở nhà, hoạt động vui chơi, khám và điều trị chăm sóc tại nhà: ' },
            { content: '- Chăm sóc tại trường. ' },
            { content: '+ Biện pháp tổ chức bồi dưỡng cho nhân viên, cấp dưỡng chế biến thức ăn khoa học, đảm bảo đủ số lượng và chất lượng bửa ăn Theo nhu cầu dinh dưỡng của trẻ ở từng độ tuổi, đảm bảo an toàn vệ sinh thực phẩm:', wrapText: true },
            { content: '+ Có kế hoạch tổ chức hoạt động phát triển thể lực cho trẻ tại nhóm/lớp:  ' },
            { content: '+ Chăm sóc phục hồi sức khỏe cho trẻ sau khi khỏi bệnh:  ' },
          ];
          NTSheet.range(`A${rowIndex}`, `C${rowIndex + 5}`).merged(true);
          NTSheet.range(`A${rowIndex}`, `C${rowIndex + 5}`).style({
            border: true,
            bold: true,
            horizontalAlignment: "center",
            verticalAlignment: "center"
          });
          NTSheet.cell(`A${rowIndex}`).value("Kết quả");
          NTSheet.range(`D${rowIndex}`, `D${rowIndex + 1}`).merged(true);
          NTSheet.cell(`D${rowIndex}`).value("Bình thường (BT): ");
          const weightBT = unit.information.sgd === 'tayninh' ? (counter.weightBT - counter.DC - counter.BP) : counter.weightBT;
          const heightBT = unit.information.sgd === 'tayninh' ? (counter.heightBT - counter.DC - counter.BP) : counter.heightBT;

          NTSheet.range(`E${rowIndex}`, `${lastColumName}${rowIndex}`).merged(true);
          NTSheet.cell(`E${rowIndex}`).value(`Số trẻ đạt BT cân nặng/số trẻ được cân, tỷ lệ: ${counter.weightBT ? `${weightBT}/${counter.total}; ${Math.round(weightBT * 10000 / counter.total) / 100}%` : ''}`);

          NTSheet.range(`E${rowIndex + 1}`, `${lastColumName}${rowIndex + 1}`).merged(true);
          NTSheet.cell(`E${rowIndex + 1}`).value(`Số trẻ đạt BT chiều cao/số trẻ được đo, tỷ lệ: ${counter.heightBT ? `${heightBT}/${counter.total}; ${Math.round(heightBT * 10000 / counter.total) / 100}%` : ''}`);

          NTSheet.range(`D${rowIndex + 2}`, `${lastColumName}${rowIndex + 2}`).merged(true);
          NTSheet.cell(`D${rowIndex + 2}`).value(`SDD thể nhẹ cân: Số trẻ SDD /số trẻ được cân, tỷ lệ: ${counter.NC ? `${counter.NC}/${counter.total}; ${Math.round(counter.NC * 10000 / counter.total) / 100}%` : ''}`);

          NTSheet.range(`D${rowIndex + 3}`, `${lastColumName}${rowIndex + 3}`).merged(true);
          NTSheet.cell(`D${rowIndex + 3}`).value(`SDD thể thấp còi: Số trẻ SDD /số trẻ được đo, tỷ lệ: ${counter.TC ? `${counter.TC}/${counter.total}; ${Math.round(counter.TC * 10000 / counter.total) / 100}%` : ''}`);

          NTSheet.range(`D${rowIndex + 4}`, `${lastColumName}${rowIndex + 4}`).merged(true);
          NTSheet.cell(`D${rowIndex + 4}`).value(`Thừa cân (TC): Số trẻ TC/số trẻ được cân, tỷ lệ: ${counter.DC ? `${counter.DC}/${counter.total}; ${Math.round(counter.DC * 10000 / counter.total) / 100}%` : ''}`);

          NTSheet.range(`D${rowIndex + 5}`, `${lastColumName}${rowIndex + 5}`).merged(true);
          NTSheet.cell(`D${rowIndex + 5}`).value(`Béo phì (BP): Số trẻ BP/số trẻ được cân, tỷ lệ: ${counter.BP ? `${counter.BP}/${counter.total}; ${Math.round(counter.BP * 10000 / counter.total) / 100}%` : ''}`);

          NTSheet.range(`D${rowIndex}`, `${lastColumName}${rowIndex + 5}`).style({
            border: true,
            bold: true,
            horizontalAlignment: "left",
            verticalAlignment: "center",
          });
          NTSheet.range("A8", `${lastColumName}${rowIndex + 5}`).style({ border: true });
          NTSheet.range("A5", `${lastColumName}${rowIndex + 5}`).style({ fontSize: 10 });
          NTSheet.cell(`C${rowIndex + 6}`).value('*Nội dung và giải pháp').style({ bold: true, fontSize: 10 });
          rowIndex += 7;
          _.forEach(contentAndSolution, ({ content, value, wrapText }) => {
            if (value) {
              // eslint-disable-next-line no-unused-expressions
              content.length > 25 ? NTSheet.cell(`F${rowIndex}`).value(value).style({ horizontalAlignment: 'center', verticalAlignment: 'center', bold: true, fontSize: 10, }) : NTSheet.cell(`D${rowIndex}`).value(value).style({ horizontalAlignment: 'center', verticalAlignment: 'center', bold: true, fontSize: 10, })
            }
            if (wrapText) {
              NTSheet.range(`C${rowIndex}`, `${lastColumName}${rowIndex + 1}`).merged(true)
              NTSheet.cell(`C${rowIndex}`).value(content).style({
                verticalAlignment: 'center',
                horizontalAlignment: 'left',
                wrapText,
                fontSize: 10,
              });
              rowIndex += 1;
            } else {
              NTSheet.cell(`C${rowIndex}`).value(content).style({
                verticalAlignment: 'center',
                horizontalAlignment: 'left',
                fontSize: 10,
              });
            }
            rowIndex += 1;
          });
          NTSheet.range("A1", `${lastColumName}${rowIndex}`).style({ fontFamily: "Tahoma" });
        }
        NTSheet._node.children.push({
          name: 'pageSetup',
          attributes: {
            paperSize: '9',
            orientation: 'landscape',
          },
        });
        SDDSheet._node.children.push({
          name: 'pageSetup',
          attributes: {
            paperSize: '9',
            orientation: 'landscape',
          },
        });
        NTSheet.pageMargins("top", 0.35);
        NTSheet.pageMargins("right", 0.25);
        NTSheet.pageMargins("bottom", 0.28);
        NTSheet.pageMargins("left", 0.32);
        NTSheet.pageMargins("footer", 0.24);
        NTSheet.pageMargins("header", 0.2);
        SDDSheet.pageMarginsPreset("narrow");
        SDDSheet.pageMargins("top", 0.48);
        SDDSheet.pageMargins("bottom", 0.43);
        SDDSheet.pageMargins("header", 0.28);
        SDDSheet.pageMargins("footer", 0.3);
        return wb.outputAsync().then(data => {
          this.setState({ isWaiting: false });
          if (data !== undefined)
            saveAs(data, "SDD_TC_BP.xlsx");
          else
            this.errorHandler("Xuất báo cáo thất bại. Xin vui lòng thử lại sau.");
        });
      });

    } else {
      exportSoLieu(this.evaluatedStudents, this.props.classInfo, this.props.unit.information.name, this.props.dantoc).then(res => {
        this.setState({ isWaiting: false });
        saveAs(new Blob([res.data]), `${this.props.classInfo.name}_${this.props.classInfo.month}.html`);
      }).catch(() => {
        this.setState({ isWaiting: false });
        this.props.errorHandler("Xuất số liệu cân đo không thành công. Xin vui lòng thử lại sau.");
      });
    }
  }

  importHandler = (files) => {
    if (files.length === 0)
      return;
    this.setState({ isWaiting: true });

    const reader = new FileReader();
    reader.onload = () => {
      const wb = XLSX.read(reader.result, { type: "binary" });
      const excelData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"], skipHeader: true, blankrows: true });
      this.setState({ isWaiting: false });
      const { students } = this.state;
      _.range(3, excelData.length).forEach(index => {
        if (_.every(["A", "B", "C", "D", "E", "F"], col => (excelData[index][col] !== undefined && excelData[index][col] !== ""))) {
          const gender = (excelData[index].G !== undefined && excelData[index].G !== "") ? "F" : "M";
          if (_.every(["A", "D", "E", "F"], col => (!isNaN(excelData[index][col])))) {
            const key = _.camelCase(`${excelData[index].B}${excelData[index].C}${excelData[index].D}${excelData[index].E}${excelData[index].F}${gender}`);
            let ethnic = "kinh";
            if (excelData[index].H !== undefined && excelData[index].H !== "") {
              const found = _.find(this.state.dantoc, dt => _.toLower(dt.text) === _.toLower(excelData[index].H));
              if (found !== undefined)
                ethnic = found.key;
            }
            students[key] = {
              order: excelData[index].A,
              lastName: excelData[index].B,
              firstName: excelData[index].C,
              date: excelData[index].D,
              month: excelData[index].E,
              year: excelData[index].F,
              gender,
              ethnic,
              weight: (excelData[index].I !== undefined && excelData[index].I !== "" && !isNaN(excelData[index].I)) ? parseFloat(excelData[index].I) : "",
              height: (excelData[index].J !== undefined && excelData[index].J !== "" && !isNaN(excelData[index].J)) ? parseFloat(excelData[index].J) : "",
            }
          }
        }
      });
      this.setState({ isWaiting: false, students });
    };
    reader.onabort = () => {
      this.setState({ isWaiting: false });
    };
    reader.onerror = () => {
      this.setState({ isWaiting: false }, () => this.props.errorHandler("Đã có lỗi xảy ra trong quá trình đọc số liệu từ Excel"));
    };

    reader.readAsBinaryString(files[0]);
  }

  validateNumber = (value) => {
    if (isNaN(value)) {
      this.props.errorHandler("Giá trị không hợp lệ", "Giá trị nhập vào phải là số.");
      return undefined;
    }
    return value;
  }

  updateEthnicHandler = (student, value) => {
    this.changed = true;
    this.setState({
      students: {
        ...this.state.students,
        [student.studentID]: {
          ...this.state.students[student.studentID],
          ethnic: value
        }
      }
    });
  }

  updateNumberHandler = (student, value, field) => {
    const verified = this.validateNumber(value);
    if (verified === undefined)
      return;
    let number;
    if (verified.endsWith('.'))
      number = `${parseFloat(verified)}.`;
    else
      number = verified;
    this.updateValueHandler(student, number, field);
  }

  updateValueHandler = (student, value, field) => {
    this.changed = true;
    this.setState({
      students: {
        ...this.state.students,
        [student.studentID]: {
          ...this.state.students[student.studentID],
          [field]: value
        }
      }
    });
  }

  removeStudent = (studentID) => {
    const clone = this.state.students;
    delete clone[studentID];
    this.setState({ editor: undefined, students: clone });
  }

  sortABCHandler = () => {
    const { students } = this.state;
    const newStudents = {};
    const converted = Object.entries(students || {}).map(([studentID, values]) => ({ studentID, ...values }));
    const sorted = converted.sort((a, b) => (a?.firstName ?? '').localeCompare(b?.firstName ?? '', 'vi'));
    let order = 1;
    sorted.forEach(({ studentID, ...rest }) => {
      newStudents[studentID] = {
        ...rest,
        order
      }
      order += 1;
    })
    this.setState({ students: newStudents });
  }

  updateInfoHandler = () => {
    if (this.state.editor.firstName === "") {
      this.props.errorHandler("Tên không được bó trống");
      return;
    }
    if (this.state.editor.lastName === "") {
      this.props.errorHandler("Họ không được bó trống");
      return;
    }
    if (this.state.editor.date === "") {
      this.props.errorHandler("Ngày sinh không được bó trống");
      return;
    }
    if (this.state.editor.month === "") {
      this.props.errorHandler("Tháng sinh không được bó trống");
      return;
    }
    if (this.state.editor.year === "") {
      this.props.errorHandler("Năm sinh không được bó trống");
      return;
    }
    if (!moment(`${this.state.editor.date}/${this.state.editor.month}/${this.state.editor.year}`, "D/M/YYYY").isValid()) {
      this.props.errorHandler("Ngày tháng năm sinh không hợp lệ");
      return;
    }
    const id = _.camelCase(`${this.state.editor.lastName}${this.state.editor.firstName}${this.state.editor.date}${this.state.editor.month}${this.state.editor.year}${this.state.editor.gender}`);
    const { order, firstName, lastName, date, month, year, gender } = this.state.editor;
    const updateObj = { order, firstName, lastName, date, month, year, gender };
    if (this.state.editor.weight !== undefined)
      updateObj.weight = this.state.editor.weight;
    if (this.state.editor.height !== undefined)
      updateObj.height = this.state.editor.height;
    if (this.state.editor.comment !== undefined)
      updateObj.comment = this.state.editor.comment;
    if (this.state.editor.studentID === id)
      this.setState({
        students: {
          ...this.state.students,
          [this.state.editor.studentID]: updateObj
        },
        editor: undefined
      });
    else {
      const newStudents = this.state.students;
      if (this.state.editor.studentID !== "")
        delete newStudents[this.state.editor.studentID];
      newStudents[id] = updateObj;
      this.setState({
        students: newStudents,
        editor: undefined
      });
    }
  }

  renderLabel = (point) => (
    <Popup trigger={<Label color={colors[point]}>{mappingDescs[point]}</Label>} content={description[point]} />
  )

  renderGuide = () => (
    <Modal size="small" className="custom" open={this.state.showGuide} closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState({ showGuide: false })} />}>
      <Header className="form-header">Nguyên tắc tính tuổi</Header>
      <Modal.Content scrolling>
        <Message warning>
          <Message.Header>Cần xác định tuổi của trẻ trước khi cân đo</Message.Header>
          Theo quy định mới của Tổ chức Y tế thế giới: Tháng tuổi của trẻ sẽ được tính tròn tháng, nghĩa là nếu trẻ chưa đủ 30 ngày tuổi thì tính là 0 tháng tuổi.
        </Message>
        <Message positive>
          <Message.Header>Quy tắc xác định này được dựa vào ngày tổ chức cân đo, Có 2 trường hợp:</Message.Header>
          <List as="ol">
            <List.Item as="li">
              Nếu ngày cân trẻ
              {' '}
              <strong>lớn hơn hay bằng</strong>
              {' '}
              ngày sinh của trẻ thì giữ nguyên số tháng đã tính theo công thức.
            </List.Item>
            <List.Item as="li">
              Nếu ngày cân trẻ
              {' '}
              <strong>nhỏ hơn</strong>
              {' '}
              ngày sinh của trẻ thì trừ đi một so với tháng đã tính theo công thức.
            </List.Item>
          </List>
        </Message>
        <Segment>
          Thông tin chi tiết hướng dẫn cách tính, Cô có thể tham khảo
          {' '}
          <a href="http://viendinhduong.vn/FileUpload/Documents/TL%20PEM/Kythuatcandonhantrac_MTBT.pdf" rel="noopener noreferrer" target="_blank">tại đây (Viện dinh dưỡng)</a>
        </Segment>

      </Modal.Content>
    </Modal>
  )

  renderEditor = () => {
    if (this.state.editor === undefined)
      return null;
    return (
      <Modal size="small" className="custom" open closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState({ editor: undefined })} />}>
        <Header className="form-header">{(this.state.editor.studentID === "") ? "Thêm mới" : "Chỉnh sửa"}</Header>
        <Modal.Content scrolling>
          <Form>
            <Form.Group width="equal">
              <Form.Field>
                <label>Họ</label>
                <Input
                  value={this.state.editor.lastName}
                  onChange={(e, { value }) => this.setState({ editor: { ...this.state.editor, lastName: value } })}
                  placeholder="Nhập họ và tên lót của học sinh"
                />
              </Form.Field>
              <Form.Field>
                <label>Tên</label>
                <Input
                  value={this.state.editor.firstName}
                  onChange={(e, { value }) => this.setState({ editor: { ...this.state.editor, firstName: value } })}
                  placeholder="Nhập tên của học sinh"
                />
              </Form.Field>
              <Form.Field />
              <Form.Field>
                <Checkbox
                  style={{ marginTop: 30 }}
                  label="Nữ"
                  checked={this.state.editor.gender === "F"}
                  onChange={() => this.setState({ editor: { ...this.state.editor, gender: (this.state.editor.gender === "M") ? "F" : "M" } })}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group width="equal" inline>
              <Form.Field>
                <label>Ngày</label>
                <Input
                  value={this.state.editor.date}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...this.state.editor, date: verified } });
                  }}
                  placeholder="Ngày sinh"
                />
              </Form.Field>
              <Form.Field>
                <label>Tháng</label>
                <Input
                  value={this.state.editor.month}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...this.state.editor, month: verified } });
                  }}
                  placeholder="Tháng sinh"
                />
              </Form.Field>
              <Form.Field>
                <label>Năm</label>
                <Input
                  value={this.state.editor.year}
                  onChange={(e, { value }) => {
                    const verified = this.validateNumber(value);
                    if (verified !== undefined)
                      this.setState({ editor: { ...this.state.editor, year: verified } });
                  }}
                  placeholder="Năm sinh"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" size="small" onClick={() => this.removeStudent(this.state.editor.studentID)}>
            <Icon name="trash" />
            {' '}
            Xoá
          </Button>
          <Button color="blue" size="small" onClick={this.updateInfoHandler}>{(this.state.editor.studentID === "") ? "Thêm" : "Sửa"}</Button>
        </Modal.Actions>
      </Modal>
    )
  }

  renderStudents = () => {
    const months = [];
    const monthsStatus = _.get(this.props.monthsStatus, this.props.classInfo.classID, {});
    Object.entries(monthsStatus).forEach(([month, { count, unfilled }]) => {
      if (count > 0 || unfilled > 0) {
        months.push({
          key: month,
          text: month,
          value: parseFloat(month),
        })
      }
    })
    if (_.keys(this.state.students).length === 0)
      return (
        <Message warning>
          Chưa có dữ liệu học sinh.
          {(this.props.classInfo.previousMonth !== undefined) ? (
            <>
              <Button
                style={{ marginLeft: 5 }}
                size="mini"
                color="teal"
                onClick={this.getPreviousMonthData}
              >
                <Icon name="copy" />
                {' '}
                Lấy danh sách Tháng
              </Button>
              <Dropdown
                inline
                scrolling
                style={{ marginLeft: 5 }}
                options={months}
                value={this.state.previousMonth}
                onChange={(event, { value }) => {
                  this.setState({ previousMonth: value });
                }}
              />
            </>
          ) : null}
        </Message>
      );
    if (this.state.day === 0)
      return <Message warning>Chưa chọn ngày tổ chức cân đo.</Message>;
    const sgd = _.get(this.props, 'unit.information.sgd')
    this.evaluatedStudents = evaluateStudents(
      this.state.students,
      parseFloat(this.props.classInfo.month),
      this.props.unit.activeYear,
      this.props.solieucando,
      this.state.day,
      this.state.useDay,
      sgd
    ).map(student => {
      const weightPoint = (student.weightPoint !== undefined) ? computeWeightPointResult(student.weightPoint) : "";
      const heightPoint = (student.heightPoint !== undefined) ? computeHeightPointResult(student.heightPoint) : "";
      const bmiPoint = (student.bmiPoint !== undefined) ? computeBMIPointResult(student.bmiPoint) : "";
      const weightLengthPoint = (student.weightLengthPoint !== undefined) ? computeWeightLengthPointResult(student.weightLengthPoint) : "";
      let warning = "";
      if (weightPoint === "BT+")
        warning = "Nặng hơn";
      if (heightPoint === "BT+") {
        if (warning === "")
          warning = "Cao hơn so với tuổi";
        else
          warning += " và Cao hơn so với tuổi";
      } else if (weightPoint === "BT+")
        warning += " so với tuổi";
      return ({
        ...student,
        weightPoint,
        heightPoint,
        bmiPoint,
        weightLengthPoint,
        warning
      });
    });
    const remarks = {
      BT: "Trẻ bình thường",
      DC: "Trẻ thừa cân",
      BP: "Trẻ béo phì",
      GC1: "Trẻ SDD gầy còm",
      GC2: "Trẻ SDD gầy còm (nặng)",
      NC1: "Trẻ SDD nhẹ cân.",
      NC2: "Trẻ SDD nhẹ cân (nặng).",
      TC1: "Trẻ SDD thấp còi.",
      TC2: "Trẻ SDD thấp còi (nặng).",
      NC1TC1: "Trẻ SDD nhẹ cân / thấp còi.",
      NC1TC2: "Trẻ SDD nhẹ cân / thấp còi (nặng).",
      NC2TC1: "Trẻ SDD nhẹ cân (nặng) / thấp còi.",
      NC2TC2: "Trẻ SDD nhẹ cân (nặng) / thấp còi (nặng).",
    }
    return (
      <Table celled striped unstackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="solieuHeader" rowSpan="2" colSpan="2">Họ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Tên</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Ngày sinh</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Nữ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Dân tộc</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Số tháng</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" colSpan="2">Cân nặng theo tuổi</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" colSpan="2">Chiều cao theo tuổi</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Cân nặng theo chiều cao</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" colSpan="2">BMI theo tuổi</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Đánh giá Cân nặng theo Chiều cao / BMI</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader" rowSpan="2">Ghi chú</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className="solieuHeader">Số cân (kg)</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">KQ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">Số đo (cm)</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">KQ</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">Chỉ số BMI</Table.HeaderCell>
            <Table.HeaderCell className="solieuHeader">KQ</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.evaluatedStudents.map(student => {
            const {
              studentID,
              lastName,
              firstName,
              gender,
              monthsCount,
              date,
              month,
              year,
              comment,
              height,
              weight,
              weightPoint,
              heightPoint,
              weightLengthPoint,
              bmiPoint,
              bmi,
              warning
            } = student;
            let remark = "";
            if (weightPoint !== 'NC1' && weightPoint !== 'NC2' && heightPoint !== 'TC1' && heightPoint !== 'TC2') {
              remark = remarks[bmiPoint || weightLengthPoint];
            } else {
              if ((heightPoint === 'BT' || heightPoint === 'BT+') && (weightPoint === 'NC1' || weightPoint === 'NC2')) remark = remarks[weightPoint];
              if ((weightPoint === 'BT' || weightPoint === 'BT+') && (heightPoint === 'TC1' || heightPoint === 'TC2')) remark = remarks[heightPoint];
              if (heightPoint !== 'BT' && heightPoint !== 'BT+' && weightPoint !== 'BT' && weightPoint !== 'BT+') remark = remarks[`${weightPoint}${heightPoint}`];
            }
            return (
              <Table.Row key={studentID} warning={height === undefined || height === "" || weight === undefined || weight === ""}>
                <Table.Cell collapsing className="solieuValue">
                  <Icon name="edit" color="blue" onClick={() => this.setState({ editor: student })} />
                </Table.Cell>
                <Table.Cell collapsing className="solieuValue">{lastName}</Table.Cell>
                <Table.Cell collapsing className="solieuValue">{firstName}</Table.Cell>
                <Table.Cell collapsing className="solieuValue">
                  {date}
                  /
                  {month}
                  /
                  {year}
                </Table.Cell>
                <Table.Cell collapsing>{(gender === "F") ? <Icon name="female" /> : null}</Table.Cell>
                <Table.Cell collapsing><Dropdown placeholder="Kinh" fluid search selection options={this.state.dantoc} value={_.get(student, "ethnic", "kinh")} onChange={(e, { value }) => this.updateEthnicHandler(student, value)} /></Table.Cell>
                <Table.Cell collapsing className="solieuThang">{monthsCount}</Table.Cell>
                <Table.Cell collapsing>
                  <Input fluid className="solieuInput" value={(weight !== undefined) ? weight : ""} onChange={(e, { value }) => this.updateNumberHandler(student, value, "weight")} />
                </Table.Cell>
                <Table.Cell collapsing>{(weightPoint !== "") ? this.renderLabel(weightPoint) : null}</Table.Cell>
                <Table.Cell collapsing>
                  <Input fluid className="solieuInput" value={(height !== undefined) ? height : ""} onChange={(e, { value }) => this.updateNumberHandler(student, value, "height")} />
                </Table.Cell>
                <Table.Cell collapsing>{(heightPoint !== "") ? this.renderLabel(heightPoint) : null}</Table.Cell>
                <Table.Cell collapsing>{(weightLengthPoint !== "") ? this.renderLabel(weightLengthPoint) : null}</Table.Cell>
                <Table.Cell collapsing>{(bmi !== undefined) ? Math.round(bmi * 100) / 100 : ""}</Table.Cell>
                <Table.Cell collapsing>{(bmiPoint !== "") ? this.renderLabel(bmiPoint) : null}</Table.Cell>
                <Table.Cell warning>
                  {remark}
                </Table.Cell>
                {(warning !== "") ? (
                  <Table.Cell warning>
                    <Icon name="attention" color="brown" />
                    {' '}
                    {warning}
                  </Table.Cell>
                ) : <Table.Cell />}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    );
  }

  renderActions = () => (
    <Modal.Actions>
      {(_.keys(this.state.students).length === 0) ? (
        <Button
          style={{ float: "left" }}
          size="small"
          onClick={() => {
            setTimeout(() => {
              const response = { file: `${RESOURCES}/rebot/file_mau_hs_dt.xls` };
              window.open(response.file);
            }, 100);
          }}
        >
          <Icon name="download" />
          File mẫu
        </Button>
      ) : null}
      {(_.keys(this.state.students).length === 0) ? (
        <Dropzone accept=".xls, .xlsx" multiple={false} onDrop={this.importHandler}>
          {({ getRootProps, getInputProps }) => (
            <div style={{ float: "left" }} {...getRootProps()}>
              <input {...getInputProps()} />
              <Button color="green" size="small">
                <Icon name="file excel outline" />
                Nhập từ Excel
              </Button>
            </div>
          )}
        </Dropzone>
      ) : null}
      {(_.keys(this.state.students).length > 0) ? (
        <Button style={{ float: "left" }} color="brown" size="small" onClick={this.sortABCHandler}>
          <Icon name="sort alphabet ascending" />
          Sắp xếp tên
        </Button>
      ) : null}

      <Button color="orange" size="small" onClick={() => this.exportHandler(2)}>
        <Icon name="file excel outline" />
        Theo dõi sức khoẻ
      </Button>

      {(_.keys(this.state.students).length > 0) ? (
        <Button color="teal" size="small" onClick={this.exportHandler}>
          <Icon name="download" />
          Kết quả cân đo
        </Button>
      ) : null}
      <Button size="small" color="green" onClick={() => this.setState({ editor: { order: 1, studentID: "", firstName: "", lastName: "", date: "", month: "", year: "", gender: "M" } })}>
        <Icon name="add" />
        Thêm HS
      </Button>
      {(_.keys(this.state.students).length > 0) ? (
        <Button
          color="red"
          size="small"
          onClick={() => this.setState({ students: undefined })}
        >
          <Icon name="remove" />
          Xoá tất cả
        </Button>
      ) : null}
      <Button color="blue" size="small" onClick={this.saveHandler}>
        <Icon name="save" />
        Lưu
      </Button>
    </Modal.Actions>
  )

  renderAskForSave = () => (
    <Modal size="small" open>
      <Header className="form-header">Thông báo</Header>
      <Modal.Content scrolling>
        {(this.props.user.gender === "M") ? "Thầy" : "Cô"}
        {' '}
        có muốn lưu lại những thay đổi vừa rồi không?
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" size="small" onClick={this.saveHandler}>
          <Icon name="checkmark" />
          {' '}
          Có
        </Button>
        <Button color="red" size="small" onClick={() => this.setState({ showAskForSave: false }, this.props.closeHandler)}>Không</Button>
      </Modal.Actions>
    </Modal>
  )

  render() {
    const { isSaving, useDay } = this.state;
    if (isSaving)
      return (
        <Dimmer active>
          <Loader>Đang xử lý...</Loader>
        </Dimmer>
      );
    return (
      <div>
        {this.renderGuide()}
        <Modal size="fullscreen" open>
          <Header className="form-header">
            <Header.Content as="h3">
              Số liệu cân đo Tháng
              {' '}
              {this.props.classInfo.month}
              . Lớp
              {' '}
              {this.props.classInfo.name}
              .
              {(this.changed ? "(Chưa lưu)" : "")}
              <Label>
                {(_.keys(this.state.students).length > 0) ? `Sĩ số: ${_.keys(this.state.students).length}` : "Chưa có học sinh"}
              </Label>
            </Header.Content>
            <Header.Subheader style={{ color: "red" }}>
              <Form>
                <Form.Group inline>
                  <label>Nguyên tắc tính tuổi:</label>
                  <Form.Field>
                    <Radio
                      label='Theo Bộ Giáo dục (chỉ tính tháng)'
                      name='radioGroup'
                      checked={!useDay}
                      onChange={() => this.setState({ useDay: !useDay })}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Theo Viện Dinh dưỡng (dựa trên ngày cân đo)'
                      name='radioGroup'
                      checked={useDay}
                      onChange={() => this.setState({ useDay: !useDay })}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <Icon name="help circle" color="blue" onClick={() => this.setState({ showGuide: true })} />
              {' '}
              Ngày tổ chức cân đo:
              <Dropdown
                style={{ marginLeft: 10 }}
                floating
                inline
                scrolling
                options={this.state.days}
                value={(this.state.day === 0) ? "" : this.state.day}
                onChange={(event, { value }) => this.setState({ day: parseFloat(value) }, () => { this.changed = true; })}
                placeholder="Chọn ngày tổ chức cân đo"
              />
              {' '}
              /
              {' '}
              {this.props.classInfo.month}
            </Header.Subheader>
            <Button style={{ float: "right", marginTop: -50 }} onClick={this.closeHandler}>
              <Icon name="angle left" />
              {' '}
              Quay lại
            </Button>
          </Header>
          <Modal.Content scrolling>
            {this.state.isWaiting ? (
              <Dimmer active>
                <Loader>Đang kết nối...</Loader>
              </Dimmer>
            ) : null}
            {this.state.showAskForSave ? this.renderAskForSave() : null}
            {this.renderEditor()}
            {this.renderStudents()}
          </Modal.Content>
          {this.renderActions()}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(SoLieu);