import { REBOT_REPORTS_MANAGE, REBOT_UNITS_MANAGE } from "./constants";


/**
 * Update status information of unit 
 * 
 * @module Redux.Actions.Manages
 * @ngdoc method
 * @name $reportsManage
 * @param  {String} [unitID]
 * @param  {Object} [status] as node status on firebase
 */
export function reportsManage(unitID, status) {
  return {
    type: REBOT_REPORTS_MANAGE,
    unitID,
    status
  };
}

/**
 * Update information of unit 
 * 
 * @module Redux.Actions.Manages
 * @ngdoc method
 * @name $unitsManage
 * @param  {String} [unitID]
 * @param  {Object} [information] as node unit on firebase
 */
export function unitsManage(unitID, information) {
  return {
    type: REBOT_UNITS_MANAGE,
    unitID,
    information
  };
}