/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button, Input, Label, Icon, Dimmer, Loader, Form, Dropdown, Modal, Header, Popup } from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { FormattedNumber } from "react-intl";
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { saveAs } from "file-saver";

import ConfirmModal from "../../../components/ConfirmModal";

import { db } from "../../../libs/firebase";
import { RESOURCES } from "../../../libs/config";


class Grade extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    cocauCatalogs: PropTypes.object.isRequired,
    unitID: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    activeYear: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    grade: PropTypes.string.isRequired,
    data: PropTypes.shape({
      price: PropTypes.number,
      cocau: PropTypes.string,
      days: PropTypes.object
    }),
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      gender: PropTypes.string
    }).isRequired,
    allowEdit: PropTypes.bool
  };

  static defaultProps = {
    allowEdit: false,
    data: {
      price: 0,
      cocau: "",
      days: {}
    }
  };

  constructor(props) {
    super(props);

    const { price, cocau } = this.props.data;
    this.state = {
      price,
      cocau,
      isWaiting: false,
      showHelp: false
    };

  }

  onComplete = (error) => {
    this.setState({ isWaiting: false, askToRemove: undefined });
    if (error)
      this.props.errorHandler("Cập nhật dữ liệu không thành công. Xin vui lòng thử lại sau.");
    else
      this.props.successHandler("Cập nhật dữ liệu thành công.");
  }

  computeYear = () => {
    const { activeYear, month } = this.props;
    if (month >= 9)
      return parseFloat(activeYear);
    return parseFloat(activeYear) + 1;
  }

  exportHandler = (data, day) => {
    this.setState({ isWaiting: true });
    try {
      const req = new XMLHttpRequest();
      req.open("GET", `${RESOURCES}/rebot/export/dieuchinhthucdon.xlsx`, true);
      req.responseType = "arraybuffer";
      req.onload = () => {
        XlsxPopulate.fromDataAsync(req.response).then(workbook => {
          const sheet = workbook.sheet("thucdon");
          const { unitName, month, grade } = this.props;
          if (sheet) {
            const {
              TenThucDon,
              Nhom,
              SiSo,
              Tien1Tre,
              TongTien,
              Sang,
              CanhTrua,
              CanhTrua2,
              Man,
              Man2,
              KhaiVi,
              XeTrua,
              XeTrua2,
              TrangMieng,
              TrangMieng2,
              ThucPhams,
              DichVu,
              Gia1Calo,
              Summarize
            } = data;
            sheet.cell("B2").value(unitName);
            sheet.cell("C3").value(`${day}/${month}/${this.computeYear()}`);
            sheet.cell("C4").value(TenThucDon);
            sheet.cell("C5").value(Nhom);
            sheet.cell("C6").value(`${SiSo} x ${Tien1Tre} = ${TongTien}`);
            sheet.cell("F3").value(Sang);
            sheet.cell("F4").value(CanhTrua);
            sheet.cell("F5").value(CanhTrua2);
            sheet.cell("F6").value(Man);
            sheet.cell("F7").value(Man2);
            sheet.cell("I3").value(KhaiVi);
            sheet.cell("I4").value(XeTrua);
            sheet.cell("I5").value(XeTrua2);
            sheet.cell("I6").value(TrangMieng);
            sheet.cell("I7").value(TrangMieng2);

            let rowIndex = 11;
            _.forEach(ThucPhams, (thucpham, index) => {
              const {
                TenThucPham,
                LuongKhauPhanGam,
                DonGiaDonViTinh,
                DonGiaKilogam,
                LuongKhauPhanDonViTinh,
                SoPhan,
                DonViTinh,
                ThucMuaDonViTinh,
                ThucMuaKilogam,
                HeSoThaiBo
              } = thucpham;
              sheet.cell(`A${rowIndex}`).value(index + 1);
              sheet.cell(`B${rowIndex}`).value(TenThucPham);
              sheet.cell(`C${rowIndex}`).value(Math.round(LuongKhauPhanGam * 100) / 100);
              sheet.cell(`D${rowIndex}`).value(parseFloat(DonGiaKilogam).toLocaleString("en"));
              sheet.cell(`E${rowIndex}`).value(Math.round(SoPhan * 100) / 100);
              sheet.cell(`F${rowIndex}`).value(HeSoThaiBo);
              sheet.cell(`G${rowIndex}`).value(ThucMuaKilogam);
              sheet.cell(`H${rowIndex}`).value(ThucMuaDonViTinh);
              sheet.cell(`I${rowIndex}`).value(DonViTinh);
              sheet.cell(`J${rowIndex}`).value(Math.round(LuongKhauPhanDonViTinh * 100) / 100);
              sheet.cell(`K${rowIndex}`).value(parseFloat(DonGiaDonViTinh).toLocaleString("en"));
              sheet.range(`A${rowIndex}:K${rowIndex}`).style({ border: true, horizontalAlignment: "center" });
              rowIndex += 1;
            });

            rowIndex += 1;
            sheet.range(`C${rowIndex}:K${rowIndex + 1}`).style({ border: true, bold: true, horizontalAlignment: "center", verticalAlignment: "center" });
            sheet.cell(`C${rowIndex}`).value("Đạm");
            sheet.range(`C${rowIndex}:D${rowIndex}`).merged(true);
            sheet.cell(`E${rowIndex}`).value("Đạm");
            sheet.range(`E${rowIndex}:F${rowIndex}`).merged(true);
            sheet.cell(`G${rowIndex}`).value("Đường");
            sheet.range(`G${rowIndex}:G${rowIndex + 1}`).merged(true);
            sheet.cell(`H${rowIndex}`).value("Calo");
            sheet.range(`H${rowIndex}:H${rowIndex + 1}`).merged(true);
            sheet.cell(`I${rowIndex}`).value("Tiền");
            sheet.range(`I${rowIndex}:I${rowIndex + 1}`).merged(true);
            sheet.cell(`J${rowIndex}`).value("Thông tin khác");
            sheet.range(`J${rowIndex}:K${rowIndex + 1}`).merged(true);
            rowIndex += 1;
            sheet.cell(`C${rowIndex}`).value("Động vật");
            sheet.cell(`D${rowIndex}`).value("Thực vật");
            sheet.cell(`E${rowIndex}`).value("Động vật");
            sheet.cell(`F${rowIndex}`).value("Thực vật");
            rowIndex += 1;

            sheet.range(`B${rowIndex}:K${rowIndex + 6}`).style({ border: true, horizontalAlignment: "right" });
            const {
              TongCong,
              DinhMuc1Ngay,
              TyLeTungLoai,
              DongVatThucVat,
              TyLeDat,
              CoCauApDung,
              TyLePLG
            } = Summarize;

            sheet.cell(`B${rowIndex}`).value("Tổng cộng:");
            sheet.cell(`B${rowIndex}`).style({ bold: true, border: false });
            sheet.cell(`C${rowIndex}`).value(Math.round(TongCong.BeoDV * 100) / 100);
            sheet.cell(`D${rowIndex}`).value(Math.round(TongCong.BeoTV * 100) / 100);
            sheet.cell(`E${rowIndex}`).value(Math.round(TongCong.DamDV * 100) / 100);
            sheet.cell(`F${rowIndex}`).value(Math.round(TongCong.DamTV * 100) / 100);
            sheet.cell(`G${rowIndex}`).value(Math.round(TongCong.Duong * 100) / 100);
            sheet.cell(`H${rowIndex}`).value(Math.round(TongCong.Calo * 100) / 100);
            sheet.cell(`I${rowIndex}`).value(TongCong.Tien);
            sheet.cell(`J${rowIndex}`).value(`Dịch vụ: ${DichVu}\n Giá 1 Calo: ${Gia1Calo}`);
            sheet.cell(`J${rowIndex}`).style({ wrapText: true });
            sheet.range(`I${rowIndex}:I${rowIndex + 6}`).merged(true);
            sheet.range(`J${rowIndex}:K${rowIndex + 6}`).merged(true);
            sheet.range(`C${rowIndex}:K${rowIndex}`).style({ border: true, horizontalAlignment: "center", verticalAlignment: "center" });
            rowIndex += 1;

            sheet.cell(`B${rowIndex}`).value("Định mức 1 ngày:");
            sheet.cell(`B${rowIndex}`).style({ bold: true, border: false });
            sheet.cell(`C${rowIndex}`).value(Math.round(DinhMuc1Ngay.BeoDV * 100) / 100);
            sheet.cell(`D${rowIndex}`).value(Math.round(DinhMuc1Ngay.BeoTV * 100) / 100);
            sheet.cell(`E${rowIndex}`).value(Math.round(DinhMuc1Ngay.DamDV * 100) / 100);
            sheet.cell(`F${rowIndex}`).value(Math.round(DinhMuc1Ngay.DamTV * 100) / 100);
            sheet.cell(`G${rowIndex}`).value(Math.round(DinhMuc1Ngay.Duong * 100) / 100);
            sheet.cell(`H${rowIndex}`).value(Math.round(DinhMuc1Ngay.Calo * 100) / 100);
            sheet.range(`C${rowIndex}:K${rowIndex}`).style({ border: true, horizontalAlignment: "center", verticalAlignment: "center" });
            rowIndex += 1;

            sheet.cell(`B${rowIndex}`).value("Tỷ lệ từng loại (%):");
            sheet.cell(`B${rowIndex}`).style({ bold: true, border: false });
            sheet.cell(`C${rowIndex}`).value(Math.round(TyLeTungLoai.BeoDV * 100) / 100);
            sheet.cell(`D${rowIndex}`).value(Math.round(TyLeTungLoai.BeoTV * 100) / 100);
            sheet.cell(`E${rowIndex}`).value(Math.round(TyLeTungLoai.DamDV * 100) / 100);
            sheet.cell(`F${rowIndex}`).value(Math.round(TyLeTungLoai.DamTV * 100) / 100);
            sheet.cell(`G${rowIndex}`).value(Math.round(TyLeTungLoai.Duong * 100) / 100);
            sheet.cell(`H${rowIndex}`).value(Math.round(TyLeTungLoai.Calo * 100) / 100);
            sheet.range(`C${rowIndex}:K${rowIndex}`).style({ border: true, horizontalAlignment: "center", verticalAlignment: "center" });
            rowIndex += 1;

            sheet.cell(`B${rowIndex}`).value("Động vật thực vật (%):");
            sheet.cell(`B${rowIndex}`).style({ bold: true, border: false });
            sheet.cell(`C${rowIndex}`).value(Math.round(DongVatThucVat.BeoDV * 100) / 100);
            sheet.cell(`D${rowIndex}`).value(Math.round(DongVatThucVat.BeoTV * 100) / 100);
            sheet.cell(`E${rowIndex}`).value(Math.round(DongVatThucVat.DamDV * 100) / 100);
            sheet.cell(`F${rowIndex}`).value(Math.round(DongVatThucVat.DamTV * 100) / 100);
            sheet.range(`C${rowIndex}:K${rowIndex}`).style({ border: true, horizontalAlignment: "center", verticalAlignment: "center" });
            rowIndex += 1;

            sheet.cell(`B${rowIndex}`).value("Tỷ lệ đạt (%):");
            sheet.cell(`B${rowIndex}`).style({ bold: true, border: false });
            sheet.cell(`C${rowIndex}`).value(Math.round(TyLeDat.Beo * 100) / 100);
            sheet.range(`C${rowIndex}:D${rowIndex}`).merged(true);
            sheet.cell(`E${rowIndex}`).value(Math.round(TyLeDat.Dam * 100) / 100);
            sheet.range(`E${rowIndex}:F${rowIndex}`).merged(true);
            sheet.cell(`G${rowIndex}`).value(Math.round(TyLeDat.Duong * 100) / 100);
            sheet.cell(`H${rowIndex}`).value(Math.round(TyLeDat.Calo * 100) / 100);
            sheet.range(`C${rowIndex}:K${rowIndex}`).style({ border: true, horizontalAlignment: "center", verticalAlignment: "center" });
            rowIndex += 1;

            sheet.cell(`B${rowIndex}`).value("Cơ cấu áp dụng (%):");
            sheet.cell(`B${rowIndex}`).style({ bold: true, border: false });
            sheet.cell(`C${rowIndex}`).value(Math.round(CoCauApDung.Beo * 100) / 100);
            sheet.range(`C${rowIndex}:D${rowIndex}`).merged(true);
            sheet.cell(`E${rowIndex}`).value(Math.round(CoCauApDung.Dam * 100) / 100);
            sheet.range(`E${rowIndex}:F${rowIndex}`).merged(true);
            sheet.cell(`G${rowIndex}`).value(Math.round(CoCauApDung.Duong * 100) / 100);
            sheet.range(`C${rowIndex}:K${rowIndex}`).style({ border: true, horizontalAlignment: "center", verticalAlignment: "center" });
            rowIndex += 1;

            sheet.cell(`B${rowIndex}`).value("Tỷ lệ P:L:G (%):");
            sheet.cell(`B${rowIndex}`).style({ bold: true, border: false });
            sheet.cell(`C${rowIndex}`).value(Math.round(TyLePLG.Beo * 100) / 100);
            sheet.range(`C${rowIndex}:D${rowIndex}`).merged(true);
            sheet.cell(`E${rowIndex}`).value(Math.round(TyLePLG.Dam * 100) / 100);
            sheet.range(`E${rowIndex}:F${rowIndex}`).merged(true);
            sheet.cell(`G${rowIndex}`).value(Math.round(TyLePLG.Duong * 100) / 100);
            sheet.range(`C${rowIndex}:K${rowIndex}`).style({ border: true, horizontalAlignment: "center", verticalAlignment: "center" });

          }
          return workbook.outputAsync().then(excel => {
            this.setState({ isWaiting: false });
            if (excel)
              saveAs(excel, `${grade}_${day}_${month}_${this.computeYear()}.xlsx`);
            else
              this.errorHandler("Xuất báo cáo thất bại. Xin vui lòng thử lại sau.");
          });
        }, (error) => {
          this.setState({ isWaiting: false });
          if (error)
            this.props.errorHandler("Xuất báo cáo dinh dưỡng không thành công. Xin vui lòng thử lại sau.");
        });
      }
      req.onerror = () => {
        this.props.errorHandler("Xuất báo cáo dinh dưỡng không thành công. Xin vui lòng thử lại sau.");
      }
      req.send();

    } catch (err) {
      this.setState({ isWaiting: false });
      this.props.errorHandler("Xuất báo cáo dinh dưỡng không thành công. Xin vui lòng thử lại sau.");
    }
  }

  updatePriceHandler = (e, { value }) => {
    if (isNaN(value)) {
      this.props.errorHandler("Giá trị nhập vào phải là số.");
      return;
    }
    this.setState({ price: value });
  }

  updateHandler = () => {
    const { price, cocau } = this.state;
    this.setState({ isWaiting: true });
    const { unitID, activeYear, month, grade } = this.props;
    db.ref(`dinhduong/${activeYear}/reports/${unitID}/${month}/${grade}`).update({
      price: parseFloat(price),
      cocau
    }, this.onComplete);
  }

  importHandler = (files, day) => {
    if (files.length === 0)
      return;
    this.setState({ isWaiting: true });
    const { unitID, activeYear, month, grade, user } = this.props;
    const reader = new FileReader();
    reader.onload = () => {
      db.ref(`dinhduong/${activeYear}/reports/${unitID}/${month}/${grade}/days/d${day}`).set({
        modifiedBy: user.email,
        modifiedTime: new Date().getTime(),
        data: JSON.parse(reader.result)
      }, this.onComplete)
    };
    reader.onabort = () => { };
    reader.onerror = () => {
      this.setState({ isWaiting: false }, () => this.props.errorHandler("Đã có lỗi xảy ra trong quá trình đọc số liệu báo cáo."));
    };

    reader.readAsText(files[0]);
  }

  removeHandler = () => {
    this.setState({ isWaiting: true });
    const { unitID, activeYear, month, grade, user } = this.props;
    const { askToRemove } = this.state;
    db.ref(`dinhduong/${activeYear}/reports/${unitID}/${month}/${grade}/days/d${askToRemove}`).set({
      modifiedBy: user.email,
      modifiedTime: new Date().getTime()
    }, this.onComplete)
  }

  round = (number) => {
    if (number && !isNaN(number))
      return Math.round(number * 100) / 100;
    return "";
  }

  computeStatus = (month) => {
    const result = {
      total: moment([this.computeYear(), month - 1]).daysInMonth(),
      hasData: 0,
      passed: 0
    };
    const { data } = this.props;
    _.forEach(_.range(1, result.total), date => {
      const details = _.get(data, `days.d${date}.data`);
      if (details) {
        result.hasData += 1;
        if (details.Passed)
          result.passed += 1;
      }
    });
    return result;
  }

  computeChenhLech = (tienan) => {
    const { price } = this.state;
    if (isNaN(tienan))
      return price;
    return price - tienan;
  }

  computeCoCauDatDuoc = (tylePLG) => {
    const { Dam, Beo, Duong } = tylePLG;
    if (Dam && Beo && Duong)
      return `${this.round(Dam)} - ${this.round(Beo)} - ${this.round(Duong)}`;
    return "";
  }

  renderHelp = () => {
    const { cocauCatalogs } = this.props;
    const { showHelp } = this.state;
    if (cocauCatalogs)
      return (
        <Modal className="custom" size="fullscreen" open={showHelp} closeIcon={<Icon name="close" color="red" size="large" onClick={() => this.setState({ showHelp: false })} />}>
          <Header className="form-header">Chú thích danh mục cơ cấu</Header>
          <Modal.Content scrolling>
            <Table celled striped color="blue" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell rowSpan="2">Cơ cấu</Table.HeaderCell>
                  <Table.HeaderCell colSpan="2">Đạm</Table.HeaderCell>
                  <Table.HeaderCell colSpan="2">Béo</Table.HeaderCell>
                  <Table.HeaderCell rowSpan="2">Đường</Table.HeaderCell>
                  <Table.HeaderCell rowSpan="2">Calo</Table.HeaderCell>
                  <Table.HeaderCell colSpan="2">Tỷ lệ đạt</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>ĐV</Table.HeaderCell>
                  <Table.HeaderCell>TV</Table.HeaderCell>
                  <Table.HeaderCell>ĐV</Table.HeaderCell>
                  <Table.HeaderCell>TV</Table.HeaderCell>
                  <Table.HeaderCell>Tối thiểu</Table.HeaderCell>
                  <Table.HeaderCell>Tối đa</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(_.keys(cocauCatalogs), cocau => {
                  const { name, DamDongVat, DamThucVat, BeoDongVat, BeoThucVat, Duong, Calo, TyLeDatMin, TyLeDatMax } = cocauCatalogs[cocau];
                  return (
                    <Table.Row key={cocau}>
                      <Table.Cell>{name}</Table.Cell>
                      <Table.Cell>{DamDongVat}</Table.Cell>
                      <Table.Cell>{DamThucVat}</Table.Cell>
                      <Table.Cell>{BeoDongVat}</Table.Cell>
                      <Table.Cell>{BeoThucVat}</Table.Cell>
                      <Table.Cell>{Duong}</Table.Cell>
                      <Table.Cell>{Calo}</Table.Cell>
                      <Table.Cell>{TyLeDatMin}</Table.Cell>
                      <Table.Cell>{TyLeDatMax}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>
      );
    return null;
  }

  renderDay = (day) => {
    const { data, allowEdit } = this.props;
    const details = _.get(data, `days.d${day}.data`);
    if (details) {
      const { TenThucDon, Tien1Tre, Summarize, Passed } = details;
      const chenhlech = this.computeChenhLech(Tien1Tre);
      return (
        <Table.Row style={{ textAlign: "center" }} key={day} positive={Passed || false} negative={!Passed}>
          <Table.Cell collapsing>
            <Popup
              trigger={(
                <Icon
                  color="green"
                  name="file excel outline"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.exportHandler(details, day)}
                />
              )}
              content="Tải báo cáo về"
            />
            {allowEdit ? (
              <Popup
                trigger={(
                  <Icon
                    color="red"
                    name="trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ askToRemove: day })}
                  />
                )}
                content="Xoá báo cáo"
              />
            ) : null}
          </Table.Cell>
          <Table.Cell collapsing>{day}</Table.Cell>
          <Table.Cell collapsing>{TenThucDon}</Table.Cell>
          <Table.Cell>{this.computeCoCauDatDuoc(_.get(Summarize, "TyLePLG"))}</Table.Cell>
          <Table.Cell><FormattedNumber value={Tien1Tre} /></Table.Cell>
          <Table.Cell negative={chenhlech !== 0}><FormattedNumber value={chenhlech} /></Table.Cell>
          <Table.Cell>{this.round(_.get(Summarize, "TyLeDat.Dam", ""))}</Table.Cell>
          <Table.Cell>{this.round(_.get(Summarize, "TyLeDat.Beo", ""))}</Table.Cell>
          <Table.Cell>{this.round(_.get(Summarize, "TyLeDat.Duong", ""))}</Table.Cell>
          <Table.Cell>{this.round(_.get(Summarize, "TyLeDat.Calo", ""))}</Table.Cell>
          <Table.Cell><Label color={Passed ? "blue" : "red"}>{Passed ? "Đạt" : "Chưa đạt"}</Label></Table.Cell>
        </Table.Row>
      );
    }
    return (
      <Table.Row style={{ textAlign: "center" }} key={day} warning>
        <Table.Cell collapsing>
          {allowEdit ? (
            <Popup
              trigger={(
                <Dropzone
                  style={{ float: "left" }}
                  accept=".json"
                  multiple={false}
                  onDrop={files => this.importHandler(files, day)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div style={{ float: "left" }} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Icon name="upload" color="green" style={{ cursor: "pointer" }} />
                    </div>
                  )}
                </Dropzone>
              )}
              content="Tải file báo cáo lên hệ thống"
            />
          ) : null}
        </Table.Cell>
        <Table.Cell collapsing>{day}</Table.Cell>
        <Table.Cell colSpan="9">Chưa có dữ liệu</Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const { month, cocauCatalogs, grade, allowEdit } = this.props;
    const { price, cocau, isWaiting, askToRemove } = this.state;
    const options = _.map(_.keys(cocauCatalogs), value => ({
      value,
      text: cocauCatalogs[value].name
    }));
    const { total, hasData, passed } = this.computeStatus(month);
    return (
      <div>
        {isWaiting ? (
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
        ) : null}
        {askToRemove ? (
          <ConfirmModal
            icon="warning"
            title={`Xoá ngày ${askToRemove}/${month}`}
            content="Sau khi xoá thì sẽ không thể phục hồi. Tiếp tục xoá?"
            closeHandler={() => this.setState({ askToRemove: undefined })}
            confirmHandler={this.removeHandler}
          />
        ) : null}
        {this.renderHelp()}
        <Form key={grade}>
          <Form.Group inline>
            <Form.Field inline>
              <label>Tiền ăn tiêu chuẩn 1 ngày:</label>
              <Input placeholder="Nhập tiền ăn tiêu chuẩn 1 ngày" value={price} onChange={this.updatePriceHandler} disabled={!allowEdit} />
            </Form.Field>
            <Form.Field inline>
              <label>
                Cơ cấu áp dụng:
                {' '}
                <Icon name="help" color="blue" onClick={() => this.setState({ showHelp: true })} />
              </label>
              <Dropdown
                search
                selection
                multiple={false}
                onChange={(event, { value }) => this.setState({ cocau: value })}
                options={options}
                value={cocau || undefined}
                placeholder="Chọn cơ cấu áp dụng"
                disabled={!allowEdit}
              />
            </Form.Field>
            {allowEdit ? <Form.Field control={Button} color="blue" onClick={this.updateHandler}>Cập nhật cấu hình</Form.Field> : null}
          </Form.Group>
        </Form>

        <Label.Group>
          <Label color="teal">
            {passed}
/
            {hasData}
            {' '}
thực đơn đạt
          </Label>
          <Label color="red">
            {total - hasData}
            {' '}
ngày chưa có dữ liệu
          </Label>
        </Label.Group>
        <Table style={{ background: "transparent" }} celled striped color="brown" selectable>
          <Table.Header>
            <Table.Row style={{ textAlign: "center" }}>
              <Table.HeaderCell collapsing rowSpan="2" colSpan="2">Ngày</Table.HeaderCell>
              <Table.HeaderCell collapsing rowSpan="2">Mã thực đơn</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">Cơ cấu đạt được</Table.HeaderCell>
              <Table.HeaderCell collapsing colSpan="2">Tiền ăn</Table.HeaderCell>
              <Table.HeaderCell collapsing colSpan="4">Tỷ lệ % đạt</Table.HeaderCell>
              <Table.HeaderCell collapsing rowSpan="2">Đánh giá</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell collapsing>Trong ngày</Table.HeaderCell>
              <Table.HeaderCell collapsing>Chênh lệch</Table.HeaderCell>
              <Table.HeaderCell collapsing>Đạm Protid</Table.HeaderCell>
              <Table.HeaderCell collapsing>Béo Lipid</Table.HeaderCell>
              <Table.HeaderCell collapsing>Đường Gluxid</Table.HeaderCell>
              <Table.HeaderCell collapsing>Calo</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(_.range(1, moment([this.computeYear(), month - 1]).daysInMonth()), date => this.renderDay(date))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Grade);