/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Button,
  Modal,
  Header,
  Dimmer,
  Loader,
  Table,
  Label,
  Checkbox,
  Form,
  Radio
} from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";
import { saveAs } from "file-saver";

// services
import { db } from "../../../libs/firebase";
import { eventTracking } from "../../../libs/ga";
import { convertTimeStamp } from "../../../libs/time";
import { evaluateStudents, computeBMIPointResult, computeHeightPointResult, computeWeightLengthPointResult, computeWeightPointResult, exportCanDo } from "./helpers";


const cities = require("../../../libs/cities.json");

const months = ["8", "9", "10", "11", "12", "1", "2", "3", "4", "5", "6", "7"];

class Extractor extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      gender: PropTypes.string,
    }).isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      activeMilestone: PropTypes.string,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        ward: PropTypes.string
      }).isRequired
    }).isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      classID: PropTypes.string.isRequired,
      grade: PropTypes.arrayOf(PropTypes.string).isRequired
    })).isRequired,
    monthsStatus: PropTypes.object.isRequired,
    solieucando: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      selected: {},
      useDay: false
    };
  }

  getStatus = (classID, month) => {
    if (this.props.monthsStatus[classID][month] === undefined)
      return "Chưa có số liệu";
    return `${this.props.monthsStatus[classID][month].modifiedBy}. ${convertTimeStamp(this.props.monthsStatus[classID][month].modifiedTime)}`;
  }

  readDoTuoi = (dotuoi) => {
    switch (dotuoi) {
      case "3-4tuoi":
        return "3 - 4 tuổi";
      case "4-5tuoi":
        return "4 - 5 tuổi";
      case "5-6tuoi":
        return "5 - 6 tuổi";
      case "3-6thangtuoi":
        return "3 - 6 tháng tuổi";
      case "6-12thangtuoi":
        return "6 - 12 tháng tuổi";
      case "12-18thangtuoi":
        return "12 - 18 tháng tuổi";
      case "18-24thangtuoi":
        return "18 - 24 tháng tuổi";
      case "24-36thangtuoi":
        return "24 - 36 tháng tuổi";
      default:
        return dotuoi;
    }
  }

  exportHandler = () => {
    if (this.exportedClasses === undefined) {
      this.props.errorHandler("Không lấy được danh sách học sinh. Xin vui lòng thử lại sau.");
      return;
    }
    this.setState({ isWaiting: true });
    exportCanDo(this.exportedClasses, this.props.unit.information.name, _.get(cities, `${this.props.unit.information.ward.slice(0, 2)}.districts.${this.props.unit.information.ward.slice(0, 6)}.name`, "")).then(res => {
      this.setState({ isWaiting: false });
      saveAs(new Blob([res.data]), `${this.props.unit.information.name}.html`);
    }).catch(() => {
      this.setState({ isWaiting: false });
      this.props.errorHandler("Xuất số liệu cân đo không thành công. Xin vui lòng thử lại sau.");
    });
  }

  saveHandler = () => {
    if (this.props.data[this.props.unit.unitID] === undefined) {
      this.props.errorHandler("Sở không có mẫu báo cáo Sức khoẻ Mẫu giáo và Sức khoẻ Nhà trẻ.");
      return;
    }
    const { dsuckhoemg, esuckhoent } = this.props.data[this.props.unit.unitID];
    if (dsuckhoemg === undefined) {
      this.props.errorHandler("Sở không có mẫu báo cáo Sức khoẻ Mẫu giáo.");
      return;
    }
    if (esuckhoent === undefined) {
      this.props.errorHandler("Sở không có mẫu báo cáo Sức khoẻ Nhà trẻ.");
      return;
    }

    this.setState({ isWaiting: true });

    // preprocess data
    const processSuckhoeMG = _.isArray(dsuckhoemg) ? {} : dsuckhoemg;
    const processSuckhoeNT = _.isArray(esuckhoent) ? {} : esuckhoent;

    processSuckhoeMG.C = this.state.results.MG.total;
    processSuckhoeNT.C = this.state.results.NT.total;
    [{
      key: "BT",
      report: "D"
    }, {
      key: "BP",
      report: "E"
    }, {
      key: "DC",
      report: "F"
    }, {
      key: "NC1",
      report: "G"
    }, {
      key: "NC2",
      report: "H"
    }, {
      key: "TC1",
      report: "I"
    }, {
      key: "TC2",
      report: "J"
    }, {
      key: "GC1",
      report: "K"
    }, {
      key: "GC2",
      report: "L"
    }].forEach(item => {
      processSuckhoeMG[item.report] = this.state.results.MG[item.key];
      processSuckhoeNT[item.report] = this.state.results.NT[item.key];
    });

    eventTracking("report", "save", 1, this.props.unit.unitID);
    const modifiedBy = {
      email: this.props.user.email,
      displayName: this.props.user.displayName,
      gender: this.props.user.gender
    };
    const modifiedTime = new Date().getTime();
    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}`).update({
      dsuckhoemg: {
        data: processSuckhoeMG,
        modifiedBy,
        modifiedTime
      },
      esuckhoent: {
        data: processSuckhoeNT,
        modifiedBy,
        modifiedTime
      }
    }, (error) => {
      this.setState({ isWaiting: false });
      if (error)
        this.props.errorHandler("Cập nhật số liệu báo cáo không thành công");
      else
        this.props.successHandler("Cập nhật số liệu báo cáo thành công");
    });
  }

  compute = (classes) => {
    const init = {
      BT: 0,
      BP: 0,
      DC: 0,
      NC1: 0,
      NC2: 0,
      TC1: 0,
      TC2: 0,
      GC1: 0,
      GC2: 0
    };
    const results = {
      MG: { ...init, total: 0 },
      NT: { ...init, total: 0 }
    };
    this.exportedClasses = [];
    _.keys(classes).forEach(classID => {
      const foundClass = _.find(this.props.classes, c => c.classID === classID);
      const currentClass = {
        stt: this.exportedClasses.length + 1,
        name: (foundClass !== undefined) ? foundClass.name : "",
        siso: classes[classID].length,
        weightCount: 0,
        heightCount: 0,
        thenhecan: 0,
        thethapcoi: 0,
        thuacanbeophi: 0,
        cnhontuoi: 0,
        cchontuoi: 0,
        coinhe: 0,
        coinang: 0
      };
      classes[classID].forEach(student => {
        if (student.weight !== undefined && student.weight !== "" && !isNaN(student.weight))
          currentClass.weightCount += 1;
        if (student.height !== undefined && student.height !== "" && !isNaN(student.height))
          currentClass.heightCount += 1;

        const weightPoint = (student.weightPoint !== undefined) ? computeWeightPointResult(student.weightPoint) : "";
        const heightPoint = (student.heightPoint !== undefined) ? computeHeightPointResult(student.heightPoint) : "";
        const bmiPoint = (student.bmiPoint !== undefined) ? computeBMIPointResult(student.bmiPoint) : "";
        const weightLengthPoint = (student.weightLengthPoint !== undefined) ? computeWeightLengthPointResult(student.weightLengthPoint) : "";

        const count = { ...init };
        if (weightPoint === 'BT' && heightPoint === 'BT' && (bmiPoint || weightLengthPoint) === 'BT')
          count.BT = 1;
        switch (weightPoint) {
          case "NC1":
            count.NC1 = 1;
            currentClass.thenhecan += 1;
            break;
          case "NC2":
            count.NC2 = 1;
            currentClass.thenhecan += 1;
            break;
          case "BT+":
            currentClass.cnhontuoi += 1;
            break;
          default:
        }
        switch (heightPoint) {
          case "TC1":
            count.TC1 = 1;
            currentClass.thethapcoi += 1;
            break;
          case "TC2":
            count.TC2 = 1;
            currentClass.thethapcoi += 1;
            break;
          case "BT+":
            currentClass.cchontuoi += 1;
            break;
          default:
        }
        switch (bmiPoint || weightLengthPoint) {
          case "GC1":
            count.GC1 = 1;
            currentClass.coinhe += 1;
            break;
          case "GC2":
            count.GC2 = 1;
            currentClass.coinang += 1;
            break;
          case "BP":
            count.BP = 1;
            currentClass.thuacanbeophi += 1;
            break;
          case "DC":
            count.DC = 1;
            currentClass.thuacanbeophi += 1;
            break;
          default:
        }
        ["BT", "BP", "DC", "NC1", "NC2", "TC1", "TC2", "GC1", "GC2"].forEach(key => { results[(student.monthsCount >= 36) ? "MG" : "NT"][key] += count[key]; });
        results[(student.monthsCount >= 36) ? "MG" : "NT"].total += 1;
      });

      currentClass.weightTyle = Math.round(currentClass.weightCount * 10000 / currentClass.siso) / 100;
      currentClass.heightTyle = Math.round(currentClass.heightCount * 10000 / currentClass.siso) / 100;
      currentClass.thenhecanTyle = Math.round(currentClass.thenhecan * 10000 / currentClass.siso) / 100;
      currentClass.thethapcoiTyle = Math.round(currentClass.thethapcoi * 10000 / currentClass.siso) / 100;
      currentClass.thuacanbeophiTyle = Math.round(currentClass.thuacanbeophi * 10000 / currentClass.siso) / 100;
      currentClass.cnhontuoiTyle = Math.round(currentClass.cnhontuoi * 10000 / currentClass.siso) / 100;
      currentClass.cchontuoiTyle = Math.round(currentClass.cchontuoi * 10000 / currentClass.siso) / 100;
      currentClass.coiTyle = Math.round((currentClass.coinang + currentClass.coinhe) * 10000 / currentClass.siso) / 100;

      ["thenhecan", "thenhecanTyle", "thethapcoi", "thethapcoiTyle", "thuacanbeophi", "thuacanbeophiTyle", "cnhontuoi", "cnhontuoiTyle", "cchontuoi", "cchontuoiTyle", "coinang", "coinhe", "coiStyle"].forEach(key => {
        if (currentClass[key] === 0)
          currentClass[key] = "";
      })
      this.exportedClasses.push(currentClass);
    });

    this.setState({ isWaiting: false, results });
  }

  analyze = () => {
    this.setState({ isWaiting: true });

    const students = {};
    this.props.classes.forEach(classInfo => {
      if (this.state.selected[classInfo.classID] !== undefined && this.props.monthsStatus[classInfo.classID][this.state.selected[classInfo.classID]].day !== undefined) {
        db.ref(`cando/${this.props.unit.unitID}/${this.props.unit.activeYear}/${classInfo.classID}/months/${this.state.selected[classInfo.classID]}`).once("value", snapshot => {
          if (snapshot !== null && snapshot.val() !== null && snapshot.val() !== undefined)
            students[classInfo.classID] = evaluateStudents(snapshot.val(), parseFloat(this.state.selected[classInfo.classID]), this.props.unit.activeYear, this.props.solieucando, parseFloat(this.props.monthsStatus[classInfo.classID][this.state.selected[classInfo.classID]].day, this.state.useDay));
          else
            students[classInfo.classID] = [];
          if (_.keys(students).length === this.props.classes.length)
            this.compute(students);
        }, () => {
          students[classInfo.classID] = [];
          if (_.keys(students).length === this.props.classes.length)
            this.compute(students);
        });
      } else
        students[classInfo.classID] = [];
      if (_.keys(students).length === this.props.classes.length)
        this.compute(students);
    });
  }

  renderMonths = (classInfo, monthList) => monthList.map(month => {
    if (this.props.monthsStatus[classInfo.classID][month] === undefined || this.props.monthsStatus[classInfo.classID][month].day === undefined || this.props.monthsStatus[classInfo.classID][month].count === undefined || parseFloat(this.props.monthsStatus[classInfo.classID][month].count) <= 0)
      return null;
    const color = (this.props.monthsStatus[classInfo.classID][month].unfilled !== undefined && parseFloat(this.props.monthsStatus[classInfo.classID][month].unfilled) > 0) ? "brown" : "blue";
    return (
      <Label key={month} as="a" color={color} style={{ color: "white" }}>
        <Checkbox
          checked={this.state.selected[classInfo.classID] === month}
          onChange={() => this.setState({ selected: { ...this.state.selected, [classInfo.classID]: (this.state.selected[classInfo.classID] === month) ? undefined : month } })}
        />
        {' '}
        {`Tháng ${month}`}
      </Label>
    );
  })

  renderClass = (classInfo, index) => {
    const style = (index % 2 === 0) ? { background: "aliceblue" } : {};
    return (
      <Table.Row key={classInfo.classID} style={style}>
        <Table.Cell collapsing>{classInfo.name}</Table.Cell>
        <Table.Cell collapsing>{_.join(classInfo.grade.map(grade => this.readDoTuoi(grade)), ", ")}</Table.Cell>
        {(this.props.monthsStatus[classInfo.classID] === undefined) ?
          <Table.Cell collapsing>Đang tải dữ liệu</Table.Cell>
          :
          <Table.Cell collapsing>{this.renderMonths(classInfo, months)}</Table.Cell>}
      </Table.Row>
    );
  }

  renderActions = () => {
    if (this.state.results === undefined)
      return (
        <Modal.Actions>
          <Button color="brown" size="small" onClick={this.analyze}>
            <Icon name="save" />
            {' '}
            Tổng hợp
          </Button>
          <Button color="red" onClick={this.props.closeHandler}>Đóng</Button>
        </Modal.Actions>
      );
    return (
      <Modal.Actions>
        <Button color="teal" size="small" onClick={this.exportHandler}>
          <Icon name="download" />
          {' '}
          Xuất Số liệu
        </Button>
        <Button color="brown" size="small" onClick={this.saveHandler}>
          <Icon name="save" />
          {' '}
          Sử dụng làm số liệu báo cáo
        </Button>
        <Button color="red" onClick={() => this.setState({ results: undefined })}>Đóng</Button>
      </Modal.Actions>
    );
  }

  renderResult = () => (
    <Table celled striped color="brown" unstackable selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="solieuHeader" collapsing />
          <Table.HeaderCell className="solieuHeader" collapsing>Mẫu giáo (3-6 tuổi)</Table.HeaderCell>
          <Table.HeaderCell className="solieuHeader" collapsing>Nhà trẻ (dưới 3 tuổi)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {[{
          key: "BT",
          name: "Bình thường"
        }, {
          key: "BP",
          name: "Béo phì"
        }, {
          key: "DC",
          name: "Thừa cân"
        }, {
          key: "NC1",
          name: "SDD nhẹ cân"
        }, {
          key: "NC2",
          name: "SDD nhẹ cân (nặng)"
        }, {
          key: "TC1",
          name: "SDD thấp còi"
        }, {
          key: "TC2",
          name: "SDD thấp còi (nặng)"
        }, {
          key: "GC1",
          name: "SDD gầy còm"
        }, {
          key: "GC2",
          name: "SDD gầy còm (nặng)"
        }].map(item => (
          <Table.Row key={item.key}>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{this.state.results.MG[item.key]}</Table.Cell>
            <Table.Cell>{this.state.results.NT[item.key]}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )

  render() {
    const { isSaving, useDay } = this.state;
    if (isSaving)
      return (
        <Dimmer active>
          <Loader>Đang xử lý...</Loader>
        </Dimmer>
      );
    return (
      <div>
        <Modal size="fullscreen" open>
          <Header className="form-header">
            <Header.Content as="h3">Tổng hợp số liệu cân đo</Header.Content>
            <Header.Subheader>
              <Form>
                <Form.Group inline>
                  <label>Nguyên tắc tính tuổi:</label>
                  <Form.Field>
                    <Radio
                      label='Theo Bộ Giáo dục (chỉ tính tháng)'
                      name='radioGroup'
                      checked={!useDay}
                      onChange={() => this.setState({ useDay: !useDay })}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Theo Viện Dinh dưỡng (dựa trên ngày cân đo)'
                      name='radioGroup'
                      checked={useDay}
                      onChange={() => this.setState({ useDay: !useDay })}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Header.Subheader>
          </Header>
          <Modal.Content scrolling>
            {this.state.isWaiting ? (
              <Dimmer active>
                <Loader>Đang kết nối...</Loader>
              </Dimmer>
            ) : null}
            {(this.state.results === undefined) ? (
              <Table celled striped color="brown" unstackable selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="solieuHeader" collapsing>Tên lớp</Table.HeaderCell>
                    <Table.HeaderCell className="solieuHeader" collapsing>Khối</Table.HeaderCell>
                    <Table.HeaderCell className="solieuHeader" collapsing>Tháng</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {this.props.classes.map((classInfo, index) => this.renderClass(classInfo, index))}
                </Table.Body>
              </Table>
            )
              : this.renderResult()}
          </Modal.Content>
          {this.renderActions()}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data
});

export default connect(mapStateToProps)(Extractor);