import { REBOT_REPORTS_MANAGE, REBOT_UNITS_MANAGE, SIGN_OUT } from "../actions/constants";


const initialState = {
};

export default function manage(state = initialState, action) {
  switch(action.type) {
    case REBOT_REPORTS_MANAGE:
      if (state[action.unitID] !== undefined)
        return {
          ...state,
          [action.unitID]: {
            ...state[action.unitID],
            status: action.status
          }
        };
      return {
        ...state,
        [action.unitID]: {
          status: action.status
        }
      };
      case REBOT_UNITS_MANAGE:
      if (state[action.unitID] !== undefined)
        return {
          ...state,
          [action.unitID]: {
            ...state[action.unitID],
            information: action.information
          }
        };
      return {
        ...state,
        [action.unitID]: {
          information: action.information
        }
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}