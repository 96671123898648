import { UNIT_FETCH, UNIT_ACTIVE_YEAR, UNIT_ACTIVE_MILESTONE, UNIT_NOTIFICATION, UNIT_DEADLINE, SIGN_OUT } from "../actions/constants";


const initialState = {
  unitID: undefined,
  activeYear: undefined,
  activeMilestone: undefined,
  notification: undefined,
  information: undefined
};

export default function unit(state = initialState, action) {
  switch(action.type) {
    case UNIT_FETCH:
      return {
        ...state,
        unitID: action.unitID,
        information: action.information
      };
    case UNIT_NOTIFICATION:
      if (state.unitID === action.unitID)
        return {
          ...state,
          notification: action.notification
        };
      return state;
    case UNIT_DEADLINE:
      if (state.unitID === action.unitID)
        return {
          ...state,
          deadline: action.deadline
        };
      return state;
    case UNIT_ACTIVE_YEAR:
      if (state.unitID === action.unitID)
        return {
          ...state,
          activeYear: action.year
        };
      return state;
    case UNIT_ACTIVE_MILESTONE:
      if (state.unitID === action.unitID)
        return {
          ...state,
          activeMilestone: action.milestone
        };
      return state;
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}