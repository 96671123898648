/* eslint-disable no-restricted-globals */
import _ from "lodash";
import axios from "axios";

import { EXPORT_URL } from "../../../libs/config";

export const readDoTuoi = (dotuoi) => {
  switch (dotuoi) {
    case "3-4tuoi":
      return "3 - 4 tuổi";
    case "4-5tuoi":
      return "4 - 5 tuổi";
    case "5-6tuoi":
      return "5 - 6 tuổi";
    case "3-6thangtuoi":
      return "3 - 6 tháng tuổi";
    case "6-12thangtuoi":
      return "6 - 12 tháng tuổi";
    case "12-18thangtuoi":
      return "12 - 18 tháng tuổi";
    case "18-24thangtuoi":
      return "18 - 24 tháng tuổi";
    case "24-36thangtuoi":
      return "24 - 36 tháng tuổi";
    default:
      return dotuoi;
  }
};

export const computeWeightPointResult = (point) => {
  if (point < -3)
    return "NC2";
  if (point < -2)
    return "NC1";
  if (point <= 2)
    return "BT";
  return "BT+";
}
export const round = (value, dec = 2) => Number((value || 0).toFixed(dec));

export const computeHeightPointResult = (point) => {
  if (point < -3)
    return "TC2";
  if (point < -2)
    return "TC1";
  if (point <= 2)
    return "BT";
  return "BT+";
}

export const computeWeightLengthPointResult = (point) => {
  if (point < -3)
    return "GC2";
  if (point < -2)
    return "GC1";
  if (point <= 2)
    return "BT";
  if (point <= 3)
    return "DC";
  return "BP";
}

export const computeBMIPointResult = (point) => {
  if (point < -3)
    return "GC2";
  if (point < -2)
    return "GC1";
  if (point <= 1)
    return "BT";
  if (point <= 2)
    return "DC";
  return "BP";
}

export const computeMonths = (student, toMonth, toYear, day, useDay = false) => {
  if (day === 0)
    return -1;
  const { month, year } = student;
  if (toYear < year)
    return -1;
  if (parseFloat(year) === parseFloat(toYear))
    return toMonth - month;
  let count = toMonth + (12 - month);
  if (toYear - year - 1 > 0)
    _.range(0, toYear - year - 1).forEach(() => { count += 12; });
  if (useDay && parseFloat(student.date) > parseFloat(day))
    count -= 1;
  return count;
}

const computePoint = (value, type, points) => {
  const idx = _.indexOf(points, _.find(points, point => value >= points[0][type] && point[type] >= value));
  const lastIdx = _.indexOf(points, _.last(points));
  if (idx !== -1) {
    if (idx - 1 >= 0 && idx !== lastIdx && points[idx][type] !== value)
      return points[idx - 1].point;
    return points[idx].point;
  }
  const max = _.last(points);
  if (value > max[type])
    return max.point + 1;
  return points[0].point - 1;
}

export const evaluateStudents = (
  students,
  month,
  activeYear,
  solieucando,
  day,
  useDay = false,
  sgd
) => {
  let year = parseFloat(activeYear);
  if (month < 8)
    year += 1;
  return (
    _.map(_.sortBy(_.keys(students), (student) => {
      if (students[student].order !== undefined)
        return parseFloat(students[student].order);
      return 0;
    }), (studentID, index) => {
      const gender = (students[studentID].gender === "M") ? "male" : "female";
      const cando = {
        weightPoint: undefined,
        heightPoint: undefined,
        weightLengthPoint: undefined,
        bmi: undefined,
        bmiPoint: undefined
      };
      const monthsCount = computeMonths(students[studentID], month, year, day, useDay);
      const weight = (students[studentID].weight !== undefined && students[studentID].weight !== "" && !isNaN(students[studentID].weight)) ? parseFloat(students[studentID].weight) : undefined;
      const height = (students[studentID].height !== undefined && students[studentID].height !== "" && !isNaN(students[studentID].height)) ? parseFloat(students[studentID].height) : undefined;
      if (monthsCount >= 0) {
        if (solieucando.weight !== undefined && solieucando.weight[gender] !== undefined && weight !== undefined) {
          if (solieucando.weight[gender][`${monthsCount}`] !== undefined)
            cando.weightPoint = computePoint(weight, "weight", solieucando.weight[gender][`${monthsCount}`]);
        }
        if (solieucando.height !== undefined && solieucando.height[gender] !== undefined && height !== undefined) {
          if (solieucando.height[gender][`${monthsCount}`] !== undefined)
            cando.heightPoint = computePoint(height, "height", solieucando.height[gender][`${monthsCount}`]);
        }
        if(sgd === 'lamdong') {
          if (height !== undefined && weight !== undefined && cando.weightPoint >= -2 && cando.heightPoint >= -2 ) {
            if (solieucando.weightLength !== undefined && solieucando.weightLength[gender] !== undefined) {
              let dotuoi = (monthsCount >= 0 && monthsCount < 24) ? "duoi2tuoi" : "";
              if (monthsCount >= 24 && monthsCount <= 60)
                dotuoi = "duoi5tuoi";
              if (solieucando.weightLength[gender][dotuoi] !== undefined) {
                let foundHeight = _.find(solieucando.weightLength[gender][dotuoi], point => point.height >= height);
                if (foundHeight === undefined) {
                  const maxHeight = _.last(solieucando.weightLength[gender][dotuoi]);
                  if (height > maxHeight.height)
                    foundHeight = maxHeight;
                  else
                    foundHeight = _.first(solieucando.weightLength[gender][dotuoi]);
                }
                const idx = _.indexOf(foundHeight.weights, _.find(foundHeight.weights, point => weight >= foundHeight.weights[0].weight && point.weight >= weight));
                const lastIdx = _.indexOf(foundHeight.weights, _.last(foundHeight.weights));
                if (idx !== -1) {
                  if (idx - 1 >= 0 && idx !== lastIdx && foundHeight.weights[idx].weight !== weight)
                    cando.weightLengthPoint = foundHeight.weights[idx - 1].point;
                  else cando.weightLengthPoint = foundHeight.weights[idx].point;
                } else {
                  const maxWeight = _.last(foundHeight.weights);
                  if (weight > maxWeight.weight)
                    cando.weightLengthPoint = maxWeight.point + 1;
                  else cando.weightLengthPoint = _.first(foundHeight.weights).point - 1;
                }
              }
            }
            if (solieucando.bmi !== undefined && solieucando.bmi[gender] !== undefined && cando.heightPoint >= -2 && cando.weightPoint >= -2 ) {
              if (monthsCount >= 61 && monthsCount <= 78 && solieucando.bmi[gender][`${monthsCount}`] !== undefined) {
                cando.bmi = weight * 10000 / (height * height);
                cando.bmiPoint = computePoint(cando.bmi, "bmi", solieucando.bmi[gender][`${monthsCount}`]);
              }
            }
          }
        } else if (height !== undefined && weight !== undefined && cando.weightPoint >= -2 ) {
            if (solieucando.weightLength !== undefined && solieucando.weightLength[gender] !== undefined) {
              let dotuoi = (monthsCount >= 0 && monthsCount < 24) ? "duoi2tuoi" : "";
              if (monthsCount >= 24 && monthsCount <= 60)
                dotuoi = "duoi5tuoi";
              if (solieucando.weightLength[gender][dotuoi] !== undefined) {
                let foundHeight = _.find(solieucando.weightLength[gender][dotuoi], point => point.height >= height);
                if (foundHeight === undefined) {
                  const maxHeight = _.last(solieucando.weightLength[gender][dotuoi]);
                  if (height > maxHeight.height)
                    foundHeight = maxHeight;
                  else
                    foundHeight = _.first(solieucando.weightLength[gender][dotuoi]);
                }
                const idx = _.indexOf(foundHeight.weights, _.find(foundHeight.weights, point => weight >= foundHeight.weights[0].weight && point.weight >= weight));
                const lastIdx = _.indexOf(foundHeight.weights, _.last(foundHeight.weights));
                if (idx !== -1) {
                  if (idx - 1 >= 0 && idx !== lastIdx && foundHeight.weights[idx].weight !== weight)
                    cando.weightLengthPoint = foundHeight.weights[idx - 1].point;
                  else cando.weightLengthPoint = foundHeight.weights[idx].point;
                } else {
                  const maxWeight = _.last(foundHeight.weights);
                  if (weight > maxWeight.weight)
                    cando.weightLengthPoint = maxWeight.point + 1;
                  else cando.weightLengthPoint = _.first(foundHeight.weights).point - 1;
                }
              }
            }
            if (solieucando.bmi !== undefined && solieucando.bmi[gender] !== undefined) {
              if (monthsCount >= 61 && monthsCount <= 78 && solieucando.bmi[gender][`${monthsCount}`] !== undefined) {
                cando.bmi = weight * 10000 / (height * height);
                cando.bmiPoint = computePoint(cando.bmi, "bmi", solieucando.bmi[gender][`${monthsCount}`]);
              }
            }
          }
      }
      return ({
        ...students[studentID],
        order: index + 1,
        studentID,
        ...cando,
        monthsCount
      });
    })
  );
}

export const exportSoLieu = (students, classInfo, school, dantoc) => {
  const result = {
    class: {
      school,
      name: classInfo.name,
      day: `${classInfo.day}-${classInfo.month}-${classInfo.year}`
    },
    students: students.map((student, index) => ({
      ...student,
      stt: index + 1,
      fullname: `${student.lastName} ${student.firstName}`,
      birthday: `${student.date}/${student.month}/${student.year}`,
      gender: (student.gender === "M") ? "" : "x",
      ethnic: dantoc[_.get(student, "ethnic", "kinh")],
      bmi: (student.bmi !== undefined) ? Math.round(student.bmi * 100) / 100 : ""
    }))
  };

  return axios.post(EXPORT_URL, { fileName: "rebot_cando_solieu", inputData: result }, { responseType: "blob" });
}

export const exportCanDo = (classes, school, pgd) => {
  const result = {
    pgd,
    school,
    classes
  };
  return axios.post(EXPORT_URL, { fileName: "rebot_cando_tonghop", inputData: result }, { responseType: "blob" });
}