/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Table, Header } from "semantic-ui-react";
import { FormattedNumber } from "react-intl";
import ScrollArea from "react-scrollbar";


export default class SimpleViewer extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    schema: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    information: PropTypes.object.isRequired,
    schoolType: PropTypes.string.isRequired
  };

  componentWillMount() {
    if (this.props.schema) {
      this.maxDepth = 0;
      const rows = this.extractNode(this.props.schema, this.props.data, 0);
      this.setState({ rows });
    }
  }

  extractNode = (node, data, depth) => {
    if (this.maxDepth < depth)
      this.maxDepth = depth;

    if (node.key !== undefined) {
      const value = {};
      value[this.props.schoolType] = 0;
      Object.keys(data).forEach(unitID => {
        value[unitID] = (data[unitID][node.key] !== undefined) ? data[unitID][node.key] : 0;
        value[this.props.schoolType] += value[unitID];
      });
      return [{
        parents: [],
        header: node.name,
        key: node.key,
        type: node.type,
        value,
        depth
      }];
    }

    const rows = [];
    Object.keys(node).forEach((item) => {
      if (item === "name" || item === "permission")
        return;
      const tmp = this.extractNode(node[item], data, depth + 1);
      if (tmp.length > 0)
        rows.push(...tmp);
    });
    if (rows.length > 0 && node.name !== undefined) {
      rows[0].parents.unshift({
        name: node.name,
        span: rows.length
      });
    }

    return rows;
  }

  readerAdvanceHeader = () => (
    <Table fixed celled unstackable style={{ width: "350px", float: "left" }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="row-header" colSpan={this.maxDepth} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.rows.map((row) => this.renderAdvanceRowHeader(row))}
      </Table.Body>
    </Table>
  )

  renderAdvanceRowHeader = (row) => {
    const span = (this.maxDepth > row.depth) ? (this.maxDepth - row.depth + 1) : 1;
    return (
      <Table.Row key={row.key}>
        {row.parents.map((parent, k) => <Table.Cell key={k} className="column-header" width="1" rowSpan={parent.span}>{parent.name}</Table.Cell>)}
        <Table.Cell className="column-header" width="1" colSpan={span}>{row.header}</Table.Cell>
      </Table.Row>
    );
  }

  renderAdvanceRow = (row, index, orderedUnits) => (
    <Table.Row key={row.key}>
      {orderedUnits.map(item => (
        <Table.Cell id={item.unitID} key={item.unitID} className={`${this.props.schoolType}-value ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1">
          {(row.type === "text") ? _.get(row.value, item.unitID, "") : (
            <FormattedNumber
              value={(row.value[item.unitID] !== undefined && row.value[item.unitID] !== 0) ? row.value[item.unitID] : ""}
            />
          )}
        </Table.Cell>
      ))}
      <Table.Cell className={`stt ${(row.parents.length === row.depth - 1) ? "groupBorder" : ""}`} width="1">{index + 1}</Table.Cell>
    </Table.Row>
  )

  renderAdvanceReport = (orderedUnits) => (
    <div>
      {this.readerAdvanceHeader()}
      <ScrollArea
        contentStyle={{ width: orderedUnits.length * 100 + 150 }}
        verticalScrollbarStyle={{ borderRadius: 5 }}
        verticalContainerStyle={{ borderRadius: 5 }}
        horizontalScrollbarStyle={{ borderRadius: 5 }}
        horizontalContainerStyle={{ borderRadius: 5 }}
        smoothScrolling
        minScrollSize={40}
        onScroll={this.handleScroll}
        swapWheelAxes
      >
        <Table fixed celled unstackable style={{ background: "transparent" }}>
          <Table.Header>
            <Table.Row>
              {orderedUnits.map(item => <Table.HeaderCell key={item.unitID} className={`${this.props.schoolType}`}>{item.name}</Table.HeaderCell>)}
              {/* <Table.HeaderCell className={this.props.schoolType}>Tổng cộng</Table.HeaderCell> */}
              <Table.HeaderCell className="stt" style={{ height: 110 }}>STT</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.rows.map((row, index) => this.renderAdvanceRow(row, index, orderedUnits))}
          </Table.Body>
        </Table>
      </ScrollArea>
    </div>
  )

  render() {
    const orderedUnits = [];
    Object.keys(this.props.data).map((unitID) => {
      orderedUnits.push({ unitID, name: (this.props.information[unitID] !== undefined) ? this.props.information[unitID] : "" });
      return unitID;
    });
    return (
      <div style={{ background: "#FFFeF1", maxWidth: 800 + orderedUnits.length * 70 }}>
        <Header className="report-header" as="h3">{this.props.name}</Header>
        {this.renderAdvanceReport(orderedUnits)}
      </div>
    );
  }
}