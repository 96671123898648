/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Message, Button, Popup, Header, Dimmer, Loader, Radio, Modal } from "semantic-ui-react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import _ from "lodash";

// components
import MilestoneForm from "../../components/MilestoneForm";

// services
import { eventTracking } from "../../libs/ga";
import { db } from "../../libs/firebase";


class Settings extends Component {
  static propTypes = {
    milestone: PropTypes.object.isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      openNewMilestone: false,
      showUpdateMilestone: {}
    }
  }

  insertHandler = (content) => {
    this.setState({ isWaiting: true });
    eventTracking("milestone", "insert", 1, this.props.unit.unitID);
    db.ref(`rebot/${this.props.unit.unitID}/${this.props.unit.activeYear}/milestones`).push({
      description: content,
      active: false,
      time: (new Date()).getTime()
    }, (error) => {
      if (error)
        this.notificationSystem.addNotification({
          title: "Lỗi",
          message: "Tạo đợt nộp báo cáo thất bại. Xin vui lòng thử lại sau.",
          level: "error",
          position: "tr",
          autoDismiss: 4
        });
      this.setState({ isWaiting: false, openNewMilestone: false });
    });
  }

  updateHandler = (key, content) => {
    this.setState({ isWaiting: true });
    eventTracking("milestone", "update", 1, this.props.unit.unitID);
    db.ref(`rebot/${this.props.unit.unitID}/${this.props.unit.activeYear}/milestones/${key}`).update({
      description: content
    }, (error) => {
      if (error)
        this.notificationSystem.addNotification({
          title: "Lỗi",
          message: "Cập nhật mô tả thất bại. Xin vui lòng thử lại sau.",
          level: "error",
          position: "tr",
          autoDismiss: 4
        });
      this.setState({ isWaiting: false, showUpdateMilestone: {} });
    });
  }

  removeHandler = () => {
    if (this.state.removeMilestone === undefined)
      return;
    this.setState({ isWaiting: true });
    eventTracking("milestone", "remove", 1, this.props.unit.unitID);
    db.ref(`rebot/${this.props.unit.unitID}/${this.props.unit.activeYear}/milestones/${this.state.removeMilestone}`).remove((error) => {
      if (error)
        this.notificationSystem.addNotification({
          title: "Lỗi",
          message: "Xoá đợt báo cáo thất bại. Xin vui lòng thử lại sau.",
          level: "error",
          position: "tr",
          autoDismiss: 4
        });
      this.setState({ isWaiting: false, removeMilestone: undefined });
    });
  }

  activeHandler = (key) => {
    const newMilestone = {};
    _.forEach(_.keys(this.props.milestone), milestoneID => {
      newMilestone[milestoneID] = {
        ...this.props.milestone[milestoneID],
        active: milestoneID === key
      };
    });

    let activeCount = 0;
    _.forEach(_.keys(newMilestone), milestoneID => {
      if (newMilestone[milestoneID].active)
        activeCount += 1;
    });
    
    let message = "";
    if (activeCount === 0)
      message = "Phải có ít nhất một đợt báo cáo được kích hoạt.";
    else if (activeCount > 1)
      message = "Chỉ được kích hoạt tối đa một đợt báo cáo.";
    
    if (message !== "") 
      this.notificationSystem.addNotification({
        title: "Lỗi",
        message,
        level: "error",
        position: "tr",
        autoDismiss: 4
      });
    else {
      this.setState({ isWaiting: true });
      eventTracking("milestone", "active", 1, this.props.unit.unitID);
      db.ref(`rebot/${this.props.unit.unitID}/${this.props.unit.activeYear}/milestones/`).set(newMilestone, (error) => {
        if (error)
          this.notificationSystem.addNotification({
            title: "Lỗi",
            message: "Kích hoạt đợt báo cáo thất bại. Xin vui lòng thử lại sau.",
            level: "error",
            position: "tr",
            autoDismiss: 4
          });
        this.setState({ isWaiting: false });
      });
    }
  }
  
  renderRow = (key, milestone) => (
    <Table.Row key={key} error={milestone.active}>
      <Table.Cell width="14">{milestone.description}</Table.Cell>
      <Table.Cell width="2"><Radio toggle checked={milestone.active} onClick={() => this.activeHandler(key)} /></Table.Cell>
      <Table.Cell width="1">
        <Popup 
          open={this.state.showUpdateMilestone[key] !== undefined} 
          trigger={<Button onClick={() => this.setState({ showUpdateMilestone: {[key]: true} })} basic icon="edit" />} 
          on="click"
        >
          <MilestoneForm closeHandler={() => this.setState({ showUpdateMilestone: {} })} description={milestone.description} milestoneID={key} updateHandler={this.updateHandler} />
        </Popup>
      </Table.Cell>
      {milestone.active?
        <Table.Cell width="1" />
        :
        <Table.Cell width="1"><Button basic icon="remove" onClick={() => this.setState({ removeMilestone: key })} /></Table.Cell>
      }
    </Table.Row>
  )

  renderConfirmation = () => (
    <Modal size="mini" open={this.state.removeMilestone !== undefined} onClose={() => this.setState({ removeMilestone: undefined })}>
      <Modal.Header>
        Lưu ý
      </Modal.Header>
      <Modal.Content>
        Khi xoá một đợt nộp báo cáo thì tất cả dữ liệu báo cáo của các đơn vị trong đợt báo cáo đó sẽ không còn truy xuất được nữa.
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => this.removeHandler()}>Xoá</Button>
        <Button positive onClick={() => this.setState({ removeMilestone: undefined })}>Huỷ</Button>
      </Modal.Actions>
    </Modal>
  )

  render() {
    return (
      <div>
        {this.state.isWaiting?
          <Dimmer active inverted>
            <Loader inverted>Đang cập nhật thông tin...</Loader>
          </Dimmer> : null
        }
        {this.renderConfirmation()}
        <Message warning>
          <Message.Content>
            <Header className="unitHeader" as="h3">Cấu hình các đợt nộp báo cáo trong năm</Header>
            <Header className="unitSubHeader" as="h4">{`Năm học ${this.props.unit.activeYear}`}</Header>            
            <Popup 
              open={this.state.openNewMilestone} 
              trigger={<Button onClick={() => this.setState({ openNewMilestone: true })} className="sendReportBtn" basic color="brown" floated="right" icon="clock" content="Tạo đợt nộp báo cáo" />} 
              on="click"
            >
              <MilestoneForm closeHandler={() => this.setState({ openNewMilestone: false })} insertHandler={this.insertHandler} />
            </Popup>
            <p>Mọi hoạt động báo cáo của các đơn vị trong đợt báo cáo được kích hoạt nào thì sẽ chỉ lưu trữ trong đợt báo cáo đó.</p>
          </Message.Content>
        </Message>
        <Table unstackable celled striped color="brown" style={{ background: "transparent" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="stt">Mô tả</Table.HeaderCell>
              <Table.HeaderCell className="stt">Kích hoạt</Table.HeaderCell>
              <Table.HeaderCell className="stt">Sửa</Table.HeaderCell>
              <Table.HeaderCell className="stt">Xoá</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {(this.props.milestone !== undefined)? _.keys(this.props.milestone).map((key) => this.renderRow(key, this.props.milestone[key])) : null}
          </Table.Body>
        </Table>
        <NotificationSystem ref={(ref) => {this.notificationSystem = ref}} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unit: state.unit,
  milestone: state.milestone
});

export default connect(mapStateToProps)(Settings);