/* eslint-disable react/forbid-prop-types, no-restricted-globals */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Input, Dimmer, Loader } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import NotificationSystem from "react-notification-system";
import _ from "lodash";

// services
import { expandLogic } from "../../libs/report";


export default class PhoCap extends Component {
  static propTypes = {
    units: PropTypes.arrayOf(PropTypes.shape({
      unitID: PropTypes.string,
      name: PropTypes.string
    })).isRequired,
    data: PropTypes.object.isRequired,
    logic: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data
    };
  }

  getData = () => this.state.data

  processData = (value) => {
    if (isNaN(value))
      return 0;
    return parseFloat(value);
  }

  importHandler = (unitID, files) => {
    if (files.length === 0)
      return;
    this.setState({ isWaiting: true });

    const reader = new FileReader();
    reader.onload = () => {
      const wb = XLSX.read(reader.result, { type: "binary" });
      const excelData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"], skipHeader: true, blankrows: true });
      const data = {
        ...this.state.data,
        O: {
          ...this.state.data.O,
          [unitID]: this.processData(excelData[6].E) + this.processData(excelData[6].F) + this.processData(excelData[6].G)
        },
        P: {
          ...this.state.data.P,
          [unitID]: this.processData(excelData[8].E) + this.processData(excelData[8].F) + this.processData(excelData[8].G)
        },
        Q: {
          ...this.state.data.Q,
          [unitID]: this.processData(excelData[9].E) + this.processData(excelData[9].F) + this.processData(excelData[9].G)
        },
        X: {
          ...this.state.data.X,
          [unitID]: this.processData(excelData[6].H) + this.processData(excelData[6].I) + this.processData(excelData[6].J)
        },
        Y: {
          ...this.state.data.Y,
          [unitID]: this.processData(excelData[8].H) + this.processData(excelData[8].I) + this.processData(excelData[8].J)
        },
        Z: {
          ...this.state.data.Z,
          [unitID]: this.processData(excelData[9].H) + this.processData(excelData[9].I) + this.processData(excelData[9].J)
        },
        AG: {
          ...this.state.data.AG,
          [unitID]: this.processData(excelData[6].J)
        },
        AH: {
          ...this.state.data.AH,
          [unitID]: this.processData(excelData[8].J)
        },
        AI: {
          ...this.state.data.AI,
          [unitID]: this.processData(excelData[9].J)
        }
      };

      const expandedLogic = expandLogic([], data, this.props.logic, [], unitID);
      _.forEach(_.keys(expandedLogic), item => {
        data[item][unitID] = expandedLogic[item];
      });
      this.setState({ isWaiting: false, data });
    };
    reader.onabort = () => {
      this.setState({ isWaiting: false });
    };
    reader.onerror = () => {
      this.setState({ isWaiting: false });
      this.errorHandler("Đã có lỗi xảy ra trong quá trình đọc số liệu từ Excel");
    };

    reader.readAsBinaryString(files[0]);
  }

  errorHandler = (message) => {
    this.notificationSystem.addNotification({
      title: "Lỗi",
      message,
      level: "error",
      position: "tr",
      autoDismiss: 4
    });
  }

  updateValueHandler = (key, unitID, value) => {
    if (isNaN(value)) {
      this.errorHandler("Giá trị không hợp lệ", "Giá trị nhập vào phải là số.");
      return;
    }
    let newValue;
    if (value.endsWith('.'))
      newValue = `${parseFloat(value)}.`;
    else if (value.length > 0)
      newValue = parseFloat(value);
    else
      newValue = value;

    const data = {
      ...this.state.data,
      [key]: {
        ...this.state.data[key],
        [unitID]: newValue
      }
    };

    const expandedLogic = expandLogic([], data, this.props.logic, [], unitID);
    _.forEach(_.keys(expandedLogic), item => {
      data[item][unitID] = expandedLogic[item];
    });

    this.setState({ data });
  }

  renderRow = (unit) => (
    <Table.Row key={unit.unitID}>
      <Table.Cell id={unit.unitID} width="2">{unit.name}</Table.Cell>
      <Table.Cell id={`${unit.unitID}O`} width="1">
        <Input fluid placeholder="0" value={this.state.data.O[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("O", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell id={`${unit.unitID}P`} width="1">
        <Input fluid placeholder="0" value={this.state.data.P[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("P", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell id={`${unit.unitID}Q`} width="1">
        <Input fluid placeholder="0" value={this.state.data.Q[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("Q", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell id={`${unit.unitID}X`} width="1">
        <Input fluid placeholder="0" value={this.state.data.X[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("X", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell id={`${unit.unitID}Y`} width="1">
        <Input fluid placeholder="0" value={this.state.data.Y[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("Y", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell id={`${unit.unitID}Z`} width="1">
        <Input fluid placeholder="0" value={this.state.data.Z[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("Z", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell id={`${unit.unitID}AG`} width="1">
        <Input fluid placeholder="0" value={this.state.data.AG[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("AG", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell id={`${unit.unitID}AH`} width="1">
        <Input fluid placeholder="0" value={this.state.data.AH[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("AH", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell id={`${unit.unitID}AI`} width="1">
        <Input fluid placeholder="0" value={this.state.data.AI[unit.unitID]} onChange={(event, value) => { this.updateValueHandler("AI", unit.unitID, value.value); }} />
      </Table.Cell>
      <Table.Cell style={{ textAlign: "center" }} width="1">
        <Dropzone accept=".xls, .xlsx" multiple={false} onDrop={(files) => this.importHandler(unit.unitID, files)}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Click / Kéo thả</p>
            </div>
          )}
        </Dropzone>
      </Table.Cell>
    </Table.Row>
  )

  render() {
    return (
      <div>
        {this.state.isWaiting ? (
          <Dimmer active inverted>
            <Loader inverted>Đang đọc file...</Loader>
          </Dimmer>
        ) : null}
        <Table unstackable celled striped color="brown">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan="2" width="2">Tên Phường</Table.HeaderCell>
              <Table.HeaderCell colSpan="3">Dân số từ 0-36 tháng tuổi</Table.HeaderCell>
              <Table.HeaderCell colSpan="3">Dân số từ 3-5 tuổi</Table.HeaderCell>
              <Table.HeaderCell colSpan="3">Dân số 5 tuổi</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2" className="stt">Dữ liệu PC</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Tổng DS</Table.HeaderCell>
              <Table.HeaderCell>Dân tộc TS</Table.HeaderCell>
              <Table.HeaderCell>Trẻ khuyết tật</Table.HeaderCell>
              <Table.HeaderCell>Tổng DS</Table.HeaderCell>
              <Table.HeaderCell>Dân tộc TS</Table.HeaderCell>
              <Table.HeaderCell>Trẻ khuyết tật</Table.HeaderCell>
              <Table.HeaderCell>Tổng DS</Table.HeaderCell>
              <Table.HeaderCell>Dân tộc TS</Table.HeaderCell>
              <Table.HeaderCell>Trẻ khuyết tật</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.units.map(unit => this.renderRow(unit))}
          </Table.Body>
        </Table>
        <NotificationSystem ref={(ref) => { this.notificationSystem = ref }} />
      </div>
    )
  }
}