import { REBOT_MILESTONE, CLEAR_MILESTONE } from "./constants";


/**
 * Update milestones settings
 * 
 * @module Redux.Actions.Milestones
 * @ngdoc method
 * @name $fetchMilestone
 * @param  {String} [key]
 * @param  {Object} [milestone] as firebase model
 */
export function fetchMilestone(key, milestone) {
  return {
    type: REBOT_MILESTONE,
    key,
    milestone
  };
}

/**
 * Clear all milestone
 * 
 * @module Redux.Actions.Milestones
 * @ngdoc method
 * @name $clearMilestone
 */
export function clearMilestone() {
  return {
    type: CLEAR_MILESTONE
  };
}