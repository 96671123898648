import React, { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Item, Button, Icon, Grid, Header, Image } from "semantic-ui-react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// services
import { app, googleProvider } from "../../libs/firebase";
import { RESOURCES } from "../../libs/config";

// styles
import "./styles.css";


// images
const logo = `${RESOURCES}/xbot.png`;
const mainWelcome = `${RESOURCES}/rebot/welcome/main.jpg`;
const explainBefore = `${RESOURCES}/rebot/welcome/explain-before.png`;
const explain1 = `${RESOURCES}/rebot/welcome/explain1.png`;
const explain2 = `${RESOURCES}/rebot/welcome/explain2.png`;
const after11 = `${RESOURCES}/rebot/welcome/after11.jpg`;
const after12 = `${RESOURCES}/rebot/welcome/after12.jpg`;
const after21 = `${RESOURCES}/rebot/welcome/after21.jpg`;
const after22 = `${RESOURCES}/rebot/welcome/after22.jpg`;

class Welcome extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired
    }).isRequired
  };

  componentDidMount() {
    // check if user has already logged in
    if (this.props.user.email !== "")
      this.props.history.push("/workspace");
  }

  errorHander = (message) => {
    this.notificationSystem.addNotification({
      title: "Lỗi",
      message,
      level: "error",
      position: "tr",
      autoDismiss: 2
    });
  }

  successHandler = () => {
    this.props.history.push("/workspace");
    window.location.reload();
  }

  authWithGoogle = () => {
    app.auth().signInWithPopup(googleProvider).then((user, error) => {
      if (error) {
        this.errorHander(error.message);
      } else {
        this.successHandler();
      }
    });
  }

  render() {
    return (
      <div className="white-background">
        <div className="wrap textTheme">
          <Menu className="menu-header welcome-container" secondary size="tiny">
            <Menu.Item>
              <Item>
                <Item.Image src={logo} size="mini" />
              </Item>
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Button basic color="green" onClick={() => this.authWithGoogle()}>
                  <Icon name="google" />
                  Đăng nhập bằng Gmail
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          <center className="welcome-intro">
            <div style={{ maxWidth: "1500px" }}>
              <Header className="welcome-intro title" as='h1'>Quản lý báo cáo với REBOT</Header>
              <Header.Subheader className="welcome-text">
                Chỉ việc đăng nhập bằng Email của bạn là mọi thứ đã sẵn sàng.
              </Header.Subheader>
              <Image src={mainWelcome} fluid />
            </div>
          </center>
        </div>
        <div className="welcome-explain">
          <center className="wrap welcome-container">
            <Header className="welcome-explain header" as='h2'>Phương pháp gửi báo cáo bằng Excel truyền thống</Header>
            <Grid className="welcome-explain row-margin">
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Image size="small" src={explain1} />
                </Grid.Column>
                <Grid.Column>
                  <Image size="small" src={explain2} />
                </Grid.Column>
                <Grid.Column>
                  <Image size="small" src={explain1} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <span className="welcome-text">Mỗi đơn vị sử dụng một mẫu khác nhau.</span>
                </Grid.Column>
                <Grid.Column>
                  <span className="welcome-text">Khả năng dữ liệu bị sai sót dẫn tới không khớp.</span>
                </Grid.Column>
                <Grid.Column>
                  <span className="welcome-text">Quá trình gửi báo cáo và phản hồi không tức thời.</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Image className="welcome-explain row-margin" src={explainBefore} size="medium" rounded />
          </center>
        </div>
        <center className="wrap after">
          <Grid className="welcome-container">
            <Grid.Row>
              <Grid.Column width={10}>
                <Image size="huge" src={after11} />
              </Grid.Column>
              <Grid.Column width={6} className="after detail">
                <Header className="welcome-intro title" as="h3">
                  Kiểm tra dữ liệu bằng REBOT
                  <Header.Subheader>
                    Hệ thống sẽ đối chiếu các số liệu giữa các báo cáo để tìm ra điểm bất hợp lý.
                  </Header.Subheader>
                </Header>
                <Image size="small" src={after12} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </center>
        <center className="wrap after">
          <Grid className="welcome-container">
            <Grid.Row>
              <Grid.Column width={6} className="after detail">
                <Header className="welcome-intro title" as="h3">
                  Gửi báo cáo và nhận phản hồi qua REBOT
                  <Header.Subheader>
                    Việc gửi và nhận báo cáo vô cùng đơn giản trực tiếp thông qua hệ thống. Cấp trên có thể gửi phản hồi trực tiếp tới đơn vị.
                  </Header.Subheader>
                </Header>
                <Image size="small" src={after21} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Image size="huge" src={after22} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </center>
        <NotificationSystem ref={(ref) => { this.notificationSystem = ref }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(Welcome));