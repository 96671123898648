/* eslint-disable react/no-did-mount-set-state, react/no-array-index-key, react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Table, Message, Button, Grid, Popup, Header, Segment, Form, Dropdown } from "semantic-ui-react";

// components
import ReportViewer from "../../components/ReportViewer";

// services
import { eventTracking } from "../../libs/ga";
import { db } from "../../libs/firebase";
import { convertTimeStamp } from "../../libs/time";


class Watch extends Component {
  static propTypes = {
    errorHandler: PropTypes.func.isRequired,
    successHandler: PropTypes.func.isRequired,
    unit: PropTypes.shape({
      unitID: PropTypes.string.isRequired,
      activeYear: PropTypes.string.isRequired,
      activeMilestone: PropTypes.string,
      information: PropTypes.shape({
        rootID: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    milestone: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    status: PropTypes.object.isRequired,
    reports: PropTypes.object.isRequired,
    manage: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      selectedMilestone: this.props.unit.activeMilestone
    }
  }

  refreshHandler = () => {
    this.setState({ isWaiting: true });
    eventTracking("history", "load", 1, this.props.unit.unitID);

    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.state.selectedMilestone}/final/${this.props.unit.unitID}/status`).orderByChild("time").limitToLast(20).once("value", (data) => {
      if (data !== null && data.val() !== null) {
        localStorage.setItem(`${this.props.unit.unitID}${this.state.selectedMilestone}`, JSON.stringify(data.val()));
      }
      this.setState({ isWaiting: false });
    });
  }

  removeHandler = (statusKey, dataKey) => {
    this.setState({ isWaiting: true });
    eventTracking("history", "remove", 1, this.props.unit.unitID);

    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.state.selectedMilestone}/final/${this.props.unit.unitID}/status/${statusKey}`).remove((error) => {
      if (error) {
        this.props.errorHandler("Xoá thất bại", "Xoá lịch sử gửi báo cáo thất bại. Xin vui lòng thử lại sau.");
        this.setState({ isWaiting: false });
      } else
        db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.state.selectedMilestone}/final/${this.props.unit.unitID}/data/${dataKey}`).remove((err) => {
          if (err) {
            this.props.errorHandler("Xoá thất bại", "Xoá lịch sử gửi báo cáo thất bại. Xin vui lòng thử lại sau.");
            this.setState({ isWaiting: false });
          } else {
            this.props.successHandler("Xoá lịch gửi báo cáo thành công.");
            this.refreshHandler();
          }
        });
    });
  }

  milestoneHandler = (event, { value }) => {
    this.setState({ selectedMilestone: value }, () => this.refreshHandler());
  }

  copyHandler = () => {
    this.setState({ isCopying: true });
    eventTracking("history", "copy", 1, this.props.unit.unitID);

    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.props.unit.activeMilestone}/reports/${this.props.unit.unitID}`).update(this.state.viewer.data, (error) => {
      this.setState({ isCopying: false, viewer: undefined });
      if (error)
        this.props.errorHandler("Sao chép dữ liệu thất bại. Xin vui lòng thử lại sau.");
      else
        this.props.successHandler("Sao chép dữ liệu thành công.");
    });
  }

  showReport = (date, dataKey) => {
    this.setState({ isWaiting: true });
    eventTracking("history", "show", 1, this.props.unit.unitID);

    db.ref(`rebot/${this.props.unit.information.rootID}/${this.props.unit.activeYear}/${this.state.selectedMilestone}/final/${this.props.unit.unitID}/data/${dataKey}`).once("value", snapshot => {
      if (snapshot === null || snapshot.val() === null)
        this.props.errorHandler("Không tìm thấy dữ liệu của báo cáo trên hệ thống.");
      else {
        this.setState({
          viewer: {
            date: convertTimeStamp(date, true),
            data: JSON.parse(snapshot.val()),
            reports: this.props.reports[this.props.unit.information.rootID]
          }
        });
      }
      this.setState({ isWaiting: false });
    });
  }

  renderViewer = () => (
    <div>
      <Message warning>
        <Message.Content>
          <Header className="unitHeader" as="h3">Lịch sử gửi báo cáo</Header>
          <Header className="unitSubHeader" as="h4">Gửi ngày {this.state.viewer.date}</Header>
          {(this.props.unit.information.type === "th")?
            <Button.Group className="right-top-button">
              <Button 
                loading={this.state.isCopying} 
                color="blue" 
                icon="save" 
                content="Sao chép dữ liệu" 
                labelPosition="right" 
                onClick={this.copyHandler} 
              />
              <Button 
                loading={this.state.isCopying} 
                color="blue" 
                content="" 
                labelPosition="right" 
                onClick={this.copyHandler} 
              />
              <Button color="grey" icon="close" onClick={() => this.setState({ viewer: undefined })} />
            </Button.Group>
            : <Button.Group className="right-top-button" vertical icon><Button color="grey" icon="close" onClick={() => this.setState({ viewer: undefined })} /></Button.Group>
          }
        </Message.Content>
      </Message>
      <ReportViewer 
        validateRules={this.props.reports.validate}
        data={this.state.viewer.data} 
        reports={this.state.viewer.reports} 
        manage={this.props.manage} 
        unitType={this.props.unit.information.type} 
      />
    </div>
  )

  renderRow = (key, row, index) => (
    <Table.Row key={index}>
      <Table.Cell width="3">{convertTimeStamp(row.time, true)}</Table.Cell>
      <Table.Cell width="5">{row.author.displayName}</Table.Cell>
      <Table.Cell width="5">{row.author.email}</Table.Cell>
      <Table.Cell className="stt" width="1"><Button color="blue" icon="braille" onClick={() => this.showReport(row.time, row.data)} /></Table.Cell>
      {(this.props.status[this.props.unit.unitID].data !== row.data)?
        <Table.Cell className="stt" width="1"><Button color="red" icon="remove" onClick={() => this.removeHandler(key, row.data)} /></Table.Cell>
        : <Table.Cell className="stt" width="1" />
      }
    </Table.Row>
  )

  renderMilestones = () => {
    const options = [];
    Object.keys(this.props.milestone).map(milestone => (
      options.push({
        key: milestone,
        text: this.props.milestone[milestone].description,
        value: milestone
      })
    ));
    return (
      <Dropdown
        selection
        multiple={false}
        onChange={this.milestoneHandler}
        options={options}
        defaultValue={this.state.selectedMilestone}
        placeholder="Chọn đợt báo cáo"
      />
    )
  }

  render() {
    if (this.state.viewer !== undefined)
      return this.renderViewer();

    const history = localStorage.getItem(`${this.props.unit.unitID}${this.state.selectedMilestone}`);
    let rows;
    if (history !== null)
      rows = JSON.parse(history);
    return (
      <div>
        <Message
          icon="desktop"
          warning
          header="Lịch sử gửi báo cáo"
          list={[
            "Lần gửi báo cáo gần nhất không được xoá vì ảnh hưởng tới quá trình thống kê báo cáo. Thầy/Cô có thể gửi lại một bản báo cáo mới để cập nhật.",
            "Hệ thống giới hạn chỉ cho phép xem tối đa 20 lần gửi trước đó. Để xem được lịch sử cũ hơn cần phải xoá bớt lịch sử dưới đây.",
            "Nếu Thầy/Cô có nhu cầu xem lại lịch sử toàn bộ, xin vui lòng liên hệ công ty để được hỗ trợ.",
          ]}
        />
        <Segment style={{background: "transparent"}} stacked>
          <Form>
            <Form.Group inline>
              <label>Các đợt báo cáo: </label>
              {this.renderMilestones()}
            </Form.Group>
          </Form> 
        </Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={15}>
              <Table style={{background: "transparent"}} celled striped color="brown">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="stt">Thời gian gửi</Table.HeaderCell>
                    <Table.HeaderCell className="stt">Tên người gửi</Table.HeaderCell>
                    <Table.HeaderCell className="stt">Email</Table.HeaderCell>
                    <Table.HeaderCell className="stt">Xem</Table.HeaderCell>
                    <Table.HeaderCell className="stt">Xoá</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {(rows !== undefined && rows !== null)?
                    Object.keys(rows).reverse().map((row, index) => this.renderRow(row, rows[row], index))
                    : null
                  }
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width={1}>
              <div className="refresh-stick">
                <Button.Group vertical icon>
                  <Popup trigger={<Button loading={this.state.isWaiting} color="teal" icon="refresh" onClick={this.refreshHandler} />} content="Cập nhật lại thông tin" />
                </Button.Group>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unit: state.unit,
  status: state.status,
  reports: state.reports,
  manage: state.manage,
  milestone: state.milestone
});

export default connect(mapStateToProps)(Watch);