import { CANDO, CANDO_CLASS } from "./constants";


export function candoFetch(year, classes) {
  return {
    type: CANDO,
    year,
    classes
  };
}

export function candoClassFetch(year, id, data) {
  return {
    type: CANDO_CLASS,
    year,
    id,
    data
  };
}