import Excel from 'exceljs';
import { intToExcelCol } from 'excel-column-name';
import _ from 'lodash';
import {
  evaluateStudents,
  computeBMIPointResult,
  computeHeightPointResult,
  computeWeightLengthPointResult,
  computeWeightPointResult,
  round,
} from '../helpers';
import {
  writeHeaders,
  writeReport,
  writeCell,
  writeRange,
  writeRanges,
} from './common';



const theodoisdd = (
  allMonthData,
  activeYear,
  solieucando,
  unitName,
  useDay,
  gradesListArr,
  all,
  errorHandler,
  closeHandler,
  onComplete,
  sgd
) => {

  const NTMths = ['9', '10', '11', '12', '1', '2', '3', '4', '5'];
  const mappingDescs = {
    NC2: "SDDNC(n)",
    NC1: "SDDNC",
    TC2: "SDDTC(n)",
    TC1: "SDDTC",
    GC1: "SDDGC",
    GC2: "SDDGC(n)",
    BP: "BP",
    DC: "ThC",
    BT: "BT",
    [`BT+`]: "BT",
    NC1TC1: "SDDNC /TC",
    NC1TC2: "SDDNC /TC(n)",
    NC2TC1: "SDDNC(n)/TC",
    NC2TC2: "SDDNC(n)/TC(n)",
  };
  const addPrefix = (val) => {
    return val > 0 ? `+${val}` : val;
  }
  const isAbnormal = (weightPoint, heightPoint, weightLengthPoint) => {
    if (weightPoint && weightPoint !== "BT" && weightPoint !== "BT+")
      return true;
    if (heightPoint && heightPoint !== "BT" && heightPoint !== "BT+")
      return true;
    if (weightLengthPoint && weightLengthPoint !== "BT" && weightLengthPoint !== "BT+")
      return true;
    return false;
  }
  const wb = new Excel.Workbook();
  _.forEach(gradesListArr, ({ grade, name, classID }) => {
    const hasBMI = grade.includes('4-5tuoi') || grade.includes('5-6tuoi') || all;
    const sheet = wb.addWorksheet(`${name}`, {
      pageSetup: {
        scale: hasBMI ? 48 : 54,
        orientation: 'landscape',
        paperSize: 9,
        horizontalCentered: true,
        margins : {
          left: 0.32,
          right: 0.25,
          top: 0.35,
          bottom: 0.28,
          header: 0.2,
          footer: 0.24,
        }
      },
    });
    sheet.getRow(all ? 3 : 4).height = 9.5;
    sheet.getRow(all ? 5 : 6).height = 67.5;
    const infos = [
      { title: 'Trường', value: unitName },
      { title: 'Năm học', value: activeYear },
    ];
    if (!all) infos.splice(1, 0, { title: 'Lớp', value: name });
    _.forEach(
      infos,
      ({ title, value }, idx) => {
        writeRange(sheet, idx + 1, 1, title, `A${idx + 1}:B${idx + 1}`, {
          bold: true,
          horizontal: 'left',
        });
        writeCell(sheet, idx + 1, 3, value, {
          color: 'FFFF0000',
          bold: true,
          horizontal: 'left',
        });
      }
    );
    sheet.getColumn(2).width = 5.4;
    sheet.getColumn(3).width = 16.4;
    sheet.getColumn(42).width = 5.4;
    sheet.getColumn(43).width = 17.1;
    writeRange(
      sheet,
      1,
      7,
      'THEO DÕI SỨC KHỎE TRẺ',
      all ? 'G1:AG2' : 'G1:AA3',
      {
        bold: true,
        fontSize: 12,
      }
    );
    writeRange(
      sheet,
      1,
      all ? 48 : 43,
      'THEO DÕI SỨC KHỎE TRẺ',
      all ? 'AV1:BN2' : 'AQ1:BF3',
      {
        bold: true,
        fontSize: 12,
      }
    );
    const leftHeaders = [
      { name: 'Nữ', width: 4.3, wrapText: true },
      { name: 'Ngày sinh', width: 11.4, wrapText: true },
    ];
    _.forEach(['09', '10', '11', '12', '01'], (month) => {
      if (hasBMI)
        leftHeaders.push({
          name: `Tháng ${month}`,
          subHeaders: [
            { name: 'Cân nặng', width: 5.1, vertical: true },
            { name: 'Kết quả', width: 7.4, vertical: true },
            { name: 'Chiều cao', width: 5.7, vertical: true },
            { name: 'Kết quả', width: 6.4, vertical: true },
            { name: 'BMI', width: 6.4, vertical: true },
            {
              name: 'Cân nặng theo\nchiều cao/BMI',
              width: 7.6,
              vertical: true,
              wrapText: true,
            },
            { name: 'Đánh giá', width: 11.7, vertical: true },
          ],
        });
      else leftHeaders.push({
        name: `Tháng ${month}`,
        subHeaders: [
          { name: 'Cân nặng', width: 5.1, vertical: true },
          { name: 'Kết quả', width: 7.4, vertical: true },
          { name: 'Chiều cao', width: 5.7, vertical: true },
          { name: 'Kết quả', width: 6.4, vertical: true },
          {
            name: 'Cân nặng theo chiều cao',
            width: 7.6,
            vertical: true,
            wrapText: true,
          },
          { name: 'Đánh giá', width: 11.7, vertical: true },
        ],
      });
    });
    const rightHeaders = [
      { name: 'Nữ', width: 4.3, wrapText: true },
      { name: 'Ngày sinh', width: 11.4, wrapText: true },
    ];
    _.forEach(['02', '03', '04', '05'], (month) => {
      if (hasBMI) {
        rightHeaders.push({
          name: `Tháng ${month}`,
          subHeaders: [
            { name: 'Cân nặng', width: 5.1, vertical: true },
            { name: 'Kết quả', width: 7.4, vertical: true },
            { name: 'Chiều cao', width: 5.7, vertical: true },
            { name: 'Kết quả', width: 6.4, vertical: true },
            { name: 'BMI', width: 6.4, vertical: true },
            {
              name: 'Cân nặng theo\nchiều cao/BMI',
              width: 7.6,
              vertical: true,
              wrapText: true,
            },
            { name: 'Đánh giá', width: 11.7, vertical: true },
          ],
        });
      }
      else {
        rightHeaders.push({
          name: `Tháng ${month}`,
          subHeaders: [
            { name: 'Cân nặng', width: 5.1, vertical: true },
            { name: 'Kết quả', width: 7.4, vertical: true },
            { name: 'Chiều cao', width: 5.7, vertical: true },
            { name: 'Kết quả', width: 6.4, vertical: true },
            {
              name: 'Cân nặng theo\nchiều cao',
              width: 7.6,
              vertical: true,
              wrapText: true,
            },
            { name: 'Đánh giá', width: 11.7, vertical: true },
          ],
        });
      }
    });
    writeHeaders(
      sheet,
      [{ name: 'STT', wrapText: true, width: 3.4 }],
      all ? 4 : 5,
      0,
      2
    );
    writeRange(
      sheet,
      all ? 4 : 5,
      2,
      'Họ và tên',
      all ? 'B4:C5' : 'B5:C6',
      {
        bold: true,
        border: true,
      }
    );
    writeHeaders(
      sheet,
      leftHeaders,
      all ? 4 : 5,
      3,
      2
    );
    writeHeaders(
      sheet,
      [{ name: 1, wrapText: true, width: 3.4 }],
      all ? 6 : 7,
      0,
      1,
      { fill: 'FFD9D9D9' }
    );
    writeRange(
      sheet,
      all ? 6 : 7,
      2,
      2,
      all ? 'B6:C6' : 'B7:C7',
      {
        bold: true,
        border: true,
        fill: 'FFD9D9D9',
      });
    writeHeaders(
      sheet,
      hasBMI ? _.map(_.range(3, 40), (idx) => ({ name: idx })) : _.map(_.range(3, 35), (idx) => ({ name: idx })),
      all ? 6 : 7,
      3,
      1,
      { fill: 'FFD9D9D9' }
    );
    writeHeaders(
      sheet,
      [{ name: 'STT', wrapText: true, width: 3.9 }],
      all ? 4 : 5,
      hasBMI ? 40 : 35,
      2
    );
    if (all) {
      writeRange(
        sheet,
        4,
        hasBMI ? 42 : 37,
        'Họ và tên',
        'AP4:AQ5',
        {
          bold: true,
          border: true,
        });
      writeRange(
        sheet,
        6,
        hasBMI ? 42 : 37,
        2,
        'AP6:AQ6',
        {
          bold: true,
          border: true,
          fill: 'FFD9D9D9',
        });
    } else {
      writeRange(
        sheet,
        5,
        hasBMI ? 42 : 37,
        'Họ và tên',
        hasBMI ? 'AP5:AQ6' : 'AK5:AL6',
        {
          bold: true,
          border: true,
        });
      writeRange(
        sheet,
        7,
        hasBMI ? 42 : 37,
        2,
        hasBMI ? 'AP7:AQ7' : 'AK7:AL7',
        {
          bold: true,
          border: true,
          fill: 'FFD9D9D9',
        });
    }
    writeHeaders(
      sheet,
      rightHeaders,
      all ? 4 : 5,
      hasBMI ? 43 : 38,
      2
    );

    writeHeaders(
      sheet,
      [{ name: 1, wrapText: true }],
      all ? 6 : 7,
      hasBMI ? 40 : 35,
      1,
      {
        fill: 'FFD9D9D9',
      }
    );
    writeHeaders(
      sheet,
      hasBMI ? _.map(_.range(3, 33), (idx) => ({ name: idx })) : _.map(_.range(3, 29), (idx) => ({ name: idx })),
      all ? 6 : 7,
      hasBMI ? 43 : 38,
      1,
      { fill: 'FFD9D9D9' }
    );
    const evaluatedYearStudents = [];
    _.forEach(NTMths, (mth) => {
      if (!all) {
        const { students: mthStudents, day } = _.get(allMonthData[mth], classID, {});
        const counter = {
          NC1: 0,
          NC2: 0,
          TC1: 0,
          TC2: 0,
          GC1: 0,
          GC2: 0,
          BP: 0,
          DC: 0,
          BTWeight: 0,
          BTHeight: 0,
          BTBmiWeiHei: 0,
          FNC2: 0,
          FTC1: 0,
          FNC1: 0,
          FTC2: 0,
          FGC1: 0,
          FGC2: 0,
          FBP: 0,
          FDC: 0,
          FBTWeight: 0,
          FBTHeight: 0,
          FBTBmiWeiHei: 0,
          total: 0,
          female: 0,
          checked: 0,
        }
        counter.checked += _.filter(mthStudents, (student) => student.weight !== '' && student.height !== '').length;
        counter.female += _.filter(mthStudents, (student) => student.gender === 'F' && student.weight !== '' && student.height !== '').length;
        counter.total += _.keys(mthStudents).length;
        const evaluatedMthStudents = evaluateStudents(
          mthStudents,
          parseFloat(mth),
          activeYear,
          solieucando,
          day,
          useDay,
          sgd
        ).map(student => {
          const weightPoint = (student.weightPoint !== undefined) ? computeWeightPointResult(student.weightPoint) : "";
          const heightPoint = (student.heightPoint !== undefined) ? computeHeightPointResult(student.heightPoint) : "";
          const bmiPoint = (student.bmiPoint !== undefined) ? computeBMIPointResult(student.bmiPoint) : "";
          const weightLengthPoint = (student.weightLengthPoint !== undefined) ? computeWeightLengthPointResult(student.weightLengthPoint) : "";
          return ({
            ...student,
            weightPoint,
            heightPoint,
            bmiPoint,
            weightLengthPoint,
          });
        });
        const abnormalMthStudents = {};
        _.forEach(evaluatedMthStudents, (student) => {
          const {
            weightPoint,
            heightPoint,
            bmiPoint,
            weightLengthPoint,
            gender,
            studentID,
          } = student;
          if (isAbnormal(weightPoint, heightPoint, bmiPoint || weightLengthPoint)) abnormalMthStudents[studentID] = student;
          if (weightPoint === 'BT' || weightPoint === 'BT+') counter.BTWeight += 1;
          if (weightPoint === 'NC1') counter.NC1 += 1;
          if (weightPoint === 'NC2') counter.NC2 += 1;
          if (heightPoint === 'TC1') counter.TC1 += 1;
          if (heightPoint === 'TC2') counter.TC2 += 1;
          if (heightPoint === 'BT' || heightPoint === 'BT+') counter.BTHeight += 1;
          if ((weightLengthPoint || bmiPoint) === 'BT') counter.BTBmiWeiHei += 1;
          if ((weightLengthPoint || bmiPoint) === 'BP') counter.BP += 1;
          if ((weightLengthPoint || bmiPoint) === 'DC') counter.DC += 1;
          if ((weightLengthPoint || bmiPoint) === 'GC1') counter.GC1 += 1;
          if ((weightLengthPoint || bmiPoint) === 'GC2') counter.GC2 += 1;

          if ((weightPoint === 'BT' || weightPoint === 'BT+') && gender === 'F') counter.FBTWeight += 1;
          if (weightPoint === 'NC1' && gender === 'F') counter.FNC1 += 1;
          if (weightPoint === 'NC2' && gender === 'F') counter.FNC2 += 1;
          if (heightPoint === 'TC1' && gender === 'F') counter.FTC1 += 1;
          if (heightPoint === 'TC2' && gender === 'F') counter.FTC2 += 1;
          if ((heightPoint === 'BT' || heightPoint === 'BT+') && gender === "F") counter.FBTHeight += 1;
          if ((weightLengthPoint || bmiPoint) === 'BT' && gender === 'F') counter.FBTBmiWeiHei += 1;
          if ((weightLengthPoint || bmiPoint) === 'BP' && gender === 'F') counter.FBP += 1;
          if ((weightLengthPoint || bmiPoint) === 'DC' && gender === 'F') counter.FDC += 1;
          if ((weightLengthPoint || bmiPoint) === 'GC1' && gender === 'F') counter.FGC1 += 1;
          if ((weightLengthPoint || bmiPoint) === 'GC2' && gender === 'F') counter.FGC2 += 1;
        })
        evaluatedYearStudents.push({ students: abnormalMthStudents, mth, counter });
      } else {
        const monthData = allMonthData[mth];
        const abnormalMthStudents = {};
        const counter = {
          NC1: 0,
          NC2: 0,
          TC1: 0,
          TC2: 0,
          GC1: 0,
          GC2: 0,
          BP: 0,
          DC: 0,
          BTWeight: 0,
          BTHeight: 0,
          BTBmiWeiHei: 0,
          FNC2: 0,
          FTC1: 0,
          FNC1: 0,
          FTC2: 0,
          FGC1: 0,
          FGC2: 0,
          FBP: 0,
          FDC: 0,
          FBTWeight: 0,
          FBTHeight: 0,
          FBTBmiWeiHei: 0,
          total: 0,
          female: 0,
          checked: 0,
        }
        _.forEach(monthData, (classData, id) => {
          const { students, day } = classData;
          counter.checked += _.filter(
            students,
            (student) => student.weight  && student.height 
          ).length;
          counter.female += _.filter(students, (student) => student.gender === 'F' && student.weight  && student.height ).length;
          counter.total += _.keys(students).length;
          const data = evaluateStudents(
            students,
            parseFloat(mth),
            activeYear,
            solieucando,
            day,
            useDay,
            sgd
          ).map((student) => {
            const weightPoint =
              student.weightPoint !== undefined
                ? computeWeightPointResult(student.weightPoint)
                : '';
            const heightPoint =
              student.heightPoint !== undefined
                ? computeHeightPointResult(student.heightPoint)
                : '';
            const bmiPoint =
              student.bmiPoint !== undefined
                ? computeBMIPointResult(student.bmiPoint)
                : '';
            const weightLengthPoint =
              student.weightLengthPoint !== undefined
                ? computeWeightLengthPointResult(student.weightLengthPoint)
                : '';
            return {
              ...student,
              weightPoint,
              heightPoint,
              bmiPoint,
              weightLengthPoint,
            };
          });
          _.forEach(
            data,
            (student) => {
              const {
                weightPoint,
                heightPoint,
                bmiPoint,
                weightLengthPoint,
                gender,
                studentID,
              } = student;
              if (isAbnormal(weightPoint, heightPoint, bmiPoint || weightLengthPoint)) abnormalMthStudents[studentID] = student;
              if (weightPoint === 'BT' || weightPoint === 'BT+') counter.BTWeight += 1;
              if (weightPoint === 'NC1') counter.NC1 += 1;
              if (weightPoint === 'NC2') counter.NC2 += 1;
              if (heightPoint === 'TC1') counter.TC1 += 1;
              if (heightPoint === 'TC2') counter.TC2 += 1;
              if (heightPoint === 'BT' || heightPoint === 'BT+') counter.BTHeight += 1;
              if ((weightLengthPoint || bmiPoint) === 'BT') counter.BTBmiWeiHei += 1;
              if ((weightLengthPoint || bmiPoint) === 'BP') counter.BP += 1;
              if ((weightLengthPoint || bmiPoint) === 'DC') counter.DC += 1;
              if ((weightLengthPoint || bmiPoint) === 'GC1') counter.GC1 += 1;
              if ((weightLengthPoint || bmiPoint) === 'GC2') counter.GC2 += 1;

              if ((weightPoint === 'BT' || weightPoint === 'BT+') && gender === 'F') counter.FBTWeight += 1;
              if (weightPoint === 'NC1' && gender === 'F') counter.FNC1 += 1;
              if (weightPoint === 'NC2' && gender === 'F') counter.FNC2 += 1;
              if (heightPoint === 'TC1' && gender === 'F') counter.FTC1 += 1;
              if (heightPoint === 'TC2' && gender === 'F') counter.FTC2 += 1;
              if ((heightPoint === 'BT' || heightPoint === 'BT+') && gender === 'F') counter.FBTHeight += 1;
              if ((weightLengthPoint || bmiPoint) === 'BT' && gender === 'F') counter.FBTBmiWeiHei += 1;
              if ((weightLengthPoint || bmiPoint) === 'BP' && gender === 'F') counter.FBP += 1;
              if ((weightLengthPoint || bmiPoint) === 'DC' && gender === 'F') counter.FDC += 1;
              if ((weightLengthPoint || bmiPoint) === 'GC1' && gender === 'F') counter.FGC1 += 1;
              if ((weightLengthPoint || bmiPoint) === 'GC2' && gender === 'F') counter.FGC2 += 1;
            }
          );
          evaluatedYearStudents.push({ students: abnormalMthStudents, mth, counter });
        })

      }
    });
    const allStudents = {};
    _.forEach(evaluatedYearStudents, (mth) => {
      const mthStudents = _.get(mth, 'students', {});
      _.forEach(mthStudents, ({
        firstName,
        lastName,
        date,
        month: childMonth,
        year,
        gender,
      }, id) => {
        if (!allStudents[id]) {
          allStudents[id] = {
            firstName,
            lastName,
            dayOfBth: `${date}/${childMonth}/${year}`,
            gender,
            studentID: id
          }
        }
      })
    });
    const allStudentsArr = _.map(allStudents, student => student);
    let rowIndex = all ? 7 : 8;
    _.forEach(allStudentsArr, ({
      firstName,
      lastName,
      dayOfBth,
      gender,
      studentID,
    }, idx) => {
      writeCell(
        sheet,
        rowIndex,
        1,
        idx + 1,
        {
          border: { bottom: 'dotted', top: 'dotted' },
        }
      )
      writeRange(
        sheet,
        rowIndex,
        2,
        `${lastName} ${firstName}`,
        `B${rowIndex}:C${rowIndex}`,
        {
          border: { bottom: 'dotted', top: 'dotted' }, horizontal: 'left'
        }
      )
      writeCell(
        sheet,
        rowIndex,
        4,
        `${gender === 'F' ? 'Nữ' : ''}`,
        {
          border: { bottom: 'dotted', top: 'dotted' },
        }
      );
      writeCell(
        sheet,
        rowIndex,
        5,
        dayOfBth,
        {
          border: { bottom: 'dotted', top: 'dotted' }, horizontal: 'left'
        }
      )
      let colIndex = 6;
      _.forEach(['9', '10', '11', '12', '1'], (mth) => {
        const students = _.get(_.find(evaluatedYearStudents, ({ mth: mthData }) => mth === mthData), 'students', {});
        const {
          heightPoint,
          weightPoint,
          height,
          weight,
          weightLengthPoint,
          bmi,
          bmiPoint
        } = _.find(students, ({ studentID: id }) => studentID === id) || {};
        let remark = "";
        if (weightPoint !== 'NC1' && weightPoint !== 'NC2' && heightPoint !== 'TC1' && heightPoint !== 'TC2') {
          remark = mappingDescs[bmiPoint || weightLengthPoint];
        } else {
          if ((heightPoint === 'BT' || heightPoint === 'BT+') && (weightPoint === 'NC1' || weightPoint === 'NC2')) remark = mappingDescs[weightPoint];
          if ((weightPoint === 'BT' || weightPoint === 'BT+') && (heightPoint === 'TC1' || heightPoint === 'TC2')) remark = mappingDescs[heightPoint];
          if (heightPoint !== 'BT' && heightPoint !== 'BT+' && weightPoint !== 'BT' && weightPoint !== 'BT+') remark = mappingDescs[`${weightPoint}${heightPoint}`];
        }
        writeCell(
          sheet,
          rowIndex,
          colIndex,
          weight,
          {
            border: { bottom: 'dotted', top: 'dotted' },
          }
        )
        colIndex += 1;
        writeCell(
          sheet,
          rowIndex,
          colIndex,
          mappingDescs[weightPoint],
          {
            border: { bottom: 'dotted', top: 'dotted' },
          }
        )
        colIndex += 1;
        writeCell(
          sheet,
          rowIndex,
          colIndex,
          height,
          {
            border: { bottom: 'dotted', top: 'dotted' },
          }
        )
        colIndex += 1;
        writeCell(
          sheet,
          rowIndex,
          colIndex,
          mappingDescs[heightPoint],
          {
            border: { bottom: 'dotted', top: 'dotted' },
          }
        )
        colIndex += 1;
        if (!hasBMI) {
          if (weightLengthPoint) {
            writeCell(
              sheet,
              rowIndex,
              colIndex,
              mappingDescs[weightLengthPoint],
              {
                border: { bottom: 'dotted', top: 'dotted' },
              }
            )
            colIndex += 1;
            writeCell(
              sheet,
              rowIndex,
              colIndex,
              remark,
              {
                border: { bottom: 'dotted', top: 'dotted' },
              }
            )
            colIndex += 1;
          } else {
            writeCell(
              sheet,
              rowIndex,
              colIndex,
              round(bmi, 2),
              {
                border: { bottom: 'dotted', top: 'dotted' },
              }
            )
            colIndex += 1;
            writeCell(
              sheet,
              rowIndex,
              colIndex,
              remark,
              {
                border: { bottom: 'dotted', top: 'dotted' },
              }
            )
            colIndex += 1;
          }
        } else if (weightLengthPoint) {
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            '',
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            mappingDescs[weightLengthPoint],
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            remark,
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
        } else {
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            round(bmi, 2),
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            mappingDescs[bmiPoint],
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            remark,
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
        }
      })
      writeCell(
        sheet,
        rowIndex,
        colIndex,
        idx + 1,
        {
          border: { bottom: 'dotted', top: 'dotted' },
        }
      )
      colIndex += 1;
      writeRange(
        sheet,
        rowIndex,
        colIndex,
        `${lastName} ${firstName}`,
        hasBMI ? `AP${rowIndex}:AQ${rowIndex}` : `AK${rowIndex}:AL${rowIndex}`,
        {
          border: { bottom: 'dotted', top: 'dotted' }, horizontal: 'left'
        }
      )
      colIndex += 1;
      writeCell(
        sheet,
        rowIndex,
        colIndex + 1,
        `${gender === 'F' ? 'Nữ' : ''}`,
        {
          border: { bottom: 'dotted', top: 'dotted' },
        }
      );
      colIndex += 2;
      writeCell(
        sheet,
        rowIndex,
        colIndex,
        dayOfBth,
        {
          border: { bottom: 'dotted', top: 'dotted' }, horizontal: 'left'
        }
      )
      colIndex += 1;
      _.forEach(['2', '3', '4', '5'], (mth) => {
        const students = _.get(_.find(evaluatedYearStudents, ({ mth: mthData }) => mth === mthData), 'students', {});
        const {
          heightPoint,
          weightPoint,
          height,
          weight,
          weightLengthPoint,
          bmi,
          bmiPoint
        } = _.find(students, ({ studentID: id }) => studentID === id) || {};
        let remark = "";
        if (weightPoint !== 'NC1' && weightPoint !== 'NC2' && heightPoint !== 'TC1' && heightPoint !== 'TC2') {
          remark = mappingDescs[bmiPoint || weightLengthPoint];
        } else {
          if ((heightPoint === 'BT' || heightPoint === 'BT+') && (weightPoint === 'NC1' || weightPoint === 'NC2')) remark = mappingDescs[weightPoint];
          if ((weightPoint === 'BT' || weightPoint === 'BT+') && (heightPoint === 'TC1' || heightPoint === 'TC2')) remark = mappingDescs[heightPoint];
          if (heightPoint !== 'BT' && heightPoint !== 'BT+' && weightPoint !== 'BT' && weightPoint !== 'BT+') remark = mappingDescs[`${weightPoint}${heightPoint}`];
        }
        writeCell(
          sheet,
          rowIndex,
          colIndex,
          weight,
          {
            border: { bottom: 'dotted', top: 'dotted' },
          }
        )
        colIndex += 1;
        writeCell(
          sheet,
          rowIndex,
          colIndex,
          mappingDescs[weightPoint],
          {
            border: { bottom: 'dotted', top: 'dotted' },
          }
        )
        colIndex += 1;
        writeCell(
          sheet,
          rowIndex,
          colIndex,
          height,
          {
            border: { bottom: 'dotted', top: 'dotted' },
          }
        )
        colIndex += 1;
        writeCell(
          sheet,
          rowIndex,
          colIndex,
          mappingDescs[heightPoint],
          {
            border: { bottom: 'dotted', top: 'dotted' },
          }
        )
        colIndex += 1;
        if (!hasBMI) {
          if (weightLengthPoint) {
            writeCell(
              sheet,
              rowIndex,
              colIndex,
              mappingDescs[weightLengthPoint],
              {
                border: { bottom: 'dotted', top: 'dotted' },
              }
            )
            colIndex += 1;
            writeCell(
              sheet,
              rowIndex,
              colIndex,
              remark,
              {
                border: { bottom: 'dotted', top: 'dotted' },
              }
            )
            colIndex += 1;
          } else {
            writeCell(
              sheet,
              rowIndex,
              colIndex,
              round(bmi, 2),
              {
                border: { bottom: 'dotted', top: 'dotted' },
              }
            )
            colIndex += 1;
            writeCell(
              sheet,
              rowIndex,
              colIndex,
              remark,
              {
                border: { bottom: 'dotted', top: 'dotted' },
              }
            )
            colIndex += 1;
          }
        } else if (weightLengthPoint) {
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            '',
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            mappingDescs[weightLengthPoint],
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            remark,
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
        } else {
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            round(bmi, 2),
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            mappingDescs[bmiPoint],
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
          writeCell(
            sheet,
            rowIndex,
            colIndex,
            remark,
            {
              border: { bottom: 'dotted', top: 'dotted' },
            }
          )
          colIndex += 1;
        }
      })

      rowIndex += 1;
    })
    writeRange(
      sheet,
      rowIndex,
      1,
      `Tổng số: ${allStudentsArr.length} trẻ`,
      `A${rowIndex}:C${rowIndex}`,
      { border: true, bold: true }
    );
    writeRange(
      sheet,
      rowIndex,
      hasBMI ? 41 : 36,
      `Tổng số: ${allStudentsArr.length} trẻ`,
      hasBMI ? `AO${rowIndex}:AQ${rowIndex}` : `AJ${rowIndex}:AL${rowIndex}`,
      { border: true, bold: true }
    );
    if (hasBMI) {
      _.forEach([4, 5, 44, 45], (col) => writeCell(
        sheet,
        rowIndex,
        col,
        '',
        { border: true }
      ));
      _.forEach([6, 13, 20, 27, 34, 46, 53, 60, 67], (col) => {
        writeRange(
          sheet,
          rowIndex,
          col,
          '',
          `${intToExcelCol(col)}${rowIndex}:${intToExcelCol(col + 6)}${rowIndex}`,
          { border: true }
        );
      })
    } else {
      _.forEach([4, 5, 39, 40], (col) => writeCell(
        sheet,
        rowIndex,
        col,
        '',
        { border: true }
      ));
      _.forEach([6, 12, 18, 24, 30, 41, 47, 53, 59], (col) => {
        writeRange(
          sheet,
          rowIndex,
          col,
          '',
          `${intToExcelCol(col)}${rowIndex}:${intToExcelCol(col + 5)}${rowIndex}`,
          { border: true }
        );
      })
    }

    rowIndex += 1;
    const ranges = [
      {
        row: rowIndex,
        col: 1,
        val: 'TỔNG HỢP',
        range: `A${rowIndex}:B${rowIndex + 9}`,
        style: { bold: true, border: true }
      },
      {
        row: rowIndex + 10,
        col: 1,
        val: 'SO VỚI ĐẦU NĂM',
        range: `A${rowIndex + 10}:B${rowIndex + 17}`,
        style: { bold: true, border: true, wrapText: true, fontSize: 10, }
      },
      {
        row: rowIndex,
        col: 3,
        val: '',
        range: `C${rowIndex}:E${rowIndex}`,
        style: { fill: 'FFD9D9D9', border: true, fontSize: 10, }
      },
      {
        row: rowIndex + 1,
        col: 3,
        val: 'Tổng số trẻ cân đo',
        range: `C${rowIndex + 1}:E${rowIndex + 1}`,
        style: { fill: 'FFD9D9D9', bold: true, border: true, fontSize: 10, }
      },
      {
        row: rowIndex + 2,
        col: 3,
        val: 'Cân nặng',
        range: `C${rowIndex + 2}:C${rowIndex + 3}`,
        style: { bold: true, border: true, fontSize: 10, }
      },
      {
        row: rowIndex + 4,
        col: 3,
        val: 'Chiều cao',
        range: `C${rowIndex + 4}:C${rowIndex + 5}`,
        style: { bold: true, border: true, fontSize: 10, }
      },
      {
        row: rowIndex + 6,
        col: 3,
        val: 'Cân nặng theo chiều cao',
        range: `C${rowIndex + 6}:C${rowIndex + 9}`,
        style: { bold: true, wrapText: true, border: true, fontSize: 10, }
      },
      {
        row: rowIndex + 10,
        col: 3,
        val: 'Cân nặng',
        range: `C${rowIndex + 10}:C${rowIndex + 11}`,
        style: { bold: true, border: true, fontSize: 10, }
      },
      {
        row: rowIndex + 12,
        col: 3,
        val: 'Chiều cao',
        range: `C${rowIndex + 12}:C${rowIndex + 13}`,
        style: { bold: true, border: true, fontSize: 10, }
      },
      {
        row: rowIndex + 14,
        col: 3,
        val: 'Cân nặng theo chiều cao',
        range: `C${rowIndex + 14}:C${rowIndex + 17}`,
        style: { bold: true, wrapText: true, border: true, fontSize: 10, }
      },
    ];
    let headerColIdx = 6;
    _.forEach(
      [
        { val: 'Số trẻ' },
        { val: 'Nữ' },
        { val: 'Tỷ lệ' },
        { val: 'Số trẻ' },
        { val: 'Nữ' },
        { val: 'Tỷ lệ' },
        { val: 'Số trẻ' },
        { val: 'Nữ' },
        { val: 'Tỷ lệ' },
        { val: 'Số trẻ' },
        { val: 'Nữ' },
        { val: 'Tỷ lệ' },
        { val: 'Số trẻ' },
        { val: 'Nữ' },
        { val: 'Tỷ lệ' },
      ]
      , ({ val }) => {
        if (hasBMI) {
          const nextColumn = val !== 'Nữ' ? 1 : 2;
          ranges.push({
            row: rowIndex,
            col: headerColIdx,
            val,
            range: `${intToExcelCol(headerColIdx)}${rowIndex}:${intToExcelCol(headerColIdx + nextColumn)}${rowIndex}`,
            style: {
              wrapText: true,
              border: true,
              fill: 'FFD9D9D9',
              bold: true,
              fontSize: 10,
            }
          });
          headerColIdx += val !== 'Nữ' ? 2 : 3;
        } else {
          ranges.push({
            row: rowIndex,
            col: headerColIdx,
            val,
            range: `${intToExcelCol(headerColIdx)}${rowIndex}:${intToExcelCol(headerColIdx + 1)}${rowIndex}`,
            style: {
              wrapText: true,
              border: true,
              fill: 'FFD9D9D9',
              bold: true,
              fontSize: 10,
            }
          })
          headerColIdx += 2;
        }
      })
    ranges.push({
      row: rowIndex,
      col: headerColIdx,
      val: 'TỔNG HỢP',
      range: `${intToExcelCol(headerColIdx)}${rowIndex}:${intToExcelCol(headerColIdx + 1)}${rowIndex + 9}`,
      style: { bold: true, border: true }
    },
      {
        row: rowIndex + 10,
        col: headerColIdx,
        val: 'SO VỚI ĐẦU NĂM',
        range: `${intToExcelCol(headerColIdx)}${rowIndex + 10}:${intToExcelCol(headerColIdx + 1)}${rowIndex + 17}`,
        style: { bold: true, border: true, wrapText: true, fontSize: 10, }
      },

      {
        row: rowIndex,
        col: headerColIdx + 2,
        val: '',
        range: `${intToExcelCol(headerColIdx + 2)}${rowIndex}:${intToExcelCol(headerColIdx + 4)}${rowIndex}`,
        style: { fill: 'FFD9D9D9', border: true, fontSize: 10, }
      },

      {
        row: rowIndex + 1,
        col: headerColIdx + 2,
        val: 'Tổng số trẻ cân đo',
        range: `${intToExcelCol(headerColIdx + 2)}${rowIndex + 1}:${intToExcelCol(headerColIdx + 4)}${rowIndex + 1}`,
        style: { fill: 'FFD9D9D9', bold: true, border: true, fontSize: 10, }
      },

      {
        row: rowIndex + 2,
        col: headerColIdx + 2,
        val: 'Cân nặng',
        range: `${intToExcelCol(headerColIdx + 2)}${rowIndex + 2}:${intToExcelCol(headerColIdx + 2)}${rowIndex + 3}`,
        style: { bold: true, border: true, fontSize: 10, }
      },

      {
        row: rowIndex + 4,
        col: headerColIdx + 2,
        val: 'Chiều cao',
        range: `${intToExcelCol(headerColIdx + 2)}${rowIndex + 4}:${intToExcelCol(headerColIdx + 2)}${rowIndex + 5}`,
        style: { bold: true, border: true, fontSize: 10, }
      },

      {
        row: rowIndex + 6,
        col: headerColIdx + 2,
        val: 'Cân nặng theo chiều cao',
        range: `${intToExcelCol(headerColIdx + 2)}${rowIndex + 6}:${intToExcelCol(headerColIdx + 2)}${rowIndex + 9}`,
        style: { bold: true, wrapText: true, border: true, fontSize: 10, }
      },

      {
        row: rowIndex + 10,
        col: headerColIdx + 2,
        val: 'Cân nặng',
        range: `${intToExcelCol(headerColIdx + 2)}${rowIndex + 10}:${intToExcelCol(headerColIdx + 2)}${rowIndex + 11}`,
        style: { bold: true, border: true, fontSize: 10, }
      },

      {
        row: rowIndex + 12,
        col: headerColIdx + 2,
        val: 'Chiều cao',
        range: `${intToExcelCol(headerColIdx + 2)}${rowIndex + 12}:${intToExcelCol(headerColIdx + 2)}${rowIndex + 13}`,
        style: { bold: true, border: true, fontSize: 10, }
      },

      {
        row: rowIndex + 14,
        col: headerColIdx + 2,
        val: 'Cân nặng theo chiều cao',
        range: `${intToExcelCol(headerColIdx + 2)}${rowIndex + 14}:${intToExcelCol(headerColIdx + 2)}${rowIndex + 17}`,
        style: { bold: true, wrapText: true, border: true, fontSize: 10, }
      },
    )
    let tempRowIdx = rowIndex + 2;
    _.forEach(
      [
        { val: 'SDD Nhẹ cân (SDDNC)' },
        { val: 'SDD Nhẹ cân MĐ nặng (SDDNC(n))' },
        { val: 'SDD Thấp còi (TC)' },
        { val: 'SDD Thấp còi MĐ nặng (TC(n))' },
        { val: 'Thừa cân (ThC)' },
        { val: 'Béo phì (BP)' },
        { val: 'SDD thể gầy còm (SDDGC)' },
        { val: 'SDD thể gầy còm MĐ nặng (SDDGC(n))' },
        { val: 'SDD Nhẹ cân (SDDNC)' },
        { val: 'SDD Nhẹ cân MĐ nặng (SDDNC(n))' },
        { val: 'SDD Thấp còi (TC)' },
        { val: 'SDD Thấp còi MĐ nặng (TC(n))' },
        { val: 'Thừa cân (ThC)' },
        { val: 'Béo phì (BP)' },
        { val: 'SDD thể gầy còm (SDDGC)' },
        { val: 'SDD thể gầy còm MĐ nặng (SDDGC(n))' },
      ]
      , ({ val }) => {
        if (val.length >= 20) sheet.getRow(tempRowIdx).height = 26.25;
        else sheet.getRow(tempRowIdx).height = 18.75;
        const border = val === 'SDD thể gầy còm MĐ nặng (SDDGC(n))' ? { top: 'dotted' } : { top: 'dotted', bottom: 'dotted' }
        ranges.push({ row: tempRowIdx, col: 4, val, range: `D${tempRowIdx}:E${tempRowIdx}`, style: { wrapText: true, border } },)
        ranges.push({ row: tempRowIdx, col: headerColIdx + 3, val, range: `${intToExcelCol(headerColIdx + 3)}${tempRowIdx}:${intToExcelCol(headerColIdx + 4)}${tempRowIdx}`, style: { wrapText: true, border } },)
        tempRowIdx += 1;
      })
    _.forEach([
      { val: 'Số trẻ' },
      { val: 'Nữ' },
      { val: 'Tỷ lệ' },
      { val: 'Số trẻ' },
      { val: 'Nữ' },
      { val: 'Tỷ lệ' },
      { val: 'Số trẻ' },
      { val: 'Nữ' },
      { val: 'Tỷ lệ' },
      { val: 'Số trẻ' },
      { val: 'Nữ' },
      { val: 'Tỷ lệ' },
    ], ({ val }) => {
      if (hasBMI) {
        const nextColumn = val !== 'Nữ' ? 1 : 2;
        ranges.push({
          row: rowIndex,
          col: headerColIdx + 5,
          val,
          range: `${intToExcelCol(headerColIdx + 5)}${rowIndex}:${intToExcelCol(headerColIdx + 5 + nextColumn)}${rowIndex}`,
          style: {
            wrapText: true,
            border: true,
            fill: 'FFD9D9D9',
            bold: true,
            fontSize: 10,
          }
        })
        headerColIdx += val !== 'Nữ' ? 2 : 3;
      } else {
        ranges.push({
          row: rowIndex,
          col: headerColIdx + 5,
          val,
          range: `${intToExcelCol(headerColIdx + 5)}${rowIndex}:${intToExcelCol(headerColIdx + 6)}${rowIndex}`,
          style: {
            wrapText: true,
            border: true,
            fill: 'FFD9D9D9',
            bold: true,
            fontSize: 10,
          }
        })
        headerColIdx += 2;
      }
    })
    writeRanges(
      sheet,
      ranges
    )
    rowIndex += 1;
    let totalColIdx = 6;
    const nextColumn = hasBMI ? 2 : 1;
    _.forEach(['9', '10', '11', '12', '1'], (mth) => {
      const counter = _.get(_.find(evaluatedYearStudents, ({ mth: mthData }) => mth === mthData), 'counter', {});
      writeRange(
        sheet,
        rowIndex,
        totalColIdx,
        _.get(counter, 'checked'),
        `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(totalColIdx + 1)}${rowIndex}`,
        { border: true }
      )
      totalColIdx += 2;

      writeRange(
        sheet,
        rowIndex,
        totalColIdx,
        _.get(counter, 'female'),
        `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(totalColIdx + nextColumn)}${rowIndex}`,
        { border: true }
      )
      totalColIdx += hasBMI ? 3 : 2;
      const ratio = (_.get(counter, 'checked', 0) / _.get(counter, 'total', 0)) * 100;
      writeRange(
        sheet,
        rowIndex,
        totalColIdx,
        round(ratio, 2),
        `${intToExcelCol(totalColIdx)}${rowIndex}:${intToExcelCol(totalColIdx + 1)}${rowIndex}`,
        { border: true, numberFormat: '0.00' }
      )
      totalColIdx += 2;
    });
    
    const {  counter: firstMonthCounter,  mth: firstMth } = evaluatedYearStudents.find(({ students }) => Object.keys(students).length) ?? {};
    rowIndex += 1;
    _.forEach([
      'NC1',
      'NC2',
      'TC1',
      'TC2',
      'DC',
      'BP',
      'GC1',
      'GC2',
    ], (key) => {
      let headerColIdx2 = 6;
      _.forEach(['9', '10', '11', '12', '1'], (mth) => {
        const counter = _.get(_.find(evaluatedYearStudents, ({ mth: mthData }) => mth === mthData), 'counter', {});
        const border = key === 'GC2' ? { top: 'dotted' } : { top: 'dotted', bottom: 'dotted' };
        writeRange(
          sheet,
          rowIndex,
          headerColIdx2,
          _.get(counter, key),
          `${intToExcelCol(headerColIdx2)}${rowIndex}:${intToExcelCol(headerColIdx2 + 1)}${rowIndex}`,
          { border }
        );
        if (mth === firstMth) {
          writeRange(
            sheet,
            rowIndex + 8,
            headerColIdx2,
            '',
            `${intToExcelCol(headerColIdx2)}${rowIndex + 8}:${intToExcelCol(headerColIdx2 + 1)}${rowIndex + 8}`,
            { border }
          );
          writeRange(
            sheet,
            rowIndex + 8,
            headerColIdx2 + 2,
            '',
            `${intToExcelCol(headerColIdx2 + 2)}${rowIndex + 8}:${intToExcelCol(headerColIdx2 + 2 + nextColumn)}${rowIndex + 8}`,
            { border }
          );
          writeRange(
            sheet,
            rowIndex + 8,
            headerColIdx2 + 3 + nextColumn,
            '',
            `${intToExcelCol(headerColIdx2 + 3 + nextColumn)}${rowIndex + 8}:${intToExcelCol(headerColIdx2 + 4 + nextColumn)}${rowIndex + 8}`,
            { border }
          );
        } else {
          writeRange(
            sheet,
            rowIndex + 8,
            headerColIdx2,
            _.get(counter, 'checked', 0) ? addPrefix(_.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)) : 0,
            `${intToExcelCol(headerColIdx2)}${rowIndex + 8}:${intToExcelCol(headerColIdx2 + 1)}${rowIndex + 8}`,
            { border }
          );
          writeRange(
            sheet,
            rowIndex + 8,
            headerColIdx2 + 2,
            _.get(counter, 'checked', 0) ? addPrefix(_.get(counter, `F${key}`, 0) - _.get(firstMonthCounter, `F${key}`, 0)) : 0,
            `${intToExcelCol(headerColIdx2 + 2)}${rowIndex + 8}:${intToExcelCol(headerColIdx2 + 2 + nextColumn)}${rowIndex + 8}`,
            { border }
          );
          const comparedRatio = _.get(counter, 'checked') ? Math.abs((_.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)) / _.get(counter, 'total', 0)) : 0;
          writeRange(
            sheet,
            rowIndex + 8,
            headerColIdx2 + 3 + nextColumn,
            round(comparedRatio * 100, 2),
            `${intToExcelCol(headerColIdx2 + 3 + nextColumn)}${rowIndex + 8}:${intToExcelCol(headerColIdx2 + 4 + nextColumn)}${rowIndex + 8}`,
            { border, numberFormat: '0.00' }
          );
        }
        headerColIdx2 += 2;
        writeRange(
          sheet,
          rowIndex,
          headerColIdx2,
          _.get(counter, `F${key}`),
          `${intToExcelCol(headerColIdx2)}${rowIndex}:${intToExcelCol(headerColIdx2 + nextColumn)}${rowIndex}`,
          { border }
        );
        headerColIdx2 += hasBMI ? 3 : 2;
        const ratio = (_.get(counter, key, 0) / _.get(counter, 'total', 0)) * 100;
        writeRange(
          sheet,
          rowIndex,
          headerColIdx2,
          round(ratio, 2),
          `${intToExcelCol(headerColIdx2)}${rowIndex}:${intToExcelCol(headerColIdx2 + 1)}${rowIndex}`,
          { border, numberFormat: '0.00' }
        );
        headerColIdx2 += 2;
      })
      rowIndex += 1;
    })
    let rightRowIdx = allStudentsArr.length + (all ? 9 : 10);
    totalColIdx += 5;
    _.forEach(['2', '3', '4', '5'], (mth) => {
      const counter = _.get(_.find(evaluatedYearStudents, ({ mth: mthData }) => mth === mthData), 'counter', {});
      writeRange(
        sheet,
        rightRowIdx,
        totalColIdx,
        _.get(counter, 'checked'),
        `${intToExcelCol(totalColIdx)}${rightRowIdx}:${intToExcelCol(totalColIdx + 1)}${rightRowIdx}`,
        { border: true }
      )
      totalColIdx += 2;
      writeRange(
        sheet,
        rightRowIdx,
        totalColIdx,
        _.get(counter, 'female'),
        `${intToExcelCol(totalColIdx)}${rightRowIdx}:${intToExcelCol(totalColIdx + nextColumn)}${rightRowIdx}`,
        { border: true }
      )
      totalColIdx += hasBMI ? 3 : 2;
      const ratio = (_.get(counter, 'checked', 0) / _.get(counter, 'total', 0)) * 100;
      writeRange(
        sheet,
        rightRowIdx,
        totalColIdx,
        round(ratio, 2),
        `${intToExcelCol(totalColIdx)}${rightRowIdx}:${intToExcelCol(totalColIdx + 1)}${rightRowIdx}`,
        { border: true, numberFormat: '0.00' }
      );
      totalColIdx += 2;
    });
    rightRowIdx += 1;
    _.forEach([
      'NC1',
      'NC2',
      'TC1',
      'TC2',
      'DC',
      'BP',
      'GC1',
      'GC2',
    ], (key) => {
      let headerColIdx2 = hasBMI ? 46 : 41;
      _.forEach(['2', '3', '4', '5'], (mth) => {
        const counter = _.get(_.find(evaluatedYearStudents, ({ mth: mthData }) => mth === mthData), 'counter', {});
        const border = key === 'GC2' ? { top: 'dotted' } : { top: 'dotted', bottom: 'dotted' };
        writeRange(
          sheet,
          rightRowIdx,
          headerColIdx2,
          _.get(counter, key),
          `${intToExcelCol(headerColIdx2)}${rightRowIdx}:${intToExcelCol(headerColIdx2 + 1)}${rightRowIdx}`,
          { border }
        );
        writeRange(
          sheet,
          rightRowIdx + 8,
          headerColIdx2,
          _.get(counter, 'checked', 0) ? addPrefix(_.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)) : 0,
          `${intToExcelCol(headerColIdx2)}${rightRowIdx + 8}:${intToExcelCol(headerColIdx2 + 1)}${rightRowIdx + 8}`,
          { border }
        );
        headerColIdx2 += 2;
        writeRange(
          sheet,
          rightRowIdx,
          headerColIdx2,
          _.get(counter, `F${key}`),
          `${intToExcelCol(headerColIdx2)}${rightRowIdx}:${intToExcelCol(headerColIdx2 + nextColumn)}${rightRowIdx}`,
          { border }
        );
        writeRange(
          sheet,
          rightRowIdx + 8,
          headerColIdx2,
          _.get(counter, 'checked', 0) ? addPrefix(_.get(counter, `F${key}`, 0) - _.get(firstMonthCounter, `F${key}`, 0)) : 0,
          `${intToExcelCol(headerColIdx2)}${rightRowIdx + 8}:${intToExcelCol(headerColIdx2 + nextColumn)}${rightRowIdx + 8}`,
          { border }
        );
        headerColIdx2 += hasBMI ? 3 : 2;
        const ratio = (_.get(counter, key, 0) / _.get(counter, 'total', 0)) * 100;
        const comparedRatio = _.get(counter, 'checked') ? (_.get(counter, key, 0) - _.get(firstMonthCounter, key, 0)) / _.get(counter, 'total', 0) : 0;
        writeRange(
          sheet,
          rightRowIdx,
          headerColIdx2,
          round(ratio, 2),
          `${intToExcelCol(headerColIdx2)}${rightRowIdx}:${intToExcelCol(headerColIdx2 + 1)}${rightRowIdx}`,
          { border, numberFormat: '0.00' }
        );
        writeRange(
          sheet,
          rightRowIdx + 8,
          headerColIdx2,
          round(comparedRatio * 100, 2),
          `${intToExcelCol(headerColIdx2)}${rightRowIdx + 8}:${intToExcelCol(headerColIdx2 + 1)}${rightRowIdx + 8}`,
          { border, numberFormat: '0.00' }
        );
        headerColIdx2 += 2;
      })
      rightRowIdx += 1;
    })

  });
  return wb.xlsx.writeBuffer().then((data) => {
    if (data) {
      writeReport(new Blob([data]), 'Theo doi suy dinh duong.xlsx', closeHandler);
    }
    else {
      errorHandler("Xuất báo cáo không thành công, vui lòng kiểm tra lại");
      onComplete()
    }
  });
};

export default theodoisdd;
